import React, { Fragment, memo } from "react";
import AttributeNormal from "./AttributeNormal";

export default memo(function index(props) {
  let {  attrNormals, setAttrNormals, brand, setBrand, brandList } = props;
  return (
    <Fragment>
      {/* {console.log("re-render Attribute")} */}
      <AttributeNormal brand={brand} setBrand={setBrand} brandList={brandList} attrNormals={attrNormals} setAttrNormals={setAttrNormals} />
    </Fragment>
  );
});
