import React, { useEffect, useState, lazy, Suspense } from "react";
import { Card, CardHeader, CardTitle, CardBody, CardFooter, Row, Col, Button, Spinner } from "reactstrap";
import { getMarketplacesList } from "services/dataservice";
import zaloraLogo from "assets/img/zalora.jpg";
import magentoLogo from "assets/img/magentologo.png";
const LazadaMarketplace = lazy(() => import("./ProductAdd/Lazada2/LazadaProductAdd"));
const ShopifyMarketplace = lazy(() => import("./ProductAdd/Shopify2/ShopifyProductAdd"));
const ZaloraMarketplace = lazy(() => import("./ProductAdd/Zalora/ZaloraProductAdd"));
const ShopeeMarketplace = lazy(() => import("./ProductAdd/Shopee/ShopeeProductAdd"));

export default function ProductAdd() {
  const [selectedMarketplace, setSelectedMarketplace] = useState({});
  const [marketplaces, setMarketplaces] = useState({});
  const [stores, setStores] = useState([]);
  useEffect(() => {
    const fetchMarketplaceList = async () => {
      try {
        let response = await getMarketplacesList();

        if (response.status === 200) {
          let arrPlatform = [];
          let objPlatform = {};
          let { platformList } = response.data;
          //console.log(platformList);
          platformList.forEach((platform) => {
            let { platformName, name, userMarketPlaceId } = platform;
            if (platformName === "iWeb") return;
            let isPlatformNameExist = arrPlatform.some((p) => p.name === platformName); //if platformName not exist in arrPlatform, it will return false
            if (!isPlatformNameExist) {
              objPlatform[platformName] = [{ label: name, value: userMarketPlaceId }]; // {shopify:[{label:"store_shopify",value:43}]}
              switch (platformName) {
                case "Shopify":
                  arrPlatform.push({ name: platformName, id: 4, logo: "https://cdn.shopify.com/assets/images/logos/shopify-bag.png" }); //[{name:"Shopify",id:"4",logo:"https://............"}]
                  break;
                case "Lazada":
                  arrPlatform.push({ name: platformName, id: 3, logo: "//laz-img-cdn.alicdn.com/images/ims-web/TB1HKyxaMFY.1VjSZFqXXadbXXa.png" });
                  break;
                case "Zalora":
                  arrPlatform.push({ name: platformName, id: 5, logo: zaloraLogo });
                  break;
                case "Zalora (MY)":
                  arrPlatform.push({ name: platformName, id: 6, logo: zaloraLogo });
                  break;
                case "ShopeeV2":
                  arrPlatform.push({ name: platformName, id: 1, logo: "https://vectorise.net/logo/wp-content/uploads/2017/09/Logo-Shopee.png" });
                  break;
                case "Magento":
                  arrPlatform.push({ name: platformName, id: 7, logo: magentoLogo });
                  break;
                default:
                  break;
              }
            } else {
              objPlatform[platformName].push({ label: name, value: userMarketPlaceId });
            }
          });

          setStores(arrPlatform);
          setMarketplaces(objPlatform);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    };
    fetchMarketplaceList();
  }, []);

  const selectStore = (store) => {
    let { name, id } = store;
    setSelectedMarketplace({ name, id });
  };
  const AddProductForm = () => {
    console.log("re-render add product form");
    return (
      <Suspense fallback={<Spinner>Loading...</Spinner>}>
        {(selectedMarketplace.id === 3 && <LazadaMarketplace data={marketplaces.Lazada} />) ||
          // (selectedMarketplace.id === 4 && <ZaloraMarketplace data={marketplaces.Zalora} />) ||
          (selectedMarketplace.id === 4 && <ShopifyMarketplace data={marketplaces.Shopify} />) ||
          (selectedMarketplace.id === 1 && <ShopeeMarketplace data={marketplaces.ShopeeV2} />) ||
          ((selectedMarketplace.id === 5 || selectedMarketplace.id === 6) && <ZaloraMarketplace data={marketplaces.Zalora} />)}
      </Suspense>
    );
  };

  const Stores = stores.map((x) => {
    return (
      <Col md="3" key={x.name}>
        <Card>
          <CardBody>
            <img style={{ maxHeight: "50px" }} alt={x.name} src={x.logo}></img>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="6">
                <strong>{x.name}</strong>
              </Col>
              <Col md="6">
                <Button color="primary" onClick={() => selectStore(x)}>
                  Select
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    );
  });

  return (
    <div className="content">
      {console.log("re-render select marketplace form")}
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Select Marketplace</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>{Stores}</Row>
        </CardBody>
      </Card>
      <AddProductForm />
    </div>
  );
}
