import React, { useEffect, useState } from 'react'
import {
   Col,
   Row,
   Card,
   CardHeader,
   CardBody,
   CardTitle,
   CardFooter,
   Input,
   Button,
   Form,
   FormGroup,
   Label,
   Table
} from 'reactstrap'

import { useTable } from 'react-table'
import SelectAsync from 'react-select/async'
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { Link, useHistory } from "react-router-dom"

import { checkSKU, addOrder, getMarketplace,searchCatalogue, getUserMarketplace, getMarketplace_AllowCreateOrder } from 'services/dataservice'

export default function OrderAdd(prop) {
   const [alert, setAlert] = useState(null);
   const [formData, setFormData] = useState({ country:"Malaysia", items: [] });
   const [skuList, setSKUList] = useState([]);
   const [availableMarketplace, setAvailableMarketPlace] = useState([]);
   const [shopList, setShopList] = useState([]);

   const [orderNumberState, setOrderNumberState] = useState("has-success");
   const [recipientState, setRecipientState] = useState("has-success");
   const [addressState, setAddressState] = useState("has-success");

   const [filter,setFilter]=useState("sku")
   const [optionValue,setOptionValue]=useState("")
   const [options,setOptions]=useState([])

   let history = useHistory();

   useEffect(() => {
      //Load Available Marketplace
      getMarketplace_AllowCreateOrder().then(x => {
         let list = x.data;
         setAvailableMarketPlace(list);
         if (list.length > 0) {
            loadUserMarkerplace(list[0].id);
         }

      })

   }, [])

   const showSuccess = (msg, callBack = null) => {
      setAlert(
         <ReactBSAlert
            success
            style={{ display: "block" }}
            title={msg}
            onConfirm={() => callBack == null ? setAlert(null) : callBack()}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info">
         </ReactBSAlert>
      )
   };


   const showFail = (msg) => {
      setAlert(
         <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title={msg}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info"
            btnSize=""
         >
         </ReactBSAlert>
      )
   }

   const verifySKU = (sku) => {
      showFail('SKU not found');
   }

   const onSubmit = async (e) => {
      //Validate
      if(skuList.length===0){
         showFail("Must have at least one SKU")
         return
      }
      let newFormData = { ...formData, items: skuList.map(x=>{return{skuCode:x.skuCode,orderQuantity:x.orderQuantity}}) };
      console.log(newFormData);
      let validate = true;
      if (!formData.orderNumber) {
         setOrderNumberState("has-danger");
         validate = false;
      }
      if (!formData.recipient) {
         setRecipientState("has-danger");
         validate = false;
      }

      if (validate === false) {
         showFail("Field Validation Failed");
         return;
      }
      try {

         let resp = await addOrder(newFormData);
         console.log(resp);
         if (resp.data.error) {
            showFail(resp.data.description)
         } else {
            let backToList = () => {
               history.push('/admin/order/list');
            }
            showSuccess(`Order Number ${newFormData.orderNumber} submitted`, backToList);
         }
      } catch (e) {
         if (e.response.status === 400)
            showFail(e.response.data.description);
         else
            showFail(e.message);
      }
   };

   const removeElement = (val) => {
      let index = skuList.indexOf(val);
      let newList = [...skuList]
      newList.splice(index, 1);
      setSKUList(newList);
      console.log(newList);
   }

   const closeForm = () => {
      history.goBack();
   }

   const onChangeMarketplace = async (e) => {
      const selectedPlatform = e.target.value;
      setFormData({ ...formData, platform: selectedPlatform });
      loadUserMarkerplace(selectedPlatform);
   }

   const loadUserMarkerplace = async (selectedPlatform) => {
      //Load User MarketName
      const resp = await getUserMarketplace(selectedPlatform);
      setShopList(resp.data);
      if (resp.data.length > 0) {
         setFormData({ ...formData, userMarketplaceId: resp.data[0].id, platform: selectedPlatform })
      }
   }

   const setOrderNumber = (orderNumber) => {
      setOrderNumberState("has-success");
      setFormData({ ...formData, orderNumber: orderNumber })
   }

   const setRecipient = (e) => {
      setRecipientState("has-success");
      setFormData({ ...formData, recipient: (e.target.value) });
   }

   const loadOptions=(e,v)=>{
      if(e.length<3){
         v([])
      }else{
         setTimeout(async()=>{
             try {
               const options=await searchCatalogue(formData.userMarketplaceId,filter,e)
               const res = options.data.map(o=>{
                  return{
                     value:o.marketplaceSKU,
                     label:`${o.marketplaceSKU}-----${o.name}`
                  }
               })
               v(res)
             } catch (error) {
               v([])
             }
         })
      }
   }

   const selectOptionChange=(v)=>{
      document.getElementById("txtSKU").value=v.value
      setOptionValue(v)
   }

   const SKUListView = () => {
      return (
         <Row>
            <Col>
               <Table>
                  <thead className="text-primary">
                     <tr>
                        <th className="text-center">#</th>
                        <th>SKU</th>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th className="text-right">Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        skuList.map((val, index) => {
                           return (
                              <tr key={val.skuCode}>
                                 <td className="text-center">{index + 1}</td>
                                 <td>{val.skuCode}</td>
                                 <td>{val.name}</td>
                                 <td>{val.orderQuantity}</td>
                                 <td className="text-right">
                                    <div className="actions-right">
                                       <Button
                                          id="btnDelete"
                                          size="sm"
                                          className="btn-icon btn-link remove"
                                          color="danger"
                                          onClick={() => {
                                             removeElement(val);
                                          }}>
                                          <i className="fa fa-times" />
                                       </Button>
                                    </div>
                                 </td>
                              </tr>
                           )
                        })
                     }
                  </tbody>
               </Table>
            </Col>
         </Row>
      )
   }

   return (
      <div className="content">
         {alert}
         <Row>
            <Col md="12">
               <Form id="orderAdd">
                  <Card>
                     <CardHeader>
                        <CardTitle tag="h4">
                           Add Order
                           <i onClick={closeForm} style={{ float: 'right', right: '1%' }} className="nc-icon nc-simple-remove" />
                        </CardTitle>

                     </CardHeader>
                     <CardBody>
                        <FormGroup>
                           <label>Platform</label>
                           <Input name="platform"
                              type="select"
                              disabled
                              onChange={(e) => onChangeMarketplace(e)}>
                              {
                                 availableMarketplace.map(x => (<option key={x.id} value={x.id}>{x.platformName}</option>))
                              }
                           </Input>
                        </FormGroup>
                        <FormGroup>
                           <label>Shop Name</label>
                           <Input name="shopName"
                              type="select"
                              disabled
                              onChange={(e) => setFormData({ ...formData, userMarketplaceId: (e.target.value) })}>
                              {
                                 shopList.length > 0 ? shopList.map(x => (<option key={x.id} value={x.id}>{x.name}</option>)) : null
                              }
                           </Input>
                        </FormGroup>
                        <FormGroup className={`has-label ${orderNumberState}`}>
                           <label>Order Number *</label>
                           <Input name="orderNumber"
                              type="text"
                              onChange={(e) => setOrderNumber(e.target.value)}
                              maxLength={20} />
                           {
                              orderNumberState === "has-success" ? null : (<label className="error">This field is required.</label>)
                           }
                        </FormGroup>
                        <FormGroup>
                           <label>Shipping Carrier</label>
                           {/* <Input name="shippingCarrier"
                              type="text"
                              onChange={(e) => setFormData({ ...formData, shippingCourier: (e.target.value) })} /> */}
                           <Input name="shippingCarrier" type="select"
                              onChange={(e)=>setFormData({...formData,shippingCourier: (e.target.value)})}>
                              <option value=""></option>
                              <option value="DHL">DHL</option>
                              <option value="J&T">J&T</option>
                              <option value="SF EXPRESS">SF EXPRESS</option>
                              <option value="SNT">SNT</option>
                           </Input>
                        </FormGroup>
                        <FormGroup className={`has-label ${recipientState}`}>
                           <label>Recipient *</label>
                           <Input name="recipient"
                              type="text"
                              onChange={(e) => setRecipient(e)} />
                           {
                              recipientState === "has-success" ? null : (<label className="error">This field is required.</label>)
                           }
                        </FormGroup>
                        <FormGroup>
                           <label>Contact Number</label>
                           <Input name="contactNumber"
                              type="text"
                              onChange={(e) => setFormData({ ...formData, contactNumber: (e.target.value) })} />
                        </FormGroup>
                        <FormGroup>
                           <label>Address</label>
                           <Input name="address"
                              type="textarea"
                              onChange={(e) => setFormData({ ...formData, address: (e.target.value) })} />
                        </FormGroup>
                        <FormGroup>
                           <label>City</label>
                           <Input name="city"
                              type="text"
                              onChange={(e) => setFormData({ ...formData, district: (e.target.value) })} />
                        </FormGroup>
                        <FormGroup>
                           <label>State</label>
                           <Input name="state"
                              type="text"
                              onChange={(e) => setFormData({ ...formData, state: (e.target.value) })} />
                        </FormGroup>
                        <FormGroup>
                           <label>Postcode</label>
                           <Input name="postcode"
                              type="text"
                              onChange={(e) => setFormData({ ...formData, postcode: (e.target.value) })} />
                        </FormGroup>
                        <FormGroup>
                           <label>Country</label>
                           <Input name="country" type="select"
                              onChange={(e)=>setFormData({...formData,country: (e.target.value)})}>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Singapore">Singapore</option>
                           </Input>
                        </FormGroup>
                        <FormGroup>
                           <label>Amount</label>
                           <Input name="amount"
                              type="number"
                              onChange={(e) => setFormData({ ...formData, totalAmount: (e.target.value) })} />
                        </FormGroup>
                        <FormGroup>
                           <Row>
                              <Col md="1">
                                 <label>Search By</label>
                              </Col>
                              <Col md="1">
                                 <label>SKU:</label>
                                 <input style={{marginLeft:"5px"}} type="radio" name="NameSKU" label="123" value="SKU" onChange={()=>setFilter("sku")} checked={filter==="sku"?true:false}/>
                              </Col>
                              <Col>
                                 <label>Name:</label>
                                 <input style={{marginLeft:"5px"}} type="radio" name="NameSKU" label="123" value="Name" onChange={()=>setFilter("name")} checked={filter==="name"?true:false}/>
                              </Col>
                           </Row>
                           <SelectAsync
                              cacheOptions
                              className="react-select info"
                              classNamePrefix="react-select form-control"
                              //options={options}
                              loadOptions={loadOptions}
                              onChange={selectOptionChange}
                           />
                        </FormGroup>
                        <FormGroup>
                        </FormGroup>
                        <Card>
                           <CardBody>
                              <Row form>
                                 <Col md="9">
                                    <Row>
                                       <Label md="3">SKU</Label>
                                       <Col md="9">
                                          <FormGroup>
                                             <Input id="txtSKU" />
                                          </FormGroup>
                                       </Col>
                                    </Row>
                                 </Col>
                              </Row>
                              <Row form>
                                 <Col md="9">
                                    <Row>
                                       <Label md="3">Quantity</Label>
                                       <Col md="9">
                                          <FormGroup>
                                             <Input id="txtQuantity" type="number" />
                                          </FormGroup>
                                       </Col>
                                    </Row>
                                 </Col>
                                 <Col md="3">
                                    <Button style={{ margin: 0 }}
                                       onClick={
                                          async (e) => {
                                             let sku = document.getElementById("txtSKU").value;
                                             let quantity = document.getElementById("txtQuantity").value;

                                             let val = { sku, quantity };
                                             console.log(val);
                                             if (sku === '') {
                                                showFail('SKU cannot be empty');
                                                return;
                                             }
                                             if (quantity === '') {
                                                showFail('Quantity cannot be empty');
                                                return;
                                             }
                                             try {
                                                let i = skuList.find(x => x.sku === sku);
                                                if (i) {
                                                   showFail(`${sku} already in list`);
                                                   return;
                                                }
                                                let res = await checkSKU(sku);
                                                if (res.data.exist) {
                                                   let lst = [...skuList]
                                                   lst.push({ skuCode: sku,name:res.data.productName, orderQuantity: quantity })
                                                   setSKUList(lst);
                                                }
                                                else {
                                                   showFail(`${sku} not in system`);
                                                }
                                             } catch {
                                                showFail('Connection to server failed');
                                                return;
                                             }

                                             document.getElementById("txtSKU").value = '';
                                             document.getElementById("txtQuantity").value = '';
                                          }
                                       }>Add</Button>
                                 </Col>
                              </Row>
                              <SKUListView />
                           </CardBody>
                        </Card>
                     </CardBody>
                     <CardFooter>
                        <Button onClick={onSubmit}>Submit</Button>
                     </CardFooter>
                  </Card>
               </Form>
            </Col>
         </Row>
      </div>
   )
}