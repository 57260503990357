import React, { useEffect, useState, memo } from "react";
import { CardBody, CardTitle, Table } from "reactstrap";
import { UploadAntd } from "../UploadAntd";
import styles from "../../../Less/required.module.less";

export default memo(function OptionImages(props) {
  let { optionList, optionImages, setOptionImages, count, multiple } = props;

  useEffect(() => {
    // console.log("optionList is changing.........");
    // alert("ok");
    // const updateOptionImages = () => {
    //   let optionImagesClone = { ...optionImages };
    //   optionList[0].value.forEach((item) => {
    //     if (!(item in optionImages)) {
    //       optionImagesClone[item] = [];
    //       console.log(`${item} not in optionImages`);
    //     }
    //   });
    //   setOptionImages(optionImagesClone);
    // };
    // if (optionList.length > 0) {
    //   updateOptionImages();
    //   console.log("optionList is re-rendering");
    // }

    const updateOptionImages = () => {
      let optionImagesClone = { ...optionImages };
      let isUpdate = false;
      for (let key in optionImagesClone) {
        if (!optionList[0].value.includes(key)) {
          delete optionImagesClone[key];
          isUpdate = true;
        }
        if (isUpdate) {
          setOptionImages(optionImagesClone);
        }
      }
    };

    if (optionList.length > 0) {
      updateOptionImages();
    }
  }, [optionList]);
  const [alertSkuMsg, setAlertSkuMsg] = useState(null);
  return (
    <CardBody>
      <CardBody style={{ border: "1px solid #e3e3e3", borderRadius: "8px" }}>
        <CardTitle tag="h6" style={{ textDecoration: "underline" }}>
          Option Images
        </CardTitle>
        {alertSkuMsg}
        {optionList.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th style={{ width: "20%" }}>{optionList[0].name}</th>
                <th>Image</th>
              </tr>
            </thead>
            <tbody>
              {optionList[0].value.map((v, index) => (
                <tr key={index}>
                  {/* {console.log(v)} */}
                  <td>{v}</td>

                  <td className={styles.myUpload}>
                    <UploadAntd
                      data={optionImages[v]}
                      setData={setOptionImages}
                      dataObj={optionImages}
                      name={v}
                      {...(count && { count })}
                      {...(!multiple && { multiple })}
                      variation={true}
                      setMsg={setAlertSkuMsg}
                      index={index}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </CardBody>
    </CardBody>
  );
});
