import React, { useState, useRef, useEffect, useContext, createContext } from "react";

import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { listInventoryForModal } from "services/dataservice";
import { Table, Form, Input } from "antd";
import "./index.css";

export default function PromotionItemsBulkPrimaryModal(props) {
  const [form] = Form.useForm();
  const EditableContext = createContext(null);
  let { isOpen, promotion, setPromotion, setAlert } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredSelectedRowKeys, setFilteredSelectedRowKeys] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const [tableData, setTableData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const toggle = () => {
    setSearchWord("");
    setDataSource(tableData);
    setSelectedRowKeys(
      tableData
        .filter((row) => {
          if (promotion.promotionOption === "1") {
            return props.dataSourceItemsOptionOne.some((source) => row.inventorySKU === source.primary);
          } else {
            return props.dataSourceItemsOptionSecThird.some((source) => row.inventorySKU === source.primary);
          }
        })
        .map((filtered) => filtered.key)
    );
    props.toggle();
  };
  const defaultColumns = [
    {
      key: "sku",
      title: "SKU Code",
      dataIndex: "inventorySKU",
    },
    {
      key: "name",
      title: "Product Name",
      dataIndex: "productName",
    },
    {
      key: "quantity",
      title: "Quantity",
      dataIndex: "quantity",
      editable: true,
    },
  ];
  const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
  };
  const EditableCell = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }) => {
    const inputRef = useRef(null);

    const form = useContext(EditableContext);

    useEffect(() => {
      if (editable) {
        inputRef.current.value = children.length > 0 ? children[1].toString() : 0;
      }
    }, [editable,children]);

    const toggleEdit = () => {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();
        handleSave({
          ...record,
          ...values,
        });
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editable ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} is required.`,
            },
          ]}
          initialValue={children.length > 0 ? children[1].toString() : 0}>
          <Input ref={inputRef} type="number" onBlur={save} />
        </Form.Item>
      ) : (
        children
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const onSelectChange = (newSelectedRowKeys) => {
    if (searchResult.length > 0) {
      let toAdd = newSelectedRowKeys.filter((newSelected) => !selectedRowKeys.some((selected) => selected === newSelected));
      let checkedSearch = searchResult.filter((searchRow) => selectedRowKeys.some((selected) => selected === searchRow.key));
      let toDelete = checkedSearch.filter((selected) => !newSelectedRowKeys.some((newSelected) => selected.key === newSelected));
      let keysToChange = selectedRowKeys;

      if (toDelete.length > 0) {
        keysToChange = keysToChange.filter((row) => !toDelete.some((deletedRow) => deletedRow.key === row));
      }
      if (toAdd.length > 0) {
        keysToChange.push.apply(keysToChange, toAdd);
      }
      setSelectedRowKeys(keysToChange);
      setFilteredSelectedRowKeys(newSelectedRowKeys);
    } else {
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  const rowSelection = {
    selectedRowKeys: searchResult.length === 0 ? [...selectedRowKeys] : [...filteredSelectedRowKeys],
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      name: record.inventorySKU,
    }),
  };

  const handleSave = (row) => {
    const newData = [...tableData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setTableData(newData);
  };
  const isEditing = (record) => selectedRowKeys.includes(record.key);

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: isEditing(record),
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const showFail = (msg) => {
    props.setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""></ReactBSAlert>
    );
  };

  useEffect(() => {
    const loadTable = async () => {
      let listInv = await listInventoryForModal();
      let index = 0;
      let dS = listInv.data.map((res) => ({
        key: index++,
        inventorySKU: res.inventorySKU,
        productName: res.productName,
        quantity: 0,
      }));
      if (promotion.promotionOption === "1") {
        if (props.dataSourceItemsOptionOne.length > 0) {
          setTableData(
            dS.map((x) => {
              var result = props.dataSourceItemsOptionOne.filter((record) => record.primary === x.inventorySKU);
              if (result.length > 0) {
                //checkbox here
                let keysSelected = selectedRowKeys;
                keysSelected.push(x.key);
                setSelectedRowKeys(keysSelected);
              }
              return x;
            })
          );
        } else {
          setTableData(dS);
        }
      } else if (promotion.promotionOption === "2" || promotion.promotionOption === "3") {
        if (props.dataSourceItemsOptionSecThird.length > 0) {
          setTableData(
            dS.map((x) => {
              var result = props.dataSourceItemsOptionSecThird.filter((record) => record.primary === x.inventorySKU);
              if (result.length > 0) {
                x.quantity = result[0].quantity;
                //checkbox here
                let keysSelected = selectedRowKeys;
                keysSelected.push(x.key);
                setSelectedRowKeys(keysSelected);
              }
              return x;
            })
          );
        } else {
          setTableData(dS);
        }
      }
    };
    loadTable();
  }, []);

  useEffect(() => {
    setSelectedRowKeys(tableData.filter((row) => props.dataSourceItemsOptionOne.some((source) => source.primary === row.inventorySKU)).map((row) => row.key));
  }, [props.dataSourceItemsOptionOne]);

  useEffect(() => {
    setTableData(
      tableData.map((row) => {
        var result = props.dataSourceItemsOptionSecThird.filter((record) => record.primary === row.inventorySKU);
        if (result.length > 0) {
          row.quantity = result[0].quantity;
        } else {
          row.quantity = 0;
        }
        return row;
      })
    );
    setSelectedRowKeys(tableData.filter((row) => props.dataSourceItemsOptionSecThird.some((source) => source.primary === row.inventorySKU)).map((row) => row.key));
  }, [props.dataSourceItemsOptionSecThird]);

  const bulkAddPrimary = () => {
    //checking
    let table = tableData;
    let checkedRow = table.filter((row) => selectedRowKeys.some((selectedRow) => selectedRow === row.key));
    //set dataSourceItemsOptionOne and dataSourceItemsOptionSecThird
    if (promotion.promotionOption === "1") {
      props.setDataSourceItemsOptionOne(
        checkedRow.map((product) => ({
          primary: product.inventorySKU,
          productName: product.productName,
        }))
      );
    } else if ((promotion.promotionOption === "2" || promotion.promotionOption === "3") && checkedRow.some((row) => row.quantity === 0 || row.quantity == 0)) {
      showFail("Missing quantity, please check with every product selected");
      return;
    } else if (promotion.promotionOption === "2" || promotion.promotionOption === "3") {
      props.setDataSourceItemsOptionSecThird(
        checkedRow.map((product) => ({
          primary: product.inventorySKU,
          quantity: product.quantity,
          productName: product.productName,
        }))
      );
    }
    setTableData(
      tableData.map((row) => {
        if (!selectedRowKeys.some((selected) => selected === row.key)) row.quantity = 0;
        return row;
      })
    );
    setSearchResult([]);
    promotion["edit"] = true;
    setPromotion({ ...promotion });
    toggle();
    return;
  };

  const searchProduct = (res) => {
    const key = res.key;
    const val = encodeURIComponent(res.target.value);
    if (key === "Enter") {
      if (val) {
        setSearchWord(decodeURI(val));
      } else {
        setSearchWord("");
      }
    }
  };
  useEffect(() => {
    if (searchWord) {
      let search = tableData.filter((row) => row.inventorySKU.toLowerCase().includes(searchWord.toLowerCase()) || row.productName.toLowerCase().includes(searchWord.toLowerCase()));
      setSearchResult(search);
    } else {
      setSearchResult([]);
    }
  }, [searchWord]);

  useEffect(() => {
    if (searchResult.length === 0) {
      setDataSource(tableData);
      setFilteredSelectedRowKeys([]);
    } else {
      let search = tableData.filter((res) => {
        let inventorysku = res.inventorySKU.toLowerCase();
        let productname = res.productName.toLowerCase();
        return inventorysku.includes(searchWord.toLowerCase()) || productname.includes(searchWord.toLowerCase());
      });
      setSearchResult(search);
    }
  }, [tableData]);

  useEffect(() => {
    if (searchWord) {
      if (searchResult.length > 0) {
        setDataSource(searchResult);
        setFilteredSelectedRowKeys(selectedRowKeys.filter((selectedKeys) => searchResult.some((searchRow) => searchRow.key === selectedKeys)));
      } else {
        setDataSource([]);
        setFilteredSelectedRowKeys([]);
      }
    } else {
      setDataSource(tableData);
      setFilteredSelectedRowKeys([]);
    }
  }, [searchResult]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static" size="xl">
      <ModalHeader toggle={toggle}>Bulk Add Primary Product</ModalHeader>
      <ModalBody>
        <Row>
          {/* search bar and {option === 1 && <input>qty and <button> apply all} */}
          <Col
            md="6"
            lg="6"
            style={{
              margin: "auto",
            }}>
            <Input type="text" id="search_bar_modal" onKeyDown={searchProduct} placeholder="Search by SKU/product name" />
          </Col>
        </Row>
        {/* render a table */}
        <Form form={form} component={false} size="large">
          <Table
            components={components}
            rowSelection={{ ...rowSelection }}
            columns={promotion.promotionOption !== "1" ? columns : columns.filter((col) => col.dataIndex !== "quantity")}
            dataSource={dataSource}
            size="small"
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={bulkAddPrimary}>Submit</Button>
        <Button onClick={toggle}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}
