import React, {useState} from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';

import {changePassword} from 'services/dataservice'
import { Form, FormGroup, Label, Input, Card, CardBody, CardFooter, CardHeader, CardTitle, Button } from 'reactstrap'
import { useHistory } from 'react-router';

export default function ChangePassword(props) {
   const [currentPassword, setCurrentPassword] = useState("");
   const [newPassword, setNewPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState("");
   const [alert, setAlert] = useState(null);

   const history = useHistory();

   const showSuccess = (msg, callBack = null) =>{
      setAlert(
         <ReactBSAlert
            success
            style={{display:"block"}}
            title={msg}
            onConfirm={() => callBack == null ? setAlert(null) : callBack()}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info">
            </ReactBSAlert>
      )
   };
   const showFail = (msg) => {
      setAlert(
         <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={msg}
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          btnSize=""
        >
        </ReactBSAlert>
      )
   };

   const onSubmit = async () => {
      if (newPassword != confirmPassword){
         showFail("New Password and Confirm Password mismatch");
         return;
      }
      if (!newPassword){
         showFail("New Password cannot be empty");
         return;
      }
      
      try {
         const resp = await changePassword(currentPassword, newPassword);
         //console.log(resp);
         if (resp.data.success){
            showSuccess("Password Changed", 
            () => {
               history.push("/admin/order/list");
            });
         }
         else{
            showFail(resp.data.msg);
         }

      }
      catch (err) {
         console.log(err);
      }
   }

   return (
      <div class="content">
         {alert}
         <Card>
            <CardBody>
               <Form>
                  <FormGroup>
                     <Label for="currentPassword">Current Password</Label>
                     <Input type="password" name="currentPassword" onChange={(e) => setCurrentPassword(e.target.value)}></Input>
                  </FormGroup>
                  <FormGroup>
                     <Label for="newPassword">New Password</Label>
                     <Input type="password" name="newPassword" onChange={(e) => setNewPassword(e.target.value)}></Input>
                  </FormGroup>
                  <FormGroup>
                     <Label for="confirmPassword">Confirm Password</Label>
                     <Input type="password" name="confirmPassword" onChange={(e) => setConfirmPassword(e.target.value)}></Input>
                  </FormGroup>
               </Form>
            </CardBody>
            <CardFooter>
               <Button onClick={() => onSubmit()}>Submit</Button>

            </CardFooter>

         </Card>
         
      </div>
   )
}