import React, { useEffect, useMemo, useRef, useState } from "react";

import { Link, useHistory, Route, useRouteMatch, Switch } from "react-router-dom";
import { Badge, Col, Row, Card, CardHeader, CardBody, CardTitle, Nav, NavItem, NavLink, Button, Input, Alert } from "reactstrap";

import Select from "react-select";
import ReactTable from "components/ReactTable/ReactTable.js";
import { getOrdersStatusCount, getOrderList, getMarketplaceList, getOrderListByOrderNumbers, getOrderListBySKU } from "services/dataservice";
import { Fragment } from "react";
import OrderDetail from "./OrderDetail";
import moment from "moment";
import "./OrderList.css";
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { getAWBByOrderNumber } from "services/dataservice";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getTokenInfo } from "services/dataservice";
import { updateOrderTracking } from "services/dataservice";

export default function OrderList(prop) {
  let { path, url } = useRouteMatch();

  const [tabs, setTabs] = useState("New");
  const columns = useMemo(() => [
    {
      Header: "Order",
      accessor: "orderNumber",
      Cell: ({ row }) => <Link to={`${path}/detail/${row.original.orderId}`}>{row.original.orderNumber}</Link>,
      minWidth: 250,
    },
    {
      Header: "Store Name",
      accessor: "storeName",
      minWidth: 350,
    },
    {
      Header: "Amount",
      accessor: "totalAmount",
    },
    {
      Header: "Shipping Carrier",
      accessor: "shippingCourier",
      style: { whiteSpace: "unset" },
    },
    {
      Header: "Marketplace Status",
      accessor: "marketplaceStatus",
    },
    /*{
         Header: "System Status",
         accessor: "orderStatus",
         hiddenColumns: true
      },*/
    {
      Header: "Order Date",
      accessor: "orderDate",
      sortType: (rowA, rowB, id) => {
        const dateFormat = "DD/MM/YYYY";
        const rowAUnix = moment(rowA.original[id], dateFormat).unix();
        const rowBUnix = moment(rowB.original[id], dateFormat).unix();

        if (rowAUnix > rowBUnix) return -1;
        if (rowBUnix > rowAUnix) return 1;
        return 0;
      },
    },
    {
      Header: "Pending Since",
      accessor: "pendingSince",
      disableFilters: true,
      sortType: (rowA, rowB, id, desc) => {
        //use previous column for sorting
        //const newId = id - 1;
        const dateFormat = "DD/MM/YYYY";
        const rowAUnix = moment(rowA.original.orderDate, dateFormat).unix();
        const rowBUnix = moment(rowB.original.orderDate, dateFormat).unix();

        if (rowAUnix > rowBUnix) return -1;
        if (rowBUnix > rowAUnix) return 1;
        return 0;
      },
    },
    { Header: "Actions", accessor: "action", disableFilters: true, maxWidth: 100, width: 50 },
    /*{
         Header: "Actions",
         accessor: "action"
      }*/
  ]);

  const [tableData, setTableData] = useState([]);

  const [orderStatusCount, setOrderStatusCount] = useState({
    newOrder: 0,
    readyToShip: 0,
    shipped: 0,
    delivered: 0,
    returnAndExchange: 0,
    cancelled: 0,
    unpaid: 0,
    hold: 0,
    partial: 0,
    search: 0,
  });
  const [stores, setStores] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [alert, setAlert] = useState(null)

  const [searchResults, setSearchResults] = useState([]);
  const [tokenInfo, setTokenInfo] = useState([]);

  const divRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    const load = async () => {
      const storesResp = await getMarketplaceList();
      const stores = storesResp.data.platformList.map((x) => ({ value: x.userMarketPlaceId, label: x.name }));
      setStores(stores);
    };
    load();
  }, []);

  useEffect(() => {
    if (tabs === "Search") {
      setTableData(searchResults);
      return;
    }

    const load = async () => {
      let filter = "";
      if (selectedStores) {
        filter = selectedStores.map((x) => x.value).join(",");
      }
      try {
        const respCount = await getOrdersStatusCount(filter);
        const countData = respCount.data;
        setOrderStatusCount({ ...orderStatusCount, ...countData });
        const resp = await getOrderList(tabs, filter);
        const data = resp.data;
        data.forEach((x) => (x.orderStatus = x.orderStatus.replace(/_/g, " ")));
        setTableData(
          data.map((res, index) => {
            return {
              ...res,
              /*action: (
                     <div className="actions-right">
                        <Button id="btnEdit"
                           size="sm"
                           className="btn-icon btn-link edit"
                           color="primary"
                           onClick={() => history.push(`${path}/detail/${res.orderId}`)}>
                           <i className="fa fa-edit"></i>
                        </Button>
                     </div>
                  )*/
              ...(res.updateTracking && {
                action: (
                <div className="actions-right">
                  <Button id="btnPrint" size="sm" color="primary" title="update tracking number" className="btn-icon btn-link print" onClick={() => onUpdateTracking(res.orderId,res.orderNumber)}>
                    <i className="fa fa-edit"></i>
                  </Button>
                </div>
              ),
            }),
              ...(res.awbUrl && {
                action: (
                  <div className="actions-right">
                    <Button id="btnPrint" size="sm" color="primary" title="print awb" className="btn-icon btn-link print" onClick={() => onSearchAWB(res.orderId)}>
                      <i className="fa fa-print"></i>
                    </Button>
                  </div>
                ),
              }),
            };
          })
        );
      } catch {
        //history.push("/auth/login");
      }
    };

    load();
  }, [tabs, history, selectedStores]);
  const updateOrderTrackingMutation = useMutation(updateOrderTracking,{})

  useQuery({
    queryKey: ["token-info"],
    queryFn: () => getTokenInfo(),
    onSuccess: (data) => {
      setTokenInfo(data.data.data);
    },
  });

  function onChangeSelectedStores(val) {
    setSelectedStores(val);
  }

  const onSearchBySKUTriggerd = async (res) => {
    const key = res.key;
    const val = encodeURIComponent(res.target.value);
    if (key === "Enter") {
      if (val) {
        const resp = await getOrderListBySKU(val);
        setTabs("Search");
        setOrderStatusCount({ ...orderStatusCount, search: resp.data.length });
        setTableData(
          resp.data.map((res, index) => {
            return {
              ...res,
            };
          })
        );
        setSearchResults(
          resp.data.map((res, index) => {
            return {
              ...res,
            };
          })
        );
      }
    }
  };

  const onSearchByOrderTriggered = async (res) => {
    const key = res.key;
    const val = encodeURIComponent(res.target.value);

    if (key === "Enter") {
      if (val) {
        const resp = await getOrderListByOrderNumbers(val);
        console.log(resp);
        setTabs("Search");
        setOrderStatusCount({ ...orderStatusCount, search: resp.data.length });
        setTableData(
          resp.data.map((res, index) => {
            return {
              ...res,
            };
          })
        );
        setSearchResults(
          resp.data.map((res, index) => {
            return {
              ...res,
            };
          })
        );
      }
    }
  };

  const showSuccess = (msg, callBack = null) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => (callBack == null ? setAlert(null) : callBack())}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"></ReactBSAlert>
    );
  };

  const onUpdateTracking = async (orderId,orderNumber) => {
    setAlert(
      <ReactBSAlert
         input
         showCancel
         style={{ display: "block" }}
         title={"Update Tracking Number: " + orderNumber}
         onConfirm={(val)=>{
          updateOrderTrackingMutation.mutate({orderId,trackingNumber:val},
            {
              onSuccess:(data)=> showSuccess(data.data.message),
              onError:()=>showSuccess("Error")
            })
         }}
         onCancel={() => setAlert(null)}
         confirmBtnBsStyle="info"
         cancelBtnBsStyle="danger"
         btnSize=""
      >
      </ReactBSAlert>);
  };

  const onSearchAWB = async (ordNbr) => {
    try {
      const response = await getAWBByOrderNumber(ordNbr);
      const { data } = response;
      window.open(data, "_blank");
    } catch (error) {
      const { data } = error.response;
      alert(data);
    }
  };

  //Order Details Modal handling
  const Modal = (content) => {
    let scrollTop = 0;

    if (divRef.current) {
      divRef.current.style.position = "fixed";
      scrollTop = divRef.current.parentElement.scrollTop;
      divRef.current.parentElement.style.top = `-${scrollTop}px`;
    }

    const back = () => {
      history.push(path);
      if (divRef.current) {
        console.log(scrollTop);
        divRef.current.style.position = "";
        divRef.current.parentElement.style.top = "";
        setTimeout(() => {
          divRef.current.parentElement.scrollTop = scrollTop;
        }, 1);
      }
    };

    return (
      <div role="button" className="modal-wrapper" onMouseDown={(e) => back()}>
        <div className="custom-modal" onMouseDown={(e) => e.stopPropagation()}>
          {content}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="content" style={{ minWidth: "1200px" }} ref={divRef}>
        {alert}
        {tokenInfo.length>0 && <Alert color="danger">
          <ul style={{margin:0}}>
            {tokenInfo.map((x) => (
              <li>{x} token will expires in 30 days, please reauthenticate</li>
            ))}
          </ul>
        </Alert>}
        <Row className="row justify-content-end">
          <Col md="4" className="d-flex flex-row-reverse">
            <Link to="/admin/order/add">
              <Button color="primary">Add</Button>
            </Link>
            <Link to="/admin/order/upload">
              <Button color="primary">Upload</Button>
            </Link>
            <Link to="/admin/order/export">
              <Button color="primary">Export</Button>
            </Link>
            {/* <Link to="/admin/order/print"><Button color="primary">Print</Button></Link> */}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="card-subcategories">
              <CardHeader>
                <CardTitle tag="h4">Orders</CardTitle>
              </CardHeader>
              <CardBody>
                <div>
                  <Row>
                    <Col md="6">
                      <Input placeholder="Search By SKU" onKeyDown={onSearchBySKUTriggerd}></Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Input placeholder="Search By Order Number" onKeyDown={onSearchByOrderTriggered}></Input>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select form-control"
                        placeholder="Choose Store"
                        name="multipleSelect"
                        options={stores}
                        isMulti
                        value={selectedStores}
                        onChange={(val) => onChangeSelectedStores(val)}
                      />
                    </Col>
                  </Row>

                  <div>
                    <Nav className="nav-pills-primary nav-pills-icons justify-content-right" pills role="tablist">
                      <NavItem>
                        <NavLink aria-expanded={tabs === "New"} data-toggle="tab" href="#" onClick={() => setTabs("New")} className={tabs === "New" ? "active" : ""}>
                          New <Badge color="default">{orderStatusCount.newOrder}</Badge>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink aria-expanded={tabs === "Unpaid"} data-toggle="tab" href="#" onClick={() => setTabs("Unpaid")} className={tabs === "Unpaid" ? "active" : ""}>
                          Unpaid <Badge color="default">{orderStatusCount.unpaid}</Badge>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink aria-expanded={tabs === "Hold"} data-toggle="tab" href="#" onClick={() => setTabs("Hold")} className={tabs === "Hold" ? "active" : ""}>
                          Hold <Badge color="default">{orderStatusCount.hold}</Badge>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink aria-expanded={tabs === "Partial"} data-toggle="tab" href="#" onClick={() => setTabs("Partial")} className={tabs === "Partial" ? "active" : ""}>
                          Partial <Badge color="default">{orderStatusCount.partial}</Badge>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink aria-expanded={tabs === "Ready_To_Ship"} data-toggle="tab" href="#" onClick={() => setTabs("Ready_To_Ship")} className={tabs === "Ready_To_Ship" ? "active" : ""}>
                          Ready To Ship <Badge color="default">{orderStatusCount.readyToShip}</Badge>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink aria-expanded={tabs === "Shipped"} data-toggle="tab" href="#" onClick={() => setTabs("Shipped")} className={tabs === "Shipped" ? "active" : ""}>
                          Shipped <Badge color="default">{orderStatusCount.shipped}</Badge>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink aria-expanded={tabs === "Delivered"} data-toggle="tab" href="#" onClick={() => setTabs("Delivered")} className={tabs === "Delivered" ? "active" : ""}>
                          Delivered <Badge color="default">{orderStatusCount.delivered}</Badge>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink aria-expanded={tabs === "Cancelled"} data-toggle="tab" href="#" onClick={() => setTabs("Cancelled")} className={tabs === "Cancelled" ? "active" : ""}>
                          Cancelled <Badge color="default">{orderStatusCount.cancelled}</Badge>
                        </NavLink>
                      </NavItem>
                      {/*<NavItem>
                                    <NavLink
                                       aria-expanded={tabs === "ReturnAndExchange"}
                                       data-toggle="tab"
                                       href="#"
                                       onClick={() => setTabs("ReturnAndExchange")}
                                       className={
                                          tabs === "ReturnAndExchange" ? "active" : ""
                                       }>
                                       Return & Exchange <Badge color="default">{orderStatusCount.returnAndExchange}</Badge>
                                    </NavLink>
                                    </NavItem>*/}
                      <NavItem>
                        <NavLink aria-expanded={tabs === "Search"} data-toggle="tab" href="#" onClick={() => setTabs("Search")} className={tabs === "Search" ? "active" : ""}>
                          Search <Badge color="default">{orderStatusCount.search}</Badge>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <ReactTable data={tableData} columns={columns} className="-striped -highlight primary-pagination" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Switch>
        <Route
          path={`${path}/detail/:id`}
          render={(props) => {
            {
              return Modal(<OrderDetail {...props}></OrderDetail>);
            }
          }}></Route>
      </Switch>
    </Fragment>
  );
}
