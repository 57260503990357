import React, { useEffect, useRef, useState } from 'react'
import {
   Button,
   Container,
   Row,
   Col, Card, CardHeader, CardTitle, CardBody, CardFooter,
   UncontrolledTooltip
} from 'reactstrap'
import { Link, Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import { getMarketplaceList, syncInventory,syncInventoryByUserMarketplaceId } from 'services/dataservice';
import Notify from 'react-notification-alert';

import { EditStore } from './EditStore';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';



let date = new Date()
let dateString = date.toDateString()

//const stores = [{id: 1, platformName:"Shopee", name:"Shopee test shop", createdOn: dateString, lastInventorySync: dateString}]
export default function Integration(props) {
   const [stores, setStores] = useState([]);
   const [enableSync, setEnableSync] = useState(false)
   const history = useHistory();

   const [syncing,setSyncing]=useState(false)

   const notify = useRef(null);
   let { path, url } = useRouteMatch();

   useEffect(() => {
      async function fetchData() {
         let resp = await getMarketplaceList();
         setStores(resp.data.platformList);
      }
      fetchData();
   }, []);

   useEffect(() => {
      function toggleSync(e) {
         if (e.key === "."){
            setEnableSync(!enableSync);
         }
      }

      document.addEventListener("keydown", toggleSync)
      return () => {
         document.removeEventListener("keydown", toggleSync);
      }
   }, [enableSync])

   
   
   const Store = (props) => {

      function onClickDelete() {

      }

      async function onClickEdit() {
         history.push(`/admin/integration/edit/${props.userMarketPlaceId}`)
      }

      async function onClickSync(e) {
         let id = props.userMarketPlaceId;
         let resp = await syncInventory(id, false);

         if (resp.status === 200) {
            var options = {
               place: "br",
               message: `${props.name} Inventory Sync Submitted`,
               type: "success",
               autoDismiss: 5,
               icon: ""
            };
            e.target.disabled = true;
            notify.current.notificationAlert(options);
         }
         console.log(resp);
      }

      async function onClickInventorySync(e){
         let id = props.userMarketPlaceId;
         let resp = await syncInventoryByUserMarketplaceId(id, false);

         if (resp.status === 200) {
            var options = {
               place: "br",
               message: `${props.name}Sync SKU Submitted`,
               type: "success",
               autoDismiss: 5,
               icon: ""
            };
            //e.target.disabled = true;
            notify.current.notificationAlert(options);
         }
      }

      return (
         <Col lg="4" md="4" sm="12">
            <Card>
               <CardBody>
                  <Row>
                     <Col><strong>Platform Name: </strong></Col>
                     <Col>{props.platformName}</Col>
                  </Row>
                  <Row>
                     <Col>Name: </Col>
                     <Col>{props.name}</Col>
                  </Row>
                  <Row>
                     <Col>Created On: </Col>
                     <Col>{props.createdOn}</Col>
                  </Row>
                  <Row>
                     <Col>Last Inventory Sync: </Col>
                     <Col>{props.lastInventorySync}</Col>
                  </Row>
                  {
                     props.syncingInventory ?
                        <UncontrolledTooltip placement="right" target={'Tooltip-' + props.userMarketPlaceId}>
                           Inventory Sync In Progress
                        </UncontrolledTooltip> : null
                  }
                  {/*<Row>
                     <Col>Last Order Sync: </Col>
                     <Col>{props.lastOrderSync}</Col>
                  </Row>*/}
                  <ButtonGroup className="mb-2">
                     <div id={'Tooltip-' + props.userMarketPlaceId}>
                     {
                     enableSync ? 
                     <Button color="primary" onClick={onClickSync} disabled={props.syncingInventory || syncing}>Sync</Button>
                     : null
                     }
                     </div> 
                     <Button color="default" onClick={onClickInventorySync}>Sync SKU</Button>
                     <Button color="primary" onClick={onClickEdit}>Edit</Button>
                     <Button color="danger" onClick={onClickDelete}>Delete</Button>
                  </ButtonGroup>
               </CardBody>
            </Card>
         </Col >
      )
   }

   async function syncInventory_All() {
      setSyncing(true)
      let resp = await syncInventory(0, true);

      if (resp.status === 200) {
         var options = {
            place: "br",
            message: `Inventory Sync Submitted`,
            type: "success",
            autoDismiss: 5,
            icon: ""
         };
         notify.current.notificationAlert(options);
      }
   }

   return (
      <div className="content">
         <Notify ref={notify} />
         <Row>
            <Col>
               <Link to="/admin/store/add" style={{ float: 'right' }}>
                  <Button color="primary">Add Store</Button>
               </Link>
               {
                  enableSync ? <Button color="primary" onClick={syncInventory_All} disabled={syncing}>Sync Inventory</Button> : null
               }
               
            </Col>
         </Row>
         <Row>
            {
               stores.map(x => {
                  return (<Store key={x.userMarketPlaceId}
                     userMarketPlaceId={x.userMarketPlaceId}
                     platformName={x.platformName}
                     name={x.name}
                     createdOn={x.createdOn}
                     lastInventorySync={x.lastInventorySync}
                     lastOrderSync={x.lastOrderSync}
                     syncingInventory={x.syncingInventory} />)
               })
            }
         </Row>
      </div>

   )
}