import React, { useEffect, useState } from 'react'
import {
   Form,
   FormGroup,
   Label,
   Input,
   Card,
   CardBody,
   CardHeader,
   Table,
   Row,
   Col,
   Button,
   CardFooter
} from 'reactstrap'

import { createKit, getKit, editKit, checkSKU,searchCatalogue, getMarketplaceList } from 'services/dataservice';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import SelectAsync from 'react-select/async'


export default function CatalogueKit(props) {
   const [skuList, setSKUList] = useState([])
   const [marketplaceSKU, setMarketplaceSKU] = useState("");
   const [marketplaceName, setMarketplaceName] = useState("");
   const [userMarketplaceId, setUserMarketplaceId] = useState(0);
   const [price, setPrice] = useState(0);
   const [userMarketplaceList, setUserMarketplaceList] = useState([]);
   const [alert, setAlert] = useState(null);
   const [mode, setMode] = useState("new");

   useEffect(() => {

      const catalogueId = props.match.params.id;
      console.log (catalogueId)
      if (catalogueId){
         setMode("edit");
         getKit(catalogueId).then(res => {
            const data = res.data;
            setMarketplaceSKU(data.kitSKU);
            setMarketplaceName(data.kitName);
            setPrice(data.price);
            const newList = data.inventoryList.map((res, idx) => {
               return {
                  skuCode: res.inventorySKU,
                  name:res.productName,
                  quantity: res.kitQuantity,
                  inventoryId: res.inventoryId
               }
            })
            setSKUList(newList);
            console.log(res);
         });
      }

      getMarketplaceList().then(res => {
         setUserMarketplaceList(res.data.platformList);
         setUserMarketplaceId(res.data.platformList[0].userMarketPlaceId)
      });
   }, [])

   const removeElement = (val) => {
      let index = skuList.indexOf(val);
      let newList = [...skuList]
      newList.splice(index, 1);
      setSKUList(newList);
      console.log(newList);
   }

   const showSuccess = (msg, callBack = null) => {
      setAlert(
         <ReactBSAlert
            success
            style={{ display: "block" }}
            title={msg}
            onConfirm={() => callBack == null ? setAlert(null) : callBack()}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info">
         </ReactBSAlert>
      )
   };


   const showFail = (msg) => {
      setAlert(
         <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title={msg}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info"
            btnSize=""
         >
         </ReactBSAlert>
      )
   }

   const onAddSKU = async (e) => {
      let sku = document.getElementById("txtSKU").value;
      let quantity = document.getElementById("txtQuantity").value;

      let pass = quantity.match(/^\d+$/);
      if (!pass) {
         return;
      }


      if (!sku) {
         return;
      }
      if (!quantity) {
         return;
      }
      //Validate and get inventoryId

      const resp = await checkSKU(sku);
      if (resp.data.exist) {
         let inventoryId = resp.data.inventoryId;
         let productName=resp.data.productName
         let newSkuList = [...skuList];
         newSkuList.push({
            skuCode: sku,
            name:productName,
            inventoryId,
            quantity
         });
         setSKUList(newSkuList);

      } else {
         showFail("Invalid SKU");
      }

   }

   const onSubmit = async (e) => {
      try {
         console.log(userMarketplaceId);

         let resp = {};

         if (mode === "new"){
            resp = await createKit({
               kitSKU: marketplaceSKU,
               kitName: marketplaceName,
               userMarketplaceId: userMarketplaceId,
               inventoryList: skuList,
               price: price
            });
         }
         else if (mode === "edit"){
            resp = await editKit({
               catalogueId: props.match.params.id,
               kitSKU: marketplaceSKU,
               kitName: marketplaceName,
               userMarketplaceId: userMarketplaceId,
               inventoryList: skuList,
               price: price
            });
            console.log(resp)
         }
         else{
            resp = {
               data : {
                  code : 500
               }
            };
         }
         

         if (resp.data.code === 500) {
            showFail(resp.data.description);
         } else {
            showSuccess("Submitted");
         }
      }
      catch (err) {

      }
   }


   const SKUListView = () => {
      return (
         <Row>
            <Col>
               <Table>
                  <thead className="text-primary">
                     <tr>
                        <th className="text-center">#</th>
                        <th>SKU</th>
                        <th>Name</th>
                        <th>Quantity</th>
                        <th className="text-right">Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        skuList.map((val, index) => {
                           return (
                              <tr key={val.skuCode}>
                                 <td className="text-center">{index + 1}</td>
                                 <td>{val.skuCode}</td>
                                 <td>{val.name}</td>
                                 <td>{val.quantity}</td>
                                 <td className="text-right">
                                    <div className="actions-right">
                                       <Button
                                          id="btnDelete"
                                          size="sm"
                                          className="btn-icon btn-link remove"
                                          color="danger"
                                          onClick={() => {
                                             removeElement(val);
                                          }}>
                                          <i className="fa fa-times" />
                                       </Button>
                                    </div>
                                 </td>
                              </tr>
                           )
                        })
                     }
                  </tbody>
               </Table>
            </Col>
         </Row>
      )
   }

   return (
      <div className="content">
         {alert}

         <Card>
            <CardHeader>

            </CardHeader>
            <CardBody>
               <Form>
                  <FormGroup>
                     <Label for="marketplaceSKU">Kit SKU</Label>
                     <Input type="text" onChange={(e) => setMarketplaceSKU(e.target.value)} value={marketplaceSKU} disabled={mode === "edit"}/>
                  </FormGroup>
                  <FormGroup>
                     <Label for="marketplaceName">Kit Name</Label>
                     <Input type="text" onChange={(e) => setMarketplaceName(e.target.value)} value={marketplaceName} />
                  </FormGroup>
                  <FormGroup>
                     <Label for="marketplaceName">Price</Label>
                     <Input  type="number" step="0.01" value={price} onChange={(e) => setPrice(e.target.value)} />
                  </FormGroup>
               </Form>
            </CardBody>
            <CardFooter>
               <Button onClick={onSubmit}>Submit</Button>
            </CardFooter>

         </Card>
         <Card>
            <CardBody>
               <Row form style={{marginBottom:"10px"}}>
                  <Col>
                     <SKUSearch id={userMarketplaceId} />
                  </Col>
               </Row>
               <Row form>
                  <Col md="9">
                     <Row>
                        <Label md="3">SKU</Label>
                        <Col md="9">
                           <FormGroup>
                              <Input id="txtSKU" />
                           </FormGroup>
                        </Col>
                     </Row>
                  </Col>
               </Row>
               <Row form>
                  <Col md="9">
                     <Row>
                        <Label md="3">Quantity</Label>
                        <Col md="9">
                           <FormGroup>
                              <Input type="number" id="txtQuantity" />
                           </FormGroup>
                        </Col>
                     </Row>
                  </Col>
                  <Col md="3">
                     <Button style={{ margin: 0 }} onClick={onAddSKU}>Add</Button>
                  </Col>
               </Row>
               <SKUListView />
            </CardBody>
         </Card>
      </div>
   )
}

const SKUSearch=({id})=>{
   const[filter,setFilter]=useState("sku")
   const[options,setOptions]=useState([])

   const loadOptions=(e,v)=>{
      if(e.length<3){
         v([])
      }else{
         setTimeout(async()=>{
             try {
               const options=await searchCatalogue(id,filter,e)
               const res = options.data.map(o=>{
                  return{
                     value:o.marketplaceSKU,
                     label:`${o.marketplaceSKU}-----${o.name}`
                  }
               })
               v(res)
               setOptions(res)
             } catch (error) {
               v([])
             }
         })
      }
   }

   const selectOptionChange=(v)=>{
      document.getElementById("txtSKU").value=v.value
   }
   return(<FormGroup>
      <Row>
         <Col md="1">
            <label>Search By</label>
         </Col>
         <Col md="1">
            <label>SKU:</label>
            <input style={{marginLeft:"5px"}} type="radio" name="NameSKU" label="123" value="SKU" onChange={()=>setFilter("sku")} checked={filter==="sku"?true:false}/>
         </Col>
         <Col>
            <label>Name:</label>
            <input style={{marginLeft:"5px"}} type="radio" name="NameSKU" label="123" value="Name" onChange={()=>setFilter("name")} checked={filter==="name"?true:false}/>
         </Col>
      </Row>
      <SelectAsync
         cacheOptions
         className="react-select info"
         classNamePrefix="react-select form-control"
         //options={options}
         loadOptions={loadOptions}
         defaultOptions={options}
         onChange={selectOptionChange}
      />
   </FormGroup>)
 }