/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { isAuthorize } from "services/dataservice"

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.2.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Component } from "react";
import { useAuth } from "services/auth";

import { QueryClient,QueryClientProvider } from "@tanstack/react-query";

const hist = createBrowserHistory();
const queryClient=new QueryClient({
  defaultOptions:{
    queries:{
      refetchOnWindowFocus:false
    }
  }
})
function App() {

  const [isAuthenticate, setAuth] = useState(true);

  /*useEffect(() => {
    isAuthorize()
      .then(res => {
        if (res.status === 200) {
          setAuth(true);
        }
      })
      .catch(err => {
        setAuth(false);
      });
  })*/

  const GuardedRoute = ({ component: Component, auth, ...rest }) => {

    const isAuth = useAuth()
    //console.log(isAuth);

    return (<Route {...rest} render={(props) => (
      isAuth === true ?
        <Component {...props} />
        : <Redirect to="/auth/login" />
    )} />)
  }

  return (
    <Router history={hist}>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <GuardedRoute path="/admin" auth={isAuthenticate} component={(props) => <AdminLayout {...props} />} />
          <Redirect to="/admin/order/list" />
        </Switch>
      </QueryClientProvider>
    </Router>

  )
}



ReactDOM.render(
  <App />,
  document.getElementById("root")
);
