import React, { memo } from "react";
import { Button, CardBody, CardTitle, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Input as InputAntd } from "antd";
import { SelectAntd } from "views/product/ProductAdd/Components/AntdComponent";
import styles from "../../../../Less/required.module.less"

export default memo(function AttributeNormal(props) {
  let { brand, setBrand, brandList, attrNormals, setAttrNormals } = props;
  // const antdSelect = (value, index) => {
  //   let attrNormalsClone = [...attrNormals];
  //   attrNormalsClone[index]["value"] = value;
  //   setAttrNormals(attrNormalsClone);
  // };

  // const normalsBlur = (e, index) => {
  //   let { value } = e.target;
  //   let attrNormalsClone = [...attrNormals];
  //   attrNormalsClone[index]["value"] = value;
  //   setAttrNormals(attrNormalsClone);
  // };

  const textChange = (e, index, mandatory) => {
    let { value } = e.target;
    let attrNormalsClone = [...attrNormals];
    if (mandatory) {
      attrNormalsClone[index]["value"] = value;
      attrNormalsClone[index]["state"] = true;
    } else {
      attrNormalsClone[index]["value"] = value;
    }
    setAttrNormals(attrNormalsClone);
  };

  const selectChange = (value, index, mandatory) => {
    let attrNormalsClone = [...attrNormals];
    if (mandatory) {
      if (!value) {
        attrNormalsClone[index]["value"] = "";
        attrNormalsClone[index]["state"] = false;
      } else {
        attrNormalsClone[index]["value"] = value;
        attrNormalsClone[index]["state"] = true;
      }
    } else {
      if (!value) {
        attrNormalsClone[index]["value"] = "";
      } else {
        attrNormalsClone[index]["value"] = value;
      }
    }
    setAttrNormals(attrNormalsClone);
  };

  const brandChange = (value) => {
    let brandClone = { ...brand };
    brandClone.value = value;
    setBrand(brandClone);
  };
  return (
    <CardBody>
      <CardBody style={{ border: "1px solid #e3e3e3", borderRadius: "8px" }}>
        <CardTitle tag="h6" style={{ color: "#bf558b" }}>
          Attribute
        </CardTitle>
        <Row form>
          <Col md="4">
            <FormGroup>
              <Label>
                Brand <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              </Label>
              <div {...(!brand.state && { className: styles.mySelectRequired })}>
                <SelectAntd data={brandList} value={brand} allowClear={false} showSearch={true} onChange={brandChange} />
              </div>
              {!brand.state && <span style={{ color: "#dc3545", width: "100%", fontSize: "80%", marginTop: "0.25rem" }}>This field is required.</span>}
            </FormGroup>
          </Col>
          {attrNormals.map((attr, index) => (
            // <Fragment key={attr.name}>
            <Col md="4" key={attr.name}>
              <FormGroup>
                <Label>
                  {attr.label}
                  {attr.is_mandatory === 1 ? (
                    <span style={{ color: "red", fontWeight: "bold", fontStyle: "italic" }}>{attr.max_length !== "" && `(Max Length: ${attr.max_length})`} *</span>
                  ) : (
                    <span style={{ color: "blue", fontWeight: "bold", fontStyle: "italic" }}>{attr.max_length !== "" && `(Max Length: ${attr.max_length})`} </span>
                  )}
                </Label>
                {((attr.input_type === "dropdown" || attr.attribute_type === "option") && attr.options.length > 0 && (
                  <div {...(attr.is_mandatory === 1 && !attr.state && { className: styles.mySelectRequired })}>
                    <SelectAntd data={attr.options} value={attr.value} onChange={(value) => selectChange(value, index, attr.is_mandatory)} />
                  </div>
                )) ||
                  (attr.input_type === "multiselect" && attr.options.length > 0 && (
                    <div {...(attr.is_mandatory === 1 && !attr.state && { className: styles.mySelectRequired })}>
                      <SelectAntd
                        placeholder="Multi select"
                        value={attr.value ? (Array.isArray(attr.value) ? attr.value : [attr.value]) : []}
                        data={attr.options}
                        mode="multiple"
                        onChange={(value) => selectChange(value, index, attr.is_mandatory)}
                      />
                    </div>
                  )) ||
                  // ((attr.input_type === "textfield" || attr.input_type === "checkbox") && attr.options === "" && (
                  (attr.input_type === "textfield" && attr.options === "" && (
                    <Input
                      type="text"
                      defaultValue={attr.value}
                      {...(attr.max_length !== "" && { maxLength: parseInt(attr.max_length) })}
                      onBlur={(e) => textChange(e, index, attr.is_mandatory)}
                      {...(attr.is_mandatory === 1 && !attr.state && { invalid: true })}
                    />
                  )) ||
                  (attr.input_type === "numberfield" && attr.options === "" && (
                    <Input
                      type="number"
                      step="any"
                      defaultValue={attr.value}
                      onBlur={(e) => textChange(e, index, attr.is_mandatory)}
                      {...(attr.is_mandatory === 1 && !attr.state && { invalid: true })}
                    />
                  )) ||
                  (attr.input_type === "textarea" && attr.options === "" && (
                    <div {...(attr.is_mandatory === 1 && !attr.state && { className: styles.mySelectRequired })}>
                      <InputAntd.TextArea
                        {...(attr.max_length !== "" && { showCount: true, maxLength: parseInt(attr.max_length) })}
                        defaultValue={attr.value}
                        placeholder={`You must follow this format, start with dash(-).\n For Example:\nShoulder x Bust  x Length\n- S (36cm x 86cm x 54cm)\n- M (37cm x 90cm x 55cm)\n- L (38cm x 94cm x 56cm)`}
                        rows={6}
                        onBlur={(e) => textChange(e, index, attr.is_mandatory)}
                      />
                    </div>
                  ))}
                {attr.is_mandatory === 1 && !attr.state && <span style={{ color: "#dc3545", width: "100%", fontSize: "80%", marginTop: "0.25rem" }}>This field is required.</span>}
              </FormGroup>
            </Col>
            // </Fragment>
          ))}
        </Row>
        {/* <Row form>
        {attrNormals.map((attr, index) => (
          <Fragment key={attr.name}>
            {attr.input_type === "textarea" && (
              <Col md="6">
                <FormGroup>
                  <Label>
                    {attr.label} <span style={{ color: "blue", fontWeight: "bold", fontStyle: "italic" }}>{attr.max_length !== "" && `(Max Length: ${attr.max_length})`}</span>
                  </Label>
                  {attr.input_type === "textarea" && attr.options === "" && (
                    <InputAntd.TextArea
                      {...(attr.max_length !== "" && { showCount: true, maxLength: parseInt(attr.max_length) })}
                      defaultValue={attr.value}
                      placeholder={`You must follow this format, start with dash(-).\n For Example:\nShoulder x Bust  x Length\n- S (36cm x 86cm x 54cm)\n- M (37cm x 90cm x 55cm)\n- L (38cm x 94cm x 56cm)`}
                      rows={6}
                      onBlur={(e) => normalsBlur(e, index)}
                    />
                  )}
                </FormGroup>
              </Col>
            )}
          </Fragment>
        ))}
      </Row> */}
        {/* <Button onClick={() => console.log(attrNormals)}> Show </Button> */}
      </CardBody>
    </CardBody>
  );
});
