import React, { useState, Fragment } from "react";
import { UncontrolledAlert } from "reactstrap";
import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { uploadImage, deleteImage } from "services/dataservice";

import PropTypes from "prop-types";

export const UploadAntd = (props) => {
  let { index, count, multiple, data, dataArr, setData, setMsg, sku, variation, dataObj, name } = props;

  const IMAGE_URL = `/api/marketplace-product/getImage?fileName=`;

  const showUploadMsg = (msg, status) => {
    setMsg(null);
    setMsg(
      <UncontrolledAlert onClick={() => setMsg(null)} color={status}>
        {msg}
      </UncontrolledAlert>
    );
    setTimeout(() => {
      setMsg(null);
    }, 3000);
  };

  const [preview, setPreview] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8, color: "#666" }}>Upload</div>
    </div>
  );

  const uploadButtonSku = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 6, fontSize: "10px", color: "#666" }}>Upload</div>
    </div>
  );

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChange = ({ file, fileList }) => {
    if (fileList.length > count) {
      fileList.splice(count);
    }

    let { status } = file;

    if (status === "done") {
      showUploadMsg("Uploaded", "success");
    }
    if (status === "error") {
      showUploadMsg("Upload failed", "danger");
      file.response = "Upload failed, remove and upload again";
    }

    setData(fileList);
  };

  // const handleSkuChange = ({ file, fileList }, index) => {
  //   let skusArr = [...dataArr];

  //   if (fileList.length > count) {
  //     fileList.splice(count);
  //   }
  //   let { status } = file;
  //   if (status === "done") {
  //     showUploadMsg("Uploaded", "success");
  //   }
  //   if (status === "error") {
  //     showUploadMsg("Upload failed", "danger");
  //     file.response = "Upload failed, remove and upload again";
  //   }

  //   skusArr[index]["skuImages"] = fileList;
  //   setData(skusArr);
  // };

  const handleOptionImageChange = ({ file, fileList }, index) => {
    // let skusArr = [...dataArr];
    let optionImagesClone = { ...dataObj };

    if (fileList.length > count) {
      fileList.splice(count);
    }
    let { status } = file;
    if (status === "done") {
      showUploadMsg("Uploaded", "success");
    }
    if (status === "error") {
      showUploadMsg("Upload failed", "danger");
      file.response = "Upload failed, remove and upload again";
    }

    optionImagesClone[name] = fileList;
    setData(optionImagesClone);
  };
  const beforeUpload = (file, fileList, data) => {
    let totalFile = fileList.length + data.length;
    if (totalFile > count) {
      showUploadMsg(`Currently have ${data.length} image, and you want to upload more ${fileList.length} image, which has exceeded the limit`, "danger");
      return Upload.LIST_IGNORE;
    }

    if (fileList.length > 8) {
      showUploadMsg("Only 8 files can be uploaded", "danger");
      return Upload.LIST_IGNORE;
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      showUploadMsg(`${file.name} is not a png/jpeg file`, "danger");
      return Upload.LIST_IGNORE;
    }

    let isLt1M = file.size / 1024 / 1024 <= 1;

    if (!isLt1M) {
      showUploadMsg(`${file.name} upload failed, Image must smaller than 1MB!`, "danger");
      return Upload.LIST_IGNORE;
    }
    return (file.type === "image/png" || file.type === "image/jpeg") && isLt1M;
  };

  async function removeImage (file)  {
    if(window.confirm("Delete Image ?")){
      if (file.hasOwnProperty("error")) {
        console.log("remove error file");
        return;
      }
  
      if (file.response) {
        // let { name, catalogueAttributeId } = file.response.data;
        let {name,catalogueImageId} = file.response.data;
        console.log("file hv response................");
        // console.log("catalogueAttributeId", catalogueAttributeId);
        if (name) {
          try {
            let response = await deleteImage({ name, catalogueImageId });
            if (!response.status === 200) {
              showUploadMsg("Delete failed", "danger");
              return false;
            }
            showUploadMsg("Deleted, you must have at least one image before you can submit!", "success");
          } catch (error) {
            showUploadMsg("Server error, please remove image again", "danger");
            return false;
          }
        }
      } else {
        // let { name, catalogueAttributeId } = file;
        let {name,catalogueImageId} = file;
        console.log("file hv name................");
        // console.log("catalogueAttributeId", catalogueAttributeId);
        if (name) {
          try {
            let response = await deleteImage({ name, catalogueImageId });
  
            if (!response.status === 200) {
              showUploadMsg("Delete failed", "danger");
              return false;
            }
            showUploadMsg("Deleted, you must have at least one image before you can submit!", "success");
          } catch (error) {
            showUploadMsg("Server error, please remove image again", "danger");
            return false;
          }
        }
      }
    }else{
      return false
    }
     
  }
  const uploadProps = {
    ...uploadImage(),
    listType: "picture-card",
    multiple,
    fileList: data,
    beforeUpload: (file, fileList) => beforeUpload(file, fileList, data),
    // onChange: sku ? (f) => handleSkuChange(f, index) : handleChange,
    onChange: variation ? (f) => handleOptionImageChange(f, index) : handleChange,
    onRemove: removeImage,
    onPreview: async (file) => {
      if (!file.url && !file.preview && !file.response.data) {
        file.preview = await getBase64(file.originFileObj);
      }
      // console.log(file.response.data);
      setPreview({
        previewImage: file.preview || file.url || `${IMAGE_URL}${file.response.data.name}`,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      });
    },
  };

  const modalProps = {
    visible: preview.previewVisible,
    title: preview.previewTitle,
    footer: null,
    onCancel: () => {
      let previewObj = { ...preview };
      previewObj["previewVisible"] = false;
      setPreview(previewObj);
    },
  };

  return (
    <Fragment>
      <Upload {...uploadProps}>{data.length >= count ? null : variation ? uploadButtonSku : uploadButton}</Upload>
      <Modal {...modalProps}>
        <img src={preview.previewImage} alt={preview.previewTitle} style={{ width: "100%" }} />
      </Modal>
    </Fragment>
  );
};

UploadAntd.defaultProps = {
  index: undefined,
  count: 8,
  multiple: true,
  data: [],
  dataArr: [],
  dataObj: {},
  setData: () => {},
  setMsg: undefined,
  sku: false,
  variation: false,
};

UploadAntd.propTypes = {
  index: PropTypes.number,
  count: PropTypes.number,
  multiple: PropTypes.bool,
  data: PropTypes.array.isRequired,
  dataArr: PropTypes.array,
  dataObj: PropTypes.object,
  setData: PropTypes.func.isRequired,
  setMsg: PropTypes.func.isRequired,
  sku: PropTypes.bool,
  variation: PropTypes.bool,
};

/**
 *for product image
 * data,setData,setMsg is required
 *
 * for sku image
 * data,dataArr,setData,setMsg,sku,index is required
 *
 * count and multiple is optional
 */
