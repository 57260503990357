import React,{memo} from "react";
import { Col, FormGroup, Label } from "reactstrap";
import { SelectAntd } from "../../Components/AntdComponent";

export default memo(
    function BrandList(props) {
        console.log("brandlist")
        let{brand,setBrand,brandList}=props
        const brandChange=(value)=>{
            setBrand({value,state:true})
        }
      return (
        <Col md="3">
          <FormGroup>
            <Label>
            Brand <span style={{ color: "red", fontWeight: "bold" }}>*</span>
            </Label>
            <SelectAntd data={brandList} placeholder="Please Select Brand" showSearch allowClear={false} value={brand.value} onChange={value=>brandChange(value)}/>
          </FormGroup>
          
        </Col>
      );
    }
)
  
