import React from "react";

export default function TableSkuHead(props) {
  let { optionList, platformName } = props;
  return (
    <thead className="text-primary">
      <tr>
        {optionList.map((o, index) => (
          <th key={index}>{o.name}</th>
        ))}

        <th style={{ width: "15%" }}>Inventory Sku</th>
        <th style={{ width: "10%" }}>Price</th>
        <th>Barcode 1</th>
        <th>Barcode 2</th>
        <th>Weight</th>
        <th>Height</th>
        <th>Width</th>
        <th>Length</th>
        {/* <th>Image</th> */}
        {["Lazada", "Zalora"].includes(platformName) && <th>Active</th>}
      </tr>
    </thead>
  );
}
