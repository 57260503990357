import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Link, useRouteMatch, Switch, Route } from 'react-router-dom';
import { useHistory } from "react-router-dom";


import {
   Col,
   Row,
   Card,
   CardHeader,
   CardBody,
   CardTitle,
   Nav,
   NavItem,
   NavLink,
   Button,
   Badge,
   UncontrolledTooltip
} from 'reactstrap'
import {
   listInventory,
   getInventoryStatusCount,
   deleteInventory,
   undeleteInventory,
   setBufferStock,
   exportInventory,
   updateMarketplaceQuantity,
   setPreOrder
} from 'services/dataservice';
import InventoryInfo from "./InventoryInfo";
import ReactTable from "components/ReactTable/ReactTable.js";
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Notify from 'react-notification-alert';
import moment from "moment";

import "./InventoryList.css";

export default function InventoryList(prop) {
   const columns = useMemo(() => [
      {
         Header: "SKU",
         accessor: "inventorySKU",
         disableFilters: false,
         Cell: ({ row }) => <Link to={`${path}/detail/${row.original.inventoryId}`}>{row.original.inventorySKU}</Link>
      },
      {
         Header: "Product Name",
         accessor: "productName",
         style: { 'whiteSpace': 'unset' }
      },
      {
         Header: "Available Stock",
         accessor: "quantity",
         disableFilters: true
      },
      {
         Header: "Buffer Stock",
         accessor: "bufferStock",
         disableFilters: true
      },
      {
         Header: "Type",
         accessor: "type"
      },
      {
         Header: "Actions",
         accessor: "action",
         disableFilters: true
      }
   ])


   let { path, url } = useRouteMatch();
   const [alert, setAlert] = useState(null);
   const [tableData, setTableData] = useState([]);
   const [tabs, setTabs] = useState("all");
   const [statusCount, setStatusCount] = useState({ all: 0, stockAvailable: 0, outOfStock: 0, preOrder:0, deleted: 0 });
   let history = useHistory();

   const notify = useRef(null);

   useEffect(() => {
      reloadCount();
      reloadList(tabs);
   }, [tabs]);

   const showFail = (msg) => {
      setAlert(
         <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title={msg}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info"
            btnSize=""
         >
         </ReactBSAlert>
      )
   }

   function reloadCount() {
      getInventoryStatusCount().then(
         res => {
            setStatusCount({
               all: res.data.all,
               stockAvailable: res.data.stockAvailable,
               outOfStock: res.data.outOfStock,
               preOrder: res.data.preOrder,
               deleted: res.data.deleted
            })
         }
      );
   }

   const triggerSync = (inventoryId, inventorySKU) => {

      updateMarketplaceQuantity(inventoryId).then((res) => {
         let options = {
            place: "br",
            message: `${inventorySKU} Sync Submitted`,
            type: "success",
            autoDismiss: 5,
            icon: ""
         };
         notify.current.notificationAlert(options);
      });
   };

   function reloadList(status) {
      listInventory(status).then(res => {
         setTableData(res.data.map((res, index) => {
            return {
               ...res,
               action: (
                  <div className="actions-right">
                     {
                        status !== "deleted" && status !== "pre_order" ? (
                           <>
                              <Button
                                 id={"btnSync-" + index}
                                 size="sm"
                                 className="btn-icon btn-link refresh"
                                 color="primary"
                                 onClick={() => {
                                    triggerSync(res.inventoryId, res.inventorySKU)
                                 }
                                 }>
                                 <i className="fas fa-sync" />
                              </Button>
                              <Button
                                 id={"btnEdit-" + index}
                                 size="sm"
                                 className="btn-icon btn-link edit"
                                 color="primary"
                                 onClick={() => {
                                    triggerEdit(res)
                                 }}>
                                 <i className="fa fa-edit" />
                              </Button>
                              <Button
                                 id="btnDelete"
                                 size="sm"
                                 className="btn-icon btn-link remove"
                                 color="danger"
                                 onClick={() => {
                                    triggerDelete(res.inventoryId, status)
                                 }
                                 }>
                                 <i className="fa fa-times" />
                              </Button>
                           </>
                        ) : status === "deleted" ?
                           (
                              <Button
                                 id="btnDelete"
                                 size="sm"
                                 className="btn-icon btn-link edit"
                                 color="danger"
                                 onClick={() => {
                                    triggerUndelete(res.inventoryId, status)
                                 }
                                 }>
                                 <i className="fa fa-undo" />
                              </Button>
                           ):(
                              <Button
                              id="btnUndo"
                              size="sm"
                              className="btn-icon btn-link edit"
                              color="danger"
                              onClick={() => {
                                 triggerSetPreOrder(res.inventoryId, status)
                              }
                              }>
                              <i className="fa fa-undo" />
                           </Button>
                           )

                     }
                  </div >
               )
            }
         }))
      });
   }

   function triggerEdit(inventory) {
      let id = inventory.inventoryId;
      history.push(`/admin/inventory/edit/${id}`, tabs);
      //console.log(inventory);
   }

   const triggerDelete = async (id, status) => {
      let resp = await deleteInventory({ inventoryId: id });
      if (resp.data.code === 400) {
         showFail(resp.data.description)
      }
      reloadCount();
      reloadList(status);
   }

   const triggerUndelete = async (id, status) => {
      let resp = await undeleteInventory({ inventoryId: id });
      reloadCount();
      reloadList(status);
   }

   const triggerSetPreOrder =async (id,status)=>{
      let data = [{inventoryId:id,preOrder:false}]
      let resp = await setPreOrder(data);
      reloadCount();
      reloadList(status);
   }
   
   const triggerExport = async () => {
      let resp = await exportInventory();
      const date = new Date()
      const dateStr = moment(date).format("YYYYMMDDHHmmss")
      //let data = res.data;
      //setExportData();

      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Inventory_${dateStr}.csv`);
      document.body.appendChild(link);
      link.click();

   }

   async function SetBufferStock(bufferStock) {
      try {
         let valid = /^\d*$/.test(bufferStock);
         if (!valid) {
            showFail("Only number is allowed");
            return;
         }

         const resp = await setBufferStock(bufferStock);
         setAlert(null);
         reloadList(tabs);
      }
      catch {
         showFail("Error at updating Buffer Stock");
      }

   }

   const SetAllBufferStockAlert = () => {
      setAlert(
         <ReactBSAlert
            input
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title="Set Buffer Stock"
            onConfirm={(val) => SetBufferStock(val)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            btnSize=""
         >
         </ReactBSAlert>);
   }

   const Modal = (content) => (
      <div
         role="button"
         className="modal-wrapper"
         onClick={() => history.goBack()}>
         <div
            className="custom-modal"
            onClick={e => e.stopPropagation()}>
            {content}
         </div>
      </div>
   )

   return (
      <div className="content" style={{ "minWidth": "1200px" }}>
         {alert}
         <Notify ref={notify} />
         <Row className="row justify-content-end">
            <Col md="12" className="d-flex flex-row-reverse">
               <Link to="/admin/inventory/add">
                  <Button color="primary">Add</Button>
               </Link>
               <Link to="/admin/inventory/upload">
                  <Button color="primary">Upload</Button>
               </Link>
               <Button color="primary" onClick={SetAllBufferStockAlert}>Set Buffer</Button>
               <Button color="primary" onClick={triggerExport}>Export</Button>
               <Link to="/admin/inventory/add-pre-order">
                  <Button color="primary">Pre Order</Button>
               </Link>
            </Col>
         </Row>
         <Row>
            <Col md="12">
               <Card className="card-subcategories">
                  <CardHeader>
                     <CardTitle tag="h4">Inventory</CardTitle>
                  </CardHeader>
                  <CardBody>
                     <Nav
                        className="nav-pills-primary nav-pills-icons justify-content-right"
                        pills
                        role="tablist"
                     >
                        <NavItem>
                           <NavLink
                              role="tablist"
                              onClick={() => setTabs("all")}
                              data-toggle="tab"
                              href="#"
                              className={
                                 tabs === "all" ? "active" : ""
                              }>
                              All <Badge color="default">{statusCount.all}</Badge>
                           </NavLink>
                        </NavItem>
                        <NavItem>
                           <NavLink
                              aria-expanded={tabs === "stock_available"}
                              data-toggle="tab"
                              href="#"
                              onClick={() => setTabs("stock_available")}
                              className={
                                 tabs === "stock_available" ? "active" : ""
                              }>
                              Stock Available <Badge color="default">{statusCount.stockAvailable}</Badge>
                           </NavLink>
                        </NavItem>
                        <NavItem>
                           <NavLink
                              aria-expanded={tabs === "out_of_stock"}
                              data-toggle="tab"
                              href="#"
                              onClick={() => setTabs("out_of_stock")}
                              className={
                                 tabs === "out_of_stock" ? "active" : ""
                              }>
                              Out Of Stock <Badge color="default">{statusCount.outOfStock}</Badge>
                           </NavLink>
                        </NavItem>
                        <NavItem>
                           <NavLink
                              aria-expanded={tabs === "pre_order"}
                              data-toggle="tab"
                              href="#"
                              onClick={() => setTabs("pre_order")}
                              className={
                                 tabs === "pre_order" ? "active" : ""
                              }>
                              Pre Order <Badge color="default">{statusCount.preOrder}</Badge>
                           </NavLink>
                        </NavItem>
                        <NavItem>
                           <NavLink
                              aria-expanded={tabs === "deleted"}
                              data-toggle="tab"
                              href="#"
                              onClick={() => setTabs("deleted")}
                              className={
                                 tabs === "deleted" ? "active" : ""
                              }>
                              Deleted <Badge color="default">{statusCount.deleted}</Badge>
                           </NavLink>
                        </NavItem>
                     </Nav>
                     <ReactTable
                        data={tableData}
                        columns={columns}
                        className="-striped -highlight primary-pagination">
                     </ReactTable>
                  </CardBody>
               </Card>
            </Col>
         </Row>
         <Switch>
            <Route path={`${path}/detail/:id`}
               render={(props) => {
                  {
                     return Modal(<InventoryInfo {...props}></InventoryInfo>)
                  }
               }}>
            </Route>
         </Switch>
      </div >
   )
}