import React, { useState, useEffect, useCallback } from "react";
import { Button,Card, CardHeader, CardTitle, Form } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
//Api
import { getMarketplaceBrands,getMarketplaceAttribute, getProductDetail } from "services/dataservice";
//Function
import { formatLazadaAttributes, assignValue } from "views/product/common";
//Components
import BasicInfo from "../Components/BasicInfo";
// import SpecificationKey from "../../Lazada2/Components/SpecificationKey";
// import Specification from "../../Lazada2/Components/Specification";
import Specification from "../../ProductAdd/Lazada2/Components/Specification"
import Options from "../Components/Options/Options3";
import OptionImages from "../Components/Options/OptionImages";
import Skus from "../Components/Skus/Skus";
import EditFormSubmit from "./Components/EditFormSubmit";
import { generateCancelToken } from "views/product/fetch";

export default function LazadaProductDetail(props) {
  const [alert, setAlert] = useState(null);
  const showFail = useCallback((msg) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      ></SweetAlert>
    );
  }, []);

  const [product, setProduct] = useState({});
  const [options, setOptions] = useState([]);
  const [optionImages, setOptionImages] = useState({});
  const [optionList, setOptionList] = useState([]);
  const [platformName, setPlatformName] = useState("");
  const [basicInformation, setBasicInformation] = useState({});
  const [parentSKU, setParentSKU] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [variations, setVariations] = useState([]);
  // const [packageContent, setPackageContent] = useState({ AttributeName: "package_content", AttributeValue: "" });
  const [brand, setBrand] = useState({ value: "", state: true });
  const[brandList,setBrandList]=useState([])
  // const [attrKeys, setAttrKeys] = useState([]);
  const [attrNormals, setAttrNormals] = useState([]);
  const [attrOptions,setAttrOptions] = useState([]);
  // const [attrMandatoryNormals, setAttrMandatoryNormals] = useState([]);

  const [objOptions, setObjOptions] = useState({
    color_family: [
      { label: "Red", value: "Red" },
      { label: "Blue", value: "Blue" },
      { label: "Purple", value: "Purple" },
      { label: "Yellow", value: "Yellow" },
      { label: "Green", value: "Green" },
      { label: "Cherry", value: "Cherry" },
    ],
    size: [
      { label: "X", value: "X" },
      { label: "L", value: "L" },
      { label: "M", value: "M" },
    ],
  });
  // const [attrOptions, setAttrOptions] = useState({});

  useEffect(() => {
    // console.log(props.match.params.id);
    const{cancel,cancelToken}=generateCancelToken()
    const fetchProductDetail = async () => {
      try {
        let CatalogueBasicInfoId = props.match.params.id;
        let response = await getProductDetail(CatalogueBasicInfoId,{cancelToken});
        if (response.status === 200) {
          let productDetail = response.data.data;
          //console.log(response);
          // let productDetail = [
          //   {
          //     catalogueBasicInfoId: 79,
          //     description: "121212",
          //     productName: "Huawei Nova 5T",
          //     brand: "No Brand",
          //     categoryId: "3",
          //     categoryValue: "Smartphones",
          //     parentSKU: "colorssssssssssssss",
          //     tags: null,
          //     active: false,
          //     isVariation: false,
          //     marketplace: null,
          //     status: null,
          //     userMarketplaceId: 43,
          //     platformName: "Lazada",
          //     variations: [
          //       {
          //         inventoryId: 96048,
          //         inventorySKU: "11112",
          //         quantity: 0,
          //         variationId: null,
          //         sellingPrice: 1,
          //         sellingPriceState: true,
          //         costPrice: 0,
          //         option: null,
          //         barcode1: "1",
          //         barcode2: null,
          //         weight: 1,
          //         height: 1,
          //         width: 1,
          //         length: 1,
          //         status: null,
          //         skuImages: null,
          //         optionList: [
          //           {
          //             catalogueAttributeId: 963,
          //             oValue: "Cherry",
          //             oState: true,
          //           },
          //           {
          //             catalogueAttributeId: 965,
          //             oValue: "X",
          //             oState: true,
          //           },
          //         ],
          //         variant: "Cherry/X",
          //       },
          //       {
          //         inventoryId: 96004,
          //         inventorySKU: "1111",
          //         quantity: 0,
          //         variationId: null,
          //         sellingPrice: 1,
          //         sellingPriceState: true,
          //         costPrice: 0,
          //         option: null,
          //         barcode1: "fawfawflfawfa",
          //         barcode2: null,
          //         weight: 3.2,
          //         height: 4.6,
          //         width: 4.5,
          //         length: 3.2,
          //         status: null,
          //         skuImages: null,
          //         optionList: [
          //           {
          //             catalogueAttributeId: 962,
          //             oValue: "Olive",
          //             oState: true,
          //           },
          //           {
          //             catalogueAttributeId: 964,
          //             oValue: "X",
          //             oState: true,
          //           },
          //         ],
          //         variant: "Olive/X",
          //       },
          //     ],
          //     newVariations: null,
          //     variation: null,
          //     attributes: '{"warranty_type":["International Manufacturer Warranty"]}',
          //     productImages: [],
          //     optionImages: {
          //       Olive: [{ name: "20220117012047122_3e205567bdd00beb31d518afa1a78efe.png", url: "/api/catalogue/getImage?fileName=20220117012047122_3e205567bdd00beb31d518afa1a78efe.png" }],
          //       Cherry: [{ name: "20220117012301630_3e205567bdd00beb31d518afa1a78efe.png", url: "/api/catalogue/getImage?fileName=20220117012301630_3e205567bdd00beb31d518afa1a78efe.png" }],
          //     },
          //     options: [
          //       {
          //         name: "color_family",
          //         value: ["Olive", "Cherry"],
          //       },
          //       {
          //         name: "size",
          //         value: ["X"],
          //       },
          //       // {
          //       //   name: "size",
          //       //   value: ["X", "L"],
          //       // },
          //     ],
          //   },
          // ];
          // console.log(productDetail[0]);
          let { productName, description, parentSKU:pSKU, productImages, brand, isVariation, variations, options, platformName, optionImages, categoryId, userMarketplaceId, attributes } = productDetail[0];

          setProduct(productDetail[0]);

          setBasicInformation({
            productName,
            description,
          });
          if (pSKU) {
            setParentSKU(pSKU);
          }

          setProductImages(productImages);
          setBrand({ value: brand, state: true });
          getBrands(userMarketplaceId)
          setPlatformName(platformName);
          variations.forEach(v=>{
            v["inventorySKUState"] = true;
            v["inventorySKUFound"] = false;
            v["barcode1State"] = true;
            v["weightState"] = true;
            v["heightState"] = true;
            v["widthState"] = true;
            v["lengthState"] = true;
          })
          setVariations(variations);
          getAttributes(categoryId, userMarketplaceId, attributes);

          if (isVariation) {
            let oImage = {};
            optionImages.forEach((o) => {
              oImage[o.option] = o.image;
            });

            let oList = options.map((o) => {
              return { name: o.name, value: o.value.map((v) => v) };
            });

            // console.log(oList)
            // setProductImages(productImages);
            // if(options.length === 1){
            //   options.push({name:"",value:[]})
            // }
            setOptions(options);
            setOptionList(oList);
            setOptionImages(oImage);
          }
        }
      } catch (error) {
        // console.log("error: ", error);
        showFail("Server Error [GetProduct]")
      }
    };
    fetchProductDetail();

    const getAttributes = async (categoryId, userMarketplaceId, attributes) => {
      try {
        let response = await getMarketplaceAttribute(categoryId, userMarketplaceId);
        if (response.status === 200) {
          let attribute = response.data.data;

          let attributesParsed = JSON.parse(attributes);
          //test for change git
          let formattedAttributes = formatLazadaAttributes(attribute);
          let packageContent = [
            {
              id: 100005496,
              label: "What's in the box",
              name: "package_content",
              options: [],
              input_type: "text",
              value: "",
              is_mandatory: 0,
            },
          ];
          let attrNormalsMerged = [...packageContent,...formattedAttributes.attrKeys,...formattedAttributes.attrNormals]
          // console.log("formatted.", formattedAttributes);
          // console.log(attributes);
          // let attrKeysMapped = assignValue(formattedAttributes.attrKeys, attributesParsed);
          // console.log("keys.", attrKeysMapped);

          let attrNormalsMapped = assignValue(attrNormalsMerged, attributesParsed);

          // let attrMandatoryNormalsMapped = assignValue(formattedAttributes.attrMandatoryNormals, attributesParsed);
          // if (attributesParsed["package_content"]) {
          //   let AttributeValue = attributesParsed["package_content"][0];
          //   // console.log(AttributeValue);
          //   setPackageContent({ AttributeName: "package_content", AttributeValue });
          // }

          // setAttrKeys(attrKeysMapped);
          setAttrNormals(attrNormalsMapped);
          // setAttrMandatoryNormals(attrMandatoryNormalsMapped);
          // setAttrOptions(formattedAttributes.attrOptions);
          let newAttrOptions = formattedAttributes.attrOptions.map(attr=>{
            return {label:attr.label,value:attr.name}
          })
          setAttrOptions(newAttrOptions);
          setObjOptions(formattedAttributes.objOptions);
        }
      } catch (error) {
        showFail("Server Error in [getMarketplaceAttribute]");
      }
    };
    const getBrands=async(userMarketplaceId)=>{
      try {
        let response=await getMarketplaceBrands(userMarketplaceId,0,{cancelToken})
        if(response.status===200){
          let data=response.data.map(item=>{
            return{label:item,value:item}
          })
          setBrandList(data)
        }
      } catch (error) {
        
      }
    }
    //console.log("@useEffect");
    return()=>{console.log("Request Cancelled")
  cancel()}
  }, []);

  // useEffect(()=>{
  //   const getBrands=async()=>{
  //     try {
  //       let response=await getMarketplaceBrands()
  //     } catch (error) {
        
  //     }
  //   }
  // })

  const basicInfoProps = {
    product,
    basicInformation,
    setBasicInformation,
    parentSKU,
    setParentSKU,
    productImages,
    setProductImages,
    platformName,
  };

  const optionsProps = {
    platformName,
    options,
    setOptions,
    optionList,
    optionImages,
    setOptionImages,
    setOptionList,
    attrOptions,
    objOptions,
    variations,
    setVariations,
    showFail,
  };

  const optionImagesProps = {
    // options,
    optionList,
    optionImages,
    setOptionImages,
  };

  const skusProps = {
    variations,
    // options,
    optionList,
    setVariations,
    // newSkus,
    // setNewSkus,
    showFail,
    platformName,
    // objOptions,
    // multiple: true,
    // count: 8,
  };

  const editFormSubmitProps = {
    setAlert,
    product,
    basicInformation,
    parentSKU,
    productImages,
    brand,
    setBrand,
    // attrMandatoryNormals,
    // setAttrMandatoryNormals,
    // attrKeys,
    attrNormals,
    // packageContent,
    optionImages,
    optionList,
    variations,
    setVariations,
    showFail,
  };

  const specificationProps = { brand, setBrand,brandList, attrNormals, setAttrNormals };
   

  return (
    <div className="content">
      <Form>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              Edit Product (Lazada)
              <Link to={{ state: props.location.state, pathname: "/admin/product/list" }}>
                <i style={{ float: "right", right: "1%" }} className="nc-icon nc-simple-remove" />
              </Link>
            </CardTitle>
            {alert}
          </CardHeader>

          <BasicInfo {...basicInfoProps} />
          {/* <SpecificationKey {...specificationKeyProps} /> */}
          <Specification {...specificationProps} />
          {product.isVariation && (
            <>
              <Options {...optionsProps} />
              <OptionImages {...optionImagesProps} />
            </>
          )}

          <Skus {...skusProps} />
          {/* <Button onClick={() => console.log(newSkus)}>show newSkus</Button>
          <Button onClick={() => console.log(variations)}>show variations</Button>
          <Button onClick={() => console.log(attrKeys)}>show attrKeys</Button>
          <Button onClick={() => console.log(attrNormals)}>show attrNormals</Button>
          <Button onClick={() => console.log(attrOptions)}>show attrOptions</Button> */}
          {/* <Button onClick={()=>console.log(optionList)}>list</Button>
          <Button onClick={()=>console.log(attrOptions)}>Attr Options</Button>
          <Button onClick ={()=>console.log(options)}>options</Button> */}
          {!props.location.state&&<EditFormSubmit {...editFormSubmitProps} />}
           
          {/* <Button onClick={()=>console.log(options)}>AAA</Button>
          <Button onClick={()=>console.log(optionList)}>List</Button>
          <Button onClick={()=>console.log(optionImages)}>Images</Button> */}
        </Card>
      </Form>
    </div>
  );
}
