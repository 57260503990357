import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Card, CardHeader, CardBody, CardTitle, Button, Nav, NavItem, NavLink, Badge } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import ReactTable from "components/ReactTable/ReactTable.js";

import { getPromotionList, deletePromotion } from 'services/dataservice';
import { getPromotionCount } from 'services/dataservice';

export default function PromotionList() {
    const columns = [
        {
            Header: "Promotion Name",
            accessor: "promotionName"
        },
        {
            Header: "Criteria",
            accessor: "promotionCriteria"
        },
        {
            Header: "Option",
            accessor: "promotionOption"
        },
        {
            Header: "Stores",
            accessor: "stores"
        },
        {
            Header: "Start Date",
            accessor: "startDate",
            // sorter: (a, b) => new Date(a.date) - new Date(b.date)
        },
        {
            Header: "End Date",
            accessor: "endDate"
        },
        {
            Header: "Actions",
            accessor: "action"
        }
    ];

    const [tabs, setTabs] = useState("upcoming");
    const [statusCount, setStatusCount] = useState({ upcoming: 0, ongoing: 0, past: 0, deleted: 0 });
    const [tableData, setTableData] = useState([]);
    const [alert, setAlert] = useState(null);
    const history = useHistory();

    useEffect(() => {
        loadCount();
        loadTable();
    }, [tabs]);

    function loadTable() {
        getPromotionList(tabs).then(res => {
            console.log(res)
            const data = res.data;
            // data.sort((a, b)=> a.startDate)
            data.forEach(i => {
                if (i.promotionCriteria === "Items") {
                    i.promotionOption = i.promotionOption.replace("1", "Apply promotion on purchase of at least one of the primary products");
                    i.promotionOption = i.promotionOption.replace("2", "Apply promotion on purchase of all the primary products");
                    i.promotionOption = i.promotionOption.replace("3", "Apply promotion on purchase of a specific number of primary products pre-defined by you");
                }
                else if (i.promotionCriteria === "Order Total") {
                    i.promotionOption = i.promotionOption.replace("1", "Greater than");
                    i.promotionOption = i.promotionOption.replace("2", "Amount Range");
                }
                else if (i.promotionCriteria === "Product Quantity") {
                    i.promotionOption = i.promotionOption.replace("1", "Greater than");
                    i.promotionOption = i.promotionOption.replace("2", "Quantity Range");
                }
            });

            setTableData(res.data.sort((a, b) => moment(a.startDate, 'DD-MM-YYYY') - moment(b.startDate, 'DD-MM-YYYY')).map((resData, index) => {
                let newDate = new Date();
                let sDate = resData.startDate;
                let eDate=resData.endDate
                sDate = moment(sDate, 'DD-MM-YYYY HH:mm:SS')
                eDate=moment(eDate,"DD-MM-YYYY HH:mm:SS")
                console.log(resData)
                return {
                    ...resData,
                    action: (
                        <div className="actions-right">
                            <Button id="btnClone"
                                        size="sm"
                                        title="copy promotion"
                                        className="btn-icon btn-link edit"
                                        color="primary"
                                        onClick={() => history.push(`/admin/promotion/detail/duplicate/${resData.gwpPromoId}`)}
                                    >
                                        <i className="fa fa-clone"></i>
                                    </Button>
                            {(tabs==="upcoming") && (
                                //(!(sDate <= newDate))
                                    <Button id="btnEdit"
                                        size="sm"
                                        title="edit promotion"
                                        className="btn-icon btn-link edit"
                                        color="success"
                                        onClick={() => history.push(`/admin/promotion/detail/edit/${resData.gwpPromoId}`)}
                                    >
                                        <i className="fa fa-edit"></i>
                                    </Button>
                            )}
                            {(tabs==="ongoing"||tabs==="past"||tabs==="deleted")&&(
                                <Button id="btnView"
                                size="sm"
                                title="view promotion"
                                className="btn-icon btn-link edit"
                                color="info"
                                onClick={() => history.push(`/admin/promotion/detail/view/${resData.gwpPromoId}`)}
                            >
                                <i className="fa fa-search"></i>
                            </Button>
                            )}
                            {(tabs==="upcoming"||tabs==="ongoing")&&(
                                //!(sDate <= newDate)||(sDate<=newDate&&eDate>=newDate)
                                <Button id="btnRemove"
                                size="sm"
                                title="delete promotion"
                                className="btn-icon btn-link remove"
                                color="danger"
                                onClick={()=>{deleteClicked(resData.gwpPromoId)}}
                            >
                                <i className="fa fa-times"></i>
                            </Button>
                            )}
                        </div>
                    )
                }
            }))
        })



    }

    function loadCount() {
        getPromotionCount().then(
            resp => {
                setStatusCount({
                    upcoming: resp.data.upcoming,
                    ongoing: resp.data.ongoing,
                    past: resp.data.past,
                    deleted: resp.data.deleted
                });
            }
        );
    }

    const PromotionTable = () => {
        return (
            <ReactTable
                data={tableData}
                columns={columns}
                className="-striped -highlight primary-pagination"
                // defaultSorted=list()
            />
        )
    }

    const showFail = (msg) => {
        setAlert(
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title={msg}
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="info"
                btnSize=""
            >
            </ReactBSAlert>
        )
    }

    const deleteClicked=async(id)=>{
        const deletefn=async()=>{
            try {
                let respDelete = await deletePromotion(id);
                setAlert(null)
                loadCount()
                loadTable();
            } catch (e) {
                showFail("Could not load data")
            }
        }

        const hideAlert=()=>{
            setAlert(null)
        }

        setAlert(
            <ReactBSAlert
               warning
               style={{ display: "block", marginTop: "-100px" }}
               title="Are you sure?"
               onConfirm={() => deletefn()}
               onCancel={() => hideAlert()}
               confirmBtnBsStyle="info"
               cancelBtnBsStyle="danger"
               confirmBtnText="Yes, delete it!"
               cancelBtnText="Cancel"
               showCancel
               btnSize="">
               You will not be able to recover this promotion
            </ReactBSAlert>
            )
    }

    return (
        <div className='content'>
            {alert}
            <Row className="row justify-content-end">
                <Col md="12" className="d-flex flex-row-reverse">
                    <Link to="/admin/promotion/add">
                        <Button color="primary">Add</Button>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <Card className="card-subcategories">
                        <CardHeader>
                            <CardTitle tag="h4">Promotion</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Nav
                                className="nav-pills-primary nav-pills-icons justify-content-right"
                                pills
                                role="tablist"
                            >
                                <NavItem>
                                    <NavLink
                                        role="tablist"
                                        onClick={() => setTabs("upcoming")}
                                        data-toggle="tab"
                                        href="#"
                                        className={
                                            tabs === "upcoming" ? "active" : ""
                                        }>
                                        Upcoming <Badge color="default">{statusCount.upcoming}</Badge>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        aria-expanded={tabs === "ongoing"}
                                        data-toggle="tab"
                                        href="#"
                                        onClick={() => setTabs("ongoing")}
                                        className={
                                            tabs === "ongoing" ? "active" : ""
                                        }>
                                        Ongoing <Badge color="default">{statusCount.ongoing}</Badge>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        aria-expanded={tabs === "past"}
                                        data-toggle="tab"
                                        href="#"
                                        onClick={() => setTabs("past")}
                                        className={
                                            tabs === "past" ? "active" : ""
                                        }>
                                        Past <Badge color="default">{statusCount.past}</Badge>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        aria-expanded={tabs === "deleted"}
                                        data-toggle="tab"
                                        href="#"
                                        onClick={() => setTabs("deleted")}
                                        className={
                                            tabs === "deleted" ? "active" : ""
                                        }>
                                        Deleted <Badge color="default">{statusCount.deleted}</Badge>
                                    </NavLink>
                                </NavItem>

                            </Nav><br></br>
                            <PromotionTable />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}