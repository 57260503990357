import React, { useEffect, useState } from 'react'
import {
   Card, CardBody, CardHeader, CardTitle,
   CardFooter, Form, FormGroup,
   Input, Button
} from 'reactstrap';

import { Link } from "react-router-dom";
import { getCatalogueDetail, checkSKU } from 'services/dataservice'



export default function CatalogueDetail(props) {
   const [inventorySKU, setInventorySKU] = useState("");
   const [inventoryName, setProductName] = useState("");
   const [invalidSKU, setInvalidSKU] = useState(true);
   const [catalogueName, setCatalogueName] = useState("");
   const [catalogueType, setCatalogueType] = useState("");

   const [inventoryId, setInventoryId] = useState(0);
   const [catalogueId, setCatalogueId] = useState(0);



   useEffect(() => {
      async function loadData() {
         let catalogueId = props.match.params.id;
         setCatalogueId(catalogueId);
         const res = await getCatalogueDetail(catalogueId);
         const catalogue = res.data;

         const res2 = await checkSKU(catalogue.inventorySKU);
         const inv = res2.data;

         setInventoryId(inv.inventoryId);

         setInventorySKU(catalogue.inventorySKU);
         setProductName(inv.productName);
         setCatalogueName(catalogue.marketplaceSKU);
         setCatalogueType(catalogue.type);
      }
      loadData();
   }, [])

   async function Submit() {

   }

   async function ValidateSKU(val) {
      setInventorySKU(val);
      const resp = await checkSKU(val);
      if (resp.data.exist) {
         setInvalidSKU(false);
         setProductName(resp.data.productName);
         setInventoryId(resp.data.inventoryId);
      } else {
         setInvalidSKU(true);
         setProductName("");
      }


   }

   return (
      <div className="content">
         <Card>
            <CardHeader>
               <CardTitle tag="h4">
                  <Link to={{ state: props.location.state, pathname: "/admin/marketplace/list" }}>
                     <i style={{ float: 'right', right: '1%' }} className="nc-icon nc-simple-remove" />
                  </Link>

               </CardTitle>
            </CardHeader>
            <CardBody>
               <Form>
                  <FormGroup>
                     <label>Catalogue SKU</label>
                     <Input type="text" value={catalogueName} readOnly />
                  </FormGroup>
                  <FormGroup>
                     <label>Inventory SKU</label>
                     <Input type="text" value={inventorySKU} onChange={(e) => ValidateSKU(e.target.value)} />
                  </FormGroup>
                  <FormGroup>
                     <label>Inventory Name</label>
                     <Input type="text" value={inventoryName} readOnly />
                  </FormGroup>
               </Form>
            </CardBody>
            <CardFooter>
               <Button onClick={Submit} disabled={invalidSKU}>Submit</Button>
            </CardFooter>
         </Card>
      </div>
   )
}