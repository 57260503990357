import React, { useState, useEffect, Fragment } from 'react'
import { Table } from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { getProductLog } from "services/dataservice";
import moment from "moment";

export default function ProductLog(props) {
    const [offset, setOffset] = useState(0);
  const querySize = 50;
  const [message, setMessage] = useState([]);
  useEffect(() => {
    getProductLog(props.catalogueBasicInfoId, querySize, offset).then((response) => {
      const { data } = response.data;

      const dataMapped = data.map((item) => {
        return {
          createdOn: moment(item.createdOn).format("DD/MM/YYYY h:mm:ss a"),
          message: item.message,
        };
      });
      setMessage(dataMapped);
      setOffset(offset + data.length);
    });
  }, []);
  return (
    <Fragment>
      <InfiniteScroll
        dataLength={message.length}
        loader={<h4>Loading...</h4>}
        style={{ overflow: "visible" }}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>-End-</b>
          </p>
        }
        {...props}>
        <Table>
          <thead>
            <tr>
              <th>Log Date</th>
              <th>Message</th>
            </tr>
          </thead>
          {message.length > 0 ? (
            <tbody>
              {message.map((m, index) => (
                <tr key={index}>
                  <td>{m.createdOn}</td>
                  <td>{m.message}</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td></td>
                <td>No Message</td>
              </tr>
            </tbody>
          )}
        </Table>
      </InfiniteScroll>
    </Fragment>
  )
}
