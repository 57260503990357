import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, CardHeader, CardTitle, Form } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
//Api
import { getProductDetail } from "services/dataservice";
//Components
import BasicInfo from "../Components/BasicInfo";
import Options from "../Components/Options/Options3";
import OptionImages from "../Components/Options/OptionImages";
import Skus from "../Components/Skus/Skus";
import EditFormSubmit from "./Components/EditFormSubmit";
import { generateCancelToken } from "views/product/fetch";

export default function ShopifyProductDetail(props) {
  const [alert, setAlert] = useState(null);

  const showFail = useCallback((msg) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      ></SweetAlert>
    );
  }, []);

  const [product, setProduct] = useState({});
  const [tags, setTags] = useState([]);
  const [platformName, setPlatformName] = useState("");
  const [basicInformation, setBasicInformation] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [optionImages, setOptionImages] = useState({});
  const [optionList, setOptionList] = useState([]);
  const [variations, setVariations] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // let productObj = {
    //   catalogueBasicInfoId: 19,
    //   description: "1212",
    //   productName: "Huawei Nova 5T",
    //   categoryId: null,
    //   categoryValue: null,
    //   tags: "pro,max",
    //   active: true,
    //   isVariation: true,
    //   platformName: "Shopify",
    //   userMarketplaceId: 27,
    //   options: [{ name: "Color" }, { name: "Size" }],
    //   status: null,
    //   variations: [
    //     {
    //       inventoryId: 95449,
    //       inventorySKU: "1121112",
    //       quantity: 0,
    //       variationId: "42419308822779",
    //       sellingPrice: 1,
    //       sellingPriceState: true,
    //       costPrice: 0,
    //       option: null,
    //       barcode1: "1",
    //       barcode2: null,
    //       weight: 1,
    //       height: 1,
    //       width: 1,
    //       length: 1,
    //       status: null,
    //       skuImages: [
    //         {
    //           name: "20220105015133219_3e205567bdd00beb31d518afa1a78efe.png",
    //           url: "/api/catalogue/getImage?fileName=20220105015133219_3e205567bdd00beb31d518afa1a78efe.png",
    //         },
    //       ],
    //       optionList: [
    //         {
    //           catalogueAttributeId: 569,
    //           oValue: "Red",
    //           oState: true,
    //         },
    //         {
    //           catalogueAttributeId: 566,
    //           oValue: "X",
    //           oState: true,
    //         },
    //       ],
    //     },
    //     {
    //       inventoryId: 95448,
    //       inventorySKU: "1121111",
    //       quantity: 0,
    //       variationId: "42419308855547",
    //       sellingPrice: 1,
    //       sellingPriceState: true,
    //       costPrice: 0,
    //       option: null,
    //       barcode1: "1",
    //       barcode2: null,
    //       weight: 1,
    //       height: 1,
    //       width: 1,
    //       length: 1,
    //       status: null,
    //       skuImages: [
    //         {
    //           name: "20220105015133219_3e205567bdd00beb31d518afa1a78efe.png",
    //           url: "/api/catalogue/getImage?fileName=20220105015133219_3e205567bdd00beb31d518afa1a78efe.png",
    //         },
    //       ],
    //       optionList: [
    //         {
    //           catalogueAttributeId: 567,
    //           oValue: "Purple",
    //           oState: true,
    //         },
    //         {
    //           catalogueAttributeId: 563,
    //           oValue: "L",
    //           oState: true,
    //         },
    //       ],
    //     },
    //   ],
    //   attributes: [],
    //   productImages: [
    //     {
    //       name: "20220105015133219_3e205567bdd00beb31d518afa1a78efe.png",
    //       url: "/api/catalogue/getImage?fileName=20220105015133219_3e205567bdd00beb31d518afa1a78efe.png",
    //     },
    //   ],
    // };
    // let { productName, description, productImages, variations, options, platformName, tags } = productObj;
    const{cancel,cancelToken}=generateCancelToken()
    const fetchProductDetail = async () => {
      try {
        let CatalogueBasicInfoId = props.match.params.id;
        let response = await getProductDetail(CatalogueBasicInfoId,{cancelToken});
        if (response.status === 200) {
          //console.log(response.data);
          let { data: productDetail } = response.data;
          //console.log(productDetail[0]);

          setProduct(productDetail[0]);

          let { productName, description, tags, productImages, optionImages, isVariation, variations, options, platformName } = productDetail[0];

          setBasicInformation({
            productName,
            description,
          });

          if (tags) {
            let tagsSplited = tags.split(",");
            setTags(tagsSplited);
          }

          setProductImages(productImages);
          variations.forEach(v=>{
            v["inventorySKUState"] = true;
            v["inventorySKUFound"] = false;
            v["barcode1State"] = true;
            v["weightState"] = true;
            v["heightState"] = true;
            v["widthState"] = true;
            v["lengthState"] = true;
          })
          setVariations(variations);
          setPlatformName(platformName);
          if (isVariation) {
            let oImage = {};
            optionImages.forEach((o) => {
              oImage[o.option] = o.image;
            });
            // console.log(oImage);

            let oList = options.map((o) => {
              return { name: o.name, value: o.value.map((v) => v) };
            });
            // if (options.length === 1){
            //   options.push({name:"",value:[]})
            // }
            setOptions(options);
            setOptionList(oList);
            setOptionImages(oImage);
          }
        }
      } catch (error) {
        // console.log("error: ", error);
        showFail("Server Error [GetProduct]")
      }
    };
    fetchProductDetail();

    // let productDetail = [
    //   {
    //     catalogueBasicInfoId: 79,
    //     description: "121212",
    //     productName: "Huawei Nova 5T",
    //     brand: "No Brand",
    //     categoryId: "3",
    //     categoryValue: "Smartphones",
    //     parentSku: "colorssssssssssssss",
    //     tags: null,
    //     active: false,
    //     isVariation: false,
    //     marketplace: null,
    //     status: null,
    //     userMarketplaceId: 43,
    //     platformName: "Shopify",
    //     variations: [
    //       {
    //         inventoryId: 96048,
    //         inventorySKU: "shopify001",
    //         quantity: 0,
    //         variationId: null,
    //         sellingPrice: 1,
    //         sellingPriceState: true,
    //         costPrice: 0,
    //         option: null,
    //         barcode1: "1",
    //         barcode2: null,
    //         weight: 1,
    //         height: 1,
    //         width: 1,
    //         length: 1,
    //         status: null,
    //         skuImages: null,
    //         optionList: [
    //           {
    //             catalogueAttributeId: 963,
    //             oValue: "Blue",
    //             oState: true,
    //           },
    //           {
    //             catalogueAttributeId: 965,
    //             oValue: "Wood",
    //             oState: true,
    //           },
    //         ],
    //         variant: "BLUE/WOOD",
    //       },
    //       {
    //         inventoryId: 96004,
    //         inventorySKU: "shopify002",
    //         quantity: 0,
    //         variationId: null,
    //         sellingPrice: 1,
    //         sellingPriceState: true,
    //         costPrice: 0,
    //         option: null,
    //         barcode1: "fawfawflfawfa",
    //         barcode2: null,
    //         weight: 3.2,
    //         height: 4.6,
    //         width: 4.5,
    //         length: 3.2,
    //         status: null,
    //         skuImages: null,
    //         optionList: [
    //           {
    //             catalogueAttributeId: 962,
    //             oValue: "Blue",
    //             oState: true,
    //           },
    //           {
    //             catalogueAttributeId: 964,
    //             oValue: "Plastic",
    //             oState: true,
    //           },
    //         ],
    //         variant: "BLUE/PLASTIC",
    //       },
    //     ],
    //     newVariations: null,
    //     variation: null,
    //     attributes: '{"warranty_type":["International Manufacturer Warranty"]}',
    //     productImages: [],
    //     optionImages: {
    //       Blue: [{ name: "20220117012047122_3e205567bdd00beb31d518afa1a78efe.png", url: "/api/catalogue/getImage?fileName=20220117012047122_3e205567bdd00beb31d518afa1a78efe.png" }],
    //     },
    //     options: [
    //       {
    //         name: "Color",
    //         value: ["Blue"],
    //       },
    //       {
    //         name: "Material",
    //         value: ["Wood", "Plastic"],
    //       },
    //       // {
    //       //   name: "size",
    //       //   value: ["X", "L"],
    //       // },
    //     ],
    //   },
    // ];
    // let { productName, description, tags, productImages, optionImages, variations, platformName, options } = productDetail[0];
    // setProduct(productDetail[0]);

    // setBasicInformation({
    //   productName,
    //   description,
    // });

    // if (tags) {
    //   let tagsSplited = tags.split(",");
    //   setTags(tagsSplited);
    // }

    // let oList = options.map((o) => {
    //   return { name: o.name, value: o.value.map((v) => v) };
    // });

    // setProductImages(productImages);
    // setVariations(variations);
    // setPlatformName(platformName);
    // setOptions(options);
    // setOptionList(oList);
    // setOptionImages(optionImages);

    //console.log("@useEffect");
    return()=>{console.log("Request Cancelled")
  cancel()}
  }, []);

  const basicInfoProps = {
    basicInformation,
    setBasicInformation,
    productImages,
    setProductImages,
    platformName,
    tags,
    setTags,
  };

  const optionProps = {
    platformName,
    options,
    setOptions,
    optionList,
    setOptionList,
    optionImages,
    setOptionImages,
    variations,
    setVariations,
    showFail,
  };

  const optionImagesProps = {
    optionList,
    optionImages,
    setOptionImages,
    count: 1,
    multiple: false,
  };

  const skusProps = {
    variations,
    setVariations,
    showFail,
    platformName,
    // options,
    optionList,
  };

  const editFormSubmitProps = {
    setAlert,
    product,
    basicInformation,
    tags,
    productImages,
    variations,
    setVariations,
    optionImages,
    optionList,
    showFail,
  };

  return (
    <div className="content">
      <Form>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              Edit Product (Shopify)
              <Link to={{ state: props.location.state, pathname: "/admin/product/list" }}>
                <i style={{ float: "right", right: "1%" }} className="nc-icon nc-simple-remove" />
              </Link>
            </CardTitle>
            {alert}
          </CardHeader>

          <BasicInfo {...basicInfoProps} />
          {product.isVariation && (
            <>
              <Options {...optionProps} />
              <OptionImages {...optionImagesProps} />
            </>
          )}

          <Skus {...skusProps} />
          {/* <Button onClick={() => console.log(variations)}>show variations</Button>
          <Button onClick={() => console.log(newSkus)}>show newSkus</Button>
          <Button onClick={()=>console.log(optionImages)}>img</Button>
          <Button onClick={()=>console.log(optionList)}>list</Button> */}
          {!props.location.state&&<EditFormSubmit {...editFormSubmitProps} />}
          {/* <Button onClick={()=>{console.log(options)}}> options</Button>
          <Button onClick={()=>{console.log(optionList)}}> optionsList</Button>
          <Button onClick={()=>{console.log(optionImages)}}>option images</Button> */}
        </Card>
      </Form>
    </div>
  );
}
