import React, { useEffect, useState } from "react";
import { Row, Col, Form, Card, CardHeader, CardTitle, CardBody, FormGroup, Label, Input, Button, CardFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import SelectAsync from 'react-select/async'
import ReactBSAlert from "react-bootstrap-sweetalert";
import { DatePicker } from "antd";
import moment from "moment";
import "./index.css";

import { addPromotion, getMarketplaceList,searchCatalogue } from "services/dataservice";

import PromotionItemsOptionOne from "./PromotionItemsOptionOne";
import PromotionItemsOptionSecThird from "./PromotionItemsOptionSecThird";
import PromotionItemsGift from "./PromotionItemsGift";
import PromotionGreater from "./PromotionGreater";
import PromotionRange from "./PromotionRange";
import PromotionItemsBulkPrimaryModal from "./PromotionItemsBulkPrimaryModal";

export default function PromotionAdd() {
  const [promotion, setPromotion] = useState({
    promotionId: 0,
    promotionName: "",
    startDate: "",
    endDate: "",
    store: [],
    description: "",
    promotionCriteria: "",
    promotionOption: 0,
    // promotionCriteria: "Items",
    // promotionOption: "1",
    GWPPrimary: [],
    GWPGift: [],
    quantity: 0,
    greaterValue: "",
    edit: false,
  });

  const history = useHistory();
  const [alert, setAlert] = useState(null);
  const [optionListStore, setOptionListStore] = useState([]);

  const [promotionNameState, setPromotionNameState] = useState("has-success");
  const [startDateState, setStartDateState] = useState("has-success");
  const [storeState, setStoreState] = useState("has-success");
  const [itemsQuantityState, setItemsQuantityState] = useState("has-success");
  const [greaterAmountState, setGreaterAmountState] = useState("has-success");
  const [greaterQuantityState, setGreaterQuantityState] = useState("has-success");

  const [dataSourceItemsOptionOne, setDataSourceItemsOptionOne] = useState([]);
  const [dataSourceItemsOptionSecThird, setDataSourceItemsOptionSecThird] = useState([]);
  const [dataSourceItemsGift, setDataSourceItemsGift] = useState([]);
  const [dataSourceGreater, setDataSourceGreater] = useState([]);
  const [dataSourceRange, setDataSourceRange] = useState([]);

  const[tempSKU,setTempSKU]=useState("")
  const[tempGiftSKU,setTempGiftSKU]=useState("")

  const[quantity,setQuantity]=useState(0)

  const [modalShow, setModalShow] = useState(false);
  const toggle = () => {
    setModalShow(!modalShow);
  };

  // ---------------------- Date Range -----------------------------
  const { RangePicker } = DatePicker;
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  // --------------------- useEffect -------------------------------
  useEffect(() => {
    const loadUserMarketplace = async () => {
      try {
        const resp = await getMarketplaceList();

        let option = resp.data.platformList.map((x) => ({ value: x.userMarketPlaceId.toString(), label: x.name }));
        setOptionListStore(option);
      } catch (error) {
        showFail("Could not load store");
      }
    };
    loadUserMarketplace();
  }, []);

  // ----------------- on change --------------------------
  const onChangePromotionName = (e) => {
    if (!e.target.value) {
      setPromotionNameState("has-danger");
    } else {
      setPromotionNameState("has-success");
    }
    promotion["promotionName"] = e.target.value;
    setPromotion({ ...promotion });
  };

  const onChangeStore = (e, prop) => {
    if (e === null || e.length === 0) {
      setStoreState("errorState");
    } else {
      setStoreState("has-success");
    }
    promotion[prop] = e;
    setPromotion({ ...promotion });
  };

  function onChangeDate(date, dateString) {
    if (!dateString[1]) {
      setStartDateState("errorState");
    } else {
      setStartDateState("has-success");
    }
    promotion["startDate"] = dateString[0];
    promotion["endDate"] = dateString[1];
    setPromotion({ ...promotion });
  }

  const onChangePromotionCriteria = (e, prop) => {
    const { value, label } = e;
    promotion[prop] = label;
    if(label==="Order Total"||label==="Product Quantity"){
      promotion["promotionOption"] = "2"
      setDataSourceRange([])
    }else{
      promotion["promotionOption"] = 0;
    }
    setPromotion({ ...promotion });
  };

  const onChangePromotionOption = (e, prop) => {
    const { value, name } = e;
    promotion[prop] = value;
    setDataSourceItemsGift([]);
    //setDataSourceGreater([]);
    setDataSourceItemsOptionOne([]);
    setDataSourceItemsOptionSecThird([]);
    //setDataSourceRange([]);
    setPromotion({ ...promotion });
  };

  const onChangeDescription = (e) => {
    promotion["description"] = e.target.value;
    setPromotion({ ...promotion });
  };

  // --------------------- props ----------------------------
  const promotionItemsOptionOne = {
    tempSKU,
    setTempSKU,
    dataSourceItemsOptionOne,
    setDataSourceItemsOptionOne,
    quantity,
    promotion,
    setPromotion,
    setAlert,
  };

  const promotionItemsOptionSecThird = {
    tempSKU,
    setTempSKU,
    dataSourceItemsOptionSecThird,
    setDataSourceItemsOptionSecThird,
    promotion,
    setAlert,
    setPromotion,
  };

  const promotionItemsGift = {
    tempGiftSKU,
    setTempGiftSKU,
    dataSourceItemsGift,
    setDataSourceItemsGift,
    promotion,
    setPromotion,
    setAlert,
  };

  const promotionGreater = {
    tempGiftSKU,
    setTempGiftSKU,
    dataSourceGreater,
    setDataSourceGreater,
    promotion,
    greaterAmountState,
    greaterQuantityState,
    setAlert,
    onChangeDescription,
    setPromotion,
    setGreaterAmountState,
    setGreaterQuantityState,
  };

  const promotionRange = {
    tempGiftSKU,
    setTempGiftSKU,
    dataSourceRange,
    setDataSourceRange,
    promotion,
    setAlert,
    onChangeDescription,
    setPromotion,
  };

  // -----------------------------------------------------------
  const onSubmit = async () => {
    let validate = true;
    console.log(promotion);

    if (!promotion.promotionName) {
      setPromotionNameState("has-danger");
      validate = false;
    } else {
      setPromotionNameState("has-success");
    }

    if (promotion.store === null || promotion.store.length === 0) {
      setStoreState("errorState");
      validate = false;
    } else {
      setStoreState("has-success");
    }

    if (!promotion.startDate || !promotion.endDate) {
      setStartDateState("errorState");
      validate = false;
    } else {
      setStartDateState("has-success");
    }

    if (promotion.promotionCriteria === "Items" && promotion.promotionOption === "1") {
      var txtQuantity = document.getElementById("quantity").value;
      promotion["quantity"] = txtQuantity;
      promotion["GWPPrimary"] = dataSourceItemsOptionOne;
      promotion["GWPGift"] = dataSourceItemsGift;
      setPromotion({ ...promotion });
      const regex = /^\d{1,10}$/;
      if (promotion.quantity === "" || promotion.quantity < 1 || !promotion.quantity.match(regex)) {
        setItemsQuantityState("has-danger");
        validate = false;
      } else {
        setItemsQuantityState("has-success");
      }
      if (promotion.GWPPrimary === [] || promotion.GWPGift === [] || promotion.GWPPrimary.length === 0 || promotion.GWPGift.length === 0) {
        showFail("Primary products and gifts cannot be empty");
        return;
      }
    }

    if (promotion.promotionCriteria === "Items" && (promotion.promotionOption === "2" || promotion.promotionOption === "3")) {
      promotion["GWPPrimary"] = dataSourceItemsOptionSecThird;
      promotion["GWPGift"] = dataSourceItemsGift;
      setPromotion({ ...promotion });
      if (promotion.GWPPrimary === [] || promotion.GWPGift === [] || promotion.GWPPrimary.length === 0 || promotion.GWPGift.length === 0) {
        showFail("Primary products and gifts cannot be empty");
        return;
      }
    }

    if (promotion.promotionCriteria === "Order Total" && promotion.promotionOption === "1") {
      promotion["GWPGift"] = dataSourceGreater;
      promotion["greaterValue"] = document.getElementById("greaterAmount").value;
      setPromotion({ ...promotion });
      const regex = /^\d{1,10}\.{0,1}\d{0,2}$/;
      if (promotion.greaterValue < 0.01 || !promotion.greaterValue.match(regex)) {
        setGreaterAmountState("has-danger");
        validate = false;
      } else {
        setGreaterAmountState("has-success");
      }
      if (promotion.GWPGift === [] || promotion.GWPGift.length === 0) {
        showFail("Gifts cannot be empty");
        return;
      }
    }

    if (promotion.promotionCriteria === "Product Quantity" && promotion.promotionOption === "1") {
      promotion["GWPGift"] = dataSourceGreater;
      promotion["greaterValue"] = document.getElementById("greaterQuantity").value;
      setPromotion({ ...promotion });
      const regex = /^\d{1,10}$/;
      if (promotion.greaterValue < 1 || !promotion.greaterValue.match(regex)) {
        setGreaterQuantityState("has-danger");
        validate = false;
      } else {
        setGreaterQuantityState("has-success");
      }
      if (promotion.GWPGift === [] || promotion.GWPGift.length === 0) {
        showFail("Gifts cannot be empty");
        return;
      }
    }

    if ((promotion.promotionCriteria === "Order Total" || promotion.promotionCriteria === "Product Quantity") && promotion.promotionOption === "2") {
      promotion["GWPGift"] = dataSourceRange;
      setPromotion({ ...promotion });
      if (promotion.GWPGift === [] || promotion.GWPGift.length === 0) {
        showFail("Gifts cannot be empty");
        return;
      }
    }

    console.log(promotion);

    if (!validate) {
      showFail("Please fill in the field correctly.");
    } else {
      try {
        let resp2 = await addPromotion(promotion);
        console.log(resp2);
        history.push("/admin/promotion/list");
      } catch (e) {
        if (e.response.status === 400) showFail(e.response.data.description);
        else showFail(e.message);
      }
    }
  };

  const showFail = (msg) => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""></ReactBSAlert>
    );
  };

  const selectedId=promotion?.store?.length>0?promotion?.store[0].value:"0"

  return (
    <div className="content">
      {alert}
      <Row>
        <Col md="12">
          <Form>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Add Promotion</CardTitle>
              </CardHeader>
              <CardBody>
                <FormGroup className={`has-label ${promotionNameState}`}>
                  <Label>
                    Promotion Name <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input type="text" name="promotionName" onChange={(e) => onChangePromotionName(e)} />
                  {promotionNameState === "has-success" ? null : <label className="error">This field is required.</label>}
                </FormGroup>
                <FormGroup className={storeState}>
                  <Label>Sales Channel Store *</Label>
                  <Select className="react-select info" classNamePrefix="react-select form-control" options={optionListStore} isMulti onChange={(e) => onChangeStore(e, "store")} />
                  {storeState === "has-success" ? null : <label style={{ color: "#ef8157" }}>This field is required.</label>}
                </FormGroup>
                <FormGroup className={startDateState}>
                  <Label>Promotion Date</Label> <br></br>
                  <RangePicker
                    disabledDate={disabledDate}
                    showTime={{ format: "HH:mm:ss" }}
                    style={{ padding: "8px 10px", border: "1px solid #ccc" }}
                    format="DD-MM-YYYY HH:mm:ss"
                    onChange={onChangeDate}
                  />
                  {startDateState === "has-success" ? null : (
                    <>
                      <br></br>
                      <label style={{ color: "#ef8157" }}>This field is required.</label>
                    </>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label>Promotion Criteria</Label>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select form-control"
                    options={[
                      { value: "1", label: "Items" },
                      { value: "2", label: "Order Total" },
                      { value: "3", label: "Product Quantity" },
                    ]}
                    onChange={(e) => onChangePromotionCriteria(e, "promotionCriteria")}
                  />
                </FormGroup>
                {promotion.promotionCriteria === "Items" && (
                  <>
                    <FormGroup>
                      <Label>Promotion Option</Label>
                      <Select
                        className="react-select info"
                        classNamePrefix="react-select form-control"
                        options={[
                          { value: "1", label: "Apply promotion on purchase of at least one of the primary products" },
                          { value: "2", label: "Apply promotion on purchase of all the primary products" },
                          { value: "3", label: "Apply promotion on purchase of a specific number of primary products pre-defined by you" },
                        ]}
                        onChange={(e) => onChangePromotionOption(e, "promotionOption")}
                      />
                    </FormGroup>
                  </>
                )}
                {promotion.promotionCriteria === "Order Total" && (
                  <FormGroup>
                    <Label>Promotion Option</Label>
                    {/* <Select
                      className="react-select info"
                      classNamePrefix="react-select form-control"
                      options={[
                        { value: "1", label: "Greater than" },
                        { value: "2", label: "Amount Range" },
                      ]}
                      onChange={(e) => onChangePromotionOption(e, "promotionOption")}
                    /> */}
                    <Input type="text" value="Amount Range" disabled/>
                  </FormGroup>
                )}
                {promotion.promotionCriteria === "Product Quantity" && (
                  <FormGroup>
                    <Label>Promotion Option</Label>
                    {/* <Select
                      className="react-select info"
                      classNamePrefix="react-select form-control"
                      options={[
                        { value: "1", label: "Greater than" },
                        { value: "2", label: "Quantity Range" },
                      ]}
                      onChange={(e) => onChangePromotionOption(e, "promotionOption")}
                    /> */}
                    <Input type="text" value="Quantity Range" disabled/>
                  </FormGroup>
                )}
                {/* {JSON.stringify(promotion)} */}
              </CardBody>
            </Card>
            {promotion.promotionCriteria === "Items" && promotion.promotionOption !== 0 && (
              <Card style={{ backgroundColor: "#fafdf9" }}>
                <CardHeader>
                  <CardTitle tag="h6">Product Category & Free Gift</CardTitle>
                </CardHeader>
                <CardBody>
                   
                  <SKUSearch id={selectedId} value="primary" toggle={toggle} onChange={setTempSKU}/>
                   
                   
                   
                  
                  {promotion.promotionOption === "1" ? (
                    <>
                      <Row>
                        <Col md="6">
                          <FormGroup className={`has-label ${itemsQuantityState}`}>
                            <Label>Quantity <span style={{color:"blue",fontWeight:"bold"}}>(Apply For All Primary SKU)</span></Label>
                            <Input
                              type="number"
                              name="quantity"
                              id="quantity"
                              onChange={(e) => {
                                const regex = /^\d{1,10}$/;
                                if (e.target.value === "" || e.target.value < 1 || !e.target.value.match(regex)) {
                                  setItemsQuantityState("has-danger");
                                } else {
                                  setQuantity(e.target.value)
                                  if(dataSourceItemsOptionOne.length>0){
                                    const newData=dataSourceItemsOptionOne.map(x=>{
                                      const{quantity:qty,...x2}=x
                                      return {quantity:e.target.value,...x2}
                                      //return {...x.quantity=e.target.value,...x}
                                    })
                                    //console.log(newData)
                                    setDataSourceItemsOptionOne(newData)
                                  }
                                  setItemsQuantityState("has-success");
                                }
                              }}
                            />
                            {itemsQuantityState === "has-success" ? null : <label className="error">This field is required. Mininium quantity is 1.</label>}
                          </FormGroup>
                        </Col>
                      </Row>
                      <PromotionItemsOptionOne {...promotionItemsOptionOne} />
                      <PromotionItemsBulkPrimaryModal isOpen={modalShow} toggle={toggle} {...promotionItemsOptionOne}/>
                    </>
                  ) : (
                    <>
                    <PromotionItemsOptionSecThird {...promotionItemsOptionSecThird} />
                    <PromotionItemsBulkPrimaryModal isOpen={modalShow} toggle={toggle} {...promotionItemsOptionSecThird}/>
                    </>
                  )}
                  <SKUSearch id={selectedId} value="gift" onChange={setTempGiftSKU}/>
                  <PromotionItemsGift {...promotionItemsGift} />
                  <FormGroup>
                    <Label>Description</Label>
                    <Input type="text" name="description" onChange={(e) => onChangeDescription(e)} />
                  </FormGroup>
                </CardBody>

                <CardFooter>
                  <Button color="primary" onClick={onSubmit}>
                    Submit
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      history.push("/admin/promotion/list");
                    }}>
                    Cancel
                  </Button>
                </CardFooter>
              </Card>
            )}
            {promotion.promotionCriteria === "Order Total" && promotion.promotionOption !== 0 && (
              <Card style={{ backgroundColor: "#fefdfd" }}>
                <CardBody>
                <SKUSearch id={selectedId} value="gift" onChange={setTempGiftSKU}/>
                </CardBody>
                 
                {promotion.promotionOption === "1" ? <PromotionGreater {...promotionGreater} /> : <PromotionRange {...promotionRange} />}
                {/* <PromotionRange {...promotionRange} /> */}
                <CardFooter>
                  <Button color="primary" onClick={onSubmit}>
                    Submit
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      history.push("/admin/promotion/list");
                    }}>
                    Cancel
                  </Button>
                </CardFooter>
              </Card>
            )}
            {promotion.promotionCriteria === "Product Quantity" && promotion.promotionOption !== 0 && (
              <Card style={{ backgroundColor: "#fcf9f9" }}>
                <CardBody>
                <SKUSearch id={selectedId} value="gift" onChange={setTempGiftSKU}/>
                </CardBody>
                 
                {/* {promotion.promotionOption === "1" ? 
                <PromotionGreater {...promotionGreater} /> 
                : <PromotionRange {...promotionRange} />} */}
                <PromotionRange {...promotionRange} />
                <CardFooter>
                  <Button color="primary" onClick={onSubmit}>
                    Submit
                  </Button>{" "}
                  <Button
                    onClick={() => {
                      history.push("/admin/promotion/list");
                    }}>
                    Cancel
                  </Button>
                </CardFooter>
              </Card>
            )}
          </Form>
        </Col>
      </Row>
    </div>
  );
}

const SKUSearch=({id,value,onChange,toggle})=>{
  const [options,setOptions]=useState([])
  const [filter, setFilter] = useState("sku");
  const [giftFilter, setGiftFilter] = useState("sku");
  const loadOptions = (e, v) => {
    //console.log(v)
    //console.log(options)
    //console.log(e)
    if(id===null||id==="0"){
      v([])
      return
    }
    if (e.length < 3) {
      v([]);
    } else {
      setTimeout(async () => {
        try {
          const options = await searchCatalogue(id, value === "primary" ? filter : giftFilter, e);
          const res = options.data.map((o) => {
            return {
              value: o.marketplaceSKU,
              label: `${o.marketplaceSKU}-----${o.name}`,
            };
          });
          v(res);
          setOptions(res)
        } catch (error) {
          v([]);
        }
      });
    }
  }
  const onOptionChange=(v)=>{
    onChange(v.value)
  }
  return(
    <FormGroup>
      <Row>
        {value === "primary" ? (
          <>
            <Col md="2">
              <label style={{ color: "blue", fontWeight: "bold" }}>Search Primary Product SKU By</label>
            </Col>
            <Col md="1">
              <label>SKU:</label>
              <input style={{ marginLeft: "5px" }} type="radio" name="NameSKU" label="123" value={`SKU`} onChange={() => setFilter("sku")} checked={filter === "sku" ? true : false} />
            </Col>
            <Col md="1">
              <label>Name:</label>
              <input style={{ marginLeft: "5px" }} type="radio" name="NameSKU" label="123" value={`Name`} onChange={() => setFilter("name")} checked={filter === "name" ? true : false} />
            </Col>
            <Col>
            <Button style={{margin:"-10px 0 5px 0",backgroundColor: "#e0eeec", color: "black" }} onClick={toggle}>
            Bulk Add
          </Button>
            </Col>
             
             
          </>
        ) : (
          <>
            <Col md="2">
              <label style={{ color: "blue", fontWeight: "bold" }}>Search Gift Product SKU By</label>
            </Col>
            <Col md="1">
              <label>SKU:</label>
              <input style={{ marginLeft: "5px" }} type="radio" name="NameSKU2" label="123" value={`SKU2`} onChange={() => setGiftFilter("sku")} checked={giftFilter === "sku" ? true : false} />
            </Col>
            <Col>
              <label>Name:</label>
              <input style={{ marginLeft: "5px" }} type="radio" name="NameSKU2" label="123" value={`Name2`} onChange={() => setGiftFilter("name")} checked={giftFilter === "name" ? true : false} />
            </Col>
          </>
        )}
      </Row>
      <SelectAsync
        //cacheOptions
        className="react-select info"
        classNamePrefix="react-select form-control"
        placeholder="Search here..."
        //options={options}
        loadOptions={loadOptions}
        defaultOptions={options}
        onChange={onOptionChange}
      />
    </FormGroup>
  )
}
