/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

import { login } from 'services/dataservice'
import ReactBSAlert from "react-bootstrap-sweetalert";


class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      password: '',
      alert: null
    }
  }
  componentDidMount() {
    localStorage.removeItem("user_name");
    localStorage.removeItem("jwtToken");
    document.body.classList.toggle("login-page");

  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  invalidAlert() {
    this.setState({
      alert: (
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Invalid UserID \ Password"
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
        >
        </ReactBSAlert>
      ),
    });
    setTimeout(this.hideAlert, 2000);
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  login(e) {
    login(this.state.userId, this.state.password).then(x => {
      //console.log(x.data);
      if (x.data.error != null) {
        this.invalidAlert();
        console.log(x.data.description);
      }
      else {
        localStorage.setItem("user_name", this.state.userId)
        localStorage.setItem("jwtToken", x.data.token);
        this.props.history.push("/admin/order/list");
        //window.location = "/admin/order/list";
      }
    });
  }

  handleLoginEnter(e) {
    if (e.key === 'Enter') {
      this.login(null);
    }
  }
  render() {
    return (
      <div className="login-page">
        {this.state.alert}
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form action="" className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Login</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input placeholder="User Name" type="text" onChange={(e) => this.setState({ userId: e.target.value })} />
                    </InputGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-key-25" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="off"
                        id="txtPassword"
                        onChange={(e) => this.setState({ password: e.target.value })}
                        onKeyPress={(e) => this.handleLoginEnter(e)}
                      />
                    </InputGroup>
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      href="#pablo"
                      onClick={(e) => this.login(e)}
                    >
                      Login
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/snt.jpg")})`,
          }}
        />
      </div>
    );
  }
}

export default Login;
