import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

export default function ParentSku(props) {
  let { parentSKU, setParentSKU } = props;
  return (
    <FormGroup>
      <Label>Parent Sku</Label>
      <Input type="text" defaultValue={parentSKU} onBlur={(e) => setParentSKU(e.target.value.trim())} />
    </FormGroup>
  );
};
