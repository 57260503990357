import React, { useEffect, useState, useRef, Fragment } from 'react';
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Table, Badge, Button, UncontrolledTooltip, Input } from 'reactstrap';
import { useHistory } from "react-router-dom";

import { getOrderDetail, resendOrder, updateOrderStatus, editOrderItem, deleteOrder } from 'services/dataservice';
import Notify from 'react-notification-alert';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { cancelOrder } from 'services/dataservice';

export default function OrderDetail(props) {
   const [orderNumber, setOrderNumber] = useState("");
   const [itemList, setItemList] = useState([]);
   const [recipientName, setRecipientName] = useState("");
   const [contactNumber, setContactNumber] = useState("");
   const [address, setAddress] = useState("");
   const [district, setDistrict] = useState("")
   const [remark, setRemark] = useState("")
   const [postcode, setPostcode] = useState("");
   const [state, setState] = useState("");
   const [orderLog, setOrderLog] = useState([]);
   const [refNo, setRefNo] = useState("");
   const [orderStatus, setOrderStatus] = useState("");
   const [marketplaceId,setMarketplaceId] = useState("")

   const notify = useRef(null);
   let history = useHistory();

   const [alert, setAlert] = useState(null);


   const orderId = props.match.params.id;
   let orderRow = 0;

   useEffect(() => {
      //Load order detail
      //TestTable
      getOrderDetail(orderId).then(res => {
         let data = res.data;
         setOrderNumber(data.orderNumber);
         setRecipientName(data.recipient);
         setContactNumber(data.contactNumber);
         setAddress(data.address);
         setDistrict(data.district)
         setRemark(data.remark??"")
         setState(data.state);
         setPostcode(data.postcode);
         setItemList(data.items);
         setOrderLog(data.logs);
         setRefNo(data.po_number);
         setOrderStatus(data.orderStatus);
         setMarketplaceId(data.marketplace_id.toString())
      });
   }, [])

   const ItemsTable = () => {
      const [editOrderItemId, setEditOrderItemId] = useState(-1);
      const [editOrderItemQuantity, setEditOrderItemQuantity] = useState(-1);
      const [notification, setNotification] = useState(null);

      const onSubmitEdit = async (e, orderItem) => {
         if (e.key == "Enter") {

            console.log(orderItem);

            //Verify pass


            //If fail revert
            const newSKU = e.target.value;
            const orderQuantity = orderItem.orderQuantity;
            console.log(newSKU);
            var resp = await editOrderItem(orderId, editOrderItemId, newSKU, orderQuantity);


            setEditOrderItemId(-1);
            refresh();
         }
      }

      const onSubmitEditOrderQuantity = async (e, orderItem) => {
         if (e.key == "Enter") {
            console.log(orderItem);

            const newSKU = orderItem.marketplaceSKU;
            const orderQuantity = e.target.value;
            console.log(newSKU);
            var resp = await editOrderItem(orderId, editOrderItemQuantity, newSKU, orderQuantity);

            setEditOrderItemQuantity(-1);
            refresh();
         }
      }

      return (
         <Fragment>
            <Table className="table-shopping">
               <thead>
                  <tr>
                     <th>Product</th>
                     <th />
                     <th />
                     <th className="text-right">Order Qty</th>
                     <th className="text-right">Inventory Qty</th>
                  </tr>
               </thead>
               <tbody>
                  {
                     itemList.map((x, index) => (
                        <tr key={x.orderItemId} style={x.orderStatus === "Not_Enough_Stock" ? { color: "red" } : x.isGift ? { color: "blue"} : {}}>
                           <td className="td-name" onDoubleClick={() => setEditOrderItemId(x.orderItemId)}>
                              {x.name}
                              <br />
                              {
                                 x.orderItemId === editOrderItemId && x.orderStatus === "Not_Enough_Stock" ?
                                    <small>
                                       <input type="text" defaultValue={x.marketplaceSKU} onKeyDown={(e) => onSubmitEdit(e, x)} />
                                    </small> :
                                    <small>{x.marketplaceSKU}</small>
                              }
                           </td>
                           <td />
                           <td />
                           <td className="td-number" onDoubleClick={() => setEditOrderItemQuantity(x.orderItemId)}>
                              {
                                 x.orderItemId === editOrderItemQuantity && x.orderStatus === "Not_Enough_Stock" ?
                                    <input style={{width:'50%'}} type="number" defaultValue={x.orderQuantity} onKeyDown={(e) => onSubmitEditOrderQuantity(e, x)} /> :
                                    x.orderQuantity
                              }
                           </td>
                           <td className="td-number">
                              {x.inventory_quantity}
                           </td>
                        </tr>

                     ))
                  }
               </tbody>
            </Table>
         </Fragment>

      )
   }

   const OrderLog = (props) => {
      return (
         <li className={props.count % 2 == 0 ? "timeline-inverted" : null}>
            <div className="timeline-badge danger">
               <i className="nc-icon nc-single-copy-04" />
            </div>
            <div className="timeline-panel">
               <div className="timeline-heading">
                  <Badge color="danger" pill>
                     {props.event}
                  </Badge>
               </div>
               <div className="timeline-body">
                  <p>
                     {props.message}
                  </p>
               </div>
               <h6>
                  <i className="fa fa-clock-o" />
                  {props.createdOn}
               </h6>
            </div>
         </li>
      )
   }

   const refresh = () => {
      getOrderDetail(orderId).then(res => {
         let data = res.data;
         setOrderNumber(data.orderNumber);
         setRecipientName(data.recipient);
         setContactNumber(data.contactNumber);
         setAddress(data.address);
         setDistrict(data.district)
         setRemark(data.remark??"")
         setState(data.state);
         setPostcode(data.postcode);
         setItemList(data.items);
         setOrderLog(data.logs);
         setMarketplaceId(data.marketplace_id.toString())
      });
   }

   const resendClicked = async () => {
      const resp = await resendOrder({ orderId: orderId });

      if (resp.status === 200) {
         var options = {
            place: "br",
            message: `Resend submitted`,
            type: "success",
            autoDismiss: 5,
            icon: ""
         };
         notify.current.notificationAlert(options);
      }

      refresh();
   }

   const updateClicked=async(orderStatus)=>{
      const resp = await updateOrderStatus({
        orderId,
        orderStatus,
        remark:remark.trim()===""?null:remark.trim()
      })

      if(resp.status===200){
         var options = {
            place: "br",
            message: `Request submitted`,
            type: "success",
            autoDismiss: 5,
            icon: ""
         };
         notify.current.notificationAlert(options)
      }
   }

   const cancelClicked = async () => {
      const cancelfn = async () => {
         const resp = await cancelOrder({orderId: orderId})
         setAlert(null)
         if (resp.status === 200) {
            //window.alert(resp.data.description)
            //history.goBack()
            //history.push("/admin/order/list")
            //window.location.replace("/admin/order/list")
            if(resp.data.description){
               showFail(resp.data.description)
            }else{
               window.location.replace("/admin/order/list")
            }
         }
      }

      const hideAlert = () => {
         setAlert(null)
      }

      setAlert(
         <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => cancelfn()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, cancel it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize="">
            You will not be able to recover this order
         </ReactBSAlert>
         )

         const showFail = (msg) => {
            setAlert(
               <ReactBSAlert
                  danger
                  style={{ display: "block", marginTop: "-100px" }}
                  title={msg}
                  onConfirm={() => setAlert(null)}
                  onCancel={() => setAlert(null)}
                  confirmBtnBsStyle="info"
                  btnSize=""
               >
               </ReactBSAlert>
            )
         }
   }

   const deleteClicked = async () => {
      const deletefn = async () => {
         const resp = await deleteOrder({orderId: orderId});
         if (resp.status === 200) {
            history.goBack();
         }

      }

      const hideAlert = () => {
         setAlert(null);
      }
      
      setAlert(
         <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => deletefn()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize="">
            You will not be able to recover this order
          </ReactBSAlert>
          )
   }

   return (
      <div className="content">
         {alert}
         <Notify ref={notify} />
         <Row className="row justify-content-end">
            <Col md="4" className="d-flex flex-row-reverse">
               {orderStatus === "Failed" ? <Button color="danger" onClick={deleteClicked}>Delete</Button> : null}
               {(orderStatus==="New")&&<Button color="success" onClick={()=>{updateClicked("Hold")}} >Hold</Button>}
               {(orderStatus==="Hold")&&<Button color="success" onClick={()=>{updateClicked("New")}} >UnHold</Button>}
               <Button color="primary" onClick={resendClicked}>Resend</Button>
               {(orderStatus === "New" || orderStatus === "Partial") && marketplaceId === "2" && <Button color="danger" onClick={cancelClicked}>Cancel</Button>}
            </Col>
         </Row>
         <Row>
            <Col md="12">
               <Card>
                  <CardHeader>
                     <CardTitle tag="h4">Order {orderNumber}</CardTitle>
                  </CardHeader>
                  <CardBody>
                     <Row>
                        <Col md="6">
                           Recipient Name <br />
                           <strong>{recipientName}</strong>
                        </Col>
                        <Col md="6">
                           Contact Number <br />
                           <strong>{contactNumber}</strong>
                        </Col>
                     </Row>
                     <Row>
                        <Col md="6">
                           Ref No<br />
                           <strong>{refNo}</strong>
                        </Col>
                     </Row>
                     <Row style={{ marginTop: "20px" }}>
                        <Col>
                           <u>Address</u><br />
                           <strong>{address}</strong><br />
                           <strong>{postcode}, {district}</strong><br />
                           <strong>{state}</strong>
                        </Col>
                     </Row>
                     <Row style={{marginTop:"20px"}}>
                        <Col>
                           <u>Notes</u><br/>
                           <Row>
                              <Col md="10" style={{padding:"15px"}}>
                                 <Input type="text" value={remark} onChange={(e)=>setRemark(e.target.value)}  />
                              </Col>
                              <Col>
                                 <Button color="info" onClick={()=>{updateClicked(null)}} >Submit</Button>
                              </Col>
                           </Row>
                        </Col>
                     </Row>
                  </CardBody>
               </Card>
            </Col>

         </Row>
         <Row>
            <Col md="12">
               <Card>
                  <CardHeader>
                     <CardTitle tag="h4">Items</CardTitle>
                  </CardHeader>
                  <CardBody>
                     <ItemsTable />
                  </CardBody>
               </Card>
            </Col>
         </Row>

         <Card className="card-timeline card-plain">
            <CardHeader>
               <CardTitle tag="h4">Order Log</CardTitle>
            </CardHeader>
            <CardBody>
               <ul className="timeline">
                  {
                     orderLog.map(x => {
                        return (<OrderLog event={x.event} message={x.message} key={x.orderLogId} createdOn={x.createdOn} count={orderRow++}></OrderLog>)
                     })
                  }
               </ul>
            </CardBody>
         </Card>
      </div>
   )

}