import React, { useEffect, useState } from 'react';
import ReactTable from "components/ReactTable/ReactTable.js";
import {Form, FormGroup, Button, InputGroup, InputGroupAddon, Input, Card, CardHeader, CardBody, CardTitle} from 'reactstrap';
import Label from 'reactstrap/lib/Label';

import {listUOM, addUOM, delUOM} from 'services/dataservice';
import ReactBSAlert from 'react-bootstrap-sweetalert';

const columns = [
{
   Header : 'UOM',
   accessor: 'uom'
},
{
   Header:'Action',
   accessor:'action'
}]

export default function UomManagement(props){
   const [tableData, setTableData] = useState([]);
   const [alert, setAlert] = useState(null);

   useEffect(() => {
      loadUOMList();
   }, []);
   const [newUOM, setNewUOM] = useState("");

   const deleteUOM = async (configId) => {
      let resp = await delUOM({configId});
      loadUOMList();
   }

   const showSuccess = (msg, callBack = null) =>{
      setAlert(
         <ReactBSAlert
            success
            style={{display:"block"}}
            title={msg}
            onConfirm={() => callBack == null ? setAlert(null) : callBack()}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info">
            </ReactBSAlert>
      )
   };
   const showFail = (msg) => {
      setAlert(
         <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={msg}
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          btnSize=""
        >
        </ReactBSAlert>
      )
   };
   
   const triggerAddUOM = async () => {
      //send newUOM to api
      try
      {
         let resp = await addUOM({uom:newUOM});
         loadUOMList();
      }
      catch(err)
      {
         console.log(err.response);
         if (err.response.status === 400) {
            showFail(err.response.data.description);
            
         }
      }
      setNewUOM("");
   }

   const loadUOMList = async () => {
      let resp = await listUOM();
      let data = resp.data;
      setTableData(data.map(x => (
         {
            uom:x.value, 
            action: (
               <div className="actions-right">
               <Button
                  id="btnDelete" 
                  size="sm" 
                  className="btn-icon btn-link remove"
                  color="danger"
                  onClick = {() => {
                     //triggerDelete(res);
                     deleteUOM(x.configId)
                  }
                  }>
                     <i className="fa fa-times" />
               </Button>
               </div>
               )
         })));
   }


   return (
      <div className="content">
         {alert}
         <Card>
            <CardHeader>
               <CardTitle tag="h4">UOM Management</CardTitle>
            </CardHeader>
            <CardBody>
               <Form>
                  <FormGroup>
                     <label>UOM</label>
                     <Input value={newUOM} onChange={e => setNewUOM(e.target.value.toUpperCase())}/>
                  </FormGroup>
               </Form>
               <Button color="primary" onClick={triggerAddUOM}>Add</Button>

               <ReactTable data={tableData} columns={columns} className="-striped -highlight primary-pagination"/>
            </CardBody>
         </Card>
      </div>
   )
}