import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, CardHeader, CardTitle, Form } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
//Api
import { getProductDetail, getMarketplaceAttribute, getMarketplaceBrands } from "services/dataservice";
//Function
import { formatZaloraAttributes, assignValue } from "../../common";
//Components
import BasicInfo from "../Components/BasicInfo";
// import Attribute from "../../Zalora/Components/Attribute";
import Attribute from "../../ProductAdd/Zalora/Components/Attribute";
import Options from "../Components/Options/Options3";
// import OptionImages from "../Components/Options/OptionImages";
import Skus from "../Components/Skus/Skus";
import EditFormSubmit from "./Components/EditFormSubmit";
import GroupData from "views/product/ProductAdd/Zalora/Components/GroupData";
import { generateCancelToken } from "views/product/fetch";

export default function ZaloraProductDetail(props) {
  const [alert, setAlert] = useState(null);
  const showFail = useCallback((msg) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""></SweetAlert>
    );
  }, []);
  const [product, setProduct] = useState({});
  const [platformName, setPlatformName] = useState("");
  const [basicInformation, setBasicInformation] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [parentSKU, setParentSKU] = useState("");
  const [options, setOptions] = useState([]);
  // const [optionImages, setOptionImages] = useState({});
  const [optionList, setOptionList] = useState([]);
  const [variations, setVariations] = useState([]);
  const[groupData,setGroupData]=useState("")

  useEffect(() => {
    // const test = async () => {
    //   let productObj = {
    //     catalogueBasicInfoId: 25,
    //     description: "121212",
    //     productName: "Huawei Nova 5T",
    //     brand: "No Brand",
    //     categoryId: "10949",
    //     categoryValue: "Duvets",
    //     parentSKU: "colorssssssssssssss",
    //     tags: null,
    //     active: false,
    //     isVariation: false,
    //     marketplace: null,
    //     status: null,
    //     userMarketplaceId: 67,
    //     platformName: "Zalora",
    //     variations: [
    //       {
    //         inventoryId: 96048,
    //         inventorySKU: "11112",
    //         quantity: 0,
    //         variationId: null,
    //         sellingPrice: 1,
    //         sellingPriceState: true,
    //         costPrice: 0,
    //         option: null,
    //         barcode1: "1",
    //         barcode2: null,
    //         weight: 1,
    //         height: 1,
    //         width: 1,
    //         length: 1,
    //         active: false,
    //         optionList: [
    //           {
    //             catalogueAttributeId: 963,
    //             oValue: "One Size",
    //             oState: true,
    //           },
    //           // {
    //           //   catalogueAttributeId: 965,
    //           //   oValue: "orange",
    //           //   oState: true,
    //           // },
    //         ],
    //         variant: "One Size",
    //       },
    //       // {
    //       //   inventoryId: 96004,
    //       //   inventorySKU: "1111",
    //       //   quantity: 0,
    //       //   variationId: null,
    //       //   sellingPrice: 1,
    //       //   sellingPriceState: true,
    //       //   costPrice: 0,
    //       //   option: null,
    //       //   barcode1: "fawfawflfawfa",
    //       //   barcode2: null,
    //       //   weight: 3.2,
    //       //   height: 4.6,
    //       //   width: 4.5,
    //       //   length: 3.2,
    //       //   active: true,
    //       //   optionList: [
    //       //     {
    //       //       catalogueAttributeId: 962,
    //       //       oValue: "One Size",
    //       //       oState: true,
    //       //     },
    //       //     // {
    //       //     //   catalogueAttributeId: 964,
    //       //     //   oValue: "pink",
    //       //     //   oState: true,
    //       //     // },
    //       //   ],
    //       //   variant: "One Size",
    //       // },
    //     ],

    //     newVariations: null,
    //     variation: null,
    //     attributes: {
    //       Gender: ["Unisex"],
    //       SkuSupplierConfig: ["qrw"],
    //       BrowseNodes: ["qrw"],
    //       SubCatType: ["Bed Linen"],
    //       Color: ["rqwr"],
    //       ColorFamily: ["grey"],
    //       Condition: ["Very Good"],
    //     },
    //     productImages: [],
    //     // optionImages: {
    //     //   "One Size": [{ name: "20220117012047122_3e205567bdd00beb31d518afa1a78efe.png", url: "/api/catalogue/getImage?fileName=20220117012047122_3e205567bdd00beb31d518afa1a78efe.png" }],
    //     // },
    //     optionImages: [
    //       {
    //         option: "One Size",
    //         image: [
    //           // { catalogueImageId: 234, name: "20220117012047122_3e205567bdd00beb31d518afa1a78efe.png", url: "/api/catalogue/getImage?fileName=20220117012047122_3e205567bdd00beb31d518afa1a78efe.png" },
    //         ],
    //       },
    //     ],
    //     options: [
    //       {
    //         name: "Size",
    //         value: ["One Size"],
    //       },
    //       {
    //         name: "Color",
    //         value: [],
    //       },
    //       // {
    //       //   name: "Color",
    //       //   value: ["pink", "orange"],
    //       // },
    //       // {
    //       //   name: "size",
    //       //   value: ["X", "L"],
    //       // },
    //     ],
    //   };
    //   try {
    //     let { productName, description, parentSKU, brand, variations, attributes, options, optionImages, platformName, userMarketplaceId, categoryId } = productObj;

    //     setProduct(productObj);

    //     setBasicInformation({
    //       productName,
    //       description,
    //     });

    //     if (parentSKU) {
    //       setParentSKU(parentSKU);
    //     }

    //     setBrand({ value: brand, state: true });

    //     let oImage = {};
    //     optionImages.forEach((o) => {
    //       oImage[o.option] = o.image;
    //     });

    //     let optionsFiltered = options.filter((o) => o.value.length > 0);

    //     let oList = optionsFiltered.map((o) => {
    //       return { name: o.name, value: o.value.map((v) => v) };
    //     });

    //     variations.forEach((v) => {
    //       v["inventorySKUState"] = true;
    //       v["inventorySKUFound"] = false;
    //       v["barcode1State"] = true;
    //       v["weightState"] = true;
    //       v["heightState"] = true;
    //       v["widthState"] = true;
    //       v["lengthState"] = true;
    //     });
    //     setVariations(variations);
    //     setPlatformName(platformName);
    //     setOptions(options);
    //     setOptionList(oList);
    //     setOptionImages(oImage);
    //     // getAttributes(categoryId, userMarketplaceId, attributes);
    //   } catch (error) {
    //     console.log("error");
    //   }
    // };
    const{cancel,cancelToken}=generateCancelToken()
    const fetchProductDetail = async () => {
      try {
        let CatalogueBasicInfoId = props.match.params.id;
        let response = await getProductDetail(CatalogueBasicInfoId,{cancelToken});
        if (response.status === 200) {
          let { data: productDetail } = response.data;
          let productObj = productDetail[0];

          //console.log(productObj);
          let { productName, description, parentSKU:pSKU, productImages, brand, variations, attributes, options, productGroup, optionImages, platformName, userMarketplaceId, categoryId } = productObj;

          setProduct(productObj);

          setBasicInformation({
            productName,
            description,
          });

          if (pSKU) {
            setParentSKU(pSKU);
          }
          setProductImages(productImages);

          setBrand({ value: brand, state: true });

          // let oImage = {};
          // optionImages.forEach((o) => {
          //   oImage[o.option] = o.image;
          // });
          if (productGroup) {
            setGroupData(productGroup);
          }
          let oList = options.map((o) => {
            return { name: o.name, value: o.value.map((v) => v) };
          });

          // if(options.length === 1){
          //   options.push({name:"Color",value:[]})
          // }
          variations.forEach((v) => {
            v["inventorySKUState"] = true;
            v["inventorySKUFound"] = false;
            v["barcode1State"] = true;
            v["weightState"] = true;
            v["heightState"] = true;
            v["widthState"] = true;
            v["lengthState"] = true;
          });
          setVariations(variations);
          setPlatformName(platformName);
          setOptions(options);
          setOptionList(oList);
          // setOptionImages(oImage);
          getAttributes(categoryId, userMarketplaceId, attributes);
        }
      } catch (error) {
        // showFail("Server Error [GetProductDetail]");
        showFail("Server Error [GetProduct]");
      }
    };

    fetchProductDetail();
    // test();

    async function getAttributes(categoryId, userMarketplaceId, attributes) {
      try {
        let response = await getMarketplaceAttribute(categoryId, userMarketplaceId);
        // console.log(response)
        if (response.status === 200) {
          let attribute = response.data.SuccessResponse.Body.Attribute;
          let attributesParsed = JSON.parse(attributes);
          // processAttribute(attribute, attributes);
          let formattedAttributes = formatZaloraAttributes(attribute);
          // console.log(formatedAttributes)
          // let attrMandatoriesMapped = assignValue(formatedAttributes.attrMandatories, attributes);
          let { attrMandatories, attrMains, attrNormals, attrTextAreas } = formattedAttributes;
          let attrNormalsMerged = [...attrMandatories, ...attrMains, ...attrNormals, ...attrTextAreas];
          let attrNormalsMapped = assignValue(attrNormalsMerged, attributesParsed);

          // setAttrMandatories(attrMandatoriesMapped);

          setAttrNormals(attrNormalsMapped);

          setObjOptions(formattedAttributes.objOptions);
        }
      } catch (error) {
        showFail("Server Error [GetZaloraAttribute]");
      }
    }

    //console.log("@useEffect");
    return()=>{
      console.log("Request Cancelled")
      cancel()
    }
  }, []);

  useEffect(() => {
    // const{cancel,cancelToken}=generateCancelToken()
    const getBrands = async () => {
      try {
        let response = await getMarketplaceBrands(product.userMarketplaceId, 0);
        if (response.status === 200) {
          let brand = response.data.SuccessResponse.Body.Brands.Brand;
          processBrand(brand);
        }
      } catch (error) {
        showFail("Server error when [getMarketplaceBrands]");
      }
      function processBrand(brand) {
        let brandMapped = brand.map((b) => {
          return { value: b.Name, label: b.Name };
        });
        setBrandList(brandMapped);
      }
    };
    if (product.hasOwnProperty("catalogueBasicInfoId")) {
      getBrands();
    }
    
  }, [product]);

  // const [attrMandatories, setAttrMandatories] = useState([]);
  const [attrNormals, setAttrNormals] = useState([]);
  const [objOptions, setObjOptions] = useState({
    ColorFamily: [
      {
        label: "Red",
        value: "Red",
      },
      { label: "Blue", value: "Blue" },
      { label: "Purple", value: "Purple" },
    ],
  });
  const [brandList, setBrandList] = useState([]);
  const [brand, setBrand] = useState({ value: "", state: true });

  const basicInfoProps = {
    product,
    basicInformation,
    setBasicInformation,
    parentSKU,
    setParentSKU,
    productImages,
    setProductImages,
    platformName,
  };

  const attributeProps = {
    // attrMandatories,
    // setAttrMandatories,
    attrNormals,
    setAttrNormals,
    brandList,
    brand,
    setBrand,
  };

  const optionsProps = {
    platformName,
    options,
    setOptions,
    optionList,
    // optionImages,
    // setOptionImages,
    setOptionList,
    objOptions,
    variations,
    setVariations,
    showFail,
  };
  // const optionImagesProps = {
  //   optionList,
  //   optionImages,
  //   setOptionImages,
  // };
  const skusProps = {
    variations,
    setVariations,

    showFail,
    platformName,
    // objOptions,
    // options,
    optionList,
    // multiple: true,
    // count: 8,
  };

  const editFormSubmitProps = {
    setAlert,
    product,
    basicInformation,
    parentSKU,
    groupData,
    brand,
    setBrand,
    productImages,
    // attrMandatories,
    // setAttrMandatories,
    attrNormals,
    setAttrNormals,
    // optionImages,
    optionList,
    variations,
    setVariations,

    showFail,
  };

  return (
    <div className="content">
      <Form>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              Edit Product (Zalora)
              <Link to={{ state: props.location.state, pathname: "/admin/product/list" }}>
                <i style={{ float: "right", right: "1%" }} className="nc-icon nc-simple-remove" />
              </Link>
            </CardTitle>
            {alert}
          </CardHeader>

          <BasicInfo {...basicInfoProps} />
          <Attribute {...attributeProps} />
          <GroupData groupData={groupData} setGroupData={setGroupData}/>
          <Options {...optionsProps} />
          {/* <OptionImages {...optionImagesProps} /> */}
          <Skus {...skusProps} />
          {!props.location.state && <EditFormSubmit {...editFormSubmitProps} />}
          {/* <Button onClick={() => console.log(newSkus)}>show newSkus</Button> */}
          {/* <Button onClick={() => attrMapped()}>show newSkus</Button> */}
          {/* <Button onClick={() => console.log(variations)}>show attr variations</Button> */}
          {/* <Button onClick={() => console.log(objOptions)}>obj options</Button> */}
          {/* <Button onClick={() => console.log(attrMandatories)}>mandatories</Button> */}
          {/* <Button onClick={() => console.log(options)}> options</Button>
          <Button onClick={() => console.log(optionList)}>list</Button>
          <Button onClick={()=>console.log(variations)}>variations</Button> */}
        </Card>
      </Form>
    </div>
  );
}
