import React, { useEffect, useState } from "react";
import { Line, Bar, HorizontalBar } from "react-chartjs-2";
import { getSales3, getSalesByDate, getSalesByMarketplace, getSalesByMarketplaceAndDate, getTopSalesCategory, getTopSalesCategoryByDate } from "services/dataservice";
// reactstrap components
import { Button, Card, CardHeader, CardBody, Row, Col, CardTitle } from "reactstrap";
import { lineOptions, lineWeekOptions, line6HourOptions, barOptions, barWeekOptions, bar6HourOptions } from "./chartOptions";
import { Link } from "react-router-dom";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

function Dashboard() {
  const [days, setDays] = useState(7);
  //date label
  const [dateLabel, setDateLabel] = useState("");
  //total sales
  const [totalSales, setTotalSales] = useState(0);
  const [salesData, setSalesData] = useState({});
  const [salesTimeData, setSalesTimeData] = useState({});
  //total counts
  const [totalOrders, setTotalOrders] = useState(0);
  const [ordersData, setOrdersData] = useState({});
  const [ordersTimeData, setOrdersTimeData] = useState({});
  //top sales
  const [orderDataGroupByMarketplace, setOrderDataGroupByMarketplace] = useState({});
  const [topSellingCategoryData, setTopSellingCategoryData] = useState({});
  //custom date
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  useEffect(() => {
    fetchSalesByDays(7);
  }, []);
  function convertThousand(value) {
    function func(v) {
      const reg = /(\d+)(\d{3})/;
      let str = v.toString();
      while (reg.test(str)) {
        str = str.replace(reg, "$1,$2");
      }
      return str;
    }
    let amount = value.toFixed(2);
    let res = amount.substr(amount.indexOf(".") + 1, 2);
    amount=amount.substr(0, amount.length - 3);
    amount = func(amount);
    return `${amount}.${res}`;
  }
  function computedSalesData(data) {
    let amounts = data.map((x) => x.totalAmount);
    let totalAmounts = amounts.reduce((total, currentVal) => total + currentVal, 0);
    let orders = data.map((x) => x.totalOrder);
    let totalOrders = orders.reduce((total, currentVal) => total + currentVal, 0);
    setTotalSales(convertThousand(totalAmounts));
    setTotalOrders(totalOrders);
    let labels = data.map((x) => x.date);
    let chartSalesData = {
      labels,
      datasets: [
        {
          label: "Amount",
          borderColor: "#6bd098",
          pointHoverRadius: 5,
          pointStyle: "circle",
          pointBackgroundColor: "#6bd098",
          pointBorderWidth: 1,
          pointRadius: 5,
          fill: true,
          borderWidth: 3,
          data: amounts,
        },
      ],
    };

    let chartOrdersData = {
      labels,
      datasets: [
        {
          label: "Count",
          borderColor: "#6bd098",
          fill: true,
          borderWidth: 3,
          barPercentage: 0.4,
          data: orders,
        },
      ],
    };
    setSalesData(chartSalesData);
    setOrdersData(chartOrdersData);
  }
  function computedSalesDataByMarketplace(data) {
    let marketplaceOrder = {
      labels: data.map((x) => x.name),
      datasets: [
        {
          label: "Sales Amount",
          borderColor: "#6bd098",
          pointRadius: 0,
          pointHoverRadius: 0,
          fill: true,
          borderWidth: 2,
          barPercentage: 0.8,
          data: data.map((x) => x.totalAmount),
        },
      ],
    };
    setOrderDataGroupByMarketplace(marketplaceOrder);
  }
  function computedTopSellingCategoryData(data) {
    let topCategoryData = {
      labels: data.map((x) => x.category),
      datasets: [
        {
          label: "Quantity",
          borderColor: "#6bd098",
          pointRadius: 0,
          pointHoverRadius: 0,
          fill: true,
          borderWidth: 2,
          barPercentage: 0.8,
          data: data.map((x) => x.count),
        },
      ],
    };
    setTopSellingCategoryData(topCategoryData);
  }
  const calcMonthToDate = () => {
    let currentDatetime = new Date();
    let day = currentDatetime.getDate();
    fetchSalesByDays(day);
  };
  const fetchSalesByDays = (days) => {
    if (days === -1) {
      let dateObj = new Date();
      dateObj.setDate(0);
      let day = dateObj.getDate();
      setDays(day);
      let month = months[dateObj.getMonth()];
      let year = dateObj.getFullYear();
      setDateLabel(`1 ${month}, ${year} - ${day} ${month}, ${year}`);
    } else if (days === 1) {
      let dateObj = new Date();
      setDays(days);
      setDateLabel(`${dateObj.getDate()} ${months[dateObj.getMonth()]}, ${dateObj.getFullYear()}`);
    } else {
      let eDate = new Date();
      let sDate = new Date();
      sDate.setDate(eDate.getDate() - (days - 1));
      let sDateLabel = `${sDate.getDate()} ${months[sDate.getMonth()]}, ${sDate.getFullYear()}`;
      let eDateLabel = `${eDate.getDate()} ${months[eDate.getMonth()]}, ${eDate.getFullYear()}`;
      setDays(days);
      setDateLabel(`${sDateLabel} - ${eDateLabel}`);
    }

    getSales3(days).then((res) => {
      if (days === 1) {
        let { data } = res;
        let totalAmounts = 0;
        let totalOrders = 0;
        let labelsObj = {};

        for (let i = 0; i < 24; i++) {
          labelsObj[i] = {
            totalAmount: 0,
            totalOrder: 0,
          };
        }
        data.forEach((item) => {
          totalAmounts += item.totalAmount;
          totalOrders += item.totalOrder;
          labelsObj[item.date]["totalAmount"] = item.totalAmount;
          labelsObj[item.date]["totalOrder"] = item.totalOrder;
        });
        setTotalSales(convertThousand(totalAmounts));
        setTotalOrders(totalOrders);
        let labels = [];
        let amounts = [];
        let orders = [];
        for (let key in labelsObj) {
          labels.push(`${key.toString().length > 1 ? key : `0${key}`}:00`);
          amounts.push(labelsObj[key].totalAmount);
          orders.push(labelsObj[key].totalOrder);
        }
        let chartSalesData = {
          labels,
          datasets: [
            {
              label: "Amount",
              borderColor: "#6bd098",
              pointHoverRadius: 5,
              pointStyle: "circle",
              pointBackgroundColor: "#6bd098",
              pointBorderWidth: 1,
              pointRadius: 5,
              fill: true,
              borderWidth: 3,
              data: amounts,
            },
          ],
        };
        let chartOrdersData = {
          labels,
          datasets: [
            {
              label: "Count",
              borderColor: "#6bd098",
              fill: true,
              borderWidth: 3,
              barPercentage: 0.4,
              data: orders,
            },
          ],
        };
        setSalesTimeData(chartSalesData);
        setOrdersTimeData(chartOrdersData);
      } else {
        let { data } = res;
        computedSalesData(data);
      }
    });
    getSalesByMarketplace(days).then((res) => {
      let { data } = res;
      computedSalesDataByMarketplace(data);
    });
    getTopSalesCategory(days).then((res) => {
      let { data } = res;
      computedTopSellingCategoryData(data);
    });
  };
  const fetchSalesByDate = () => {
    if (!startDate || !endDate) {
      alert("Please Select Date");
      return;
    }
    let sDate = new Date(startDate);
    let eDate = new Date(endDate);
    if (sDate > eDate) {
      alert("Invalid Date Selection");
      return;
    }
    let sDateLabel = `${sDate.getDate()} ${months[sDate.getMonth()]}, ${sDate.getFullYear()}`;
    let eDateLabel = `${eDate.getDate()} ${months[eDate.getMonth()]}, ${eDate.getFullYear()}`;
    setDateLabel(`${sDateLabel} - ${eDateLabel}`);
    let startDateTS = sDate.getTime();
    let endDateTS = eDate.getTime();
    let diffDays = (endDateTS - startDateTS) / (1000 * 3600 * 24);
    setDays(diffDays + 1);

    getSalesByDate(startDate, endDate).then((res) => {
      let { data } = res;
      computedSalesData(data);
    });
    getSalesByMarketplaceAndDate(startDate, endDate).then((res) => {
      let { data } = res;
      computedSalesDataByMarketplace(data);
    });
    getTopSalesCategoryByDate(startDate, endDate).then((res) => {
      let { data } = res;
      computedTopSellingCategoryData(data);
    });
  };
  return (
    <>
      <div className="content">
        <div className="pull-Left">
          <Button color="success" onClick={() => fetchSalesByDays(1)} size="sm">
            Today
          </Button>
          <Button color="success" onClick={() => fetchSalesByDays(7)} size="sm">
            Last 7 Days
          </Button>
          <Button color="success" onClick={() => fetchSalesByDays(30)} size="sm">
            Last 30 Days
          </Button>
          <Button color="success" onClick={() => calcMonthToDate()} size="sm">
            Month To Date
          </Button>
          <Button color="success" onClick={() => fetchSalesByDays(-1)} size="sm">
            Last Month
          </Button>
          <Button color="success" size="sm" style={{ padding: "3px 15px", cursor: "default" }}>
            <input
              type="date"
              style={{ border: "1px solid transparent", borderRadius: "3px", backgroundColor: "#6bd098", color: "white", fontWeight: 600 }}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <span style={{ margin: "0 10px" }}>to</span>
            <input
              type="date"
              style={{ border: "1px solid transparent", borderRadius: "3px", backgroundColor: "#6bd098", color: "white", fontWeight: 600 }}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <span style={{ marginLeft: "10px", color: "#126246", cursor: "pointer" }} onClick={fetchSalesByDate}>
              Apply
            </span>
          </Button>
        </div>
        <CardTitle tag="h6" style={{ fontStyle: "italic" }}>
          Information shown from {dateLabel}
        </CardTitle>
        <Row>
          <Col lg="6" sm="6">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="7">
                    <div className="pull-left">
                      <h6>
                        <strong>Total Sales</strong>
                      </h6>
                      <h5>
                        <strong>MYR {totalSales}</strong>
                      </h5>
                    </div>
                  </Col>
                  <Col sm="5">
                    <div className="pull-right">
                      <Link to={{ pathname: "/admin/report", state: "total-sales" }}>
                        <Button color="success" size="sm">
                          Analyse
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="7">
                    <h6>SALES OVER TIME</h6>
                  </Col>
                </Row>
                <div style={{ height: "380px" }}>
                  {days > 1 && <Line data={salesData} options={days > 15 && days <= 31 ? lineWeekOptions : lineOptions} height={380} width={826} />}
                  {days === 1 && <Line data={salesTimeData} options={line6HourOptions} height={380} width={826} />}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" sm="6">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="7">
                    <div className="pull-left">
                      <h6>
                        <strong>Total Orders</strong>
                      </h6>
                      <h5>
                        <strong>{totalOrders}</strong>
                      </h5>
                    </div>
                  </Col>
                  <Col sm="5">
                    <div className="pull-right">
                      <Link to={{ pathname: "/admin/report", state: "total-orders" }}>
                        <Button color="success" size="sm">
                          Analyse
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="7">
                    <h6>ORDER OVER TIME</h6>
                  </Col>
                </Row>

                <div style={{ height: "380px" }}>
                  {days > 1 && <Bar data={ordersData} options={days > 15 && days <= 31 ? barWeekOptions : barOptions} height={380} width={826} />}
                  {days === 1 && <Bar data={ordersTimeData} options={bar6HourOptions} height={380} width={826} />}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" sm="6">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="7">
                    <div className="pull-left">
                      <h6>
                        <strong>Top Selling Categories</strong>
                      </h6>
                      <p>Top Performing Categories</p>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="7">
                    <div className="pull-left">
                      <p>Category</p>
                    </div>
                  </Col>
                  <Col sm="5">
                    <div className="pull-right">
                      <p>Sales/No. Of Units</p>
                    </div>
                  </Col>
                </Row>
                <HorizontalBar
                  data={topSellingCategoryData}
                  //options={chartExample1.options}
                  height={450}
                  width={826}
                />
              </CardBody>
            </Card>
          </Col>

          <Col lg="6" sm="6">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="7">
                    <div className="pull-left">
                      <h6>
                        <strong>Top Sales By Store</strong>
                      </h6>
                      <p>Sales Share By Store</p>
                    </div>
                  </Col>
                  <Col sm="5">
                    <div className="pull-right">
                      <Link to={{ pathname: "/admin/report", state: "top-sales" }}>
                        <Button color="success" size="sm">
                          Analyse
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <HorizontalBar data={orderDataGroupByMarketplace} height={450} width={826} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
