import React, { useState, useEffect } from 'react'
import { Fragment } from 'react';
import { Table } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import { getInventorySyncLog } from 'services/dataservice';
import moment from 'moment';

export function InventorySyncLog(props) {

   const [hasMore, setHasMore] = useState(true);
   const [offset, setOffset] = useState(0);
   const [data, setData] = useState([]);

   const querySize = 50;

   useEffect(() => {
      getInventorySyncLog(props.inventoryId, offset, querySize)
         .then(resp => {
            const respData = resp.data;
            setHasMore(respData.has_more);
            setData(respData.data);
            setOffset(offset + respData.data.length);
         });
   }, [])

   const fetchMoreData = () => {
      getInventorySyncLog(props.inventoryId, offset, querySize)
         .then(resp => {
            const respData = resp.data;
            setHasMore(respData.has_more);
            setData([...data, ...respData.data]);
            setOffset(offset + respData.data.length);
         });
   }



   return (
      <Fragment>
         <InfiniteScroll
            dataLength={data.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            style={{ overflow: "visible" }}
            endMessage={
               <p style={{ textAlign: "center" }}>
                  <b>-End-</b>
               </p>
            }
            {...props}
         >
            <Table>
               <thead>
                  <tr>
                     <th>Log Date</th>
                     <th>Order Number</th>
                     <th>Message</th>
                     <th className="text-right">Quantity Changed</th>
                  </tr>
               </thead>
               <tbody>
                  {data.map((i, index) => (
                     <tr key={index}>
                        <td>{moment(i.createdOn).format('DD/MM/YYYY h:mm:ss a')}</td>
                        <td>{i.orderNumber}</td>
                        <td>{i.message}</td>
                        <td className="text-right">{i.quantityChange}</td>
                     </tr>
                  ))}
               </tbody>
            </Table>
         </InfiniteScroll>
      </Fragment>
   )

}