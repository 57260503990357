import React, { useEffect, useState } from 'react';
import { getCatalogue } from 'services/dataservice';
import { Card, CardHeader, CardBody, CardTitle, Table } from 'reactstrap';

import { InventorySyncLog } from './InventorySyncLog';

export default function InventoryInfo(props) {
   const inventoryId = props.match.params.id;

   //const [inventoryId, setInventoryId] = useState(0);
   const [catalogueList, setCatalogueList] = useState([]);

   const loadCatalogue = async (inventoryId) => {
      const resp = await getCatalogue(inventoryId);
      const data = resp.data;

      setCatalogueList(data);
   }

   useEffect(() => {
      loadCatalogue(inventoryId);
   }, [])

   return (
      <Card>
         <CardHeader>
            <CardTitle tag="h4">Marketplace Inventory</CardTitle>
         </CardHeader>
         <CardBody>
            <Table responsive>
               <thead>
                  <tr>
                     <th>Marketplace SKU</th>
                     <th>Platform</th>
                     <th>Name</th>
                     <th>Status</th>
                     <th className="text-right">Quantity</th>
                     <th className="text-right">Lock/Reserve</th>
                  </tr>
               </thead>
               <tbody>
                  {
                     catalogueList.map((c) => (
                        <tr key={c.catalogueId}>
                           <td>{c.marketplaceSKU}</td>
                           <td>{c.platformName}</td>
                           <td>{c.name}</td>
                           <td>{c.active === 1 ? "Active" : "Inactive"}</td>
                           <td className="text-right">{c.marketplaceQuantity}</td>
                           <td className="text-right">{c.lockReserve}</td>
                        </tr>
                     ))
                  }
               </tbody>
            </Table>
            <h4>Log</h4>
            <div style={{ height: "50em", overflow: "scroll" }} id="logDiv">
               <InventorySyncLog inventoryId={inventoryId} scrollableTarget="logDiv"></InventorySyncLog>
            </div>

         </CardBody>
      </Card >

   )
}