import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, Input } from 'reactstrap'
import {
   Card,
   CardBody,
   CardHeader,
   CardTitle,
   CardFooter,
   Button,
   CustomInput
} from 'reactstrap';
import { addStore, editStore } from 'services/dataservice';

export function AddStoreShopify(props) {
   const [userMarketplaceName, setUserMarketplaceName] = useState("");
   const [apiKey, setApiKey] = useState("");
   const [apiSecret, setApiSecret] = useState("");
   const [shopUrl, setShopUrl] = useState("");
   const [shippingProvider, setShippingProvider] = useState("");
   const [userMarketplaceId, setUserMarketplaceId] = useState(0);
   const [syncInventory, setSyncInventory] = useState(true);
   const [basicAuth,setBasicAuth]=useState(false)

   const history = useHistory();

   useEffect(() => {
      if (props.edit) {
         const u = props.selectedMarketplace;

         let j = JSON.parse(u.secretKey);

         setUserMarketplaceName(u.storeName);
         setUserMarketplaceId(u.userMarketplaceId);
         setShopUrl(j.ShopUrl);
         setApiKey(j.ApiKey);
         setApiSecret(j.SecretKey);
         setShippingProvider(u.shippingProvider);
         setSyncInventory(u.syncInventory);
         setBasicAuth(u.basicAuth)
      }

   }, [props.selectedMarketplace])
   const onSubmit = async () => {
      try {
         if (props.edit) {
            const resp = await editStore({
               userMarketplaceId,
               storeName: userMarketplaceName,
               marketplaceId: props.selectedMarketplace.marketplaceId,
               apiKey,
               shopUrl,
               secretKey: apiSecret,
               active: true,
               integrated: false,
               shippingProvider: shippingProvider,
               syncInventory,
               basicAuth
            });
         }
         else {
            const resp = await addStore(
               {
                  storeName: userMarketplaceName,
                  marketplaceId: props.selectedMarketplace.marketplaceId,
                  shopUrl,
                  apiKey,
                  secretKey: apiSecret,
                  shippingProvider: shippingProvider,
                  syncInventory,
                  basicAuth
               }
            );
         }

         history.push("/admin/integration/list");
      }
      catch (err) {
         const errResp = err.response;
         if (errResp.status === 400) {
            if (errResp.data.error === "duplicated_store_name") {
               props.showFail("Duplicated Store Name");
            }

         }
      }
   };


   return (
      <Card>
         <CardHeader>
            <CardTitle tag="h4">Selected store: {props.selectedMarketplace.name}</CardTitle>
         </CardHeader>
         <CardBody>
            <Form>
               <FormGroup>
                  <label>Name</label>
                  <Input type="text" value={userMarketplaceName} onChange={(e) => setUserMarketplaceName(e.target.value)}></Input>
               </FormGroup>
               <FormGroup>
                  <label>Shop URL</label>
                  <Input type="text" value={shopUrl} onChange={(e) => setShopUrl(e.target.value)}></Input>
               </FormGroup>
               <FormGroup>
                  <label>API Key</label>
                  <Input type="text" value={apiKey} onChange={(e) => setApiKey(e.target.value)}></Input>
               </FormGroup>
               <FormGroup>
                  <label>API Secret</label>
                  <Input type="text" value={apiSecret} onChange={(e) => setApiSecret(e.target.value)}></Input>
               </FormGroup>
               <FormGroup>
                  <label>Shipping Provider</label>
                  <Input type="text" value={shippingProvider} onChange={(e) => setShippingProvider(e.target.value)}></Input>
               </FormGroup>
               <FormGroup>
                  <label>Sync Inventory</label>
                  <CustomInput type="switch" id="idIntegrated" name="integrated" label="Sync Inventory" onChange={(e) => setSyncInventory(e.target.checked)} checked={syncInventory} />
               </FormGroup>
               <FormGroup>
                  <label>Basic Auth</label>
                  <CustomInput type="switch" id="idBasicAuth" name="basicAuth" label="Basic Auth" onChange={(e) => setBasicAuth(e.target.checked)} checked={basicAuth} />
               </FormGroup>
            </Form>
         </CardBody>
         <CardFooter>
            <Button color="primary" onClick={onSubmit}>Submit</Button>
         </CardFooter>
      </Card>

   )
}