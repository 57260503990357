import React, { useEffect, useState, useMemo } from "react";
import { Row, Container, Col, Card, CardHeader, CardTitle, CardBody, Button, Nav, NavItem, NavLink, Badge, CustomInput } from "reactstrap";
//Components
import ProductInfo from "./ProductInfo";
import ReactTable from "components/ReactTable/ReactTable";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory, Link, Switch, Route, useRouteMatch } from "react-router-dom";
//Services
import { getMarketplacesList, getProductList, getProductCount, resendProduct, updateProductStatus } from "services/dataservice";
//Style
import "antd/dist/antd.css";
import "./index.css";
import "./Less/index.module.less";

export default function ProductList() {
  let { path } = useRouteMatch();
  let history = useHistory();
  const columns = useMemo(() => [
    {
      Header: "Product Name",
      accessor: "productName",
      Cell: ({ row }) => {
        return <Link to={`${path}/detail/${row.original.catalogueBasicInfoId}`}>{row.original.productName}</Link>;
      },
    },
    {
      Header: "Marketplace SKU",
      accessor: "marketplaceSKU",
    },
    {
      Header: "SKU Option",
      accessor: "option",
    },
    {
      Header: "Active",
      accessor: "active",
      disableFilters: true,
    },
    {
      Header: "Actions",
      accessor: "action",
      disableFilters: true,
    },
  ]);
  const [alert, setAlert] = useState(null);
  const [marketplace, setMarketplace] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableDataCount, setTableDataCount] = useState({});
  const [activeTab, setActiveTab] = useState(null);
  const [statusTab, setStatusTab] = useState(null);

  useEffect(() => {
    //Init List of marketplace
    // getMarketplaceList().then((res) => {
    //   if (res.data.platformList.length > 0) {
    //     let platformListFiltered = res.data.platformList.filter((platform) => platform.name !== "iWeb"); //filter iweb
    //     setMarketplace(platformListFiltered);
    //     setActiveTab(platformListFiltered[0].userMarketPlaceId);
    //     setStatusTab("Success");
    //   }
    // });
    const fetchMarketplaceList = async () => {
      try {
        const response = await getMarketplacesList();
        const { platformList } = response.data;
        //console.log(platformList)
        if (platformList.length) {
          const platformListFiltered = platformList.filter((platform) => platform.name !== "iWeb");
          setMarketplace(platformListFiltered);
          setActiveTab(platformListFiltered[0].userMarketPlaceId);
          setStatusTab("Success");
        }
      } catch (error) {
        showFail("Server Error [fetchMarketplaceList]");
      }
    };
    fetchMarketplaceList();
    return () => {};
  }, []);

  useEffect(() => {
    if (activeTab) {
      fetchMarketplaceProductCount();
    }
  }, [activeTab, tableData]);

  useEffect(() => {
    //Update table data
    if (activeTab && statusTab) {
      fetchMarketplaceProductList(activeTab, statusTab);
    }
  }, [activeTab, statusTab]);

  const showSuccess = (msg, callBack = null) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => (callBack == null ? setAlert(null) : callBack())}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        timeout={2000}></SweetAlert>
    );
  };
  const showFail = (msg) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
        timeout={2000}></SweetAlert>
    );
  };

  const askConfirm = (msg, id, status) => {
    setAlert(
      <SweetAlert
        info
        showCancel
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => handleProductStatus(id, status)}
        onCancel={() => setAlert(null)}
        confirmBtnText="Yes"
        cancelBtnText="No"
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"></SweetAlert>
    );
  };

  const fetchMarketplaceProductCount = async () => {
    try {
      const response = await getProductCount(activeTab);
      setTableDataCount(response.data);
    } catch (error) {
      showFail("Server Error in [fetchMarketplaceProductCount]");
    }
  };

  const fetchMarketplaceProductList = async (userMarketplaceId, status) => {
    try {
      let response = await getProductList(userMarketplaceId, status);
      if (response.status === 200) {
        let { data: productList } = response;
        //console.log(productList)
        let productListMapped = productList.map((item) => {
          let { variation, ...product } = item;
          let marketplaceSku = variation.map((m) => {
            return m.marketplaceSKU; // ["10001","10002"]
          });
          let option = variation.map((o) => {
            let opt = [];
            if (o.option1) {
              opt.push(o.option1);
            }
            if (o.option2) {
              opt.push(o.option2);
            }
            return opt.join(",");
          });

          let newItem = {
            ...product,
            marketplaceSKU: marketplaceSku.join("\n"),
            option: option.join("\n"),
          };

          return newItem;
        });
        setTableData(modifyData(productListMapped, statusTab));
      }
    } catch (error) {
      showFail("Server Error in [fetchMarketplaceProductList]");
    }
  };

  const Modal = (content) => (
    <div role="button" className="modal-wrapper" onClick={() => history.goBack()}>
      <div className="custom-modal" onClick={(e) => e.stopPropagation()}>
        {content}
      </div>
    </div>
  );

  const resendMarketplaceProduct = async (catalogueBasicInfoId) => {
    try {
      await resendProduct({ catalogueBasicInfoId });
      showSuccess("Resend Successfully");
    } catch (error) {
      showFail("Resend Failed");
    }
  };

  function modifyData(res, status) {
    const ButtonEdit = ({ id, platformName }) => (
      <Button
        id="btnEdit"
        title="Edit Product"
        size="sm"
        className="btn-icon btn-link edit"
        color="primary"
        onClick={() => {
          history.push(`/admin/product/${platformName}/detail/${id}`);
        }}>
        <i className="fa fa-edit" />
      </Button>
    );
    const ButtonDelete = ({ id, status }) => (
      <Button id="btnDelete" title="Delete Product" size="sm" className="btn-icon btn-link trash" color="danger" onClick={() => askConfirm("Delete Product On Marketplace?", id, status)}>
        <i className="fa fa-trash" />
      </Button>
    );
    const SwitchActive = ({ id, active }) => (
      <CustomInput
        type="switch"
        id={`idProductStatus`}
        onChange={() => askConfirm(`${active ? "Inactive" : "Active"} Product On Marketplace?`, id, `${active ? "Inactive" : "Active"}`)}
        checked={active}
      />
    );
    switch (status) {
      case "Success":
      case "Failed":
        return res.map((res, index) => {
          return {
            ...res,
            active: (
              <div className="actions-right">
                <SwitchActive id={res.catalogueBasicInfoId} active={res.active} />
              </div>
            ),
            action: (
              <div className="actions-right">
                <ButtonEdit id={res.catalogueBasicInfoId} platformName={res.platformName.toLowerCase()} />
                <ButtonDelete id={res.catalogueBasicInfoId} status="Deleted" />
              </div>
            ),
          };
        });

      case "Draft":
        return res.map((res, index) => {
          return {
            ...res,
            active: (
              <div className="actions-right">
                <SwitchActive id={res.catalogueBasicInfoId} active={res.active} />
              </div>
            ),
            action: (
              <div className="actions-right">
                <Button
                  id="btnEdit"
                  title="Resend Product"
                  size="sm"
                  className="btn-icon btn-link edit"
                  color="success"
                  onClick={() => {
                    let catalogueBasicInfoId = res.catalogueBasicInfoId;
                    resendMarketplaceProduct(catalogueBasicInfoId);
                  }}>
                  <i className="fas fa-paper-plane" />
                </Button>
                <ButtonEdit id={res.catalogueBasicInfoId} platformName={res.platformName.toLowerCase()} />
                <ButtonDelete id={res.catalogueBasicInfoId} status="Deleted" />
              </div>
            ),
          };
        });
      // default:
      case "Success_Pending_Approval":
        return res.map((res, index) => {
          return {
            ...res,
            active: (
              <div className="actions-right">
                <CustomInput type="switch" id={`idProductStatus${index}`} disabled checked={res.active} />
              </div>
            ),
            action: (
              <div className="actions-right">
                <Button
                  id="btnView"
                  title="View Product Details"
                  size="sm"
                  className="btn-icon btn-link edit"
                  color="info"
                  onClick={() => history.push(`/admin/product/${res.platformName.toLowerCase()}/detail/${res.catalogueBasicInfoId}`, { disabled: true })}>
                  <i className="fa fa-eye" />
                </Button>
              </div>
            ),
          };
        });
    }
  }
  const handleProductStatus = async (catalogueBasicInfoId, status) => {
    setAlert(null);

    try {
      // let response = await updateProductStatus(catalogueBasicInfoId, status);
      await updateProductStatus(catalogueBasicInfoId, status);
      showSuccess(`Product ${status}`);
      fetchMarketplaceProductCount();
      fetchMarketplaceProductList(activeTab, statusTab);
      // if (response.status === 200) {
      //   // if(status==="Deleted"){
      //   //   showSuccess(`Product ${status}`,calc(catalogueBasicInfoId));
      //   // }else{
      //   //   showSuccess(`Product ${status}`)
      //   // }
      //   showSuccess(`Product ${status}`);
      //   fetchMarketplaceProductCount();
      //   fetchMarketplaceProductList(activeTab, statusTab);
      // }
    } catch (error) {
      showFail(`${status} Failed`);
    }
  };

  return (
    <div className="content">
      <Row className="row justify-content-end">
        <Col md="4" className="d-flex flex-row-reverse">
          <Link to="/admin/product/add">
            <Button color="primary">Add Product</Button>
          </Link>
        </Col>
      </Row>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Product List</CardTitle>
        </CardHeader>
        {alert}
        <CardBody>
          <Container fluid>
            <Row>
              <Col md="auto">
                <div className="nav-tabs-navigation verical-navs p-0">
                  <div className="nav-tabs-wrapper">
                    <Nav className="flex-column nav-stacked" tabs>
                      {marketplace.map((val, index) => {
                        return (
                          <NavItem key={val.userMarketPlaceId}>
                            <NavLink href="#" className={activeTab === val.userMarketPlaceId ? "active" : ""} onClick={() => setActiveTab(val.userMarketPlaceId)}>
                              {val.name}
                            </NavLink>
                          </NavItem>
                        );
                      })}
                    </Nav>
                  </div>
                </div>
              </Col>
              <Col>
                <Nav pills className="nav-pills-primary">
                  <NavItem>
                    <NavLink {...(statusTab === "Success" && { className: "active" })} onClick={() => setStatusTab("Success")}>
                      Success
                      <Badge style={{ marginLeft: "8px" }} color="default">
                        {tableDataCount?.success}
                      </Badge>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink {...(statusTab === "Failed" && { className: "active" })} onClick={() => setStatusTab("Failed")}>
                      Failed
                      <Badge style={{ marginLeft: "8px" }} color="default">
                        {tableDataCount?.failed}
                      </Badge>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink {...(statusTab === "Draft" && { className: "active" })} onClick={() => setStatusTab("Draft")}>
                      Draft
                      <Badge style={{ marginLeft: "8px" }} color="default">
                        {tableDataCount?.draft}
                      </Badge>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink {...(statusTab === "Success_Pending_Approval" && { className: "active" })} onClick={() => setStatusTab("Success_Pending_Approval")}>
                      Pending Approval
                      <Badge style={{ marginLeft: "8px" }} color="default">
                        {tableDataCount?.success_Pending_Approval}
                      </Badge>
                    </NavLink>
                  </NavItem>
                </Nav>
                <ReactTable
                  style
                  // data={(statusTab === "Success" && tableData) || (statusTab === "Failed" && tableDataFailed) || (statusTab === "Draft" && tableDataDraft)}
                  data={tableData}
                  columns={columns}
                  className="-striped -highlight primary-pagination"
                />
              </Col>
            </Row>
          </Container>
          <Switch>
            <Route
              path={`${path}/detail/:id`}
              render={(props) => {
                {
                  return Modal(<ProductInfo {...props}></ProductInfo>);
                }
              }}></Route>
          </Switch>
        </CardBody>
      </Card>
    </div>
  );
}
