import React from "react";
import { Button, CardFooter } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { editProduct  } from "services/dataservice";
import { formatImages } from "views/product/common";

export default function EditFormSubmit(props) {
  const history = useHistory();
  let { setAlert, product, basicInformation, tags, productImages, variations, setVariations, optionImages, optionList, showFail } = props;

  const handleSubmit = async () => {
    // let newSkusClone = [...newSkus];
    let variationsClone = [...variations];

    // let validateImage = true;
    let validateVariations = true;
    // let validateNewSkus = true;

    if (basicInformation.productName.trim() === "" || basicInformation.description.trim() === "") {
      showFail("Product name / description cannot be empty");
      return;
    }

    optionList.forEach(o=>{
      o.value.forEach(v=>{
        if(v.trim().length===0){
          validateVariations = false;
        }
      })
    })
    if(!validateVariations){
      showFail("Option value cannot be null/empty/undefined")
      return
    }
    // if (productImages.length === 0) {
    //   showFail("Product must have at least one image");
    //   return;
    // }

    //validation
    variationsClone.forEach((v) => {
      if (!v.inventoryId) {
        if (v.inventorySKU.trim() === "") {
          v.inventorySKUState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
        if (v.barcode1.trim() === "") {
          v.barcode1State = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
        if (v.sellingPrice === "" || v.sellingPrice <= 0) {
          v.sellingPriceState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
        if (v.weight === "" || v.weight <= 0) {
          v.weightState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
        if (v.height === "" || v.height <= 0) {
          v.heightState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
        if (v.width === "" || v.width <= 0) {
          v.widthState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
        if (v.length === "" || v.length <= 0) {
          v.lengthState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
      }else{
        if (v.sellingPrice === "" || v.sellingPrice <= 0) {
          v.sellingPriceState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
      }
    });

    // if (!validateImage) {
    //   showFail("Each sku must have at least one image");
    //   return;
    // }

    if (!validateVariations) {
      showFail("* must be filled");
      return;
    }

    if (window.confirm("Are you sure you want to update the product?")) {
      let productImagesReduced = formatImages(productImages);

      let newOptionImages = [];
      if (product.isVariation) {
        for (let key in optionImages) {
          let oImages = {};
          oImages["option"] = key;
          oImages["image"] = formatImages(optionImages[key]);
          newOptionImages.push(oImages);
        }
      }
      let newVariations = variations.map((variation) => {
        let { variant, inventorySKUState, inventorySKUFound, sellingPriceState, barcode1State, weightState, heightState, widthState, lengthState, ...newVariation } = variation;
        return newVariation;
      });

      let productEdited = {
        productId:product.productId,
        catalogueBasicInfoId: product.catalogueBasicInfoId,
        description: basicInformation.description,
        productName: basicInformation.productName,
        // parentSku: product.parentSku,
        // categoryId: product.categoryId,
        // categoryValue: product.categoryValue,
        tags: tags.join(),
        active: product.active,
        // brand: product.brand,
        isVariation: product.isVariation,
        platformName: product.platformName,
        userMarketplaceId: product.userMarketplaceId,
        status: product.status,
        variations: newVariations,
        optionImages: newOptionImages,
        options: optionList,
        // attributes: product.attributes,
        productImages: productImagesReduced,
      };
      //console.log(productEdited);
      // return;
      try {
        let response = await editProduct(productEdited);
        if (response.status === 200) {
          showSuccess("Product Updated", () => history.push("/admin/product/list"));
        }
      } catch (error) {
        showFail("Server Error [EditProduct]");
      }
    }
  };

   

  const showSuccess = (msg, callBack = null) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => (callBack == null ? setAlert(null) : callBack())}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
      ></SweetAlert>
    );
  };
  return (
    <CardFooter>
      <Button onClick={handleSubmit} color="primary">
        Submit
      </Button>
      {/* <Button onClick={() => console.log(product)}>Preview Product In Console</Button> */}
       
    </CardFooter>
  );
}
