import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardBody, CardFooter, Input, Button, Form, FormGroup, CustomInput } from 'reactstrap'
import { addStore, editStore } from 'services/dataservice';

export function AddStoreMagento(props) {

    const [userMarketplaceName, setUserMarketplaceName] = useState("");
    const [userMarketplaceId, setUserMarketplaceId] = useState(0);
    const [shopUrl, setShopUrl] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [syncInventory, setSyncInventory] = useState(true);
    const history = useHistory();

    useEffect(() => {
        if (props.edit) {
            const u = props.selectedMarketplace;

            setUserMarketplaceId(u.userMarketplaceId);
            setUserMarketplaceName(u.storeName);

            let j = JSON.parse(u.oAuth2);
            setShopUrl(j.shop_url);
            setUserName(j.user_name);
            setPassword(j.password);
            setSyncInventory(u.syncInventory);
        }
    }, [])

    async function submit() {
        try {
            let resp = ""
            if (props.edit) {
                resp = await editStore({
                    userMarketplaceId,
                    storeName: userMarketplaceName,
                    marketplaceId: props.selectedMarketplace.marketplaceId,
                    integrated: false,
                    shopUrl: shopUrl,
                    apiKey: userName,
                    secretKey: password,
                    syncInventory
                });
            } else {
                resp = await addStore({
                    storeName: userMarketplaceName,
                    marketplaceId: props.selectedMarketplace.marketplaceId,
                    integrated: false,
                    shopUrl: shopUrl,
                    apiKey: userName,
                    secretKey: password,
                    syncInventory
                });
            }

            history.push("/admin/integration/list");

        }
        catch (err) {
            const errResp = err.response;
            if (errResp.status === 400) {
                if (errResp.data.error === "duplicated_store_name") {
                    props.showFail("Duplicated Store Name");
                }

            }
        }
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle tag="h4">Selected store: {props.selectedMarketplace.name}</CardTitle>
            </CardHeader>
            <CardBody>
                <Form>
                    <FormGroup>
                        <label>Store Name</label>
                        <Input type="text" value={userMarketplaceName} onChange={(e) => setUserMarketplaceName(e.target.value)}></Input>
                    </FormGroup>
                    <FormGroup>
                        <label>Shop Url</label>
                        <Input type="text" value={shopUrl} onChange={(e) => setShopUrl(e.target.value)}></Input>
                    </FormGroup>
                    <FormGroup>
                        <label>User Name</label>
                        <Input type="text" value={userName} onChange={(e) => setUserName(e.target.value)}></Input>
                    </FormGroup>
                    <FormGroup>
                        <label>Password</label>
                        <Input type="text" value={password} onChange={(e) => setPassword(e.target.value)}></Input>
                    </FormGroup>
                    <FormGroup>
                        <label>Sync Inventory</label>
                        <CustomInput type="switch" id="idIntegrated" name="integrated" label="Sync Inventory" onChange={(e) => setSyncInventory(e.target.checked)} checked={syncInventory} />
                    </FormGroup>
                </Form>
            </CardBody>
            <CardFooter>
                <Button color="primary" onClick={submit} >Submit</Button>
            </CardFooter>
        </Card>
    )
}