/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Buttons from "views/components/Buttons.js";
import Calendar from "views/Calendar.js";
import Charts from "views/Charts.js";
import Dashboard from "views/Dashboard.js";
import Dashboard2 from "views/Dashboard2.js";
import ExtendedForms from "views/forms/ExtendedForms.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import GridSystem from "views/components/GridSystem.js";
import Icons from "views/components/Icons.js";
import LockScreen from "views/pages/LockScreen.js";
import Login from "views/pages/Login.js";
import Notifications from "views/components/Notifications.js";
import Panels from "views/components/Panels.js";
import ReactTables from "views/tables/ReactTables.js";
import Register from "views/pages/Register.js";
import RegularForms from "views/forms/RegularForms.js";
import RegularTables from "views/tables/RegularTables.js";
import SweetAlert from "views/components/SweetAlert.js";
import Timeline from "views/pages/Timeline.js";
import Typography from "views/components/Typography.js";
import UserProfile from "views/pages/UserProfile.js";
import ValidationForms from "views/forms/ValidationForms.js";
import VectorMap from "views/maps/VectorMap.js";
import Widgets from "views/Widgets.js";
import Wizard from "views/forms/Wizard.js";

import InventoryAdd from "views/inventory/InventoryAdd"
import InventoryList from "views/inventory/InventoryList"
import InventoryUpload from "views/inventory/InventoryUpload"

import OrderUpload from "views/order/OrderUpload"
import OrderList from "views/order/OrderList"
import OrderAdd from "views/order/OrderAdd";
import Integration from "views/integrations/Integration"
import MarketplaceList from "views/marketplace/MarketplaceList"
import UserRegister from "views/user/UserRegister"
import AddStore from "views/integrations/AddStore";
import OrderDetail from "views/order/OrderDetail";
import OrderExport from "views/order/OrderExport"

import FailedOrderList from "views/order/FailedOrderList"

import UomManagement from "views/management/UomManagement";
import ChangePassword from "views/user/ChangePassword";
import CatalogueKit from "views/marketplace/CatalogueKit"
import CatalogueDetail from "views/marketplace/CatalogueDetail"
import { EditStore } from "views/integrations/EditStore";

const routes_admin = [
  {
    collapse: true,
    name: "Management",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    invisible: false,
    views: [
      {
        path: "/management/uom",
        name: "UOM Management",
        mini: "U",
        component: UomManagement,
        layout: "/admin"
      },
      {
        path: "/user/add",
        name: "Add User",
        mini: "A",
        component: UserRegister,
        layout: "/admin"
      },
    ]
  }
];


export default routes_admin;
