import React, { useEffect, useState } from 'react';
import {
   Card,
   CardHeader,
   CardBody,
   CardTitle,
   Form,
   Input,
   FormGroup,
   Row,
   Col,
   Button
} from "reactstrap";
import { useHistory } from 'react-router-dom';
import CardFooter from 'reactstrap/lib/CardFooter';
import { Link } from "react-router-dom";
import ReactBSAlert from 'react-bootstrap-sweetalert';

import {
   getInventory,
   updateInventory,
   getInventoryFormData
} from 'services/dataservice'
import { addInventory } from 'services/dataservice';

export default function InventoryAdd(props) {
   const [inventoryId, setInventoryId] = useState(null)
   const [inventorySKU, setSKU] = useState("");
   const [productName, setProduct] = useState("");
   const [quantity, setQuantity] = useState(0);
   const [bufferQuantity, setBufferQuantity] = useState(0);
   const [weight, setWeight] = useState(0);
   const [height, setHeight] = useState(0);
   const [width, setWidth] = useState(0);
   const [length, setLength] = useState(0);
   const [costPrice, setCostPrice] = useState(0);
   const [storageType, setStorageType] = useState("");
   const [editMode, setEditMode] = useState(false);
   const [alert, setAlert] = useState(null);
   const [skuGroup, setSKUGroup] = useState("");
   const [skuSubGroup, setSKUSubGroup] = useState("");
   const [category, setCategory] = useState("");
   const [uom, setUOM] = useState("");
   const [barcode1, setBarcode1] = useState("");
   const [barcode2, setBarcode2] = useState("");

   const [inventoryState, setInventoryState] = useState("has-success")
   const [categoryState, setCategoryState] = useState("has-success");
   const [uomState, setUomState] = useState("has-success");
   const [skuGroupState, setGroupState] = useState("has-success");
   const [skuSubGroupState, setSubGroupState] = useState("has-success");
   const [productNameState, setProductNameState] = useState("has-success");
   const [weightState, setWeightState] = useState("has-success");
   const [heightState, setHeightState] = useState("has-success");
   const [widthState, setWidthState] = useState("has-success");
   const [lengthState, setLengthState] = useState("has-success");


   const [uomList, setUomList] = useState([]);


   const history = useHistory();

   useEffect(() => {
      let inventoryId = props.match.params.id;
      //Initiate Form
      getInventoryFormData().then(res => {
         const data = res.data;
         if (data.uomList.length > 0) {
            setUomList(data.uomList.map(x => (x.value)));
            setUOM(data.uomList[0].value);
         }
         let customerCode = data.customerCode;

         if (inventoryId !== undefined) {
            setEditMode(true);
            console.log(inventoryId);
            loadDetail(inventoryId);
            setInventoryId(inventoryId);
         }
         else {
            console.log(customerCode);
            setCategory(customerCode);
            setSKUGroup("General");
            setSKUSubGroup("General");
         }
      });
   }, [props.match.params.id]);

   async function loadDetail(inventoryId) {
      let resp = await getInventory(inventoryId);
      let detail = resp.data;
      setSKU(detail.inventorySKU);
      setProduct(detail.productName);
      setLength(detail.length);
      setWidth(detail.width);
      setWeight(detail.weight);
      setHeight(detail.height);
      setCostPrice(detail.costPrice);
      setStorageType(detail.storageType);
      setQuantity(detail.quantity);
      setBufferQuantity(detail.bufferStock);
      setUOM(detail.baseUOM);
      setCategory(detail.category);
      setSKUGroup(detail.skuGroup);
      setSKUSubGroup(detail.skuSubGroup);
      setBarcode1(detail.barcode1);
      setBarcode2(detail.barcode2);
      //console.log(detail);
   }

   const showSuccess = (msg, callBack = null) => {
      setAlert(
         <ReactBSAlert
            success
            style={{ display: "block" }}
            title={msg}
            onConfirm={() => callBack == null ? setAlert(null) : callBack()}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info">
         </ReactBSAlert>
      )
   };
   const showFail = (msg) => {
      setAlert(
         <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title={msg}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info"
            btnSize=""
         >
         </ReactBSAlert>
      )
   };

   const onChange = (e) => {
      let name = e.target.name;
      let value = e.target.value;
      switch (name) {
         case "productSKU":
            setInventoryState("has-success");
            setSKU(value);
            break;
         case "productName":
            setProductNameState("has-success");
            setProduct(value);
            break;
         case "quantity":
            setQuantity(value);
            break;
         case "bufferQuantity":
            setBufferQuantity(value);
            break;
         case "weight":
            setWeightState("has-success");
            setWeight(value);
            break;
         case "height":
            setHeightState("has-success");
            setHeight(value);
            break;
         case "width":
            setWidthState("has-success");
            setWidth(value);
            break;
         case "length":
            setLengthState("has-success");
            setLength(value);
            break;
         case "storageType":
            setStorageType(value);
            break;
         case "costPrice":
            setCostPrice(value);
            break;
         case "category":
            setCategoryState("has-success");
            setCategory(value);
            break;
         case "skuGroup":
            setGroupState("has-success");
            setSKUGroup(value);
            break;
         case "skuSubGroup":
            setSubGroupState("has-success");
            setSKUSubGroup(value);
            break;
         case "uom":
            setUomState("has-success");
            setUOM(value);
            break;
         case "barcode1":
            setBarcode1(value);
            break;
         case "barcode2":
            setBarcode2(value);
            break;
      }
   };

   const submit = async (e) => {
      let validate = true;
      if (!skuSubGroup) {
         setSubGroupState("has-danger");
         validate = false;
      }
      if (!skuGroup) {
         setGroupState("has-danger");
         validate = false;
      }
      if (!category) {
         setCategoryState("has-danger");
         validate = false;
      }
      if (!inventorySKU) {
         setInventoryState("has-danger");
         validate = false;
      }
      if (!uom) {
         setUomState("has-danger");
         validate = false;
      }
      if (!productName) {
         setProductNameState("has-danger");
         validate = false;
      }
      if (!weight || weight <= 0) {
         setWeightState("has-danger");
         validate = false;
      }
      if (!length || length <= 0) {
         setLengthState("has-danger");
         validate = false;
      }
      if (!width || width <= 0) {
         setWidthState("has-danger");
         validate = false;
      }
      if (!height || height <= 0) {
         setHeightState("has-danger");
         validate = false;
      }

      if (!validate) {
         return;
      }


      let form = {
         inventorySKU,
         productName,
         quantity,
         weight,
         height,
         width,
         length,
         storageType,
         costPrice,
         bufferStock: bufferQuantity,
         skuGroup,
         skuSubGroup,
         category,
         UOM: uom,
         barcode1,
         barcode2
      }

      if (editMode) {
         form = { ...form, inventoryId }
         try {
            let update = await updateInventory(form);
            if (update.status == 200) {
               showSuccess("Success", () => history.push("/admin/inventory/list"))
            }
         }
         catch (err) {
            showFail("Edit Failed");
         }

      }
      else {
         try {
            let addResp = await addInventory(form);
            if (addResp.status == 200) {
               showSuccess("Success", () => history.push("/admin/inventory/list"));
            }
            console.log(addResp);
         }
         catch (err) {
            if (err.response.status === 500)
               showFail("Internal Server error");

            if (err.response.data.error === "duplicated_inventory")
               showFail("Duplicated SKU");
         }
      }

   }
   return (
      <div className="content">
         {alert}
         <Row>
            <Col md="12">
               <Form id="addInventoryForm">
                  <Card>
                     <CardHeader>
                        <CardTitle tag="h4">
                           {
                              editMode ? "Edit Inventory" : "Add Inventory"
                           }
                           <Link to={{ state: props.location.state, pathname: "/admin/inventory/list" }}>
                              <i style={{ float: 'right', right: '1%' }} className="nc-icon nc-simple-remove" />
                           </Link>

                        </CardTitle>
                     </CardHeader>
                     <CardBody>
                        <FormGroup className={`has-label ${inventoryState}`}>
                           <label>Product SKU <span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                           <Input name="productSKU" value={inventorySKU}
                              type="text"
                              onChange={(e) => onChange(e)} disabled={editMode} />
                           {
                              inventoryState === "has-success" ? null : (<label className="error">This field is required.</label>)
                           }
                        </FormGroup>
                        <FormGroup className={`has-label ${productNameState}`}>
                           <label>Product Name <span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                           <Input name="productName" value={productName}
                              type="text"
                              onChange={(e) => onChange(e)} />
                           {
                              productNameState === "has-success" ? null : (<label className="error">This field is required.</label>)
                           }
                        </FormGroup>
                        <FormGroup className={`has-label ${categoryState}`}>
                           <label>Category <span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                           <Input name="category" value={category}
                              type="text"
                              onChange={(e) => onChange(e)} />
                           {
                              categoryState === "has-success" ? null : (<label className="error">This field is required.</label>)
                           }
                        </FormGroup>
                        <FormGroup className={`has-label ${skuGroupState}`}>
                           <label>SKU Group <span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                           <Input name="skuGroup" value={skuGroup}
                              type="text"
                              onChange={(e) => onChange(e)} />
                           {
                              skuGroupState === "has-success" ? null : (<label className="error">This field is required.</label>)
                           }
                        </FormGroup>
                        <FormGroup className={`has-label ${skuSubGroupState}`}>
                           <label>SKU SubGroup <span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                           <Input name="skuSubGroup" value={skuSubGroup}
                              type="text"
                              onChange={(e) => onChange(e)} />
                           {
                              skuSubGroupState === "has-success" ? null : (<label className="error">This field is required.</label>)
                           }
                        </FormGroup>
                        <FormGroup className={`has-label ${uomState}`}>
                           <label>UOM <span style={{ color: "red", fontWeight: "bold" }}>*</span></label>
                           <Input name="uom" value={uom}
                              type="select"
                              onChange={(e) => onChange(e)}>
                              {
                                 uomList.map(x => (<option key={x} value={x}>{x}</option>))
                              }
                           </Input>
                           {
                              uomState === "has-success" ? null : (<label className="error">This field is required.</label>)
                           }
                        </FormGroup>
                        <FormGroup>
                           <label>Barcode 1</label>
                           <Input name="barcode1" value={barcode1}
                              type="text"
                              onChange={e => onChange(e)}
                           />
                        </FormGroup>
                        <FormGroup>
                           <label>Barcode 2</label>
                           <Input name="barcode2" value={barcode2}
                              type="text"
                              onChange={e => onChange(e)}
                           />
                        </FormGroup>
                        {/*<FormGroup>
                           <label>Quantity</label>
                           <Input name="quantity" value={quantity}
                              type="text"
                              onChange={(e) => onChange(e)}/>
                        </FormGroup>*/}
                        <FormGroup>
                           <label>Buffer Quantity</label>
                           <Input name="bufferQuantity" value={bufferQuantity}
                              type="text"
                              onChange={(e) => onChange(e)} />
                        </FormGroup>
                        <Row form>
                           <Col md="3">
                              <FormGroup className={`has-label ${weightState}`}>
                                 <label>Weight</label>
                                 <Input name="weight" value={weight}
                                    type="number"
                                    step="any"
                                    onChange={(e) => onChange(e)} />
                                 {
                                    weightState === "has-success" ? null : (<label className="error">This field is required.</label>)
                                 }
                              </FormGroup>
                           </Col>
                           <Col md="3">
                              <FormGroup className={`has-label ${heightState}`}>
                                 <label>Height</label>
                                 <Input name="height" value={height}
                                    type="number"
                                    step="any"
                                    onChange={(e) => onChange(e)} />
                                 {
                                    heightState === "has-success" ? null : (<label className="error">This field is required.</label>)
                                 }
                              </FormGroup>
                           </Col>
                           <Col md="3">
                              <FormGroup className={`has-label ${widthState}`}>
                                 <label>Width</label>
                                 <Input name="width" value={width}
                                    type="number"
                                    step="any"
                                    onChange={(e) => onChange(e)} />
                                 {
                                    widthState === "has-success" ? null : (<label className="error">This field is required.</label>)
                                 }
                              </FormGroup>
                           </Col>
                           <Col md="3">
                              <FormGroup className={`has-label ${lengthState}`}>
                                 <label>Length</label>
                                 <Input name="length" value={length}
                                    type="number"
                                    step="any"
                                    onChange={(e) => onChange(e)} />
                                 {
                                    lengthState === "has-success" ? null : (<label className="error">This field is required.</label>)
                                 }
                              </FormGroup>
                           </Col>
                        </Row>
                        {/*<FormGroup>
                           <label>Storage Type</label>
                           <Input name="storageType" value={storageType}
                              type="text"
                              onChange={(e) => onChange(e)}/>
                        </FormGroup>*/}
                        <FormGroup>
                           <label>Cost Price</label>
                           <Input name="costPrice" value={costPrice}
                              type="number"
                              step="any"
                              onChange={(e) => onChange(e)} />
                        </FormGroup>

                     </CardBody>
                     <CardFooter>
                        <Button color="primary" onClick={submit}>
                           Submit
                        </Button>
                     </CardFooter>
                  </Card>
               </Form>
            </Col>
         </Row>
      </div>
   )
}