import React, { useState } from "react";
import { Link, useHistory, useRouteMatch, Switch, Route } from "react-router-dom";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Table, Button, Nav, NavItem, NavLink } from "reactstrap";
import ReactTable from "components/ReactTable/ReactTable";
import Container from "reactstrap/lib/Container";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import {
  getUserMarketplaceList,
  getSplitInventoryListByUserMarketplaceId,
  getSplitInventoryLog,
  deleteSplitInventory,
  syncSplitInventory,
  batchDeleteSplitInventory,
  batchSyncSplitInventory,
} from "services/dataservice";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { exportSplitInventoryCatalogue } from "services/dataservice";

export default function SplitInventoryList() {
  const [marketplace, setMarketplace] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const userMarketplaceId = activeTab;
  const [tabs, setTabs] = useState("ALL");
  const [alert, setAlert] = useState(null);
  const queryClient = useQueryClient();

  let history = useHistory();
  const { path } = useRouteMatch();

  const showSuccess = (msg, callBack = null) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => (callBack == null ? setAlert(null) : callBack())}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"></ReactBSAlert>
    );
  };

  const showFail = (msg) => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""></ReactBSAlert>
    );
  };

  const columns = [
    {
      Header: "Inventory SKU",
      accessor: "inventorySKU",
      Cell: ({ row }) => <Link to={`${path}/log/${row.original.key}`}>{row.original.inventorySKU}</Link>,
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Onhand Qty",
      accessor: "quantity",
      disableFilters: true,
    },
    {
      Header: "Lock (%)",
      accessor: "lockQty",
      disableFilters: true,
    },
    {
      Header: "Lock (%) Qty",
      accessor: "lockQuantity",
      disableFilters: true,
    },
    {
      Header: "Lock Qty",
      accessor: "reserveQty",
      disableFilters: true,
    },
    {
      Header: "Lock Balance Qty",
      accessor: "availableQuantity",
      disableFilters: true,
    },
    {
      Header: "Reserve Qty",
      accessor: "bufferQty",
      disableFilters: true,
    },
    {
      Header: "Last Sync",
      accessor: "lastSync",
      disableFilters: true,
    },
    {
      Header: "Actions",
      accessor: "action",
      disableFilters: true,
    },
  ];

  useQuery({
    queryKey: ["user-marketplace-list"],
    queryFn: () => getUserMarketplaceList(),
    onSuccess: (data) => {
      const userMarketplaceList = data.data.data;
      if (userMarketplaceList.length > 0) {
        setMarketplace(userMarketplaceList);
        setActiveTab(userMarketplaceList[0].value);
      }
    },
    onError: () => {},
  });

  const{isFetching}=useQuery({
    queryKey: ["split-inventory-list", { activeTab, tabs }],
    queryFn: () => getSplitInventoryListByUserMarketplaceId({ userMarketplaceId, criteria: tabs }),
    enabled: userMarketplaceId !== null,
    onSuccess: (data) => {
      const list = data.data.data.map((x) => {
        return {
          ...x,
          lastSync: x.lastSync === null ? "" : moment(x.lastSync).format("DD/MM/YYYY h:mm:ss a"),
          action: (
            <div className="actions-right">
              {/* <Button id="btnEdit" size="sm" className="btn-icon btn-link edit" color="primary" onClick={() => {}}>
                <i className="fa fa-edit" />
              </Button> */}
              {tabs !== "DELETED" && (
                <>
                  <Button
                    id="btnEdit"
                    title="edit"
                    size="sm"
                    className="btn-icon btn-link edit"
                    color="primary"
                    onClick={() => history.push({ pathname: "/admin/split-inventory/edit", state: { catalogueId: x.key } })}>
                    <i className="fa fa-edit" />
                  </Button>
                  <Button id="btnSync" title="sync" size="sm" className="btn-icon btn-link refresh" color="info" onClick={() => onSync(x.key)}>
                    <i className="fa fa-sync" />
                  </Button>
                  <Button id="btnDelete" title="delete" size="sm" className="btn-icon btn-link remove" color="danger" onClick={() => onDelete(x.key)}>
                    <i className="fa fa-times" />
                  </Button>
                </>
              )}
            </div>
          ),
        };
      });
      setTableData(list);
    },
  });
  const deleteMutation = useMutation(deleteSplitInventory, {
    onSuccess: () => queryClient.invalidateQueries(["split-inventory-list"]),
  });
  const syncMutation = useMutation(syncSplitInventory, {
    onSuccess: () => queryClient.invalidateQueries(["split-inventory-list"]),
  });

  const batchDeleteMutation = useMutation(batchDeleteSplitInventory, {
    onSuccess: () => queryClient.invalidateQueries(["split-inventory-list"]),
  });
  const isDelete = batchDeleteMutation.status === "loading";
  const batchSyncMutation = useMutation(batchSyncSplitInventory, {
    onSuccess: () => queryClient.invalidateQueries(["split-inventory-list"]),
  });
  const isSync = batchSyncMutation.status === "loading";

  const Modal = (content) => (
    <div role="button" className="modal-wrapper" onClick={() => history.goBack()}>
      <div className="custom-modal" onClick={(e) => e.stopPropagation()}>
        {content}
      </div>
    </div>
  );

  const onDelete = (key) => {
    deleteMutation.mutate(
      { key, userMarketplaceId: activeTab },
      {
        onSuccess: (data) => showSuccess(data.data.message),
      }
    );
  };
  const onSync = (key) => {
    syncMutation.mutate(
      { key, userMarketplaceId: activeTab },
      {
        onSuccess: (data) => showSuccess(data.data.message),
      }
    );
  };

  const onBatchDelete = (criteria) => {
    batchDeleteMutation.mutate(
      { criteria },
      {
        onSuccess: (data) => showSuccess(data.data.message),
      }
    );
  };
  const onBatchSync = (criteria) => {
    batchSyncMutation.mutate(
      { criteria },
      {
        onSuccess: (data) => showSuccess(data.data.message),
      }
    );
  };

  const onExport = async () => {
    const resp = await exportSplitInventoryCatalogue();
    const date = new Date()
      const dateStr = moment(date).format("YYYYMMDDHHmmss")
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `SplitInventory_${dateStr}.csv`);
      document.body.appendChild(link);
      link.click();
  };

  const Loading = ({msg}) =>
    <ReactBSAlert
      info
      style={{ display: "block", marginTop: "-100px" }}
      title={msg}
      onConfirm={()=>{}}
      showConfirm={false}
      btnSize=""></ReactBSAlert>

  return (
    <div className="content">
      {alert}
      {isFetching && <Loading msg="Loading ..."/>}
      <Row className="row justify-content-end">
        <Col className="d-flex flex-row-reverse">
          <Link to={{ pathname: "/admin/split-inventory/add", state: { type: "ADD" } }}>
            <Button color="primary">Add</Button>
          </Link>
          <Link to={{ pathname: "/admin/split-inventory/add", state: { type: "UPLOAD" } }}>
            <Button color="primary">Upload</Button>
          </Link>
          <Button color="primary" onClick={() => history.push({ pathname: "/admin/split-inventory/edit", state: { id: activeTab } })}>
            Edit
          </Button>
          <Button color="primary" onClick={onExport}>
            Export
          </Button>
        </Col>
      </Row>
      <Row className="row justify-content-end">
        <Col className="d-flex flex-row-reverse">
         
          <Button color="danger" onClick={() => onBatchDelete("BUFFER")} disabled={isDelete}>
            {isDelete ? "Removing" : "Remove All Reserve SKU"}
          </Button>
          <Button color="info" onClick={() => onBatchSync("BUFFER")} disabled={isSync}>
            {isSync ? "Syncing" : "Sync All Reserve SKU"}
          </Button>
           
          <Button color="danger" onClick={() => onBatchDelete("RESERVE")} disabled={isDelete}>
            {isDelete ? "Removing" : "Remove All Lock SKU"}
          </Button>
          <Button color="info" onClick={() => onBatchSync("RESERVE")} disabled={isSync}>
            {isSync ? "Syncing" : "Sync All Lock SKU"}
          </Button>
           
          <Button color="danger" onClick={() => onBatchDelete("LOCK")} disabled={isDelete}>
            {isDelete ? "Removing" : "Remove All Lock (%) SKU"}
          </Button>
          <Button color="info" onClick={() => onBatchSync("LOCK")} disabled={isSync}>
            {isSync ? "Syncing" : "Sync All Lock (%) SKU"}
          </Button>
        </Col>
      </Row>
      <Row className="row justify-content-end">
        <Col className="d-flex flex-row-reverse">
         
           
           
        </Col>
      </Row>
      <Card>
        <CardHeader>
          <Row>
            <Col md="6">
              <CardTitle tag="h4">Split Inventory</CardTitle>
            </Col>
            <Col>
              <div style={{ fontWeight: "bold" }}>
                <span style={{ color: "red" }}>*</span>Onhand Quantity = Quantity(wms) - Buffer Stock - Partial Order - Reserve Quantity - Lock Quantity
              </div>
              <div style={{ fontWeight: "bold" }}>
                <span style={{ color: "red" }}>*</span>Lock (%) Quantity = Onhand Quantity * Lock (%)
              </div>
              <div style={{ fontWeight: "bold" }}>
                <span style={{ color: "red" }}>*</span>Lock Balance Quantity = Lock Quantity - New/Partial/UnPaid Order Quantity
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Container fluid>
            <Row>
              <Col md="auto">
                <div className="nav-tabs-navigation verical-navs p-0">
                  <div className="nav-tabs-wrapper">
                    <Nav className="flex-column nav-stacked" tabs>
                      {marketplace.map((val) => {
                        return (
                          <NavItem key={val.value}>
                            <NavLink href="#" className={activeTab === val.value ? "active" : ""} onClick={() => setActiveTab(val.value)}>
                              {val.label}
                            </NavLink>
                          </NavItem>
                        );
                      })}
                    </Nav>
                  </div>
                </div>
              </Col>
              <Col>
                <Nav className="nav-pills-primary nav-pills-icons justify-content-right" pills role="tablist">
                  <NavItem>
                    <NavLink role="tablist" onClick={() => setTabs("ALL")} data-toggle="tab" href="#" className={tabs === "ALL" ? "active" : ""}>
                      ALL
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink role="tablist" onClick={() => setTabs("LOCK")} data-toggle="tab" href="#" className={tabs === "LOCK" ? "active" : ""}>
                      Lock (%)
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink aria-expanded={tabs === "RESERVE"} data-toggle="tab" href="#" onClick={() => setTabs("RESERVE")} className={tabs === "RESERVE" ? "active" : ""}>
                      Lock
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink aria-expanded={tabs === "BUFFER"} data-toggle="tab" href="#" onClick={() => setTabs("BUFFER")} className={tabs === "BUFFER" ? "active" : ""}>
                      Reserve
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink aria-expanded={tabs === "DELETED"} data-toggle="tab" href="#" onClick={() => setTabs("DELETED")} className={tabs === "DELETED" ? "active" : ""}>
                      Deleted
                    </NavLink>
                  </NavItem>
                </Nav>
                <ReactTable data={tableData} columns={columns} className="-striped -highlight primary-pagination" />
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
      <Switch>
        <Route
          path={`${path}/log/:id`}
          render={(props) => {
            return Modal(<Log {...props} />);
          }}></Route>
      </Switch>
    </div>
  );
}

const Log = (props) => {
  const catalogueId = props.match.params.id;
  useQuery({
    queryKey: ["split-inventory-log"],
    queryFn: () => getSplitInventoryLog({ catalogueId }),
    onSuccess: (data) => setData(data.data.data),
  });
  const [data, setData] = useState([]);
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Split Inventory Log</CardTitle>
      </CardHeader>
      <CardBody>
        <Table responsive>
          <thead>
            <tr>
              <th>Log Date</th>
              <th>Message</th>
              <th>Quantity</th>
              <th>Quantity Change</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, index) => (
              <tr key={index}>
                <td>{moment(d.createdOn).format("DD/MM/YYYY h:mm:ss a")}</td>
                <td>{d.event}</td>
                <td>{d.quantity}</td>
                <td>{d.quantityChange}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
