import React from "react";
import { FormGroup, Label } from "reactstrap";
import { SelectAntd } from "views/product/ProductAdd/Components/AntdComponent";

export default function Tag(props) {
  let { tags, setTags } = props;
  const handleAddTag = (value) => {
    setTags(value);
  };
  return (
    <FormGroup>
      {/* {console.log("render tag")} */}
      <Label>Tags <span style={{ color: "blue", fontWeight: "bold", fontStyle: "italic" }}>(Used for filtering and search, up to 250 tags)</span></Label>
      <SelectAntd mode="tags" value={tags} placeholder="Add a tag" onChange={handleAddTag} />
    </FormGroup>
  );
}
