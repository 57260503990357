import React from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import ProductLog from "./ProductLog";

export default function ProductInfo(props) {
  const catalogueBasicInfoId = props.match.params.id;
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Marketplace Product</CardTitle>
      </CardHeader>
      <CardBody>
        <h4>Log</h4>
        <div style={{ height: "50em", overflow: "scroll" }} id="logDiv">
          <ProductLog catalogueBasicInfoId={catalogueBasicInfoId} scrollableTarget="logDiv"></ProductLog>
        </div>
      </CardBody>
    </Card>
  );
}
