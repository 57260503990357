import React, { useState } from "react";
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardHeader,
  Table,
  Row,
  Col,
  Button,
  CardFooter,
  CardTitle,
} from "reactstrap";

import { checkSKUPreOrder, setPreOrder } from "services/dataservice";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";

export default function CatalogueKit(props) {
  const [skuList, setSKUList] = useState([]);
  const [alert, setAlert] = useState(null);
  const history = useHistory();

  const removeElement = (val) => {
    let index = skuList.indexOf(val);
    let newList = [...skuList];
    newList.splice(index, 1);
    setSKUList(newList);
    // console.log(newList);
  };

  const showSuccess = (msg, callBack = null) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => (callBack == null ? setAlert(null) : callBack())}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
      ></ReactBSAlert>
    );
  };

  const showFail = (msg) => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      ></ReactBSAlert>
    );
  };

  const onEnterAddSKU = async (e) => {
    let { keyCode } = e;

    if (keyCode !== 13) {
      return;
    }
    onAddSKU();
  };

  const onAddSKU = async (e) => {
    let sku = document.getElementById("txtSKU").value;

    if (sku.trim().length === 0) {
      return;
    }
    if (!sku) {
      return;
    }

    let addedSku = skuList.map((item) => item.skuCode);
    if (addedSku.includes(sku)) {
      showFail(`${sku} already added in table`);
      return;
    }

    try {
      let response = await checkSKUPreOrder(sku);
      // console.log(response);
      if (response.status === 200 && response.data.exist) {
        let inventoryId = response.data.inventoryId;
        let newSkuList = [...skuList];
        newSkuList.push({
          skuCode: sku,
          inventoryId,
          preOrder: true,
        });
        setSKUList(newSkuList);
      } else {
        showFail("Invalid SKU");
      }
    } catch (error) {
      showFail("Sever Error");
    }
  };

  const onSubmit = async () => {
    let data = skuList.map((item) => {
      return { inventoryId: item.inventoryId, preOrder: item.preOrder };
    });
    // console.log(data);
    try {
      let response = await setPreOrder(data);
      // console.log(response);
      showSuccess("Submitted", () => history.push("/admin/inventory/list"));
    } catch (error) {
      console.error(error);
      showFail("Server Error");
    }
  };

  const SKUListView = () => {
    return (
      <Row>
        <Col>
          <Table>
            <thead className="text-primary">
              <tr>
                <th className="text-center">#</th>
                <th className="text-center">SKU</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {skuList.map((val, index) => {
                return (
                  <tr key={val.skuCode}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{val.skuCode}</td>
                    <td className="text-right">
                      <div className="actions-right">
                        <Button
                          id="btnDelete"
                          size="sm"
                          className="btn-icon btn-link remove"
                          color="danger"
                          onClick={() => {
                            removeElement(val);
                          }}
                        >
                          <i className="fa fa-times" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  };

  return (
    <div className="content">
      {alert}

      <Card>
        <CardHeader>
          <CardTitle tag="h6" className="text-center">
            Set Inventory SKU Status to Pre-Order
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row form>
            <Col md="9">
              <Row>
                <Label
                  md="3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItem: "center",
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  SKU
                </Label>
                <Col md="9">
                  <FormGroup>
                    <Input
                      id="txtSKU"
                      placeholder="Press Enter / Click Button Add to add SKU"
                      onKeyUp={onEnterAddSKU}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md="3">
              <Button style={{ margin: 0 }} onClick={onAddSKU}>
                Add
              </Button>
            </Col>
          </Row>

          <SKUListView />
        </CardBody>
        <CardFooter>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            {skuList.length > 0 && (
              <Button color="success" onClick={onSubmit}>
                Submit
              </Button>
            )}
          </div>
        </CardFooter>
      </Card>
    </div>
  );
}
