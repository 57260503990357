import React, { Fragment, useState, memo } from "react";
import { Button, CardBody, CardTitle, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { ShopeeDatePicker, ShopeeSelect, CustomSelect } from "./ShopeeSelectAntd";
import { getMarketplaceBrands } from "services/dataservice";
// import "../../../index.module.less";
// import styles from "../../../Less/required.module.less";
import styles from "../../../../Less/required.module.less";
import moment from "moment";
import { ShopeeBrandSelect } from "./ShopeeSelectAntd";

export default memo(function AttributeNormal(props) {
  let { brand, setBrand, brandList, attrNormals, setAttrNormals } = props;
  const [customValue, setCustomValue] = useState("");
  const [customUnit, setCustomUnit] = useState("");

  const brandChange = (values) => {
    // let { value } = e.target;
    // setBrand({ value, state: true });
    console.log(values);
    let { brand_id, original_brand_name } = values.ovalue;
    setBrand({
      id: brand_id,
      value: original_brand_name,
      state: true,
    });
  };
  const customValueChange = (e) => {
    let { value } = e.target;
    console.log("custom value:",value)
    setCustomValue(value);
  };

  const customUnitChange = (v) => {
    // console.log(v);
    let { value } = v.target;
    // alert(value);
    setCustomUnit(value);
  };
  const addCustomValue = (index) => {
    if (customValue.trim().length === 0) return;
    let attrNormalsClone = [...attrNormals];
    // if (attrNormalsClone[index]["options"][0])
    attrNormalsClone[index]["options"].push({ value_id: 0, original_value_name: customValue });
    setAttrNormals(attrNormalsClone);
  };

  const addCustomValueAndUnit = (index) => {
    if (customValue.trim().length === 0) return;
    if (customUnit.trim().length === 0) return;
    let attrNormalsClone = [...attrNormals];
    attrNormalsClone[index]["options"].push({ value_id: 0, original_value_name: customValue, value_unit: customUnit });
    setAttrNormals(attrNormalsClone);
  };

  const singleSelectChange = (values, index, mandatory) => {
    let attrNormalsClone = [...attrNormals];
    // console.log(values);
    // return;
    console.log(values)
    if (mandatory) {
      if (!values) {
        attrNormalsClone[index]["value"] = "";
        attrNormalsClone[index]["temp_value"] = "";
      } else {
        let { ovalue } = values;
        attrNormalsClone[index]["value"] = ovalue;
        attrNormalsClone[index]["state"] = true;
        attrNormalsClone[index]["temp_value"] = ovalue.value_unit?`${ovalue.original_value_name} ${ovalue.value_unit}`:ovalue.original_value_name;
      }
    } else {
      if (!values) {
        attrNormalsClone[index]["value"] = "";
        attrNormalsClone[index]["temp_value"] = "";
      } else {
        let { ovalue } = values;
        attrNormalsClone[index]["value"] = ovalue;
        attrNormalsClone[index]["temp_value"] = ovalue.value_unit?`${ovalue.original_value_name} ${ovalue.value_unit}`:ovalue.original_value_name;
        console.log("ovalue is:",ovalue.value_unit?`${ovalue.original_value_name} ${ovalue.value_unit}`:ovalue.original_value_name)
      }
    }

    setAttrNormals(attrNormalsClone);
  };

  const multiSelectChange = (v, values, index, mandatory) => {
    // console.log(values);
    if (values.length > 5) return;
    let attrNormalsClone = [...attrNormals];
console.log(values)
    values.forEach((item, i) => {
      if (!item.hasOwnProperty("ovalue")) {
        item["ovalue"] = { value_id: 0, original_value_name: v[i] };
      }
    });

    if (mandatory) {
      attrNormalsClone[index]["state"] = true;
    }
    attrNormalsClone[index]["temp_value"] = v;
    attrNormalsClone[index]["value"] = values.map((item) => item.ovalue);

    setAttrNormals(attrNormalsClone);
  };

  const multiQuantitativeChange = (v, values, index) => {
    if (values.length > 5) return;
    let attrNormalsClone = [...attrNormals];

    console.log(values);
    // values.forEach((item, i) => {
    //   if (!item.hasOwnProperty("ovalue")) {
    //     item["ovalue"] = { value_id: 0, original_value_name: v[i],value_unit: };
    //   }
    // });
    attrNormalsClone[index]["temp_value"] = v;
    attrNormalsClone[index]["value"] = values.map((item) => item.ovalue);

    setAttrNormals(attrNormalsClone);
  };

  const dateChange = (dateStr, index) => {
    let attrNormalsClone = [...attrNormals];
    if (dateStr.length === 0) {
      attrNormalsClone[index]["temp_value"] = "";
      attrNormalsClone[index]["value"] = "";
      setAttrNormals(attrNormalsClone);
    } else {
      attrNormalsClone[index]["temp_value"] = dateStr;
      attrNormalsClone[index]["value"] = { value_id: 0, original_value_name: Date.parse(dateStr) / 1000 };
      setAttrNormals(attrNormalsClone);
    }
  };

  const textChange = (e, index, mandatory) => {
    let { value } = e.target;
    let attrNormalsClone = [...attrNormals];
    if (mandatory) {
      attrNormalsClone[index]["state"] = true;
    }
    attrNormalsClone[index]["temp_value"] = value;
    attrNormalsClone[index]["value"] = { value_id: 0, original_value_name: value };
    setAttrNormals(attrNormalsClone);
  };

  // const getBrandList=async()=>{
  //   let response = await getMarketplaceBrands(145,100003)
  //   console.log(response);
  //   console.log(response.data[0])
  // }
  return (
    <CardBody>
      <CardBody style={{ border: "1px solid #e3e3e3", borderRadius: "8px" }}>
        <CardTitle tag="h6" style={{ color: "#bf558b" }}>
          Attribute
        </CardTitle>
        <Row form>
          <Col md="3">
            <FormGroup>
              <Label>
                Brand <span style={{ color: "red", fontWeight: "bold" }}>*</span>
              </Label>
              {/* <Input type="text" value={brand.value} onChange={(e) => brandChange(e)} {...(!brand.state && { invalid: true })} />
              <Button color="warning" title="If the product don't have brand just click it." onClick={() => setBrand({ value: "No Brand", state: true })}>
                No Brand
              </Button>
              <Button color="success" onClick={()=>getBrandList()}>
                Get Brand
              </Button>
              <FormFeedback>This field is required.</FormFeedback> */}
              <div {...(!brand.state && { className: styles.mySelectRequired })}>
                <ShopeeBrandSelect data={brandList} placeholder="Please Select Brand" showSearch={true} value={brand.value} onChange={(v, values) => brandChange(values)} />
              </div>
            </FormGroup>
          </Col>
          {attrNormals.map((attr, index) => (
            <Fragment key={attr.attribute_id}>
              <Col md="3">
                <FormGroup>
                  <Label>
                    {attr.label} {attr.is_mandatory && <span style={{ color: "red", fontWeight: "bold" }}>*</span>}
                    {(attr.input_type === "MULTIPLE_SELECT_COMBO_BOX" || attr.input_type === "MULTIPLE_SELECT") && (
                      <span style={{ color: "blue", fontWeight: "bold", fontStyle: "italic" }}>{`${attr.temp_value.length}/5`}</span>
                    )}
                  </Label>
                  {
                    attr.format_type === "NORMAL"
                      ? (attr.input_type === "COMBO_BOX" && (
                          <div {...(attr.is_mandatory && !attr.state && { className: styles.mySelectRequired })}>
                            <CustomSelect
                              type={attr.input_validation_type}
                              data={attr.options}
                              customValueChange={customValueChange}
                              addCustomItem={() => addCustomValue(index)}
                              placeholder="Single Select or Input"
                              allowClear={true}
                              value={attr.temp_value}
                              onChange={(v, values) => singleSelectChange(values, index, attr.is_mandatory)}
                            />
                          </div>
                        )) ||
                        (attr.input_type === "DROP_DOWN" && (
                          <div {...(attr.is_mandatory && !attr.state && { className: styles.mySelectRequired })}>
                            <ShopeeSelect
                              data={attr.options}
                              placeholder="Single Select"
                              value={attr.temp_value}
                              allowClear={true}
                              onChange={(v, values) => singleSelectChange(values, index, attr.is_mandatory)}
                            />
                          </div>
                        )) ||
                        (attr.input_type === "MULTIPLE_SELECT_COMBO_BOX" && (
                          <div {...(attr.is_mandatory && !attr.state && { className: styles.mySelectRequired })}>
                            <ShopeeSelect
                              data={attr.options}
                              mode="tags"
                              placeholder="Multi Select or Input"
                              value={attr.temp_value}
                              allowClear={true}
                              onChange={(v, values) => multiSelectChange(v, values, index, attr.is_mandatory)}
                            />
                          </div>
                        )) ||
                        (attr.input_type === "MULTIPLE_SELECT" && (
                          <div {...(attr.is_mandatory && !attr.state && { className: styles.mySelectRequired })}>
                            <ShopeeSelect
                              data={attr.options}
                              mode="multiple"
                              placeholder="Multi Select"
                              value={attr.temp_value}
                              onChange={(v, values) => multiSelectChange(v, values, index, attr.is_mandatory)}
                            />
                          </div>
                        )) ||
                        (attr.input_type === "TEXT_FILED" && attr.input_validation_type === "STRING_TYPE" && (
                          <Input type="text" defaultValue={attr.temp_value} onBlur={(e) => textChange(e, index, attr.is_mandatory)} {...(attr.is_mandatory && !attr.state && { invalid: true })} />
                        )) ||
                        (attr.input_type === "TEXT_FILED" && attr.input_validation_type === "INT_TYPE" && (
                          <Input
                            type="number"
                            step="any"
                            defaultValue={attr.temp_value}
                            onBlur={(e) => textChange(e, index, attr.is_mandatory)}
                            {...(attr.is_mandatory && !attr.state && { invalid: true })}
                          />
                        )) ||
                        (attr.input_validation_type === "DATE_TYPE" &&
                          ((attr.date_format_type === "YEAR_MONTH_DATE" && (
                            // <div {...(attr.is_mandatory && !attr.state && { className: styles.mySelectRequired })}>
                            <ShopeeDatePicker {...(attr.value.length > 0 && { value: moment(attr.value, "YYYY-MM-DD") })} onChange={(dateMoment, dateStr) => dateChange(dateStr, index)} />
                            // <DatePicker size="large" style={{ width: "100%" }} allowClear={true} onChange={(dateMoment, dateStr) => dateChange(dateStr, index)} />
                            // </div>
                          )) ||
                            (attr.date_format_type === "YEAR_MONTH" && (
                              // <div {...(attr.is_mandatory && !attr.state && { className: styles.mySelectRequired })}>
                              <ShopeeDatePicker
                                picker="month"
                                {...(attr.value.length > 0 && { value: moment(attr.value, "YYYY-MM") })}
                                onChange={(dateMoment, dateStr) => dateChange(dateStr, index)}
                              />
                              // <DatePicker size="large" style={{ width: "100%" }} picker="month" allowClear={true} onChange={(dateMoment, dateStr) => dateChange(dateStr, index)} />
                              // </div>
                            ))))
                      : attr.input_type === "COMBO_BOX" ||
                        (attr.input_type === "TEXT_FILED" && attr.input_validation_type === "FLOAT_TYPE" && (
                          <div {...(attr.is_mandatory && !attr.state && { className: styles.mySelectRequired })}>
                            <CustomSelect
                              type={attr.input_validation_type}
                              data={attr.options}
                              customValueChange={customValueChange}
                              value={attr.temp_value}
                              // addCustomValue={() => addCustomValue(index)}
                              addCustomItem={() => addCustomValueAndUnit(index)}
                              placeholder="Single Select or Input"
                              unit={attr.attribute_unit}
                              customUnitChange={customUnitChange}
                              allowClear={true}
                              onChange={(v, values) => singleSelectChange(values, index, attr.is_mandatory)}
                            />
                          </div>
                        )) ||
                        (attr.input_type === "DROP_DOWN" && (
                          <div {...(attr.is_mandatory && !attr.state && { className: styles.mySelectRequired })}>
                            <ShopeeSelect data={attr.options} placeholder="Single Select" onChange={(v, values) => singleSelectChange(values, index, attr.is_mandatory)} />
                          </div>
                        )) ||
                        (attr.input_type === "MULTIPLE_SELECT_COMBO_BOX" && (
                          <CustomSelect
                            type={attr.input_validation_type}
                            data={attr.options}
                            customValueChange={customValueChange}
                            value={attr.temp_value}
                            mode="multiple"
                            placeholder="Multi Select"
                            addCustomItem={() => addCustomValueAndUnit(index)}
                            unit={attr.attribute_unit}
                            customUnitChange={customUnitChange}
                            allowClear={true}
                            onChange={(v, values) => multiQuantitativeChange(v, values, index)}
                          />
                        ))
                    // ||(attr.input_type==="TEXT_FILED"&&attr.input_validation_type==="FLOAT_TYPE"&&(<QuantitativeInput/>))
                    //  ||
                    // (attr.input_type === "TEXT_FILED" && <Input type="number" />)
                  }
                </FormGroup>
              </Col>
            </Fragment>
          ))}
        </Row>
      </CardBody>
    </CardBody>
  );
});
