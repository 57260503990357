import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardBody, CardFooter, Form, FormGroup, Input, Label, Button, Table } from 'reactstrap';
import { uploadInventoryFile, getMarketplace, getUserMarketplace } from 'services/dataservice'
import ReactBSAlert from 'react-bootstrap-sweetalert';

export default function InventoryUpload(props) {
   const [userMarkerplaceId, setUserMarketplaceId] = useState(-1);
   const [selectedFile, setFile] = useState([]);
   const [alert, setAlert] = useState(null);
   const [availablePlatform, setAvailablePlatform] = useState([]);
   const [availableShopName, setAvailableShopName] = useState([]);
   const [selectedPlatform, setPlatform] = useState(0);
   const [errData,setErrData]=useState(null)

   const [isUpload, setIsUpload] = useState(false)

   useEffect(() => {
      //Load Available Marketplace
      getMarketplace().then(x => {
         let list = x.data;
         setAvailablePlatform(list);
         if (list.length > 0) {
            loadUserMarketplace(list[0].id);
         }
      })
   }, [])

   const loadUserMarketplace = async (selectedPlatform) => {
      //Load User MarketName
      const resp = await getUserMarketplace(selectedPlatform);
      setAvailableShopName(resp.data);
      if (resp.data.length > 0) {
         setUserMarketplaceId(resp.data[0].id);
         //setFormData({...formData, userMarketplaceName: resp.data[0].name, platform: selectedPlatform})
      }
   }

   const showSuccess = (msg, callBack = null) => {
      setAlert(
         <ReactBSAlert
            success
            style={{ display: "block" }}
            title={msg}
            onConfirm={() => callBack == null ? setAlert(null) : callBack()}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info">
         </ReactBSAlert>
      )
   };


   const showFail = (msg) => {
      setAlert(
         <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title={msg}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info"
            btnSize=""
         >
         </ReactBSAlert>
      )
   }

   const upload = async () => {
      setIsUpload(true)
      setErrData(null)
      try {
         const formData = new FormData();
         formData.append('userMarketplaceId', userMarkerplaceId)
         formData.append(
            "files",
            selectedFile[0],
            selectedFile[0].name
         );
         const resp = await uploadInventoryFile(formData)
         const status=resp.data.code
         if(status===500){
            showFail(resp.data.description)
            if(resp.data.error==="error_upload"){
               setErrData(resp.data.data)
            }
         }else{
            console.log(resp.data);
            showSuccess(resp.data);
            setFile([])
            document.getElementById("file").value=""
         }
         setIsUpload(false)
      }
      catch
      {
         showFail("Fail to upload");
         setIsUpload(false)
      }
   }

   const downloadTemplate = () => {
      var link = "/api/template/getTemplate?type=inventory";
      window.open(link);
   }

   return (
      <div className="content">
         {alert}
         <Card>
            <CardHeader>
               <CardTitle tag="h4">
                  Upload Inventory
                  <Link to="/admin/inventory/list">
                     <i style={{ float: 'right', right: '1%' }} className="nc-icon nc-simple-remove" />
                  </Link>
               </CardTitle>
            </CardHeader>
            <CardBody>
               <Button color="info" onClick={downloadTemplate}>Download Template</Button>
               <Form>
                  <FormGroup>
                     <Label for="file">SKU File List</Label>
                     <Input type="file" id="file" onChange={e => setFile(e.target.files)}></Input>
                  </FormGroup>
               </Form>
            </CardBody>
            <CardFooter>
               <Button onClick={upload} disabled={isUpload}>{isUpload?"Uploading":"Upload"}</Button>

            </CardFooter>
         </Card>

         {errData && (
            <Card>
               <CardHeader>
                  <CardTitle tag="h4">Upload Response</CardTitle>
               </CardHeader>
               <CardBody>
                  <ErrorListView errData={errData} />
               </CardBody>
            </Card>
         )}
      </div>
   )
}

const ErrorListView = ({errData}) => {
   let errors = JSON.parse(errData);
   return (
     <Table>
       <thead className="text-primary">
         <th className="='text-center">#</th>
         <th>Row / SKU Code</th>
         <th>Error</th>
       </thead>
       <tbody>
         {Object.entries(errors).map(([key, values], index) => {
           return (
             <tr key={key}>
               <td className="text-center">{index + 1}</td>
               <td>{key}</td>
               <td>{values}</td>
             </tr>
           );
         })}
       </tbody>
     </Table>
   );
 }