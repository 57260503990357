import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Table } from "antd";

import { checkSKU } from "services/dataservice";
import PromotionItemsBulkPrimaryModal from "./PromotionItemsBulkPrimaryModal";
import "./index.css";

export default function PromotionItemsOptionOne(props) {
  let { tempSKU, setTempSKU, dataSourceItemsOptionOne, setDataSourceItemsOptionOne,quantity, promotion, setPromotion, setAlert } = props;
  // const [modalShow, setModalShow] = useState(false);
  // const toggle = () => {
  //   setModalShow(!modalShow);
  // };

  const columnsItemsOptionOne = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      key: "1",
      title: "Primary Product SKU",
      dataIndex: "primary",
    },
    {
      key: "2",
      title: "Product Name",
      dataIndex: "productName",
    },
    {
      key: "3",
      title: "Base UOM",
      dataIndex: "baseUOM",
    },
    {
      key: "4",
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      key: "5",
      title: "Action",
      render: (record) => {
        return (
          <>
            <Button
              id="btnDelete"
              size="sm"
              className="btn-icon btn-link remove"
              color="danger"
              onClick={() => {
                clickDeleteButtonOptionOne(record);
              }}>
              <i className="fa fa-times" />
            </Button>
          </>
        );
      },
    },
  ];

  const clickDeleteButtonOptionOne = (record) => {
    promotion["edit"] = true;
    setPromotion({ ...promotion });
    setDataSourceItemsOptionOne((pre) => {
      return pre.filter((newRecord2) => newRecord2.primary !== record.primary);
    });
  };

  const clickAddButtonOptionOne = async () => {
    var txtPrimary = document.getElementById("primary").value;
    var duplicate = false;

    if (txtPrimary === "") {
      showFail("Please do not leave empty");
      return;
    }
    for (var i = 0; i < dataSourceItemsOptionOne.length; i++) {
      if (dataSourceItemsOptionOne[i].primary.toLowerCase() == txtPrimary.toLowerCase()) {
        duplicate = true;
      }
    }
    if (duplicate) {
      showFail(txtPrimary + " already in list");
    } else {
      try {
        let res = await checkSKU(txtPrimary);
        console.log(res);
        if (res.data.exist) {
          const newRecord = {
            //primary: txtPrimary,
            primary:res.data.inventorySKU,
            quantity,
            baseUOM:res.data.baseUOM,
            productName: res.data.productName,
          };
          setDataSourceItemsOptionOne((pre) => {
            return [...pre, newRecord];
          });
          promotion["edit"] = true;
          setPromotion({ ...promotion });
          document.getElementById("primary").value = "";
          document.getElementById("primary").focus();
        } else {
          showFail(txtPrimary + " not in system");
        }
      } catch {
        showFail("Connection to server failed");
        return;
      }
    }
  };

  const showFail = (msg) => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""></ReactBSAlert>
    );
  };

  return (
    <>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label>Primary Product SKU</Label>
            <Input
              type="text"
              name="primaryProductSKU"
              id="primary"
              value={tempSKU}
              onChange={(e) => {
                setTempSKU(e.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <Button
            id="optionOneButton"
            style={{
              margin: 25,
              backgroundColor: "#d7f2ed",
              color: "black",
            }}
            onClick={clickAddButtonOptionOne}>
            Add
          </Button>
        </Col>
        {/* <Col md="2">
          <Button style={{ margin: 25, backgroundColor: "#e0eeec", color: "black" }} onClick={toggle}>
            Bulk Add
          </Button>
        </Col> */}
      </Row>
      <Table className="itemsOptionOne" columns={columnsItemsOptionOne} dataSource={dataSourceItemsOptionOne} />
      <br></br>
      {/* <PromotionItemsBulkPrimaryModal isOpen={modalShow} toggle={toggle} {...props} /> */}
    </>
  );
}
