import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Card, CardHeader, CardBody, CardFooter, Button, CardTitle, Table } from "reactstrap";
import { Link } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useMutation, useQuery } from "@tanstack/react-query";
import { uploadKitFile, getMarketplace_AllowCreateOrder, getUserMarketplace } from "services/dataservice";

export default function CatalogueKitUpload() {
  const [alert, setAlert] = useState(null);
  const [selectedFile, setFile] = useState([]);
  const [respData, setRespData] = useState([]);
  const [userMarketplaceId, setUserMarketplaceId] = useState(-1);

  const showSuccess = (msg, callBack = null) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => (callBack == null ? setAlert(null) : callBack())}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"></ReactBSAlert>
    );
  };

  const showFail = (msg) => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""></ReactBSAlert>
    );
  };

  const onError = (data) => {
    showFail(data.toString());
  };

  const { data: marketplace } = useQuery({
    queryKey: ["marketplace"],
    queryFn: getMarketplace_AllowCreateOrder,
    onError,
  });

  // console.log(marketplace??"123")

  useQuery({
    queryKey: ["marketplace", marketplace?.data?.[0]?.id],
    enabled: marketplace?.data?.[0]?.id !== undefined,
    queryFn: () => getUserMarketplace(marketplace.data[0].id),
    onSuccess: (data) => {
      if (data.data.length > 0) setUserMarketplaceId(data.data[0].id);
    },
    onError,
  });

  const uploadFileMutation = useMutation({
    mutationFn: uploadKitFile,
  });

  const fileUpload = () => {
    // if(userMarketplaceId.toString()!=="1128"){
    //   showFail("Kit Failed To Upload")
    //   return
    // }
    let formData = new FormData();
    formData.append("userMarketplaceId", userMarketplaceId);
    for (var file of selectedFile) {
      formData.append("files", file, file.name);
    }
    if (selectedFile.length === 0) {
      showFail("Kit Failed To Upload");
      return;
    }
    uploadFileMutation.mutate(formData, {
      onSuccess: (data) => {
        showSuccess("Kit Uploaded");
        //console.log(data);
        setRespData(JSON.parse(data.data.data));
        //setRespData(data)
        document.getElementById("fileSelector").value = "";
        setFile([]);
      },
      onError: (data) => {
        //console.log(data.response)
        const { description } = data.response.data;
        if (description) {
          showFail(description);
        } else {
          showFail(data.toString());
        }
      },
    });
  };

  const onFileChange = (event) => {
    let array = [];
    for (var file of event.target.files) {
      array.push(file);
    }
    console.log(array);
    setFile(array);
  };

  const templateDownload = () => {
    const link = "/api/template/getTemplate?type=kit";
    window.open(link);
  };
  return (
    <div className="content">
      {alert}
      {/* {uploadFileMutation.status.toString()} */}
      {/* {marketplace?.toString()} */}
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Upload Kit
            <Link to="/admin/marketplace/list">
              <i style={{ float: "right", right: "1%" }} className="nc-icon nc-simple-remove" />
            </Link>
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Form>
            <Button color="info" onClick={templateDownload}>
              Download Template
            </Button>
            <FormGroup>
              <Label for="fileSelector">Kit File</Label>
              <Input type="file" name="file" onChange={onFileChange} id="fileSelector"></Input>
            </FormGroup>
          </Form>
        </CardBody>
        <CardFooter>
          <Button onClick={fileUpload} disabled={uploadFileMutation.status === "loading"}>
            {uploadFileMutation.status === "loading" ? "Uploading" : "Upload"}
          </Button>
        </CardFooter>
      </Card>

      {respData.length > 0 && (
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Upload Response</CardTitle>
          </CardHeader>
          <CardBody>
            {
              <Table>
                <thead>
                  <tr>
                    <th>Kit SKU</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {respData?.map((x, i) => (
                    <tr key={i}>
                      {/* {console.log(x)} */}
                      <td>{x.Id}</td>
                      <td>
                        <ul>
                          {x.Message?.map((m, index) => (
                            <li key={index}>{m}</li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            }
          </CardBody>
        </Card>
      )}
    </div>
  );
}
