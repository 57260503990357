import React, { Fragment } from "react";
// import AttributeMandatory from "./AttributeMandatory";
import AttributeNormal from "./AttributeNormal";
export default function index(props) {
  let {  attrNormals, setAttrNormals, brand, setBrand,brandList } = props;

  // const attributeMandatoryProps = {
  //   attrMandatories,
  //   setAttrMandatories,
  //   brand,
  //   setBrand,
  //   // brands,
  // };
  return (
    <Fragment>
      {/* <AttributeMandatory {...attributeMandatoryProps} /> */}
      <AttributeNormal brand={brand} setBrand={setBrand} brandList={brandList} attrNormals={attrNormals} setAttrNormals={setAttrNormals} />
    </Fragment>
  );
}
