import React, { memo } from "react";
import { CardBody, CardTitle, FormGroup, Input, Label } from "reactstrap";

import Information from "./Information";
import Tag from "./Tag";
import Image from "./Image";
import ParentSku from "./ParentSku";

export default memo(function index(props) {
  let { product, basicInformation, setBasicInformation, parentSKU, setParentSKU, productImages, setProductImages, platformName, tags, setTags } = props;

  return (
    <CardBody>
      {/* {console.log("render basic")} */}
      <CardTitle tag="h6">Basic Information</CardTitle>
      {platformName !== "Shopify" && (
        <FormGroup>
          <Label>Category</Label>
          <Input type="text" defaultValue={product?.categoryValue} readOnly />
        </FormGroup>
      )}

      <Information basicInformation={basicInformation} setBasicInformation={setBasicInformation} />
      {["Lazada","Zalora", "ShopeeV2"].includes(platformName) && <ParentSku parentSKU={parentSKU} setParentSKU={setParentSKU} />}
      {platformName === "Shopify" && <Tag tags={tags} setTags={setTags} />}
      <Image productImages={productImages} setProductImages={setProductImages} />
    </CardBody>
  );
});
