import React, { useState } from "react";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Table } from "antd";

import { checkSKU } from "services/dataservice";
// import PromotionItemsBulkPrimaryModal from "./PromotionItemsBulkPrimaryModal";
import "./index.css";

export default function PromotionItemsOptionSecThird(props) {
  let { tempSKU, setTempSKU, dataSourceItemsOptionSecThird, setDataSourceItemsOptionSecThird, promotion, setPromotion, setAlert } = props;
  // const [modalShow, setModalShow] = useState(false);
  // const toggle = () => {
  //   setModalShow(!modalShow);
  // };

  const columnsItems = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      key: "1",
      title: "Primary Product SKU",
      dataIndex: "primary",
    },
    {
      key: "2",
      title: "Product Name",
      dataIndex: "productName",
    },
    {
      key: "3",
      title: "Base UOM",
      dataIndex: "baseUOM",
    },
    {
      key: "4",
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      key: "5",
      title: "Action",
      render: (record) => {
        return (
          <>
            <Button
              id="btnDelete"
              size="sm"
              className="btn-icon btn-link remove"
              color="danger"
              onClick={() => {
                clickDeleteButtonItemsOptionSecThird(record);
              }}>
              <i className="fa fa-times" />
            </Button>
          </>
        );
      },
    },
  ];

  const clickDeleteButtonItemsOptionSecThird = (record) => {
    promotion["edit"] = true;
    setPromotion({ ...promotion });
    setDataSourceItemsOptionSecThird((pre) => {
      return pre.filter((newRecord2) => newRecord2.primary !== record.primary);
    });
  };

  const clickAddButtonItemsOptionSecThird = async () => {
    var txtPrimary = document.getElementById("secPrimary").value;
    var txtQuantity = document.getElementById("unitPrimary").value;
    var duplicate = false;

    if (txtPrimary === "" || txtQuantity === "") {
      showFail("Please do not leave empty");
      return;
    }
    const regex = /^\d{1,10}$/;
    if (!txtQuantity.match(regex)) {
      showFail("Unit must be in whole number");
      return;
    }
    if (parseInt(txtQuantity) < 1) {
      showFail("Unit must not less then 1");
      return;
    }
    for (var i = 0; i < dataSourceItemsOptionSecThird.length; i++) {
      if (dataSourceItemsOptionSecThird[i].primary.toLowerCase() === txtPrimary.toLowerCase()) {
        duplicate = true;
      }
    }

    if (duplicate) {
      showFail(txtPrimary + " already in list");
    } else {
      try {
        let res = await checkSKU(txtPrimary);
        if (res.data.exist) {
          const newRecord = {
            //primary: txtPrimary,
            primary:res.data.inventorySKU,
            quantity: txtQuantity,
            baseUOM:res.data.baseUOM,
            productName: res.data.productName,
          };
          setDataSourceItemsOptionSecThird((pre) => {
            return [...pre, newRecord];
          });
          promotion["edit"] = true;
          setPromotion({ ...promotion });
          document.getElementById("secPrimary").value = "";
          document.getElementById("unitPrimary").value = "";
          document.getElementById("secPrimary").focus();
        } else {
          showFail(txtPrimary + " not in system");
        }
      } catch {
        showFail("Connection to server failed");
        return;
      }
    }
  };

  const showFail = (msg) => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""></ReactBSAlert>
    );
  };

  return (
    <>
      <Row>
        <Col md="6">
          <FormGroup>
            <Label>Primary Product SKU</Label>
            <Input
              type="text"
              name="primaryProductSKUSec"
              id="secPrimary"
              value={tempSKU}
              onChange={(e) => {
                setTempSKU(e.target.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <Label>Unit</Label>
            <Input type="number" name="Unit" id="unitPrimary" />
          </FormGroup>
        </Col>
        <Col md="2">
          <Button id="optionSecThirdButton" style={{ margin: 25, backgroundColor: "#e0eeec", color: "black" }} onClick={clickAddButtonItemsOptionSecThird}>
            Add
          </Button>
        </Col>
        {/* <Col md="2">
          <Button style={{ margin: 25, backgroundColor: "#e0eeec", color: "black" }} onClick={toggle}>
            Bulk Add
          </Button>
        </Col> */}
      </Row>
      <Table className="itemsOptionSecThird" columns={columnsItems} dataSource={dataSourceItemsOptionSecThird} />
      {/* <PromotionItemsBulkPrimaryModal isOpen={modalShow} toggle={toggle} {...props} /> */}
      <br></br>
    </>
  );
}
