import React from 'react';
import { Row, Col, CardHeader, CardTitle, CardBody, FormGroup, Label, Input, Button } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { Table } from 'antd';

import { checkSKU } from 'services/dataservice';
import "./index.css";

export default function PromotionGreater(props) {
    let { tempGiftSKU,setTempGiftSKU,dataSourceGreater, setDataSourceGreater, promotion, greaterAmountState, greaterQuantityState, setAlert, onChangeDescription, setPromotion, setGreaterAmountState, setGreaterQuantityState } = props;

    const columnsGreater = [
        {
            title: '#',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            key: '1',
            title: 'Gift Product SKU',
            dataIndex: 'gift',
        },
        {
            key: '2',
            title: 'Unit',
            dataIndex: 'unit',
        },
        {
            key: '3',
            title: 'Set Limit',
            dataIndex: 'limit',
        },
        {
            key: '4',
            title: 'Action',
            render: (record) => {
                return (
                    <>
                        <Button
                            id="btnDelete"
                            size="sm"
                            className="btn-icon btn-link remove"
                            color="danger"
                            onClick={() => {
                                clickDeleteButtonGreater(record);
                            }}>
                            <i className="fa fa-times" />
                        </Button>
                    </>
                )
            }
        },
    ];

    const clickDeleteButtonGreater = (record) => {
        promotion["edit"] = true;
        setPromotion({ ...promotion });
        setDataSourceGreater((pre) => {
            return pre.filter((newRecord2) => newRecord2.gift !== record.gift);
        });
    }

    const clickAddButtonGreater = async () => {
        var txtPrimary = document.getElementById("giftGreater").value;
        var txtQuantity = document.getElementById("unitGreater").value;
        var txtLimit = document.getElementById("limitGreater").value;
        var duplicate = false;

        if (txtPrimary === '' || txtQuantity === '' || txtLimit === '') {
            showFail("Please do not leave empty");
            return;
        }
        const regex = /^\d{1,10}$/;
        if (!(txtQuantity).match(regex) || !(txtLimit).match(regex)) {
            showFail("Unit and Stock Limit must be in whole number");
            return;
        }
        if (parseInt(txtQuantity) < 1) {
            showFail("Unit must not less then 1");
            return;
        }
        if (parseInt(txtLimit) < parseInt(txtQuantity)) {
            showFail("Stock limit should not less than unit");
            return;
        }
        for (var i = 0; i < dataSourceGreater.length; i++) {
            if (dataSourceGreater[i].gift === txtPrimary) {
                duplicate = true;
            }
        }

        if (duplicate) {
            showFail(txtPrimary + " already in list");
        } else {
            try {
                let res = await checkSKU(txtPrimary);
                if (res.data.exist) {
                    const newRecord = {
                        gift: txtPrimary,
                        unit: txtQuantity,
                        limit: txtLimit,
                    }; 
                    setDataSourceGreater((pre) => {
                        return [...pre, newRecord];
                    });
                    promotion["edit"] = true;
                    setPromotion({ ...promotion });
                    document.getElementById("giftGreater").value = "";
                    document.getElementById("unitGreater").value = "";
                    document.getElementById("limitGreater").value = "";
                    document.getElementById("giftGreater").focus();
                }
                else {
                    showFail(txtPrimary + " not in system");
                }
            } catch (e) {
                showFail('Connection to server failed');
                return;
            }
        }
    }

    const showFail = (msg) => {
        setAlert(
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title={msg}
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="info"
                btnSize=""
            >
            </ReactBSAlert>
        )
    }

    return (
        <>
            <CardHeader>
                {(promotion.promotionCriteria === "Order Total") && (<CardTitle tag="h6">Greater Than Order Amount</CardTitle>)}
                {(promotion.promotionCriteria === "Product Quantity") && (<CardTitle tag="h6">Greater Than Product Quantity</CardTitle>)}
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md="6">
                        {(promotion.promotionCriteria === "Order Total") && (
                            <FormGroup className={`has-label ${greaterAmountState}`}>
                                <Label>If order amount is greater than</Label>
                                <Input
                                    type="number"
                                    name="primaryProductSKU"
                                    id="greaterAmount"
                                    defaultValue={promotion.greaterValue}
                                    onChange={(e) => {
                                        const regex = /^\d{1,10}\.{0,1}\d{0,2}$/;
                                        if (e.target.value < 0.01 || !(e.target.value).match(regex)) {
                                            setGreaterAmountState("has-danger");
                                        } else {
                                            setGreaterAmountState("has-success");
                                        }
                                    }}
                                />
                                {
                                    greaterAmountState === "has-success" ? null : (<label className="error">This field is required. Must be in whole number or with two decimals.</label>)
                                }
                            </FormGroup>
                        )}
                        {(promotion.promotionCriteria === "Product Quantity") && (
                            <FormGroup className={`has-label ${greaterQuantityState}`}>
                                <Label>If product quantity is greater than (unit)</Label>
                                <Input
                                    type="number"
                                    name="primaryProductSKU"
                                    id="greaterQuantity" 
                                    defaultValue={promotion.greaterValue}
                                    onChange={(e) => {
                                        const regex = /^\d{1,10}$/;
                                        if (e.target.value < 1 || !(e.target.value).match(regex)) {
                                            setGreaterQuantityState("has-danger");
                                        } else {
                                            setGreaterQuantityState("has-success");
                                        }
                                    }}
                                />
                                {
                                    greaterQuantityState === "has-success" ? null : (<label className="error">This field is required. Must be in whole number.</label>)
                                }
                            </FormGroup>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label>Gift Product SKU</Label>
                            <Input type="text" name="giftSKU" id="giftGreater" value={tempGiftSKU} onChange={(e)=>{setTempGiftSKU(e.target.value)}} />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                            <Label>Unit</Label>
                            <Input type="number" name="unit" id="unitGreater" />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                            <Label>Stock Limit</Label>
                            <Input type="number" name="stockLimit" id="limitGreater" />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <Button
                            id="greaterButton"
                            style={{ margin: 25, backgroundColor: "#f2e0e9", color: "black" }}
                            onClick={clickAddButtonGreater}>
                            Add
                        </Button>
                    </Col>
                </Row>
                <Table
                    className='greaterThan'
                    columns={columnsGreater}
                    dataSource={dataSourceGreater}
                />
                <br></br>
                <FormGroup>
                    <Label>Description</Label>
                    <Input type="text" name="description" defaultValue={promotion.description} onChange={(e) => onChangeDescription(e)} />
                </FormGroup>
            </CardBody>

        </>
    )
}