import React, {  useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Form, FormGroup, Input, Label, UncontrolledAlert } from "reactstrap";
import { getAWBByOrderNumber } from "services/dataservice";

export default function OrderPrint() {
  const [ordNbr, setOrdNbr] = useState("");
  const [msg,setMsg]=useState({isError:false,message:""})
  
  const onSearch = async() => {
    if(!ordNbr){
      setMsg({isError:true,message:"Please Input Order Number"})
      return
    }
    try {
      const response=await getAWBByOrderNumber(ordNbr)
    
    const{data}=response
    setMsg({isError:false,message:""})
    window.open(data,"_blank")
    } catch (error) {
      
      const{data}=error.response
      setMsg({isError:true,message:data})
    }
  };
  const onEnterSearch = async(e) => {
    const { keyCode } = e;
    if (keyCode !== 13) {
      return;
    }
    
     onSearch()
     
  };
  return (
    <div className="content">
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            Print Order AWB
            <Link to="/admin/order/list">
              <i style={{ float: "right", right: "1%" }} className="nc-icon nc-simple-remove" />
            </Link>
          </CardTitle>
        </CardHeader>
        <CardBody>
          {msg.isError&&(
            <UncontrolledAlert color="danger" toggle={()=>setMsg({isError:false,message:""})}>{msg.message}</UncontrolledAlert>
          )}
           
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}>
            <FormGroup>
              <Label>Order Number</Label>
              <Input
                type="text"
                placeholder="Press Enter / Click Button Search to get AWB"
                onChange={(e) => {
                  setOrdNbr(e.target.value);
                }}
                onKeyUp={onEnterSearch}
              />
            </FormGroup>
          </Form>
        </CardBody>
        <CardFooter>
          <Button color="success" onClick={onSearch}>Search</Button>
        </CardFooter>
      </Card>
    </div>
  );
}
