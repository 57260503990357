import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import {
   Badge,
   Col,
   Row,
   Card,
   CardHeader,
   CardBody,
   CardTitle, Nav, NavItem, NavLink, Button
} from 'reactstrap'

import ReactTable from "components/ReactTable/ReactTable.js";
import { getOrdersStatusCount, getOrderList } from 'services/dataservice';

const columns = [
   {
      Header: "Order",
      accessor: "orderNumber"
   },
   {
      Header: "Store Name",
      accessor: "storeName"
   },
   {
      Header: "Marketplace Status",
      accessor: "marketplaceStatus"
   },
   {
      Header: "System Status",
      accessor: "orderStatus"
   },
   {
      Header: "Order Date",
      accessor: "orderDate"
   },
   {
      Header: "Message",
      accessor: "message"
   },
   {
      Header: "Actions",
      accessor: "action"
   }
]

export default function FailedOrderList(prop) {
   const [tableData, setTableData] = useState([])
   const [tabs, setTabs] = useState("New");
   const [orderStatusCount, setOrderStatusCount] = useState({
      newOrder: 0,
      readyToShip: 0,
      shipped: 0,
      delivered: 0,
      returnAndExchange: 0,
      cancelled: 0,
      failed: 0,
      partial: 0
   })
   const history = useHistory();

   useEffect(() => {

      async function loadTable() {
         const failedList = await getOrderList("failed", "");

         const data = failedList.data;
         data.forEach(x => x.orderStatus = x.orderStatus.replace(/_/g, " "));
         setTableData(data.map((res, index) => {
            return {
               ...res,
               action: (
                  <div className="actions-right">
                     <Button id="btnEdit"
                        size="sm"
                        className="btn-icon btn-link edit"
                        color="primary"
                        onClick={() => history.push(`/admin/order/detail/${res.orderId}`)}>
                        <i className="fa fa-edit"></i>
                     </Button>
                  </div>
               )
            }
         }))
      }

      loadTable();

   }, []);

   const OrderTable = () => {
      return (
         <ReactTable
            data={tableData}
            columns={columns}
            className="-striped -highlight primary-pagination" />
      )
   }



   return (
      <div className="content">
         <Row>
            <Col md="12">
               <Card className="card-subcategories">
                  <CardHeader>
                     <CardTitle tag="h4">Order Failed to Submit to eHub</CardTitle>
                  </CardHeader>
                  <CardBody>
                     <div>
                        <div>
                           <OrderTable />
                        </div>
                     </div>
                  </CardBody>
               </Card>
            </Col>
         </Row>
      </div>
   )
}