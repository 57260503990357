import React, { useEffect, useState } from 'react'
import { getStore } from 'services/dataservice';
import { AddStoreShopee } from './AddStoreShopee';
import { AddStoreZalora } from './AddStoreZalora';

import ReactBSAlert from 'react-bootstrap-sweetalert';
import { AddStoreShopify } from './AddStoreShopify';
import { AddStoreWooCommerce } from './AddStoreWooCommerce';
import { AddStoreLazada } from './AddStoreLazada';
import { AddStoreMagento } from './AddStoreMagento';
import { AddStoreTiktok } from './AddStoreTiktok';
import { AddStoreZalora2 } from './AddStoreZalora2';
import { AddStoreShopline } from './AddStoreShopline';

export function EditStore(props) {
   const [storeData, setStoreData] = useState({});
   const [alert, setAlert] = useState(null);

   useEffect(() => {

      async function load() {
         let resp = await getStore(props.match.params.id)
         setStoreData(resp.data);
      }

      load();

   }, [props.match.params.id])

   const showFail = (msg) => {
      setAlert(
         <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title={msg}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info"
            btnSize=""
         >
         </ReactBSAlert>
      )
   };

   return (
      <div className="content">
         {alert}
         {
            storeData.platformName === "Zalora" ? <AddStoreZalora2 showFail={showFail} selectedMarketplace={storeData} edit={true}></AddStoreZalora2> : null
         }
         {
            storeData.platformName === "Shopee" || storeData.platformName === "ShopeeV2" ? <AddStoreShopee showFail={showFail} selectedMarketplace={storeData} edit={true}></AddStoreShopee> : null
         }
         {
            storeData.platformName === "Shopify" ? <AddStoreShopify showFail={showFail} selectedMarketplace={storeData} edit={true}></AddStoreShopify> : null
         }
         {
            storeData.platformName?.includes("Lazada") ? <AddStoreLazada showFail={showFail} selectedMarketplace={storeData} edit={true}></AddStoreLazada> : null
         }
         {
            storeData.platformName === "Magento" || storeData.platformName === "MagentoV2" ? <AddStoreMagento showFail={showFail} selectedMarketplace={storeData} edit={true}></AddStoreMagento> : null
         }
          {
            storeData.platformName === "WooCommerce" ? <AddStoreWooCommerce showFail={showFail} selectedMarketplace={storeData} edit={true}></AddStoreWooCommerce> : null
         }
         {
            storeData.platformName === "Tiktok" ? <AddStoreTiktok showFail={showFail} selectedMarketplace={storeData} edit={true}></AddStoreTiktok>:null
         }
         {
            storeData.platformName === "ZaloraV2" ? <AddStoreZalora2 showFail={showFail} selectedMarketplace={storeData} edit={true}></AddStoreZalora2> : null
         }
         {
            storeData.platformName === "Shopline" ? <AddStoreShopline showFail={showFail} selectedMarketplace={storeData} edit={true}></AddStoreShopline> : null
         }

      </div>
   )


}