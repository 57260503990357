import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Button, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import ReactTable from "components/ReactTable/ReactTable";

import { getMarketplaceList, getMarketplaceInventory } from "services/dataservice";
import Container from "reactstrap/lib/Container";
import { useMutation, useQuery } from "@tanstack/react-query";
import { exportKitFile } from "services/dataservice";
import moment from "moment";
import { resubmitKit } from "services/dataservice";
import ReactBSAlert from "react-bootstrap-sweetalert"
import { syncKit } from "services/dataservice";

const columns = [
  {
    Header: "Marketplace SKU",
    accessor: "marketplaceSKU",
  },
  {
    Header: "Inventory SKU",
    accessor: "inventorySKU",
  },
  {
    Header: "Product Name",
    accessor: "name",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  // {
  //    Header: "Last Updated",
  //    accessor: "lastUpdated"
  // },
  {
    Header: "Reserved Quantity",
    accessor: "campaignReservedQuantity",
  },
  {
    Header: "Quantity",
    accessor: "marketplaceQuantity",
  },
  {
    Header: "Actions",
    accessor: "action",
  },
];

export default function MarketplaceList(props) {
  const [marketplace, setMarketplace] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [alert,setAlert]=useState(null)

  let history = useHistory();

  useEffect(() => {
    //Init List of marketplace
    getMarketplaceList().then((res) => {
      if (res.data.platformList.length > 0) {
        setMarketplace(res.data.platformList);
        setActiveTab(res.data.platformList[0].userMarketPlaceId);
      }
    });
  }, []);

  useEffect(() => {
    //Update table data
    if (activeTab != null) {
      getMarketplaceInventory(activeTab).then((res) => {
        setTableData(modifyData(res));
      });
    }
  }, [activeTab]);

  const { refetch } = useQuery({
    queryKey: ["export-kit"],
    queryFn: () => exportKitFile(),
    enabled: false,
    onSuccess: (data) => {
      const date = new Date();
      const dateStr = moment(date).format("YYYYMMDDHHmmss");
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Kit_${dateStr}.csv`);
      document.body.appendChild(link);
      link.click();
    },
  });

  const resubmitMutation = useMutation(resubmitKit,{})
  const resubmitStatus = resubmitMutation.status === "loading"

  const syncMutation = useMutation(syncKit,{})
  const syncStatus = syncMutation.status === "loading"

  const resubmit = () => {
    resubmitMutation.mutate({},{
      onSuccess:(data)=>{
        showSuccess(data.data.message)

      }
    })
  }

  const sync = () => {
    syncMutation.mutate({},{
      onSuccess:(data)=>{
        showSuccess(data.data.message)

      }
    })
  }

  function modifyData(res) {
    return res.data.map((res, index) => {
      return {
        ...res,
        action: (
          <div className="actions-right">
            <Button
              id="btnEdit"
              size="sm"
              className="btn-icon btn-link edit"
              color="primary"
              onClick={() => {
                let id = res.catalogueId;
                if (res.type == "SINGLE") {
                  history.push(`/admin/catalogue/detail/${id}`);
                } else if (res.type == "KIT") {
                  history.push(`/admin/catalogue/editkit/${id}`);
                }
              }}>
              <i className="fa fa-edit" />
            </Button>
          </div>
        ),
      };
    });
  }

  const showSuccess = (msg, callBack = null) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => (callBack == null ? setAlert(null) : callBack())}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"></ReactBSAlert>
    );
  };

  const Loading = ({msg}) =>(
    <ReactBSAlert
      info
      style={{ display: "block", marginTop: "-100px" }}
      title={msg}
      onConfirm={()=>{}}
      showConfirm={false}
      btnSize=""></ReactBSAlert>)

  return (
    <div className="content">
      {alert}
       {(resubmitStatus || syncStatus) && <Loading msg="Submitting ..."/> }
      <Row className="row justify-content-end">
        <Col md="4" className="d-flex flex-row-reverse">
          <Link to="/admin/catalogue/addkit">
            <Button color="primary">Add Kit</Button>
          </Link>
          <Link to="/admin/catalogue/uploadkit">
            <Button color="primary">Upload Kit</Button>
          </Link>
          <Button color="primary" onClick={refetch}>
            Export Kit
          </Button>
          <Button color="primary" onClick={resubmit}>
            Resubmit Kit
          </Button>
          <Button color="primary" onClick={sync}>
            Sync Kit Quantity
          </Button>
        </Col>
      </Row>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Marketplace Inventory</CardTitle>
        </CardHeader>
        <CardBody>
          <Container fluid>
            <Row>
              <Col md="auto">
                <div className="nav-tabs-navigation verical-navs p-0">
                  <div className="nav-tabs-wrapper">
                    <Nav className="flex-column nav-stacked" tabs>
                      {marketplace.map((val, index) => {
                        return (
                          <NavItem key={val.userMarketPlaceId}>
                            <NavLink
                              href="#"
                              className={activeTab === val.userMarketPlaceId ? "active" : ""}
                              onClick={() => {
                                setActiveTab(val.userMarketPlaceId);
                              }}>
                              {val.name}
                            </NavLink>
                          </NavItem>
                        );
                      })}
                    </Nav>
                  </div>
                </div>
              </Col>
              <Col>
                <ReactTable data={tableData} columns={columns} className="-striped -highlight primary-pagination" />
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </div>
  );
}
