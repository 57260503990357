import React, { useState, memo } from "react";
import { CardBody, CardTitle, Col, Row, Table } from "reactstrap";
import TableSkuHead from "./TableSkuHead";
import TableSkuBody from "./TableSkuBody";
// import ModalEdit from "./ModalEdit";
// import NewSku from "./NewSku";

export default memo(function Skus(props) {
  let { variations, setVariations,  showFail, platformName,  optionList } = props;

  // const [alertSkuMsg, setAlertSkuMsg] = useState(null);

  // const [activeItem, setActiveItem] = useState({});
  // const [modalShowEdit, setModalShowEdit] = useState(false);

  // const toggleModalShowEdit = (item) => {
  //   setActiveItem(item);
  //   setModalShowEdit(!modalShowEdit);
  // };

  // const toggleModalClose = () => {
  //   setModalShowEdit(!modalShowEdit);
  // };

  // const handleEditPrice = (e) => {
  //   let { value } = e.target;
  //   let activeItemObj = { ...activeItem };
  //   activeItemObj.sellingPrice = value;
  //   setActiveItem(activeItemObj);
  // };

  // const handleSaveEditedPrice = () => {
  //   // let productClone = { ...product };
  //   // productClone.variations[activeItem.index].sellingPrice = activeItem.sellingPrice;
  //   let variationsClone = [...variations];
  //   variationsClone[activeItem.index].sellingPrice = activeItem.sellingPrice;
  //   setVariations(variationsClone);
  //   toggleModalClose();
  // };

  // const modalEditProps = {
  //   activeItem,
  //   modalShowEdit,
  //   toggleModalShowEdit,
  //   toggleModalClose,
  // };

  const tableSkuHeadProps = {
    optionList,
    platformName,
  };

  const tableSkuBodyProps = {
    variations,
    setVariations,
    // toggleModalShowEdit,
    // setAlertSkuMsg,
    showFail,
    platformName,
    // multiple,
    // count,
  };

  // const newSkuProps = {
  //   newSkus,
  //   setNewSkus,
  //   showFail,
  //   setAlertSkuMsg,
  //   platformName,
  //   objOptions,
  //   options,
  //   multiple,
  //   count,
  // };

  return (
    <CardBody>
      {/* {console.log("render skus")} */}
      <CardBody style={{ border: "1px solid #e3e3e3", borderRadius: "8px" }}>
        <CardTitle tag="h6" style={{ textDecoration: "underline" }}>
          Sku Information
        </CardTitle>
        {/* {alertSkuMsg} */}
        <Row form>
          <Col>
            <Table borderless striped>
              <TableSkuHead {...tableSkuHeadProps} />
              <TableSkuBody {...tableSkuBodyProps} />
            </Table>
            {/* <ModalEdit {...modalEditProps} /> */}
          </Col>
        </Row>
        {/* <NewSku {...newSkuProps} /> */}
        {/* <NewSkus {...newSkuProps} options={variations[0]?.optionList} /> */}
      </CardBody>
    </CardBody>
  );
});

// const OptionSku = ({ options }) => {
//   options?.map((o) => (
//     <td key={o.catalogueAttributeId}>
//       <Input type="text" name={o.option} value={nSku[o.option]} onChange={(e) => newSkuBlur(e, index)} />
//     </td>
//   ));
// };
