import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, CardHeader, CardTitle, Form } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
//Function
import { assignShopeeAttributeValue } from "../../common";
//Components
import BasicInfo from "../Components/BasicInfo";
// import Attribute from "../../Shopee/Components/Attribute";
import Attribute from "../../ProductAdd/Shopee/Components/Attribute";
import Options from "../Components/Options/Options3";
import OptionImages from "../Components/Options/OptionImages";
import Skus from "../Components/Skus/Skus";
// import Shipping from "views/product/Shopee/Components/Shipping";
import Shipping from "../../ProductAdd/Shopee/Components/Shipping";
import EditFormSubmit from "./Components/EditFormSubmit";
import { getProductDetail, getMarketplaceAttribute, getMarketplaceLogistics } from "services/dataservice";
import { formatShopeeAttributes, formatShopeeLogisticsChannelList } from "views/product/common";
import { getMarketplaceBrands } from "services/dataservice";
import { generateCancelToken } from "views/product/fetch";

export default function ShopeeProductDetail(props) {
  //console.log(props)
  const [alert, setAlert] = useState(null);
  const showFail = useCallback((msg) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      ></SweetAlert>
    );
  }, []);

  const [brandList,setBrandList]=useState([])
  useEffect(() => {
    const{cancel,cancelToken}=generateCancelToken()
    const fetchProductDetail = async () => {
      try {
        let CatalogueBasicInfoId = props.match.params.id;
        let response = await getProductDetail(CatalogueBasicInfoId,{cancelToken});
        if (response.status === 200) {
          let productDetail = response.data.data;
          // let productDetail = [
          //   {
          //     catalogueBasicInfoId: 79,
          //     description: "121212",
          //     productName: "Huawei Nova 5T",
          //     brand: "No Brand",
          //     categoryId: 100003,
          //     categoryValue: "Smartphones",
          //     parentSKU: "colorssssssssssssss",
          //     tags: null,
          //     active: false,
          //     isVariation: true,
          //     marketplace: null,
          //     status: null,
          //     userMarketplaceId: 112,
          //     platformName: "Shopee",
          //     attributes: {
          //       "MOH Certified": {
          //         attribute_id: 100966,
          //         attribute_value_list: [
          //           {
          //             value_id: 5084,
          //             original_value_name: "No",
          //           },
          //         ],
          //         value: ["No"],
          //       },
          //       "Specialty Diet": {
          //         attribute_id: 100009,
          //         attribute_value_list: [
          //           {
          //             value_id: 501,
          //             original_value_name: "Sugar Free",
          //           },
          //         ],
          //         value: ["Sugar Free"],
          //       },
          //       "Shelf Life": {
          //         attribute_id: 100010,
          //         attribute_value_list: [
          //           {
          //             value_id: 580,
          //             original_value_name: "6 Months",
          //           },
          //         ],
          //         value: ["6 Months"],
          //       },
          //       "Pack Type": {
          //         attribute_id: 100016,
          //         attribute_value_list: [
          //           {
          //             value_id: 381,
          //             original_value_name: "Value Size",
          //           },
          //         ],
          //         value: ["Value Size"],
          //       },
          //       "Expiry Date": {
          //         attribute_id: 100061,
          //         attribute_value_list: [
          //           {
          //             value_id: 0,
          //             original_value_name: 1643673600,
          //           },
          //         ],
          //         value: ["2022-02"],
          //       },
          //       Flavour: {
          //         attribute_id: 100249,
          //         attribute_value_list: [
          //           {
          //             value_id: 807,
          //             original_value_name: "Dragonfruit",
          //           },
          //           {
          //             value_id: 644,
          //             original_value_name: "Kiwi",
          //           },
          //         ],
          //         value: ["Dragonfruit", "Kiwi"],
          //       },
          //       "Liable Company Address": {
          //         attribute_id: 100976,
          //         attribute_value_list: [
          //           {
          //             value_id: 0,
          //             original_value_name: "fasfa",
          //           },
          //         ],
          //         value: ["fasfa"],
          //       },
          //       "Liable Company Tel No.": {
          //         attribute_id: 100977,
          //         attribute_value_list: [
          //           {
          //             value_id: 0,
          //             original_value_name: "fasfa",
          //           },
          //         ],
          //         value: ["fasfa"],
          //       },
          //       "Liability Insurance": {
          //         attribute_id: 100978,
          //         attribute_value_list: [
          //           {
          //             value_id: 0,
          //             original_value_name: "fasfa",
          //           },
          //         ],
          //         value: ["fasfa"],
          //       },
          //       "Organic Food Certification No.": {
          //         attribute_id: 101022,
          //         attribute_value_list: [
          //           {
          //             value_id: 0,
          //             original_value_name: "fasf",
          //           },
          //         ],
          //         value: ["fasf"],
          //       },
          //       "Volume": {
          //         attribute_id: 100248,
          //         attribute_value_list: [
          //           {
          //             value_id: 0,
          //             original_value_name: "6",
          //             value_unit: "ml",
          //           },
          //         ],
          //         value: ["6"],
          //       },
          //     },
          //     variations: [
          //       {
          //         inventoryId: 96048,
          //         inventorySKU: "11112",
          //         quantity: 0,
          //         variationId: null,
          //         sellingPrice: 1,
          //         sellingPriceState: true,
          //         costPrice: 0,
          //         option: null,
          //         barcode1: "1",
          //         barcode2: null,
          //         weight: 1,
          //         height: 1,
          //         width: 1,
          //         length: 1,
          //         active: false,
          //         // optionList: [
          //         //   {
          //         //     catalogueAttributeId: 963,
          //         //     oValue: "Cherry",
          //         //     oState: true,
          //         //   },
          //         //   {
          //         //     catalogueAttributeId: 965,
          //         //     oValue: "X",
          //         //     oState: true,
          //         //   },
          //         // ],
          //         option1:"Cherry",
          //         option2:"X",
          //         variant: "Cherry/X",
          //       },
          //       {
          //         inventoryId: 96004,
          //         inventorySKU: "1111",
          //         quantity: 0,
          //         variationId: null,
          //         sellingPrice: 1,
          //         sellingPriceState: true,
          //         costPrice: 0,
          //         option: null,
          //         barcode1: "fawfawflfawfa",
          //         barcode2: null,
          //         weight: 3.2,
          //         height: 4.6,
          //         width: 4.5,
          //         length: 3.2,
          //         active: true,
          //         // optionList: [
          //         //   {
          //         //     catalogueAttributeId: 962,
          //         //     oValue: "Olive",
          //         //     oState: true,
          //         //   },
          //         //   {
          //         //     catalogueAttributeId: 964,
          //         //     oValue: "X",
          //         //     oState: true,
          //         //   },
          //         // ],
          //         option1:"Olive",
          //         option2:"X",
          //         variant: "Olive/X",
          //       },
          //     ],
          //     newVariations: null,
          //     variation: null,
          //     productImages: [],
          //     optionImages: [
          //       {
          //         option: "Olive",
          //         image: [{ name: "20220117012047122_3e205567bdd00beb31d518afa1a78efe.png", url: "/api/catalogue/getImage?fileName=20220117012047122_3e205567bdd00beb31d518afa1a78efe.png" }],
          //       },
          //       {
          //         option: "Cherry",
          //         image: [{ name: "20220117012301630_3e205567bdd00beb31d518afa1a78efe.png", url: "/api/catalogue/getImage?fileName=20220117012301630_3e205567bdd00beb31d518afa1a78efe.png" }],
          //       },
          //     ],
          //     options: [
          //       {
          //         name: "color_family",
          //         value: ["Olive", "Cherry"],
          //       },
          //       {
          //         name: "size",
          //         value: ["X"],
          //       },
          //     ],
          //     logisticInfo: {
          //       weight: "20",
          //       dimension: {
          //         package_length: "10",
          //         package_width: "10",
          //         package_height: "10",
          //       },
          //       logisticInfo: [
          //         {
          //           logistic_id: 20011,
          //           enabled: true,
          //           is_free: false,
          //           shipping_fee: "8",
          //         },
          //         {
          //           logistic_id: 20020,
          //           enabled: false,
          //           is_free: false,
          //           shipping_fee: "0",
          //         },
          //       ],
          //     },
          //   },
          // ];

          let {
            productName,
            description,
            parentSKU:pSKU,
            productImages,
            brand,
            brandId,
            isVariation,
            variations,
            options,
            platformName,
            optionImages,
            categoryId,
            userMarketplaceId,
            attributes,
            logisticInfo: logisticDetail,
          } = productDetail[0];

          //console.log(productDetail[0])
          setProduct(productDetail[0]);

          setBasicInformation({
            productName,
            description,
          });
          if (pSKU) {
            setParentSKU(pSKU);
          }

          setProductImages(productImages);
          setBrand({ id:brandId,value: brand, state: true });
          setPlatformName(platformName);
          variations.forEach(v=>{
            v["inventorySKUState"] = true;
            v["inventorySKUFound"] = false;
            v["barcode1State"] = true;
            v["weightState"] = true;
            v["heightState"] = true;
            v["widthState"] = true;
            v["lengthState"] = true;
          })
          setVariations(variations);
          getAttributes(categoryId, userMarketplaceId, attributes);
          getBrands(userMarketplaceId,categoryId)
          //console.log(isVariation);
          if (isVariation) {
            //   console.log("variation?",isVariation)
            let oImage = {};
            optionImages.forEach((o) => {
              oImage[o.option] = o.image;
            });

            let oList = options.map((o) => {
              return { name: o.name, value: o.value.map((v) => v) };
            });

            //console.log(oList);
            // setProductImages(productImages);
            // if(options.length === 1){
            //   options.push({name:"",value:[]})
            // }
            setOptions(options);
            setOptionList(oList);
            setOptionImages(oImage);
          }
          if(logisticDetail){
            // console.log("value",logisticDetail)
            getLogistics(userMarketplaceId, logisticDetail);
          }else{
            showFail("LogisticInfo:Null")
          }
        }
      } catch (error) {
        showFail("Server Error [GetProduct]");
      }
    };
    fetchProductDetail();
    async function getLogistics(userMarketplaceId, logisticDetail) {
      try {
        let response = await getMarketplaceLogistics(userMarketplaceId);
        // console.log(response)
        if (response.status === 200) {
          if(response.data.response){
            let { logistics_channel_list } = response.data.response;
          let formattedList = formatShopeeLogisticsChannelList(logistics_channel_list);
          // let logisticInfoClone = { weightState: true, lengthState: true, widthState: true, heightState: true };
          let logisticInfoClone = {weightState:true}
          let logisticDetailParsed = JSON.parse(logisticDetail)
          //console.log(logisticDetailParsed)
          logisticInfoClone["logistics_channel_list"] = formattedList;
          let { weight, dimension, logisticInfo } = logisticDetailParsed;
          let { package_length, package_width, package_height } = dimension;
          logisticInfoClone["weight"] = weight;
          logisticInfoClone["length"] = package_length;
          logisticInfoClone["width"] = package_width;
          logisticInfoClone["height"] = package_height;
          logisticInfoClone["logistics_channel_list"].forEach((item) => {
            logisticInfo.forEach((info) => {
              if (item.logistics_channel_id === info.logistic_id) {
                item.enabled = info.enabled;
                item.is_free = info.is_free;
                item.shipping_fee = info.shipping_fee;
              }
            });
          });
          setLogisticInfo(logisticInfoClone);
          }else{
            showFail(`Server Error [${response.data.message}]`)
          }
           
        }
      } catch (error) {
        // console.log(error);
        showFail("Server Error [GetShopeeLogistics]");
      }
    }

    async function getAttributes(categoryId, userMarketplaceId, attributes) {
      try {
        let response = await getMarketplaceAttribute(categoryId, userMarketplaceId);
        if (response.status === 200) {
          if(response.data.response){
            let attribute = response.data.response.attribute_list;
            let formattedAttributes = formatShopeeAttributes(attribute);
            let attributesParsed = JSON.parse(attributes);
            // let attrMandatoriesMapped = assignShopeeAttributeValue(formattedAttributes.attrMandatories, attributes);
            let attrNormalsMerged = [...formattedAttributes.attrMandatories,...formattedAttributes.attrNormals]
            let attrNormalsMapped = assignShopeeAttributeValue(attrNormalsMerged, attributesParsed);
  
            // setAttrMandatories(attrMandatoriesMapped);
            setAttrNormals(attrNormalsMapped);
          }else{
            showFail(`Server Error [${response.data.message}]`)
          }
          
        }
      } catch (error) {
        showFail("Server Error [GetShopeeAttribute]");
      }
    }

    async function getBrands(userMarketplaceId,categoryId){
      try {
        let response = await getMarketplaceBrands(userMarketplaceId,categoryId)
        if(response.status===200){
          setBrandList(response.data.flat())
        }
      } catch (error) {
        
      }
    }
    return()=>{console.log("Request Cancelled")
  cancel()}
  }, []);

  useEffect(() => {
    //console.log("product:", product);
    // const getLogistics = async () => {
    //   try {
    //     let response = await getMarketplaceLogistics(product.userMarketplaceId);
    //     if (response.status === 200) {
    //       let { logistics_channel_list } = response.data.response;
    //       let formattedList = formatShopeeLogisticsChannelList(logistics_channel_list);
    //       let logisticInfoClone = { ...logisticInfo };
    //       logisticInfoClone["logistics_channel_list"] = formattedList;
    //       //Assign value
    //       // let {logistic_id,enabled,is_free,shipping_fee} = product.logisticInfo
    //       console.log("clone:")
    //       let { weight, dimension, logisticInfo } = product.logisticInfo;
    //       let { package_length, package_width, package_height } = dimension;
    //       logisticInfoClone.weight = weight;
    //       logisticInfoClone.length = package_length;
    //       logisticInfoClone.width = package_width;
    //       logisticInfoClone.height = package_height;
    //       logisticInfoClone["logistics_channel_list"].forEach((item) => {
    //         logisticInfo.forEach((info) => {
    //           if (item.logistics_channel_id === info.logisticInfo.logistic_id) {
    //             item.enabled = info.logisticInfo.enabled;
    //             item.is_free = info.logisticInfo.is_free;
    //             item.shipping_fee = info.logisticInfo.shipping_fee;
    //           }
    //         });
    //       });
    //       setLogisticInfo(logisticInfoClone);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    // if (product.hasOwnProperty("catalogueBasicInfoId")) {
    //   console.log("...logistic...");
    //   getLogistics();
    // }
  }, [product]);

  const [product, setProduct] = useState({});
  const [options, setOptions] = useState([]);
  const [optionImages, setOptionImages] = useState({});
  const [optionList, setOptionList] = useState([]);
  const [platformName, setPlatformName] = useState("");
  const [basicInformation, setBasicInformation] = useState({});
  const [parentSKU, setParentSKU] = useState("");
  const [productImages, setProductImages] = useState([]);
  const [variations, setVariations] = useState([]);
  const [brand, setBrand] = useState({ id:"",value: "", state: true });
  // const [attrMandatories, setAttrMandatories] = useState([]);
  const [attrNormals, setAttrNormals] = useState([]);
  const [logisticInfo, setLogisticInfo] = useState({
    weight: "0.1",
    weightState: true,
    height: 1,
    // heightState: true,
    length: 1,
    // lengthState: true,
    width: 1,
    // widthState: true,
    logistics_channel_list: [],
  });
  const basicInfoProps = {
    product,
    basicInformation,
    setBasicInformation,
    parentSKU,
    setParentSKU,
    productImages,
    setProductImages,
    platformName,
  };

  const attributeProps = {
    // attrMandatories,
    // setAttrMandatories,
    attrNormals,
    setAttrNormals,
    brand,
    setBrand,
    brandList,
  };

  const optionsProps = {
    platformName,
    options,
    setOptions,
    optionList,
    optionImages,
    setOptionImages,
    setOptionList,
    variations,
    setVariations,
    showFail,
  };

  const optionImagesProps = {
    optionList,
    optionImages,
    setOptionImages,
    count:1,
    multiple:false,
  };

  const skusProps = {
    variations,
    optionList,
    setVariations,
    showFail,
    platformName,
  };

  const shippingProps = {
    logisticInfo,
    setLogisticInfo,
  };

  const editFormSubmitProps = {
    setAlert,
    product,
    basicInformation,
    parentSKU,
    productImages,
    brand,
    setBrand,
    // attrMandatories,
    // setAttrMandatories,
    attrNormals,
    setAttrNormals,
    optionImages,
    optionList,
    variations,
    setVariations,
    logisticInfo,
    setLogisticInfo,
    showFail,
  };

  return (
    <div className="content">
      <Form>
        <Card>
          <CardHeader>
            <CardTitle tag="h4">
              Edit Product (ShopeeV2)
              <Link to={{ state: props.location.state, pathname: "/admin/product/list" }}>
                <i style={{ float: "right", right: "1%" }} className="nc-icon nc-simple-remove" />
              </Link>
            </CardTitle>
            {alert}
          </CardHeader>

          <BasicInfo {...basicInfoProps} />
          <Attribute {...attributeProps} />
          {product.isVariation && (
            <>
              <Options {...optionsProps} />
              <OptionImages {...optionImagesProps} />
            </>
          )}
          <Skus {...skusProps} />
          <Shipping {...shippingProps} />
          {!props.location.state&&<EditFormSubmit {...editFormSubmitProps} />}
           
          {/* <Button onClick={()=>console.log(attrMandatories)}>attrMandatories</Button>
          <Button onClick={()=>console.log(attrNormals)}>attrNormals</Button> */}
          {/* <Button onClick={()=>console.log(optionList)}></Button> */}
        </Card>
      </Form>
    </div>
  );
}
