import React, { memo } from "react";
import { CardTitle, Col, FormGroup, Input, Row } from "reactstrap";
import { SelectAntd } from "views/product/ProductAdd/Components/AntdComponent";

export default memo(function OptionName(props) {
  let { options, setOptions, platformName, optionList, setOptionList, optionImages, setOptionImages, variations, setVariations, objOptions, showFail } = props;

  const modifySelectValue = (newValue, index, i) => {
    // alert(value)
    let optionsClone = [...options];
    let optionListClone = [...optionList];
    let optionImagesClone = { ...optionImages };

    let previousValue = optionListClone[index]["value"][i];
    // console.log(previousValue);
    // optionImagesClone[previousValue]
    if (optionListClone[index]["value"].includes(newValue)) {
      showFail("You cant have same option value");
      optionListClone[index]["value"][i] = previousValue;
      setOptionList(optionListClone);
    } else {
      if(platformName!=="Zalora"){
        optionImagesClone[newValue] = optionImagesClone[previousValue];
        delete optionImagesClone[previousValue];
        setOptionImages(optionImagesClone);
      }
       
      optionListClone[index]["value"][i] = newValue;
      setOptionList(optionListClone);
      optionsClone[index]["value"] = optionListClone[index]["value"];
      setOptions(optionsClone);

      let variationsClone = [...variations];

      variationsClone.forEach((item) => {
        if (item["optionList"][index]["oValue"] === previousValue) {
          item["optionList"][index]["oValue"] = newValue;
        }
        item.variant = item.optionList.map((list) => list.oValue).join("/");
        // item.variant.toUpperCase();
        console.log(item.variant);
      });
      // variationsClone.forEach((item) => {
      //   if (item[name] === previousValue) {
      //     item[name] = newValue;
      //   }
      //   item.variant = item["option2"] ? `${item["option1"] / item["option2"]}` : `${item["option1"]}`;
      // });
      // variationsClone
      setVariations(variationsClone);
    }
  };

  const modifyValue = (e, index, i) => {
    let { value } = e.target;
    let optionsClone = [...options];
    let optionListClone = [...optionList];
    let optionImagesClone = { ...optionImages };

    let previousValue = optionListClone[index]["value"][i];
    if (optionListClone[index]["value"].includes(value)) {
      showFail("You cant have same option value");
      optionListClone[index]["value"][i] = previousValue;
      setOptionList(optionListClone);
    } 
    // else if (value.trim() === "") {
    //   optionListClone[index]["value"][i] = previousValue;
    //   setOptionList(optionListClone);
    // } 
    else {
      if(platformName!=="Zalora"){
        optionImagesClone[value] = optionImagesClone[previousValue];
        delete optionImagesClone[previousValue];
        setOptionImages(optionImagesClone);
      }
       
      optionListClone[index]["value"][i] = value;
      setOptionList(optionListClone);
      optionsClone[index]["value"] = optionListClone[index]["value"];
      setOptions(optionsClone);

      let variationsClone = [...variations];

      variationsClone.forEach((item) => {
        if (item["optionList"][index]["oValue"] === previousValue) {
          item["optionList"][index]["oValue"] = value;
        }
        item.variant = item.optionList.map((list) => list.oValue).join("/");
        console.log(item.variant);
      });
      // variationsClone.forEach((item) => {
      //   if (item[name] === previousValue) {
      //     item[name] = value;
      //   }
      //   item.variant = item["option2"] ? `${item["option1"]}/${item["option2"]}` : `${item["option1"]}`;
      // });
      setVariations(variationsClone);
    }
  };
  return (
    <div>
      <CardTitle tag="h6" style={{ marginTop: "15px", textDecoration: "underline" }}>
        Options (Modify Name)
      </CardTitle>
      <Row>
        {optionList.map((list, index) => (
          <Col key={index}>
            <FormGroup>
              {list.value.map((v, i) => (
                <div key={i} style={{ marginTop: "10px" }}>
                  {/* {console.log(list.name)} */}
                  {(platformName === "Lazada" && (
                    <>
                      {objOptions[list.name]?.length > 0 ? (
                        <SelectAntd value={v} allowClear={false} data={objOptions[list.name]} onChange={(value) => modifySelectValue(value, index, i)} />
                      ) : (
                        <Input type="text" value={v} onChange={(e) => modifyValue(e, index, i)} />
                      )}
                    </>
                  )) ||
                    (platformName === "Shopify" && <Input type="text" value={v} onChange={(e) => modifyValue(e, index, i)} />) ||
                    (platformName === "Zalora" && (
                      <SelectAntd
                        value={v}
                        allowClear={false}
                        data={list.name === "Size" ? objOptions["Variation"] : objOptions["ColorFamily"]}
                        onChange={(value) => modifySelectValue(value, index, i)}
                      />
                    )) ||
                    (platformName === "ShopeeV2" && <Input type="text" value={v} onChange={(e) => modifyValue(e, index, i)} />)}
                </div>
              ))}
            </FormGroup>
          </Col>
        ))}
      </Row>
    </div>
  );
});
