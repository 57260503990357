import React from "react";
import { Button, CardFooter } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { editProduct } from "services/dataservice";
import { formatImages, formatShopeeAttributesValue } from "views/product/common";
export default function EditFormSubmit(props) {
  const history = useHistory();
  let {
    setAlert,
    product,
    basicInformation,
    parentSKU,
    productImages,
    brand,
    setBrand,
    // attrMandatories,
    // setAttrMandatories,
    attrNormals,
    setAttrNormals,
    optionImages,
    optionList,
    variations,
    setVariations,
    logisticInfo,
    setLogisticInfo,
    showFail,
  } = props;

  const handleSubmit = async () => {
    let variationsClone = [...variations];
    // let attrMandatoriesClone = [...attrMandatories];
    let attrNormalsClone = [...attrNormals];
    let logisticInfoClone = { ...logisticInfo };

    // let validate
    
    let validate = true;
    let validateShipping = true;

    if (basicInformation.productName.trim() === "" || basicInformation.description.trim() === "") {
      showFail("Product name / description cannot be empty");
      return;
    }

    if (productImages.length === 0) {
      showFail("Please upload at least one image");
      return;
    }

    if (brand.value.trim() === "") {
      console.log("product brand");
      let brandClone = { ...brand };
      brandClone.state = false;
      setBrand(brandClone);
      validate = false;
    }

    optionList.forEach(o=>{
      o.value.forEach(v=>{
        if(v.trim().length===0){
          validate=false
        }
      })
    })
    if(!validate){
      showFail("Option value cannot be null/empty/undefined")
      return
    }
    // //validation
    // attrMandatoriesClone.forEach((attr, i) => {
    //   if (Array.isArray(attr.temp_value)) {
    //     if (attr.temp_value.length === 0) {
    //       attrMandatoriesClone[i]["state"] = false;
    //       validateAttributes = false;
    //       setAttrMandatories(attrMandatoriesClone);
    //     }
    //   } else if (attr.temp_value.trim() === "") {
    //     attrMandatoriesClone[i]["state"] = false;
    //     validateAttributes = false;
    //     setAttrMandatories(attrMandatoriesClone);
    //   }
    // });

    attrNormals.forEach((attr, i) => {
      if (attr.is_mandatory) {
        if (Array.isArray(attr.temp_value)) {
          if (attr.temp_value.length === 0) {
            attrNormalsClone[i]["state"] = false;
            setAttrNormals(attrNormalsClone);
            validate = false;
          }
        } else if (attr.temp_value.trim() === "") {
          attrNormalsClone[i]["state"] = false;
          setAttrNormals(attrNormalsClone);
          validate = false;
        }
      }
    });

    if (!validate) {
      showFail("* field must be fill in");
      return;
    }

    variationsClone.forEach((v) => {
      if (!v.inventoryId) {
        if (v.inventorySKU.trim() === "") {
          v.inventorySKUState = false;
          setVariations(variationsClone);
          validate = false;
        }
        if (v.barcode1.trim() === "") {
          v.barcode1State = false;
          setVariations(variationsClone);
          validate = false;
        }
        if (v.sellingPrice === "" || v.sellingPrice <= 0) {
          v.sellingPriceState = false;
          setVariations(variationsClone);
          validate = false;
        }
        if (v.weight === "" || v.weight <= 0) {
          v.weightState = false;
          setVariations(variationsClone);
          validate = false;
        }
        if (v.height === "" || v.height <= 0) {
          v.heightState = false;
          setVariations(variationsClone);
          validate = false;
        }
        if (v.width === "" || v.width <= 0) {
          v.widthState = false;
          setVariations(variationsClone);
          validate = false;
        }
        if (v.length === "" || v.length <= 0) {
          v.lengthState = false;
          setVariations(variationsClone);
          validate = false;
        }
      } else {
        if (v.sellingPrice === "" || v.sellingPrice <= 0) {
          v.sellingPriceState = false;
          setVariations(variationsClone);
          validate = false;
        }
      }
    });

    if (!validate) {
      showFail("* must be filled");
      return;
    }

    if (logisticInfoClone.weight <= 0) {
      logisticInfoClone["weightState"] = false;
      validateShipping = false;
      setLogisticInfo(logisticInfoClone);
    }
    // if (logisticInfoClone.height <= 0) {
    //   logisticInfoClone["heightState"] = false;
    //   validateShipping = false;
    //   setLogisticInfo(logisticInfoClone);
    // }
    // if (logisticInfoClone.width <= 0) {
    //   logisticInfoClone["widthState"] = false;
    //   validateShipping = false;
    //   setLogisticInfo(logisticInfoClone);
    // }
    // if (logisticInfoClone.length <= 0) {
    //   logisticInfoClone["lengthState"] = false;
    //   validateShipping = false;
    //   setLogisticInfo(logisticInfoClone);
    // }

    let {height,width,length} = logisticInfoClone

    validateShipping =(height && width && length)?true:(!height && height !== 0 && !width && width !==0 && !length && length !==0)?true:false

    if(!validateShipping){
      showFail("Invalid parcel size")
      return
    }
    let enabledStatus = logisticInfoClone.logistics_channel_list.map((channel) => channel.enabled);

    if (!enabledStatus.includes(true)) {
      validateShipping = false;
    }

    if (!validateShipping) {
      showFail("Shipping detail must be filled in and enable at least one shipping option");
      return;
    }

    if (window.confirm("Are you sure you want to update the product?")) {
      let productImagesReduced = formatImages(productImages);
      // let resultAttrMandatories = formatShopeeAttributesValue(attrMandatories);
      let attrs = formatShopeeAttributesValue(attrNormals);
      // let attrs = { ...resultAttrMandatories, ...resultAttrNormals };
      let newLogisticInfo = {
        weight: logisticInfo.weight,
        dimension: {
          package_length: logisticInfo.length,
          package_width: logisticInfo.width,
          package_height: logisticInfo.height,
        },
        logisticInfo: logisticInfo.logistics_channel_list.map((item) => {
          let { logistics_channel_id, logistics_channel_name, logistics_description, fee_type, weight_limit, volume_limit, default_price, ...remainItem } = item;
          return {
            logistic_id: logistics_channel_id,
            ...remainItem,
          };
        }),
      };

      let newOptionImages = [];

      if (product.isVariation) {
        for (let key in optionImages) {
          let oImages = {};
          oImages["option"] = key;
          oImages["image"] = formatImages(optionImages[key]);
          newOptionImages.push(oImages);
        }
      }

      let newVariations = variations.map((variation) => {
        let { variant, inventorySKUState, inventorySKUFound, sellingPriceState, barcode1State, weightState, heightState, widthState, lengthState, ...newVariation } = variation;
        return newVariation;
      });

      let productEdited = {
        productId:product.productId,
        catalogueBasicInfoId: product.catalogueBasicInfoId,
        description: basicInformation.description,
        productName: basicInformation.productName,
        parentSKU,
        categoryId: product.categoryId,
        categoryValue: product.categoryValue,
        tags: product.tags,
        active: product.active,
        brand: brand.value,
        brandId:brand.id,
        // brand:{
        //   brand_id:brand.id,
        //   original_brand_name:brand.value
        // },
        isVariation: product.isVariation,
        platformName: product.platformName,
        userMarketplaceId: product.userMarketplaceId,
        status: product.status,
        variations: newVariations,
        optionImages: newOptionImages,
        options: optionList,
        attributes: attrs,
        logisticInfo: newLogisticInfo,
        productImages: productImagesReduced,
      };
      //console.log(productEdited);

      try {
        let response = await editProduct(productEdited);
        if (response.status === 200) {
          showSuccess("Product Updated", () => history.push("/admin/product/list"));
        }
      } catch (error) {
        showFail("Server Error [EditProduct]");
      }
    }
  };

   
  const showSuccess = (msg, callBack = null) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => (callBack == null ? setAlert(null) : callBack())}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
      ></SweetAlert>
    );
  };

  return (
    <CardFooter>
      <Button onClick={handleSubmit} color="primary">
        Submit
      </Button>{" "}
       
    </CardFooter>
  );
}
