import React, { useEffect, useState } from "react";
import { components } from "react-select";
import { useHistory } from "react-router-dom";
import { Form, FormGroup, Card, CardBody, CardFooter, Button, Input, CustomInput, Row, Col, FormFeedback } from "reactstrap";
import ReactTable from "components/ReactTable/ReactTable.js";
import Select from "react-select";
import { listUser, addUser, editUser } from "services/dataservice";

const columns = [
  {
    Header: "User Name",
    accessor: "userName",
  },
  {
    Header: "Customer Code",
    accessor: "customerCode",
  },
  {
    Header: "Secret Key",
    accessor: "secretKey",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

export default function UserRegister() {
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState({
    userId: "",
    customerCode: "",
    secretKey: "",
    wmsApiUrl: "",
    password: "",
    confirmPassword: "",
    accessControl: null,
    enableiWebMarketPlace: true,
  });
  const [subAcc, setSubAcc] = useState([]);
  const [action, setAction] = useState("Add");

  useEffect(() => {
    loadUser();
  }, []);

  const addSubAcc = () => {
    setSubAcc([...subAcc, { userId: "", userIdState: true, password: "", passwordState: true, confirmPassword: "", confirmPasswordState: true, accessControl: null }]);
  };
  const delSubAcc = (index) => {
    const subAccClone = [...subAcc];
    subAccClone.splice(index, 1);
    setSubAcc(subAccClone);
  };
  const subAccChange = (e, index) => {
    const { value, name } = e.target;
    const subAccClone = [...subAcc];
    subAccClone[index][name] = value.trim();
    subAccClone[index][`${name}State`] = true;
    setSubAcc(subAccClone);
  };
  const loadUserDetail = async (u) => {
    let accControl = null;
    if (u.accessControl) {
      accControl = JSON.parse(u.accessControl).map((a) => {
        return {
          value: a,
          label: a,
        };
      });
    }
    let sUser = [];
    if (u.subUser) {
      sUser = u.subUser.map((s) => {
        let accControl = null;
        if (s.accessControl) {
          accControl = JSON.parse(s.accessControl).map((a) => {
            return {
              value: a,
              label: a,
            };
          });
        }
        return {
          userId: s.userId,
          userIdState: true,
          parentUserId: s.parentUserId,
          password: s.userPassword ?? "",
          passwordState: true,
          confirmPassword: s.userPassword ?? "",
          confirmPasswordState: true,
          accessControl: accControl,
        };
      });
    }

    setFormData({
      ...formData,
      userId: u.userId,
      customerCode: u.customerCode,
      secretKey: u.secretKey,
      wmsApiUrl: u.wmsApiUrl,
      // accessControl=u.accessControl,
      accessControl: accControl,
      enableiWebMarketPlace: u.iWebEnabled,
    });
    setSubAcc(sUser);
    setAction("Edit");
  };

  const loadUser = async () => {
    const resp = await listUser();
    setTableData(
      resp.data.map((val) => ({
        userName: val.userId,
        customerCode: val.customerCode,
        secretKey: val.secretKey,
        action: (
          <div className="actions-right">
            <Button
              id="btnDelete"
              size="sm"
              className="btn-icon btn-link edit"
              color="danger"
              onClick={() => {
                //triggerDelete(res);
                loadUserDetail(val);
              }}>
              <i className="fa fa-edit" />
            </Button>
          </div>
        ),
      }))
    );
  };

  const onChangeEnableiWeb = (e) => {
    setFormData({
      ...formData,
      enableiWebMarketPlace: e.target.checked,
    });
  };

  const submit = async () => {
    if (!formData.userId) {
      return;
    }

    let subUser = null;
    let validate = true;
    if (subAcc.length) {
      subAcc.forEach((sub) => {
        if (!sub.userId.length) {
          sub.userIdState = false;
          validate = false;
          setSubAcc([...subAcc]);
        }
        if (action === "Add") {
          if (!sub.password.length) {
            sub.passwordState = false;
            validate = false;
            setSubAcc([...subAcc]);
          }
          if (sub.password !== sub.confirmPassword) {
            sub.confirmPasswordState = false;
            validate = false;
            setSubAcc([...subAcc]);
          }
        } else {
          if (!sub.parentUserId) {
            if (!sub.password.length) {
              sub.passwordState = false;
              validate = false;
              setSubAcc([...subAcc]);
            }
            if (sub.password !== sub.confirmPassword) {
              sub.confirmPasswordState = false;
              validate = false;
              setSubAcc([...subAcc]);
            }
          } else {
            if (sub.password.length || sub.confirmPassword.length) {
              if (sub.password !== sub.confirmPassword) {
                sub.confirmPasswordState = false;
                validate = false;
                setSubAcc([...subAcc]);
              }
            }
          }
        }
      });
    }

    if (!validate) {
      return;
    }

    if (subAcc.length) {
      subUser = subAcc.map((sub) => {
        return {
          userId: sub.userId,
          userPassword: sub.password,
          parentUserId: formData.userId,
          accessControl: sub.accessControl ? sub.accessControl.map((a) => a.value) : null,
        };
      });
    }
    let { accessControl } = formData;
    let accControl = null;
    if (accessControl) {
      accControl = accessControl.map((a) => a.value);
    }

    if (action === "Add") {
      const resp = await addUser({ ...formData, accessControl: accControl, subUsers: subUser });
      //console.log(resp.data);
      const { code, message } = resp.data;
      if (code) {
        alert(message);
        return;
      }
      alert("User Added");
    } else if (action === "Edit") {
      //console.log({ ...formData, accessControl: accControl, subUsers: subUser })
      const resp = await editUser({ ...formData, accessControl: accControl, subUsers: subUser });
      const { code, message } = resp.data;
      if (code) {
        alert(message);
        return;
      }
      alert("User Edited");
    }
    setFormData({
      userId: "",
      customerCode: "",
      secretKey: "",
      wmsApiUrl: "",
      password: "",
      confirmPassword: "",
      accessControl: null,
      enableiWebMarketPlace: false,
    });
    setSubAcc([]);
    setAction("Add");
    await loadUser();
  };

  return (
    <div className="content">
      <Card>
        <CardBody>
          <Form>
            <FormGroup>
              <label>User Name</label>
              <Input name="UserName" value={formData.userId} onChange={(e) => setFormData({ ...formData, userId: e.target.value })}></Input>
            </FormGroup>
            <FormGroup>
              <label>Password</label>
              <Input type="password" name="Password" value={formData.password} onChange={(e) => setFormData({ ...formData, password: e.target.value })}></Input>
            </FormGroup>
            <FormGroup>
              <label>Confirm Password</label>
              <Input type="password" name="ConfirmPassword" value={formData.confirmPassword} onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}></Input>
            </FormGroup>
            <FormGroup>
              <label>Customer Code</label>
              <Input name="CustomerCode" value={formData.customerCode} onChange={(e) => setFormData({ ...formData, customerCode: e.target.value })}></Input>
            </FormGroup>
            <FormGroup>
              <label>Secret Key</label>
              <Input name="SecretKey" value={formData.secretKey} onChange={(e) => setFormData({ ...formData, secretKey: e.target.value })}></Input>
            </FormGroup>
            <FormGroup>
              <label>WMS ApiUrl</label>
              <Input name="WMSApiUrl" value={formData.wmsApiUrl} onChange={(e) => setFormData({ ...formData, wmsApiUrl: e.target.value })}></Input>
            </FormGroup>
            <FormGroup>
              <label>Access Control To Lock</label>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                closeMenuOnSelect={false}
                name="multipleSelect"
                isMulti
                value={formData.accessControl}
                options={[
                  { value: "Split Inventory List", label: "Split Inventory List" },
                  { value: "Product List", label: "Product List" },
                  { value: "Inventory List", label: "Inventory List" },
                  { value: "Marketplace", label: "Marketplace" },
                  { value: "Integration", label: "Integration" },
                  { value: "Orders", label: "Orders" },
                  { value: "Failed Orders", label: "Failed Orders" },
                  { value: "Dashboard", label: "Dashboard" },
                ]}
                onChange={(e) => setFormData({ ...formData, accessControl: e })}
              />
            </FormGroup>
            <FormGroup>
              <label>iWeb Marketplace</label>
              <CustomInput type="switch" id="idIntegrated" name="enablediWeb" label="Enable iWeb Marketplace" onChange={onChangeEnableiWeb} checked={formData.enableiWebMarketPlace} />
            </FormGroup>
            <FormGroup style={{ border: "1px solid #dbd3d3", borderRadius: "5px" }}>
              <label style={{ marginLeft: "5px" }}>Sub Account</label>
              <Button color="success" className="ml-2" onClick={addSubAcc}>
                <i className="fa fa-plus" />
              </Button>
              <CardBody>
                {subAcc.map((sub, index) => (
                  <Row key={index}>
                    <Col>
                      <FormGroup>
                        <label>User Name</label>
                        <Input name="userId" value={sub.userId} {...(!sub.userIdState && { invalid: true })} onChange={(e) => subAccChange(e, index)} />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>User Password</label>
                        <Input name="password" type="password" value={sub.password} {...(!sub.passwordState && { invalid: true })} onChange={(e) => subAccChange(e, index)} />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Confirm Password</label>
                        <Input name="confirmPassword" type="password" value={sub.confirmPassword} {...(!sub.confirmPasswordState && { invalid: true })} onChange={(e) => subAccChange(e, index)} />
                        <FormFeedback>password and confirm password mismatch</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Access Control To Lock</label>
                        {/* <Input /> */}
                        <Select
                          className="react-select info"
                          classNamePrefix="react-select"
                          closeMenuOnSelect={false}
                          name="multipleSelect"
                          isMulti
                          value={sub.accessControl}
                          options={[
                            { value: "Split Inventory List", label: "Split Inventory List" },
                            { value: "Product List", label: "Product List" },
                            { value: "Inventory List", label: "Inventory List" },
                            { value: "Marketplace", label: "Marketplace" },
                            { value: "Integration", label: "Integration" },
                            { value: "Orders", label: "Orders" },
                            { value: "Failed Orders", label: "Failed Orders" },
                            { value: "Dashboard", label: "Dashboard" },
                          ]}
                          onChange={(e) => {
                            sub.accessControl = e ? (e.length ? e : null) : null;
                            setSubAcc([...subAcc]);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Button color="danger" className="mr-2" onClick={() => delSubAcc(index)}>
                      <i className="fa fa-trash" />
                    </Button>
                  </Row>
                ))}
              </CardBody>
            </FormGroup>
          </Form>
        </CardBody>
        <CardFooter>
          <Button color="primary" onClick={submit}>
            {action}
          </Button>
        </CardFooter>
      </Card>
      <Card>
        <CardBody>
          <ReactTable data={tableData} columns={columns} className="-striped -highlight primary-pagination" />
        </CardBody>
      </Card>
    </div>
  );
}
