const lineOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  tooltips: {
    enabled: true,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: "#9f9f9f",
          beginAtZero: false,
          maxTicksLimit: 5,
          //padding: 20
        },
        gridLines: {
          drawBorder: false,
          zeroLineColor: "transparent",
          color: "rgba(255,255,255,0.05)",
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: "rgba(255,255,255,0.1)",
          zeroLineColor: "transparent",
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
        },
        type: "time",
        time: {
          unit: "day",
          round: "day",
          unitStepSize: 2,
          displayFormats: {
            day: "MMM DD",
          },
        },
      },
    ],
  },
};
const lineWeekOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: lineOptions.scales.yAxes,
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: "rgba(255,255,255,0.1)",
          zeroLineColor: "transparent",
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
        },
        type: "time",
        time: {
          unit: "day",
          round: "day",
          unitStepSize: 7,
          displayFormats: {
            day: "MMM DD",
          },
        },
      },
    ],
  },
};
const lineTimeOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: lineOptions.scales.yAxes,
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: "rgba(255,255,255,0.1)",
          zeroLineColor: "transparent",
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
        },
        type: "time",
        time: {
          parser: "HH:mm",
          unit: "hour",
          unitStepSize: 2,
          displayFormats: {
            hour: "HH:mm",
          },
        },
      },
    ],
  },
};
const line6HourOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: lineOptions.scales.yAxes,
    xAxes: [
      {
        gridLines: {
          drawBorder: false,
          color: "rgba(255,255,255,0.1)",
          zeroLineColor: "transparent",
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
        },
        type: "time",
        time: {
          parser: "HH:mm",
          unit: "hour",
          unitStepSize: 6,
          displayFormats: {
            hour: "HH:mm",
          },
        },
      },
    ],
  },
};
const barOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: "#9f9f9f",
          fontStyle: "bold",
          beginAtZero: true,
          maxTicksLimit: 5,
          padding: 20,
        },
        gridLines: {
          zeroLineColor: "transparent",
          display: true,
          drawBorder: false,
          // color: "#9f9f9f",
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          zeroLineColor: "white",
          display: false,
          drawBorder: false,
          color: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
          fontStyle: "bold",
        },
        offset: true,
        type: "time",
        time: {
          unit: "day",
          round: "day",
          unitStepSize: 2,
          displayFormats: {
            day: "MMM DD",
          },
        },
      },
    ],
  },
};
const barWeekOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: barOptions.scales.yAxes,
    xAxes: [
      {
        gridLines: {
          zeroLineColor: "white",
          display: false,

          drawBorder: false,
          color: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
          fontStyle: "bold",
        },
        offset: true,
        type: "time",
        time: {
          unit: "day",
          round: "day",
          unitStepSize: 7,
          displayFormats: {
            day: "MMM DD",
          },
        },
      },
    ],
  },
};
const barTimeOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: barOptions.scales.yAxes,
    xAxes: [
      {
        gridLines: {
          zeroLineColor: "white",
          display: false,
          drawBorder: false,
          color: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
          fontStyle: "bold",
        },
        offset: true,
        type: "time",
        time: {
          parser: "HH:mm",
          unit: "hour",
          unitStepSize: 2,
          displayFormats: {
            hour: "HH:mm",
          },
        },
      },
    ],
  },
};
const bar6HourOptions = {
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: barOptions.scales.yAxes,
    xAxes: [
      {
        gridLines: {
          zeroLineColor: "white",
          display: false,
          drawBorder: false,
          color: "transparent",
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
          fontStyle: "bold",
        },
        offset: true,
        type: "time",
        time: {
          parser: "HH:mm",
          unit: "hour",
          unitStepSize: 6,
          displayFormats: {
            hour: "HH:mm",
            // min:"00:00",
            // max:"23:59"
          },
        },
      },
    ],
  },
};

export { lineOptions, lineWeekOptions, lineTimeOptions, line6HourOptions, barOptions, barWeekOptions, barTimeOptions, bar6HourOptions };
