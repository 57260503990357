import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { Table } from "antd";
import "./index.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { getUserMarketplaceList, getCatalogueListByUserMarketplaceId, exportSplitInventory, createSplitInventory, uploadSplitInventory } from "services/dataservice";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";

const baseColumn = [
  {
    title: "#",
    key: "index",
    render: (value, record, index) => index + 1,
  },
  {
    title: "Marketplace Name",
    key: "1",
    dataIndex: "marketplaceName",
  },
  {
    title: "Inventory SKU",
    key: "2",
    dataIndex: "inventorySKU",
  },
  {
    title: "Name",
    key: "3",
    dataIndex: "name",
  },
  {
    title: "OnHand Quantity",
    key: "4",
    dataIndex: "quantity",
  },
];

export default function SplitInventoryAdd() {
  const history = useHistory();
  const location = useLocation();
  const { type } = location?.state ?? {};
  const type2 = type ?? "ADD";
  const [loadSKU, setLoadSKU] = useState(false);
  const [marketplaces, setMarketplaces] = useState([]);
  const [alert, setAlert] = useState(null);
  const [catalogues, setCatalogues] = useState([
    // { key: 1, inventorySKU: "EA01-SB", name: "ea 01", quantity: 1 },
    // { key: 2, inventorySKU: "2EA01-SB", name: "2ea 01", quantity: 11 },
  ]);
  const [splitInv, setSplitInv] = useState({
    userMarketplaces: [],
    criteria: "LOCK",
    lockQty: 0,
    reserveQty: 0,
    bufferQty:0
  });
  function getCriteria() {
    const { lockQty, reserveQty,bufferQty } = splitInv;
    if (lockQty === 0 && reserveQty === 0 && bufferQty===0) return "";
    if (lockQty !== 0 && reserveQty === 0 && bufferQty === 0) return "LOCK";
    if (lockQty === 0 && reserveQty !== 0 && bufferQty === 0) return "RESERVE";
    if (lockQty === 0 && reserveQty === 0 && bufferQty !== 0) return "BUFFER";
  }
  const criteria = getCriteria();
  console.log(criteria)
  const showSuccess = (msg, callBack = null) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => (callBack == null ? setAlert(null) : callBack())}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"></ReactBSAlert>
    );
  };

  const showFail = (msg) => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""></ReactBSAlert>
    );
  };
  const showInfo = (msg, onConfirm = null) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={msg}
        onConfirm={() => onConfirm()}
        showCancel
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        btnSize=""></ReactBSAlert>
    );
  };

  const Loading = ({msg}) =>
    <ReactBSAlert
      info
      style={{ display: "block", marginTop: "-100px" }}
      title={msg} 
      onConfirm={()=>{}}
      showConfirm={false}
      btnSize=""></ReactBSAlert>

  const userMarketplaceIds = splitInv.userMarketplaces.map((x) => x.value);
  const onError = (data) => {
    try {
      const { message } = data.response.data;
      showFail(message);
    } catch (error) {
      showFail(error.response);
    }
  };
  useQuery({
    queryKey: ["user-marketplace-list"],
    queryFn: () => getUserMarketplaceList(),
    onSuccess: (data) => setMarketplaces(data.data.data),
    onError,
  });

  const{isFetching}=useQuery({
    queryKey: ["catalogues"],
    queryFn: () => getCatalogueListByUserMarketplaceId({ userMarketplaceIds, type: "Add" }),

    //enabled: userMarketplaceId !== 0 && type2 === "ADD",
    enabled: loadSKU && type2 === "ADD",
    onSuccess: (data) => {
      setCatalogues(data.data.data);
      setLoadSKU(false);
    },
    onError,
  });

  const [dataSource, setDataSource] = useState([]);
  const [errData, setErrData] = useState([]);
  const [duplicateData, setDuplicateData] = useState([]);
  const onStoreChange = (value) => {
    if (value === null) {
      setSplitInv({ ...splitInv, userMarketplaces: [] });
    } else {
      setSplitInv({ ...splitInv, userMarketplaces: value });
    }
  };

  const onQuantityChange = (e, prop) => {
    const { value } = e.target;
    const updatedValue = Number(value);
    if (updatedValue < 0) return;
    if (prop === "lockQty") {
      if (splitInv.reserveQty > 0 || splitInv.bufferQty >0) return;
      if (updatedValue > 100) return;
      setSplitInv({ ...splitInv, lockQty: updatedValue });
      const newDataSource = dataSource.map((x) => {
        if (x.reserveQty > 0 || x.bufferQty > 0) return { ...x };
        return { ...x, lockQty: updatedValue };
      });
      if (newDataSource.length > 0) setDataSource(newDataSource);
    } else if(prop === "reserveQty") {
      if (splitInv.lockQty > 0 || splitInv.bufferQty >0) return;
      setSplitInv({ ...splitInv, reserveQty: updatedValue });
      const newDataSource = dataSource.map((x) => {
        if (x.lockQty > 0 || x.bufferQty > 0) return { ...x };
        return { ...x, reserveQty: x.allowCreateReserve ? updatedValue : 0 };
      });
      if (newDataSource.length > 0) setDataSource(newDataSource);
    }else{
      if (splitInv.lockQty > 0||splitInv.reserveQty > 0) return;
      setSplitInv({ ...splitInv, bufferQty: updatedValue });
      const newDataSource = dataSource.map((x) => {
        if (x.lockQty > 0 || x.reserveQty > 0) return { ...x };
        return { ...x, bufferQty: x.allowCreateReserve ? updatedValue : 0 };
      });
      if (newDataSource.length > 0) setDataSource(newDataSource);
    }
  };

  const onColumnQuantityChange = ({ target }, record) => {
    const { value } = target;
    const updatedValue = Number(value);
    if (updatedValue < 0) return;

    const newDataSource = [...dataSource];
    const index = dataSource.findIndex((x) => x.key === record.key);
    const rows = newDataSource[index];
    if (target.name === "lockQty") {
      //if (splitInv.reserveQty > 0) return;
      if (updatedValue > 100) return;
      newDataSource.splice(index, 1, { ...rows, lockQty: updatedValue });
    } else if(target.name === "reserveQty") {
      //if (splitInv.lockQty > 0) return;
      newDataSource.splice(index, 1, { ...rows, reserveQty: updatedValue });
    }else{
      newDataSource.splice(index, 1, { ...rows, bufferQty: updatedValue });
    } 
    setDataSource(newDataSource);
  };

  const columns = [
    ...baseColumn,
    {
      title: "Lock (%)",
      key: "5",
      dataIndex: "lockQty",
      render: (value, record) => <Input type="number" name="lockQty" value={value} onChange={(e) => onColumnQuantityChange(e, record)} disabled={(record.reserveQty > 0 || record.bufferQty >0 ) ? true : false} />,
    },
    {
      title: "Lock Quantity",
      key: "6",
      dataIndex: "reserveQty",
      render: (value, record) => (
        <Input type="number" name="reserveQty" value={value} onChange={(e) => onColumnQuantityChange(e, record)} disabled={(!record.allowCreateReserve || record.lockQty > 0 || record.bufferQty > 0) ? true : false} />
      ),
    },
    {
      title: "Reserve Quantity",
      key: "7",
      dataIndex: "bufferQty",
      render: (value, record) => (
        <Input type="number" name="bufferQty" value={value} onChange={(e) => onColumnQuantityChange(e, record)} disabled={(record.reserveQty > 0 || record.lockQty > 0 ) ? true : false} />
      ),
    },
    {
      title: "Action",
      key: "8",
      render: (value, record) => (
        <Button
          size="sm"
          className="btn-icon btn-link remove"
          color="danger"
          onClick={() => {
            const newDataSource = dataSource.filter((x) => x.key !== record.key);
            setDataSource(newDataSource);
          }}>
          <i className="fa fa-times" />
        </Button>
      ),
    },
  ];

  const createMutation = useMutation(createSplitInventory, { onError });

  const uploadMutation = useMutation(uploadSplitInventory, {});

  const [files, setFiles] = useState([]);

  const onFileChange = ({ target }) => {
    let array = [];
    for (var file of target.files) {
      array.push(file);
    }
    setFiles(array);
  };

  const onUpload = () => {
    if (files.length === 0) {
      showFail("Failed To Upload No File Chosen");
      return;
    }
    // if (userMarketplaceIds.length === 0) {
    //   showFail("Failed To Upload No Store Selected");
    //   return;
    // }
    let formData = new FormData();
    //formData.append("userMarketplaceId", userMarketplaceIds);
    for (var file of files) {
      formData.append("files", file, file.name);
    }

    uploadMutation.mutate(formData, {
      onSuccess: (data) => {
        showSuccess("Uploaded");
        setDataSource(data.data.data);
        //setDuplicateData(data.data.duplicateData);
        setErrData(data.data.errData);
        document.getElementById("fileSelector").value = "";
        setFiles([]);
      },
      onError: (data) => {
        const { description } = data.response.data;
        if (description) {
          showFail(description);
        } else {
          showFail(data.toString());
        }
      },
    });
  };

  const isSubmit = createMutation.status === "loading";

  const onSubmit = () => {
    let errMsg = "";
    const isValid = dataSource.some((x) => {
      // if (x.lockQty === 0 && x.reserveQty === 0 && x.bufferQty ===0) {
      //   errMsg = `${x.inventorySKU} 0 quantity`;
      //   return true;
      // }
      if (x.lockQty > 0 && x.reserveQty > 0) {
        errMsg = `${x.inventorySKU} cannot set lock and reserve`;
        return true;
      }

      if (x.reserveQty > x.quantity) {
        errMsg = `${x.inventorySKU} reserve quantity cannot greater than onhand quantity`;
        return true;
      }
      // if (criteria === "LOCK") {
      //   return x.lockQty === 0;
      // } else if (criteria === "RESERVE") {
      //   return x.reserveQty === 0;
      // }
    });
    if (isValid) {
      showFail(errMsg);
      return;
    }
    const list = dataSource.map((x) => {
      return {
        userMarketplaceId: x.userMarketplaceId,
        inventorySKU: x.inventorySKU,
        reserveQty: x.reserveQty,
        lockQty: x.lockQty,
        bufferQty:x.bufferQty,
        quantity:x.quantity,
        allowCreateReserve: x.allowCreateReserve,
      };
    });
    const splitInventoryList = JSON.stringify(list);
    createMutation.mutate(
      { splitInventoryList },
      {
        onSuccess: (data) => {
          showSuccess(data.data.message, () => history.push("/admin/split-inventory/list"));
        },
      }
    );
  };
  const onSubmitAll = () => {
    const name = splitInv.userMarketplaces.map((x) => x.label).join(",");
    const msg = (
      <div>
        <p>Create split inventory for all SKU? </p>
        <p>Store: {name}</p>
        <p>Lock(%): {splitInv.lockQty}</p>
        <p>Reserve: {splitInv.reserveQty}</p>
      </div>
    );
    showInfo(msg, () => {
      if (splitInv.userMarketplaces.length === 0) {
        showFail("Invalid Store");
        return;
      }
      if (splitInv.lockQty === 0 && splitInv.reserveQty === 0) {
        showFail("Invalid Lock/Reserve Value");
        return;
      }
      setAlert(null);
      createMutation.mutate(
        { splitInventoryList: "[]", userMarketplaceIds: userMarketplaceIds.join(","), lockQty: splitInv.lockQty, reserveQty: splitInv.reserveQty },
        {
          onSuccess: (data) => {
            showSuccess(data.data.message, () => history.push("/admin/split-inventory/list"));
          },
        }
      );
    });
  };

  return (
    <div className="content">
      <Row>
        {alert}
        {isFetching && <Loading msg="Loading ..."/>}
        <Col md="12">
          <Form>
            <CardLayout title={"Add Split Inventory"}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Sales Channel Store</Label>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select form-control"
                      value={splitInv.userMarketplaces}
                      options={marketplaces}
                      onChange={(value) => onStoreChange(value)}
                      isMulti
                      isDisabled={type2 === "UPLOAD" ? true : false}
                    />
                  </FormGroup>
                  <Button onClick={() => setLoadSKU(!loadSKU)} disabled={splitInv.userMarketplaces.length === 0 ? true : false}>
                    Load SKU
                  </Button>
                </Col>
                {/* <FormGroup>
                  <Label>Criteria</Label>
                  <Select
                    className="react-select info"
                    classNamePrefix="react-select form-control"
                    options={[
                      { value: "LOCK", label: "LOCK" },
                      { value: "RESERVED", label: "RESERVED" },
                    ]}
                    onChange={(value) => onCriteriaChange(value, "criteria")}
                  />
                </FormGroup> */}
                <Col>
                  <FormGroup>
                    <Label>Lock (%) - Apply to all</Label>
                    <Input type="number" name="lockQty" min={0} max={100} value={splitInv.lockQty} onChange={(e) => onQuantityChange(e, "lockQty")} disabled={criteria === "RESERVE" || criteria === "BUFFER" ? true : false} />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Lock Quantity - Apply to all</Label>
                    <Input type="number" name="reserveQty" min={0} value={splitInv.reserveQty} onChange={(e) => onQuantityChange(e, "reserveQty")} disabled={criteria === "LOCK" || criteria === "BUFFER" ? true : false} />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Reserve Quantity - Apply to all</Label>
                    <Input type="number" name="bufferQty" min={0} value={splitInv.bufferQty} onChange={(e) => onQuantityChange(e, "bufferQty")} disabled={criteria === "RESERVE" || criteria === "LOCK" ? true : false} />
                  </FormGroup>
                </Col>
              </Row>
            </CardLayout>
            {type2 === "UPLOAD" && (
              <CardLayout title={"Upload Split Inventory"}>
                <FormGroup>
                  <Input type="file" id="fileSelector" onChange={onFileChange} />
                </FormGroup>
                <Button color="info" onClick={onUpload} disabled={uploadMutation.status === "loading"}>
                  {uploadMutation.status === "loading" ? "Uploading" : "Upload"}
                </Button>
                {[...duplicateData, ...errData].length > 0 && (
                  <>
                    <CardHeader>
                      <CardTitle tag="h4">Upload Response</CardTitle>
                    </CardHeader>
                    <Table
                      className="upload"
                      columns={[
                        { title: "Inventory SKU", dataIndex: "inventorySKU" },
                        { title: "Message", dataIndex: "message" },
                      ]}
                      dataSource={[...duplicateData, ...errData]}
                    />
                  </>
                )}
              </CardLayout>
            )}
            {type2 === "ADD" && (
              <CardLayout title={"Inventory SKU List"}>
                <SKUList catalogues={catalogues} splitInv={splitInv} dataSource={dataSource} setDataSource={setDataSource} />
              </CardLayout>
            )}

            <CardLayout title={"Selected Inventory SKU List"}>
              {console.log(dataSource)}
              <Table className="lock" columns={columns} dataSource={dataSource} />
              <Button color="info" onClick={onSubmit} disabled={dataSource.length === 0 || isSubmit}>
                {isSubmit ? "Submitting" : "Submit Selected Inventory"}
              </Button>
              {/* <Button color="info" onClick={onSubmitAll} disabled={dataSource.length > 0 || isSubmit}>
                {isSubmit ? "Submitting" : "Submit All Inventory"}
              </Button> */}
            </CardLayout>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

const CardLayout = ({ children, title }) => (
  <Card>
    <CardHeader>
      <CardTitle tag="h4">{title}</CardTitle>
    </CardHeader>
    <CardBody>{children}</CardBody>
  </Card>
);

const SKUList = ({ catalogues, splitInv: { userMarketplaces, lockQty, reserveQty,bufferQty }, dataSource, setDataSource }) => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState("");
  const name = userMarketplaces.map((x) => x.label).join("_");
  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedKeys(selectedRowKeys);
      setSelected(selectedRows);
    },
    //getCheckboxProps:(record)=>({inventorySKU:record.inventorySKU})
  };
  const onAdd = () => {
    if (selected.length === 0) return;
    const isDuplicate = dataSource.some((x) => selectedKeys.includes(x.key));
    if (isDuplicate) return;
    const newDataSource = selected.map((x) => {
      return { ...x, lockQty, reserveQty,bufferQty };
    });
    setDataSource([...dataSource, ...newDataSource]);
    setSelected([]);
    setSelectedKeys([]);
  };
  const exportMutation = useMutation(exportSplitInventory, {});
  const isExport = exportMutation.status === "loading";
  const onExport = async () => {
    const datetime = moment(new Date()).format("YYMMDDhhmm");
    // if (selected.length === 0) return;
    const exportSelected = selected.map((x) => {
      const { key, ...selectedValue } = x;
      return { ...selectedValue };
    });
    exportMutation.mutate(
      { exportInventory: JSON.stringify(exportSelected) },
      {
        onSuccess: (data) => {
          const blob = new Blob([data.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${name}_${datetime}.xlsx`);
          document.body.appendChild(link);
          link.click();
        },
        onError: (data) => window.alert(JSON.stringify(data)),
      }
    );
  };
  const columns = [
    {
      title: "Marketplace Name",
      dataIndex: "marketplaceName",
    },
    {
      title: "Inventory SKU",
      dataIndex: "inventorySKU",
      filteredValue: [searchText],
      onFilter: (value, record) => String(record.inventorySKU).toLowerCase().includes(value.toLowerCase()) || String(record.name).toLowerCase().includes(value.toLowerCase()),
    },
    { title: "Name", dataIndex: "name" },
    { title: "OnHand Quantity", dataIndex: "quantity" },
  ];
  return (
    <>
      <FormGroup>
        <Input type="text" placeholder="search sku or name" onChange={({ target }) => setSearchText(target.value)} />
      </FormGroup>
      <Table
        className="list"
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        columns={columns}
        dataSource={catalogues}
      />
      <Button color="info" onClick={onAdd} disabled={selected.length === 0}>
        Add To Selected SKU List
      </Button>
      <Button color="info" onClick={onExport} disabled={selected.length === 0 || isExport}>
        {isExport ? "Exporting" : "Export"}
      </Button>
    </>
  );
};
