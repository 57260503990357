import React, { useState, useEffect } from 'react'
import { uploadOrderFile, getMarketplace_AllowCreateOrder, getUserMarketplace } from 'services/dataservice';
import { Form, FormGroup, Label, Input, Card, CardHeader, CardBody, CardFooter, Button, CardTitle, List, Table } from 'reactstrap';
import { Link } from "react-router-dom"

import ReactBSAlert from 'react-bootstrap-sweetalert';

export default function OrderUpload(props) {
   const [alert, setAlert] = useState(null);
   const [selectedFile, setFile] = useState([]);
   const [availablePlatform, setAvailablePlatform] = useState([]);
   const [availableShopName, setAvailableShopName] = useState([]);


   const [selectedPlatform, setPlatform] = useState(0);
   const [selectedShop, setShop] = useState(0);

   const [isUpload, setIsUpload] = useState(false)
   const [errData,setErrData] = useState(null)

   useEffect(() => {
      //Load Available Marketplace
      getMarketplace_AllowCreateOrder().then(x => {
         let list = x.data;
         setAvailablePlatform(list);
         if (list.length > 0) {
            loadUserMarketplace(list[0].id);
         }
      })
   }, [])

   const loadUserMarketplace = async (selectedPlatform) => {
      //Load User MarketName
      const resp = await getUserMarketplace(selectedPlatform);
      setAvailableShopName(resp.data);
      if (resp.data.length > 0) {
         setShop(resp.data[0].id);
         //setFormData({...formData, userMarketplaceName: resp.data[0].name, platform: selectedPlatform})
      }
   }

   const showSuccess = (msg, callBack = null) => {
      setAlert(
         <ReactBSAlert
            success
            style={{ display: "block" }}
            title={msg}
            onConfirm={() => callBack == null ? setAlert(null) : callBack()}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info">
         </ReactBSAlert>
      )
   };


   const showFail = (msg) => {
      setAlert(
         <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title={msg}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info"
            btnSize=""
         >
         </ReactBSAlert>
      )
   }


   const fileUpload = async () => {
      let formData = new FormData();
      formData.append("userMarketplaceId", selectedShop);

      for (var file of selectedFile) {
         formData.append(
            "files",
            file,
            file.name
         );

      }
      console.log(formData);

      if (selectedShop == 0 || selectedFile.length == 0) {
         showFail("Order Failed To Upload");
         return;
      }
      setIsUpload(true)
      setErrData(null)
      try {
         const resp = await uploadOrderFile(formData);
         let status = resp.data.code;
         if (status === 200) {
            document.getElementById("fileSelector").value = "";
            setFile([])
            showSuccess("Order Uploaded");
            setIsUpload(false)
         }
         else if (status === 500) {
            showFail(resp.data.description);
            setIsUpload(false)
            if(resp.data.error==="order_fail"){
               setErrData(resp.data.data)
            }
         }
      }
      catch {
         showFail("Order Failed To Upload");
         setIsUpload(false)
      }
   }

   const onFileChange = event => {
      let array = [];
      for (var file of event.target.files) {
         array.push(file);
      }
      console.log(array);
      setFile(array);
   }

   const templateDownload = () => {
      const link = '/api/template/getTemplate?type=order';
      window.open(link);

   }

   return (
      <div className="content">
         {alert}
         <Card>
            <CardHeader>
               <CardTitle tag="h4">
                  Upload Order
                  <Link to="/admin/order/list">
                     <i style={{ float: 'right', right: '1%' }} className="nc-icon nc-simple-remove" />
                  </Link>
               </CardTitle>
            </CardHeader>
            <CardBody>
               <Form>
                  <Button color="info" onClick={templateDownload}>Download Template</Button>
                  <FormGroup>
                     <Label for="platform">Platform</Label>
                     <Input type="select" id="platform" onChange={e => setPlatform(e.target.value)}>
                        {
                           availablePlatform.map(x => (<option key={x.platformName} value={x.id}>{x.platformName}</option>))
                        }
                     </Input>
                  </FormGroup>
                  <FormGroup>
                     <Label for="userMarkerplace">Shop Name</Label>
                     <Input type="select" id="platform" onChange={e => setShop(e.target.value)}>
                        {
                           availableShopName.map(x => (<option key={x.name} value={x.id}>{x.name}</option>))
                        }
                     </Input>
                  </FormGroup>
                  <FormGroup>
                     <Label for="fileSelector">Order File</Label>
                     <Input type="file" name="file" onChange={onFileChange} id="fileSelector"></Input>
                  </FormGroup>
               </Form>
            </CardBody>
            <CardFooter>
               <Button onClick={fileUpload} disabled={isUpload}>{isUpload?"Uploading":"Upload"}</Button>
            </CardFooter>
         </Card>

         {errData &&
            <Card>
               <CardHeader>
                  <CardTitle tag="h4">Upload Response</CardTitle>
               </CardHeader>
               <CardBody>
                  {/* <div>{errData}</div> */}
                  <ErrorListView errData={errData}/>
               </CardBody>
            </Card>
         }

      </div>
   )
}

const ErrorListView = ({errData}) => {
   let errors = JSON.parse(errData);
   return (
     <Table>
       <thead className="text-primary">
         <th className="='text-center">#</th>
         <th>Order Number</th>
         <th>Error</th>
       </thead>
       <tbody>
         {Object.entries(errors).map(([key, values], index) => {
           return (
             <tr key={key}>
               <td className="text-center">{index + 1}</td>
               <td>{key}</td>
               <td>
                 <ul>
                   {values.map((_error) => {
                     return <li>{_error}</li>;
                   })}
                 </ul>
               </td>
             </tr>
           );
         })}
       </tbody>
     </Table>
   );
 }