import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardBody, CardFooter, Input, Button, Form, FormGroup, CustomInput } from "reactstrap";
import { addStore, editStore } from "services/dataservice";

export function AddStoreTiktok(props) {
  const [userMarketplaceName, setUserMarketplaceName] = useState("");
  const [userMarketplaceId, setUserMarketplaceId] = useState(0);
  const [syncInventory, setSyncInventory] = useState(true);
  const[integratedShipping,setIntegratedShipping]=useState(true)
  const history = useHistory();
  useEffect(() => {
    if (props.edit) {
      const u = props.selectedMarketplace;
      setUserMarketplaceId(u.userMarketplaceId);
      setUserMarketplaceName(u.storeName);
      setSyncInventory(u.syncInventory);
      setIntegratedShipping(u.integrated)
    }
  }, []);
  async function submit() {
    try {
      let resp = "";
      if (props.edit) {
        resp = await editStore({
          userMarketplaceId,
          storeName: userMarketplaceName,
          marketplaceId: props.selectedMarketplace.marketplaceId,
          integrated:integratedShipping,
          syncInventory,
        });
      } else {
        resp = await addStore({
          storeName: userMarketplaceName,
          marketplaceId: props.selectedMarketplace.marketplaceId,
          integrated:integratedShipping,
          syncInventory,
        });
      }

      const data = resp.data;
      //window.open(data.authorizeUrl);
      return data.authorizeUrl;
    } catch (err) {
      const errResp = err.response;
      if (errResp.status === 400) {
        if (errResp.data.error === "duplicated_store_name") {
          props.showFail("Duplicated Store Name");
        }
      }
    }
  }

  async function onClickSubmit() {
    await submit();
    history.push("/admin/integration/list");
  }

  async function onClickAuthenticate() {
    let url = await submit();
    window.open(url);
    //alert("ok")
  }
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Selected store: {props.selectedMarketplace.name}</CardTitle>
      </CardHeader>
      <CardBody>
        <Form>
          <FormGroup>
            <label>Store Name</label>
            <Input type="text" value={userMarketplaceName} onChange={(e) => setUserMarketplaceName(e.target.value)}></Input>
          </FormGroup>
          <FormGroup>
            <label>Sync Inventory</label>
            <CustomInput type="switch" id="idSyncInventory" name="syncInventory" label="Sync Inventory" onChange={(e) => setSyncInventory(e.target.checked)} checked={syncInventory} />
          </FormGroup>
          <FormGroup>
                  <label>Shipping</label>
                  <CustomInput type="switch" id="idIntegrated" name="integrated" label="Integrated Shipping" onChange={(e) => setIntegratedShipping(e.target.checked)} checked={integratedShipping} />
               </FormGroup>
        </Form>
      </CardBody>
      <CardFooter>
        <div>
          {props.edit ? (
            <Button color="primary" onClick={onClickSubmit}>
              Save
            </Button>
          ) : null}
          <Button color="primary" onClick={onClickAuthenticate}>
            Authenticate
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
