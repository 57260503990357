import React, { useState, useEffect } from 'react'
import { exportOrder } from 'services/dataservice';
import { Form, FormGroup, Label, Input, Card, CardHeader, CardBody, CardFooter, Button, CardTitle } from 'reactstrap';
import { Link } from "react-router-dom"
import Datetime from 'react-datetime';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { CSVLink } from "react-csv";
import moment from "moment";

export default function OrderExport(props) {
   var date = new Date();
   const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
   const [endDate, setEndDate] = useState(new Date());
   const [selectedStatus, setStatus] = useState("All");
   const [exportData, setExportData] = useState([]);

   useEffect(() => {
      //exportOrderData(selectedStatus, moment(startDate).format("L"), moment(endDate).format("L"));
      console.log(selectedStatus);
      console.log(startDate);
      console.log(endDate);
      console.log(moment(startDate).format("L"));
   }, [selectedStatus, startDate, endDate]);

   function exportOrderData(selectedStatus, startDate, endDate) {
      exportOrder(selectedStatus, startDate, endDate).then(res => {
         setExportData(res.data.map((res, index) => {
            return {
               ...res,
            }
         }))
      });
   }
   let csvData = [];

   async function expData(event) {
      let resp = await exportOrder(selectedStatus, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"));
      //let data = res.data;
      //setExportData();
      const date = new Date()
      const dateStr = moment(date).format("YYYYMMDDHHmmss")

      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `exportOrders_${dateStr}.csv`);
      document.body.appendChild(link);
      link.click();

   }

   function handleStartDate(e) {
      if(moment(new Date()).diff(moment(e),"months") > 2){
         window.alert("Invalid date, you can only export orders within 3 months")

         return
      }
      setStartDate(e);
   }

   function handleEndDate(e) {
      if(moment(new Date()).diff(moment(e),"months") > 2){
         window.alert("Invalid date, you can only export orders within 3 months")
         
         return
      }
      setEndDate(e);
   }

   function handleStatus(e) {
      setStatus(e.target.value);
   }

   return (
      <div className="content">
         <Card>
            <CardHeader>
               <CardTitle tag="h4">
                  Export Order
                  <Link to="/admin/order/list">
                     <i style={{ float: 'right', right: '1%' }} className="nc-icon nc-simple-remove" />
                  </Link>
               </CardTitle>
            </CardHeader>
            <CardBody>
               <Form>
                  <FormGroup>
                     <Label for="orderStatus">Order Status</Label>
                     <Input type="select" id="orderStatus" onChange={e => handleStatus(e)}>
                        <option selected value="All">All</option>
                        <option value="Partial">Partial</option>
                        <option value="NEW">New</option>
                        <option value="Ready_To_Ship">Ready to Ship</option>
                        <option value="Shipped">Shipped</option>
                        <option value="Delivered">Delivered</option>
                        <option value="Cancelled">Cancelled</option>
                        <option value="ReturnAndExchange">Return and Exchange</option>
                        <option value="Hold">Hold</option>
                        <option value="Failed">Failed</option>
                     </Input>
                  </FormGroup>
                  <FormGroup>
                     <Card>
                        <CardHeader><CardTitle>From</CardTitle></CardHeader>
                        <CardBody>
                           <FormGroup>
                              <Datetime
                                 timeFormat={false}
                                 inputProps={{ placeholder: "Start Date" }}
                                 value={startDate}
                                 onChange={handleStartDate}
                              />
                           </FormGroup>
                        </CardBody>
                     </Card>
                  </FormGroup>
                  <FormGroup>
                     <Card>
                        <CardHeader><CardTitle>To</CardTitle></CardHeader>
                        <CardBody>
                           <FormGroup>
                              <Datetime
                                 timeFormat={false}
                                 inputProps={{ placeholder: "End Date" }}
                                 value={endDate}
                                 onChange={handleEndDate}
                              />
                           </FormGroup>
                        </CardBody>
                     </Card>
                  </FormGroup>
               </Form>
            </CardBody>
            <CardFooter>
               <Button onClick={expData}>Export</Button>

               <CSVLink data={csvData} filename={"exportOrder.csv"} asyncOnClick={false} onClick={expData}>

               </CSVLink>
            </CardFooter>
         </Card>

      </div>
   )
}