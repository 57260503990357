import React from 'react';
import { Row, Col, CardHeader, CardTitle, CardBody, FormGroup, Label, Input, Button } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { Table } from 'antd';

import { checkSKU } from 'services/dataservice';
import "./index.css";

export default function PromotionRange(props) {
    let { tempGiftSKU,setTempGiftSKU,dataSourceRange, setDataSourceRange, promotion, setAlert, onChangeDescription, setPromotion } = props;

    const columnsRange = [
        {
            title: '#',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            key: '1',
            title: 'Min',
            dataIndex: 'min',
        },
        {
            key: '2',
            title: 'Gift Product SKU',
            dataIndex: 'gift',
        },
        {
            key: '3',
            title: 'Product Name',
            dataIndex: 'productName',
        },
        {
            key: '4',
            title: 'Base UOM',
            dataIndex: 'baseUOM',
        },
        {
            key: '5',
            title: 'Unit',
            dataIndex: 'unit',
        },
        {
            key: '6',
            title: 'Stock Limit',
            dataIndex: 'limit',
        },
        {
            key: '7',
            title: 'Action',
            render: (record) => {
                return (
                        <Button
                            id="btnDelete"
                            size="sm"
                            className="btn-icon btn-link remove"
                            color="danger"
                            onClick={() => {
                                clickDeleteButtonRange(record);
                            }}>
                            <i className="fa fa-times" />
                        </Button>
                )
            }
        },
    ]

    const clickDeleteButtonRange = (record) => {
        promotion["edit"] = true;
        setPromotion({ ...promotion });
        setDataSourceRange((pre) => {
            return pre.filter((newRecord2) => newRecord2.gift !== record.gift);
        });
    }

    const clickAddButtonRange = async () => {
        var duplicate = false;
        var outOfRange = false;
        var txtMin = document.getElementById("min").value;
        //var txtMax = document.getElementById("max").value;
        var txtSKU = document.getElementById("sku").value;
        var txtUnit = document.getElementById("unit").value;
        var txtLimit = document.getElementById("stockLimit").value;
        let min=""

        if (txtMin === '' ||  txtSKU === '' || txtUnit === '' || txtLimit === '') {
            showFail("Please do not leave empty");
            return;
        }
        const regex = /^\d{1,10}$/;
        if (!(txtUnit).match(regex) || !(txtLimit).match(regex)) {
            showFail("Unit and Stock Limit must be in whole number");
            return;
        }

        //console.log(promotion)
        console.log(dataSourceRange)

        if (promotion.promotionCriteria === "Order Total") {
            const regex2 = /^\d{1,10}\.{0,1}\d{0,2}$/;

            if (!(txtMin).match(regex2) ) {
                showFail("Min and Max should start on 0 (with 2 decimals)");
                return;
            }
            if (parseFloat(txtMin) < 0.01 ) {
                showFail("Min and Max must not less then 0.01");
                return;
            }
            if (parseInt(txtUnit) < 1) {
                showFail("Unit must not less then 1");
                return;
            }
            // if (parseFloat(txtMax) < parseFloat(txtMin)) {
            //     showFail("Max must be larger than Min");
            //     return;
            // }
            for (var i = 0; i < dataSourceRange.length; i++) {
                if (dataSourceRange[i].gift.toLowerCase() === txtSKU.toLowerCase() && dataSourceRange[i].min===parseFloat(txtMin).toFixed(2).toString()) {
                    duplicate = true;
                }
            }
            min=parseFloat(txtMin).toFixed(2).toString()
            // for (var i = 0; i < dataSourceRange.length; i++) {
            //     //console.log(`${parseFloat(txtMin)} <= ${parseFloat(dataSourceRange[i].max)} && ${parseFloat(dataSourceRange[i].min)} <= ${parseFloat(txtMax)}`)
            //     if (parseFloat(txtMin) <= parseFloat(dataSourceRange[i].max) && parseFloat(dataSourceRange[i].min) <= parseFloat(txtMax)) {
            //         outOfRange = true;
            //     }
            // }

            // for (var i = 0; i < dataSourceRange.length; i++) {
            //     console.log(parseFloat(txtMin)===parseFloat(dataSourceRange[i].min)&&parseFloat(txtMax)===parseFloat(dataSourceRange[i].max))
            //     if(parseFloat(txtMin)===parseFloat(dataSourceRange[i].min)&&parseFloat(txtMax)===parseFloat(dataSourceRange[i].max)){
            //         outOfRange=false
            //         console.log("in if")
            //         break
            //     }
            //     console.log("out of if")
            // }
        } else {
            const regex3 = /^\d{1,10}$/;

            if (!(txtMin).match(regex3) ) {
                showFail("Min and Max should start on 0 in whole number");
                return;
            }
            if (parseInt(txtMin) < 1 ) {
                showFail("Min and Max must not less then 1");
                return;
            }
            if (parseInt(txtUnit) < 1) {
                showFail("Unit must not less then 1");
                return;
            }
            // if (!(parseInt(txtMin) <= parseInt(txtMax))) {
            //     showFail("Max must be larger than Min");
            //     return;
            // }
            for (var i = 0; i < dataSourceRange.length; i++) {
                if (dataSourceRange[i].gift.toLowerCase() === txtSKU.toLowerCase() && dataSourceRange[i].min===parseFloat(txtMin).toFixed(2).toString()) {
                    duplicate = true;
                }
            }
            min=parseInt(txtMin).toString()
            // for (var i = 0; i < dataSourceRange.length; i++) {
            //     if (parseInt(txtMin) <= parseInt(dataSourceRange[i].max) && parseInt(dataSourceRange[i].min) <= parseInt(txtMax)) {
            //         outOfRange = true;
            //     }
            // }

            // for(var i=0;i < dataSourceRange.length; i++){
            //     if (parseInt(txtMin) === parseInt(dataSourceRange[i].min) && parseInt(dataSourceRange[i].max) === parseInt(txtMax)) {
            //         outOfRange = false
            //     }
            // }
        }

        if (parseInt(txtLimit) < parseInt(txtUnit)) {
            showFail("Stock limit should not less than unit");
            return;
        }

        //console.log(parseFloat(txtMin).toFixed(2))

        if (duplicate) {
            showFail(txtSKU + " already in list");
        } else if (outOfRange) {
            showFail("Min and Max should not out of range");
        } else {
            try {
                let res = await checkSKU(txtSKU);
                if (res.data.exist) {
                    const newRecord = {
                        min: min,
                        //max: txtMax.toString(),
                        //gift: txtSKU,
                        gift:res.data.inventorySKU,
                        productName:res.data.productName,
                        baseUOM:res.data.baseUOM,
                        unit: txtUnit,
                        limit: txtLimit
                    };
                    setDataSourceRange((pre) => {
                        return [...pre, newRecord];
                    });
                    promotion["edit"] = true;
                    setPromotion({ ...promotion });
                    //document.getElementById("min").value = "";
                    //document.getElementById("max").value = "";
                    document.getElementById("sku").value = "";
                    document.getElementById("unit").value = "";
                    document.getElementById("stockLimit").value = "";
                    document.getElementById("min").focus();
                }
                else {
                    showFail(txtSKU + " not in system");
                }
            } catch {
                showFail('Connection to server failed');
                return;
            }
        }
    }

    const showFail = (msg) => {
        setAlert(
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title={msg}
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="info"
                btnSize=""
            >
            </ReactBSAlert>
        )
    }

    return (
        <>
            <CardHeader>
                {(promotion.promotionCriteria === "Order Total") && (<CardTitle tag="h6">Order Total Tier</CardTitle>)}
                {(promotion.promotionCriteria === "Product Quantity") && (<CardTitle tag="h6">Product Quantity Tier</CardTitle>)}
            </CardHeader>
            <CardBody>
                <Row>
                    <Col md="1">
                        <FormGroup>
                            <Label>Min</Label>
                            <Input type="number"  name="min" id="min" />
                        </FormGroup>
                    </Col>
                    {/* <Col md="1">
                        <FormGroup>
                            <Label>Max</Label>
                            <Input type="number" name="max" id="max" />
                        </FormGroup>
                    </Col> */}
                    <Col md="3">
                        <FormGroup>
                            <Label>Gift Product SKU</Label>
                            <Input type="text"  name="giftSKU" id="sku" value={tempGiftSKU} onChange={(e)=>{setTempGiftSKU(e.target.value)}} />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                            <Label>Unit</Label>
                            <Input type="number"  name="unit" id="unit" />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup>
                            <Label>Stock Limit</Label>
                            <Input type="number"  name="stockLimit" id="stockLimit" />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <Button
                            id="rangeButton"
                            style={{ margin: 25, backgroundColor: "#f9dfe0", color: "black" }}
                            onClick={clickAddButtonRange}>
                            Add
                        </Button>
                    </Col>
                </Row>
                <Table
                    className='range'
                    columns={columnsRange}
                    dataSource={dataSourceRange}
                />
                <br></br>
                <FormGroup>
                    <Label>Description</Label>
                    <Input type="text" name="description" defaultValue={promotion.description} onChange={(e) => onChangeDescription(e)} />
                </FormGroup>
            </CardBody>
        </>
    )
}