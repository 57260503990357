import React, { Fragment, useState } from "react";
import { UncontrolledAlert } from "reactstrap";
import { Upload, Modal, Select, Divider, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { uploadImage, deleteImage } from "services/dataservice";
import PropTypes from "prop-types";
export const UploadAntd = ({ index, count, multiple, data, dataArr, setData, setMsg, sku, variation, dataObj, name }) => {
  // const AUTH_TOKEN = localStorage.getItem("jwtToken");
  // const token = `Bearer ${AUTH_TOKEN}`;

  // const UPLOAD_URL = `/api/catalogue/uploadImage`;
  // const DELETE_URL = `/api/catalogue/deleteImage`;
  // console.log(multiple)
  const IMAGE_URL = `/api/marketplace-product/getImage?fileName=`;

  const showUploadMsg = (msg, status) => {
    setMsg(null);
    setMsg(
      <UncontrolledAlert onClick={() => setMsg(null)} color={status}>
        {msg}
      </UncontrolledAlert>
    );
    setTimeout(() => {
      setMsg(null);
    }, 3000);
  };

  const [preview, setPreview] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  });

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8, color: "#666" }}>Upload</div>
    </div>
  );
  const uploadButtonVariation = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 6, fontSize: "10px", color: "#666" }}>Upload</div>
    </div>
  );
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleChange = ({ file, fileList }) => {
    setMsg(null);
    if (fileList.length > count) {
      fileList.splice(count);
    }

    let { status } = file;

    if (status === "done") {
      showUploadMsg("Uploaded", "success");
    }
    if (status === "error") {
      showUploadMsg("Upload failed", "danger");
      file.response = "Upload failed, remove and upload again";
    }
    setData(fileList);
  };

  // const handleSkuChange = ({ file, fileList }, index) => {
  //   let skusArr = [...dataArr];
  //   if (fileList.length > count) {
  //     fileList.splice(count);
  //   }
  //   let { status } = file;
  //   if (status === "done") {
  //     showUploadMsg("Uploaded", "success");
  //   }
  //   if (status === "error") {
  //     showUploadMsg("Upload failed", "danger");
  //     file.response = "Upload failed, remove and upload again";
  //   }
  //   skusArr[index]["skuFileList"] = fileList;
  //   setData(skusArr);
  // };

  const handleOptionImageChange = ({ file, fileList }, index) => {
    // let optionsClone = [...dataArr];
    let optionImagesClone = { ...dataObj };

    if (fileList.length > count) {
      fileList.splice(count);
    }
    let { status } = file;
    if (status === "done") {
      showUploadMsg("Uploaded", "success");
    }
    if (status === "error") {
      showUploadMsg("Upload failed", "danger");
      file.response = "Upload failed, remove and upload again";
    }
    // console.log(optionImagesClone);
    // console.log(optionImagesClone[name]);
    optionImagesClone[name] = fileList;
    setData(optionImagesClone);
  };

  const beforeUpload = (file, fileList, data) => {
    let totalFile = fileList.length + data.length;

    if (totalFile > count) {
      showUploadMsg(`Currently have ${data.length} image, and you want to upload more ${fileList.length} image, which has exceeded the limit`, "danger");
      return Upload.LIST_IGNORE;
    }
    if (fileList.length > count) {
      showUploadMsg("Only 8 files can be uploaded", "danger");
      return Upload.LIST_IGNORE;
    }
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      showUploadMsg(`${file.name} is not a png/jpeg file`, "danger");
      return Upload.LIST_IGNORE;
    }

    let isLt1M = file.size / 1024 / 1024 <= 1;

    if (!isLt1M) {
      showUploadMsg(`${file.name} upload failed, Image must smaller than 1MB!`, "danger");
      return Upload.LIST_IGNORE;
    }
    return (file.type === "image/png" || file.type === "image/jpeg") && isLt1M;
  };

  const uploadProps = {
    ...uploadImage(),
    listType: "picture-card",
    multiple,
    fileList: data,
    beforeUpload: (file, fileList) => beforeUpload(file, fileList, data),
    onChange: variation ? (f) => handleOptionImageChange(f, index) : handleChange,
    onRemove: async (file) => {
      if (file.hasOwnProperty("error")) {
        console.log("remove error file");
        return;
      }

      let { name } = file.response.data;

      if (name) {
        try {
          // const response = await axios.delete(`${DELETE_URL}`, {
          //   headers: { "content-type": "application/json", Authorization: token },
          //   data: {
          //     name,
          //   },
          // });
          let response = await deleteImage({ name });
          if (response.status === 200) {
            showUploadMsg("Deleted", "success");
          } else {
            showUploadMsg("Delete failed", "danger");
            return false;
          }
        } catch (error) {
          showUploadMsg("Server Error, please remove image again");
          return false;
        }
      }
    },
    onPreview: async (file) => {
      if (!file.url && !file.preview && !file.response.data) {
        file.preview = await getBase64(file.originFileObj);
      }

      setPreview({
        previewImage: file.preview || file.url || `${IMAGE_URL}${file.response.data.name}`,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      });
    },
  };

  const modalProps = {
    visible: preview.previewVisible,
    title: preview.previewTitle,
    footer: null,
    onCancel: () => {
      let previewObj = { ...preview };
      previewObj["previewVisible"] = false;
      setPreview(previewObj);
    },
  };
  return (
    <Fragment>
      <Upload {...uploadProps}>{data.length >= count ? null : variation ? uploadButtonVariation : uploadButton}</Upload>
      <Modal {...modalProps}>
        <img src={preview.previewImage} alt={preview.previewTitle} style={{ width: "100%" }} />
      </Modal>
    </Fragment>
  );
};

UploadAntd.defaultProps = {
  index: undefined,
  count: 8,
  multiple: true,
  // dataArr: [],
  dataObj: {},
  // sku: false,
  variation: false,
};

UploadAntd.propTypes = {
  index: PropTypes.number,
  count: PropTypes.number,
  multiple: PropTypes.bool,
  data: PropTypes.array.isRequired,
  // dataArr: PropTypes.array,
  dataObj: PropTypes.object,
  setData: PropTypes.func.isRequired,
  setMsg: PropTypes.func.isRequired,
  // sku: PropTypes.bool,
  variation: PropTypes.bool,
};

const selectAntdProps = {
  size: "large",
  style: { width: "100%" },
};

export const SelectAntd = ({ data, value, placeholder, mode, onClick, onChange, showSearch, allowClear }) => (
  <Select
    {...selectAntdProps}
    allowClear={allowClear}
    {...(mode && { mode })}
    {...(mode === "tags" && { open: false })}
    {...(showSearch && { showSearch })}
    {...(value && { value })}
    {...(onClick && { onClick })}
    placeholder={placeholder}
    onChange={onChange}
  >
    {data?.map((d, index) => (
      <Select.Option key={index} value={d.value}>
        {d.label}
      </Select.Option>
    ))}
  </Select>
);

SelectAntd.defaultProps = {
  data: [],
  value: undefined,
  placeholder: "Single Select",
  mode: undefined,
  onClick: undefined,
  onChange: undefined,
  allowClear: true,
};

SelectAntd.propTypes = {
  data: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  mode: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  allowClear: PropTypes.bool,
};

// export const SelectInputAntd = ({ data, value, placeholder, mode, showSearch, onChange, allowClear }) => {
//   const selectInputAntdProps = {
//     size: "large",
//     style: { width: "100%" },
//     ...(mode && { mode }),
//     ...(value && { value }),
//     ...(showSearch && { showSearch }),
//     allowClear,
//     placeholder,
//     onChange,
//   };
//   return (
//     <Select {...selectInputAntdProps}>
//       {data?.map((d, index) => (
//         <Select.Option key={d.value} value={d.value.toString()}>
//           {d.label}
//         </Select.Option>
//       ))}
//     </Select>
//   );
// };

// SelectInputAntd.defaultProps = {
//   data: [],
//   value: undefined,
//   placeholder: "Single select",
//   mode: undefined,
//   // onClick: undefined,
//   onChange: undefined,
//   allowClear: true,
// };

// SelectInputAntd.propTypes = {
//   data: PropTypes.array.isRequired,
//   placeholder: PropTypes.string,
//   mode: PropTypes.string,
//   onChange: PropTypes.func.isRequired,
//   allowClear: PropTypes.bool,
// };

export const SelectTag = (props) => {
  let { data, value, onChange, allowClear } = props;
  const selectTagProps = {
    style: { width: "100%" },
    size: "large",
    placeholder: "Multi Select or Multi Input",
    mode: "tags",
    value,
    onChange,
    allowClear,
  };
  return (
    <Select {...selectTagProps}>
      {data.map((d, index) => (
        <Select.Option key={index} value={d.value.toString()}>
          {d.label}
        </Select.Option>
      ))}
    </Select>
  );
};

SelectTag.defaultProps = {
  allowClear: true,
};
SelectTag.propTypes = {
  value: PropTypes.array,
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const ComboBox = (props) => {
  let { data, value, onChange, allowClear, onCustomValueChange, onAddCustomItem } = props;
  const comboBoxProps = {
    style: { width: "100%" },
    size: "large",
    // placeholder,
    placeholder:"Select or Input",
    showSearch: true,
    // value,
    ...(value&&{value}),
    onChange,
    allowClear,
    // dropdownRender,
  };

  // const dropdownRender = (menu) => (
  //   <div>
  //     {menu}
  //     <Divider style={{ margin: "4px 0" }} />
  //     <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
  //       <Input type="text" onBlur={onCustomValueChange} />
  //       <div style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }} onClick={onAddCustomItem}>
  //         <PlusOutlined style={{ color: "#13dd63" }} />
  //       </div>
  //     </div>
  //   </div>
  // );
  return (
    <Select
      
      {...comboBoxProps}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
            <Input type="text" onBlur={onCustomValueChange} />
            <div style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }} onClick={onAddCustomItem}>
              <PlusOutlined style={{ color: "#13dd63" }} />
            </div>
          </div>
        </div>
      )}
    >
      {data.map((d, index) => (
        <Select.Option key={index} value={d.value.toString()}>
          {d.label}
        </Select.Option>
      ))}
    </Select>
  );
};

ComboBox.defaultProps = {
  allowClear: true,
};

ComboBox.propTypes = {
  value: PropTypes.string,
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onCustomValueChange: PropTypes.func.isRequired,
  onAddCustomItem: PropTypes.func.isRequired,
};
