import React, { useState, memo } from "react";
import { Row, Col, CardTitle, CardBody, FormGroup, Input, Label } from "reactstrap";
import { SelectAntd, ComboBox, SelectTag } from "views/product/ProductAdd/Components/AntdComponent";
import BrandList from "./BrandList";
import styles from "../../../Less/required.module.less"
 

export default memo(function Specification(props) {
  let { brand, setBrand,brandList, attrNormals, setAttrNormals } = props;

  const [customValue, setCustomValue] = useState("");

  
  const customValueChange = (e) => {
    let { value } = e.target;
    setCustomValue(value);
  };

  const addCustomItem = (index) => {
    if (customValue.trim().length === 0) return;
    let attrNormalsClone = [...attrNormals];
    attrNormalsClone[index]["options"].push({ label: customValue, value: customValue });
    setAttrNormals(attrNormalsClone);
  };

  const textChange = (e, index, mandatory) => {
    let { value } = e.target;
    let attrNormalsClone = [...attrNormals];
    if (mandatory) {
      attrNormalsClone[index]["value"] = value;
      attrNormalsClone[index]["state"] = true;
    } else {
      attrNormalsClone[index]["value"] = value;
    }
    setAttrNormals(attrNormalsClone);
  };
  const selectChange = (value, index, mandatory) => {
    let attrNormalsClone = [...attrNormals];
    if (mandatory) {
      if (!value) {
        attrNormalsClone[index]["value"] = "";
        attrNormalsClone[index]["state"] = false;
      } else {
        attrNormalsClone[index]["value"] = value;
        attrNormalsClone[index]["state"] = true;
      }
    } else {
      if (!value) {
        attrNormalsClone[index]["value"] = "";
      } else {
        attrNormalsClone[index]["value"] = value;
      }
    }
    setAttrNormals(attrNormalsClone);
  };
  return (
    <CardBody>
      <CardBody style={{ border: "1px solid #e3e3e3", borderRadius: "8px" }}>
        {/* {console.log("re-render specification")} */}
        <CardTitle tag="h6" style={{ color: "#bf558b" }}>
          Specification
        </CardTitle>
        <Row form>

          <BrandList brand={brand} setBrand={setBrand} brandList={brandList}/>
          {attrNormals.map((attr, index) => (
            <Col md="3" key={attr.id}>
              <FormGroup>
                <Label>
                  {attr.label}
                  {attr.is_mandatory === 1 && <span style={{ color: "red", fontWeight: "bold" }}> *</span>}
                  {attr.key && <span style={{ color: "blue", fontWeight: "bold", fontStyle: "italic" }}> key</span>}
                </Label>
                {(["enumInput"].includes(attr.input_type) && attr.options.length > 0 && (
                  <div {...(attr.is_mandatory === 1 && !attr.state && { className: styles.mySelectRequired })}>
                    <ComboBox
                      data={attr.options}
                      value={attr.value}
                      onChange={(value) => selectChange(value, index, attr.is_mandatory)}
                      onCustomValueChange={customValueChange}
                      onAddCustomItem={() => addCustomItem(index)}
                    />
                  </div>
                )) ||
                  (["singleSelect", "text", "numeric"].includes(attr.input_type) && attr.options.length > 0 && (
                    <div {...(attr.is_mandatory === 1 && !attr.state && { className: styles.mySelectRequired })}>
                      <SelectAntd data={attr.options} value={attr.value} onChange={(value) => selectChange(value, index, attr.is_mandatory)} />
                    </div>
                  )) ||
                  (["text", "richText", "singleSelect", "enumInput"].includes(attr.input_type) && attr.options.length === 0 && (
                    // <div {...(attr.is_mandatory === 1 && !attr.state && { className: styles.mySelectRequired })}>
                    <Input type="text" defaultValue={attr.value} onBlur={(e) => textChange(e, index, attr.is_mandatory)} {...(attr.is_mandatory === 1 && !attr.state && { invalid: true })} />
                    // </div>
                  )) ||
                  (["numeric"].includes(attr.input_type) && attr.options.length === 0 && (
                    <Input
                      type="number"
                      step="any"
                      defaultValue={attr.value}
                      onBlur={(e) => textChange(e, index, attr.is_mandatory)}
                      {...(attr.is_mandatory === 1 && !attr.state && { invalid: true })}
                    />
                  )) ||
                  (["multiEnumInput"].includes(attr.input_type) && (
                    <div {...(attr.is_mandatory === 1 && !attr.state && { className: styles.mySelectRequired })}>
                      <SelectTag
                        data={attr.options}
                        value={attr.value ? (Array.isArray(attr.value) ? attr.value : [attr.value]) : []}
                        onChange={(value) => selectChange(value, index, attr.is_mandatory)}
                      />
                    </div>
                  )) ||
                  (["multiSelect"].includes(attr.input_type) && (
                    <div {...(attr.is_mandatory === 1 && !attr.state && { className: styles.mySelectRequired })}>
                      <SelectAntd placeholder="Multi select" mode="multiple" data={attr.options} value={attr.value} onChange={(value) => selectChange(value, index, attr.is_mandatory)} />
                    </div>
                  ))}
                {attr.is_mandatory === 1 && !attr.state && <span style={{ color: "#dc3545", width: "100%", fontSize: "80%", marginTop: "0.25rem" }}>This field is required.</span>}
              </FormGroup>
            </Col>
          ))}
        </Row>
      </CardBody>
    </CardBody>
  );
});
