import { DatePicker, Divider, Select, Input as AntdInput } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { Input } from "reactstrap";

export const ShopeeDatePicker = ({ picker, onChange, value }) => {
  const shopeeDatePickerProps = {
    size: "large",
    style: { width: "100%" },
    allowClear: true,
    ...(picker && { picker }),
    onChange,
    value,
  };
  return <DatePicker {...shopeeDatePickerProps} />;
};

export const ShopeeSelect = ({ data, mode, value, placeholder, showSearch, allowClear, onChange }) => {
  const shopeeSelectProps = {
    style: { width: "100%" },
    size: "large",
    placeholder,
    ...(mode && { mode }),
    ...(value && { value }),
    ...(showSearch && { showSearch }),
    // allowClear
    ...(allowClear && { allowClear }),
    // labelInValue: true,
    onChange,
    // fieldNames: { label: "original_value_name", value: "value_id" },
  };
  return (
    <Select {...shopeeSelectProps}>
      {data.map((d, index) => (
        <Select.Option key={index} ovalue={{ value_id: d.value_id, original_value_name: d.original_value_name }} value={d.original_value_name}>
          {d.original_value_name}
        </Select.Option>
      ))}
    </Select>
  );
};
export const ShopeeBrandSelect = ({ data, mode, value, placeholder, showSearch, allowClear, onChange }) => {
  const shopeeBrandSelectProps = {
    style: { width: "100%" },
    size: "large",
    placeholder,
    ...(mode && { mode }),
    ...(value && { value }),
    ...(showSearch && { showSearch }),
    ...(allowClear && { allowClear }),
    onChange,
  };
  return (
    <Select {...shopeeBrandSelectProps}>
      {data.map((d, index) => (
        <Select.Option key={index} ovalue={{ brand_id: d.brand_id, original_brand_name: d.original_brand_name }} value={d.original_brand_name}>
          {d.display_brand_name}
        </Select.Option>
      ))}
    </Select>
  );
};

export const CustomSelect = ({ data, type, customValueChange, customUnitChange, value, addCustomItem, allowClear,mode, index, unit = [], onChange }) => {
  const keyDown=(e)=>{
    console.log(e.keyCode)
    console.log("value",e.target.value)
    if(e.keyCode===8&&e.target.value.length===0){
      e.stopPropagation()
    }
  }
  const customSelectProps = {
    style: { width: "100%" },
    size: "large",
    placeholder: "Select or Input",
    // fieldNames: { label: "original_value_name", value: "value_id" },
    ...(value && { value }),
    showSearch: true,
    ...(allowClear && { allowClear }),
    ...(mode&&{mode}),
    onChange,
    onInputKeyDown: (e)=>keyDown(e)
  };
  return (
    <Select
      {...customSelectProps}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
            {(type === "STRING_TYPE" && <Input style={{ flex: "auto" }} type="text" onChange={customValueChange} />) ||
              (type === "FLOAT_TYPE" && (
                <>
                  <Input style={{ flex: "auto" }} type="number" onBlur={customValueChange} />
                  {/* <Select size="large" style={{ width: "100%" }} onChange={customUnitChange}>
                    {unit.map((u, index) => (
                      <Select.Option key={index} value={u}>
                        {u}
                      </Select.Option>
                    ))}
                  </Select> */}
                  <Input type="select" onChange={customUnitChange}>
                    <option></option>
                    {unit.map((u, index) => (
                      <option key={index} value={u}>
                        {u}
                      </option>
                    ))}
                  </Input>
                </>
              ))}
            {/* <a style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }} onClick={addCustomItem}> */}
            <div style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }} onClick={addCustomItem}>
              <PlusOutlined style={{color:"#13dd63"}} />
              {/* <span style={{flex:"auto"}}>Add item</span> */}
            </div>
            {/* </a> */}
          </div>
        </div>
      )}
    >
      {/* {console.log(data)} */}
      {data.map((d, index) => (
        <Select.Option key={index} ovalue={{ value_id: d.value_id, original_value_name: d.original_value_name, ...(d.value_unit && { value_unit: d.value_unit }) }} value={d.hasOwnProperty('value_unit')?`${d.original_value_name} ${d.value_unit}`:d.original_value_name}>
          {d.original_value_name} {d.value_unit}
        </Select.Option>
      ))}
    </Select>
  );
};

// export const QuantitativeInput=({data,quantitativeChange})=>{
//   return(<AntdInput.Group compact><Input type="number" name="num" style={{width:"70%"}} onChange/><Input type="select" name="sel" style={{width:"30%",height:"40px"}}>{data.map((d,index)=>(<option key={index}>{d}</option>))}</Input></AntdInput.Group>)
// }
