import React from "react";
import{CardBody,FormGroup,Input,Label} from "reactstrap"
export default function GroupData(props) {
    const{groupData,setGroupData}=props
  return (
    <CardBody>
      <FormGroup>
        <Label>Product Group Data</Label>
        <Input type="text" defaultValue={groupData} onBlur={e=>setGroupData(e.target.value.trim())} />
      </FormGroup>
    </CardBody>
  );
}
