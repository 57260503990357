import React from "react";
import { Button, CustomInput, Input } from "reactstrap";
import { getInventoryBySku } from "services/dataservice";
import { deleteVariation } from "services/dataservice";

export default function TableSkuBody(props) {
  let { variations, setVariations, toggleModalShowEdit, showFail, platformName } = props;

  const handleDeleteVariation = async (index) => {
    if (window.confirm("Are you sure you want to delete this variation?")) {
      try {
        let variationsClone = [...variations];
        let variationId = variationsClone[index]["variationId"];
        console.log(variationId);
        let response = await deleteVariation({ variationId });
        if (response.status === 200) {
          console.log("/DeleteVariation response ok");
          variationsClone.splice(index, 1);
          setVariations(variationsClone);
        }
      } catch (error) {
        showFail("Server error, please try again.");
      }
    }
  };

  // const handleOptionChange = (e, index, i) => {
  //   let { value } = e.target;
  //   let variationsClone = [...variations];
  //   variationsClone[index]["optionList"][i]["oValue"] = value;
  //   variationsClone[index]["optionList"][i]["oState"] = true;
  //   setVariations(variationsClone);
  // };

  const handleSkuStatus = (index) => {
    let variationsClone = [...variations];
    // let p_status = variationsClone[index].status;
    // if (p_status === null || p_status === "Active") {
    //   variationsClone[index].status = "InActive";
    // } else {
    //   variationsClone[index].status = "Active";
    // }
    let active = variationsClone[index]["active"];
    variationsClone[index]["active"] = !active;
    setVariations(variationsClone);
  };

  const handleSearch = async (e, index) => {
    let { keyCode, target } = e;
    let searchSku = target.value;
    let variationsClone = [...variations];
    if (keyCode !== 13) return;

    if (searchSku.trim() === "") return;

    try {
      // let response = await axios.get(`/api/catalogue/searchInventory?MarketplaceSku=${searchSku}`);
      let response = await getInventoryBySku(searchSku);
      console.log("response", response);
      if (response.status === 200) {
        // console.log(response)
        let { inventorySKU, barcode1, barcode2, costPrice, weight, height, length, width } = response.data;
        variationsClone[index].inventorySKUState = true;
        variationsClone[index].inventorySKUFound = true;
        variationsClone[index].inventorySKU = inventorySKU || "";
        variationsClone[index].barcode1 = barcode1 || "";
        variationsClone[index].barcode1State = true;
        variationsClone[index].barcode2 = barcode2 || "";
        variationsClone[index].sellingPrice = costPrice || "";
        variationsClone[index].sellingPriceState = true;
        variationsClone[index].weight = weight || "";
        variationsClone[index].weightState = true;
        variationsClone[index].height = height || "";
        variationsClone[index].heightState = true;
        variationsClone[index].length = length || "";
        variationsClone[index].lengthState = true;
        variationsClone[index].width = width || "";
        variationsClone[index].widthState = true;
        setVariations(variationsClone);
      }
    } catch (error) {
      // showFail("Not Found");
      showFail(`${searchSku} Not Found`);
    }
  };
  const handlePriceBlur = (e, index) => {
    let { value, name } = e.target;
    let variationsClone = [...variations];
    variationsClone[index][name] = value;
    variationsClone[index][`${name}State`] = true;
    setVariations(variationsClone);
  };
  const newSkuBlur = (e, index) => {
    let { value, name } = e.target;
    let variationsClone = [...variations];
    variationsClone[index][name] = value;
    variationsClone[index][`${name}State`] = true;
    if (name === "inventorySKU") {
      variationsClone[index]["inventorySKUFound"] = false;
    }
    setVariations(variationsClone);
  };
  return (
    <tbody>
      {variations.map((variation, index) => {
        let { barcode1, barcode2, height, weight, length, width, sellingPrice, inventorySKU, option1, option2, optionList, variant, status, active, inventoryId } = variation;
        return (
          <tr key={variant || index} style={{ borderTop: "1px solid #dee2e6" }}>
            {optionList.map((o, i) => (
              <td key={i}>
                {/* <Input type="text" name={o.option} defaultValue={o.oValue} onBlur={(e) => handleOptionChange(e, index, i)} {...(!o.oState && { invalid: true })} /> */}
                {o.oValue}
              </td>
            ))}
            {/* {option1 && <td>{option1}</td>}
            {option2 && <td>{option2}</td>} */}
            <td>
              {/* <Input type="text" defaultValue={inventorySKU || ""} readOnly /> */}

              <Input
                type="text"
                name="inventorySKU"
                value={inventorySKU}
                onKeyUp={(e) => handleSearch(e, index)}
                onChange={(e) => newSkuBlur(e, index)}
                {...(variation.inventorySKUFound && { valid: true })}
                {...(!variation.inventorySKUState && { invalid: true })}
                {...(platformName==="Lazada"||platformName==="Zalora")&&inventoryId&&{readOnly:true}}
              />
            </td>
            <td>
              <Input name="sellingPrice" type="number" defaultValue={sellingPrice} step="any" onBlur={(e) => newSkuBlur(e, index)} {...(!variation.sellingPriceState && { invalid: true })} />
            </td>
            <td>
              {" "}
              <Input name="barcode1" type="text" defaultValue={barcode1} onBlur={(e) => newSkuBlur(e, index)} {...(!variation.barcode1State && { invalid: true })} />{" "}
            </td>
            <td>
              {" "}
              <Input name="barcode2" type="text" defaultValue={barcode2} onBlur={(e) => newSkuBlur(e, index)} />{" "}
            </td>
            <td>
              <Input name="weight" type="number" defaultValue={weight} step="any" onBlur={(e) => newSkuBlur(e, index)} {...(!variation.weightState && { invalid: true })} />
            </td>
            <td>
              <Input name="height" type="number" defaultValue={height} step="any" onBlur={(e) => newSkuBlur(e, index)} {...(!variation.heightState && { invalid: true })} />
            </td>
            <td>
              {" "}
              <Input name="width" type="number" defaultValue={width} step="any" onBlur={(e) => newSkuBlur(e, index)} {...(!variation.widthState && { invalid: true })} />{" "}
            </td>
            <td>
              <Input name="length" type="number" defaultValue={length} step="any" onBlur={(e) => newSkuBlur(e, index)} {...(!variation.lengthState && { invalid: true })} />
            </td>
            {["Lazada", "Zalora"].includes(platformName) && (
              <td>
                <CustomInput
                  type="switch"
                  id={`idSkuStatus${index}`}
                  onChange={() => handleSkuStatus(index)}
                  // {...((status === null || status === "Active") && { checked: true })}
                  // {...(status === "InActive" && { checked: false })}
                  {...(active ? { checked: true } : { checked: false })}
                />
              </td>
            )}
            {/* <td>
              <Button
                className=" btn-round"
                color="success"
                style={{ padding: "7px 22px" }}
                onClick={() => toggleModalShowEdit({ inventorySKU, sellingPrice, barcode1, barcode2, height, weight, length, width, index })}
              >
                <i className="fa fa-eye"></i> View
              </Button>
              <Button className=" btn-round" color="danger" style={{ padding: "7px 22px" }} onClick={() => handleDeleteVariation(index)}>
                <i className="fa fa-trash"></i> Delete
              </Button>
            </td> */}
          </tr>
        );
      })}
    </tbody>
  );
}
