import React, { Fragment} from "react";
import { FormGroup, Input, Label } from "reactstrap";

export default function Information(props) {
  let { basicInformation, setBasicInformation } = props;

  const handleBasicBlur = (e) => {
    let { name, value } = e.target;
    if (value.trim().length === 0) return;
    let basicInformationClone = { ...basicInformation };
    switch (name) {
      case "productName":
        basicInformationClone[name] = value;
        setBasicInformation(basicInformationClone);
        break;
      case "description":
        basicInformationClone[name] = value;
        setBasicInformation(basicInformationClone);
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      {/* {console.log("render information")} */}
      <FormGroup>
        <Label>
          Product Name <span style={{ color: "red", fontWeight: "bold" }}>*</span>
        </Label>
        <Input name="productName" type="text" maxLength="50" onBlur={(e) => handleBasicBlur(e)} defaultValue={basicInformation.productName} />
      </FormGroup>
      <FormGroup>
        <Label>
          Product Description <span style={{ color: "red", fontWeight: "bold" }}>*</span>
        </Label>
        <Input type="textarea" name="description" style={{ maxHeight: "200px", height: "200px" }} onBlur={(e) => handleBasicBlur(e)} defaultValue={basicInformation.description} />
      </FormGroup>
    </Fragment>
  );
};
