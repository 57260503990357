import React, { useState } from "react";
import { FormGroup, Label } from "reactstrap";
import { UploadAntd } from "views/product/ProductDetail/Components/UploadAntd";

export default function Image(props) {
  let { productImages, setProductImages } = props;

  const [alertMsg, setAlertMsg] = useState(null);

  return (
    <FormGroup>
      {/* {console.log("render images")} */}
      <Label>
        Product Images <span style={{ color: "blue", fontWeight: "bold", fontStyle: "italic" }}>(Maximum 8 images, Jpg/Png only, smaller than 1mb (1024kb))</span>{" "}
      </Label>
      {alertMsg}
      <UploadAntd data={productImages} setData={setProductImages} setMsg={setAlertMsg} />
    </FormGroup>
  );
}
