/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import routes_admin from "routes-admin.js";

import Lock from "views/Lock";
import { getUserSetting } from "services/dataservice";
import { Badge } from "reactstrap";
import { getOrderFailedCount } from "services/dataservice";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      userName: "",
      routesOnUse: routes
    };
  }

  parseJwt(token) {
    if (!token) {
      return { role: "user" };
    }
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    const fetchUserSetting = async () => {
      try {
        let userName = localStorage.getItem("user_name");
        const response = await getUserSetting(userName);
        const countResponse = await getOrderFailedCount()
        //console.log(response);
        
        const { dashboard, integration, inventoryList, marketplace, orderFailedList, orders, productList,splitInventoryList } = response.data;
        const count = countResponse?.data?.data ?? 0
        
        routes.forEach((route) => {
          switch (route.name) {
            case "Product List (Beta)":
              if (productList) {
                route.path = "/product/lock";
                route.name = "Product List (Lock)";
                route.component = Lock;
                route.icon = "nc-icon nc-lock-circle-open";
              }
              break;
            case "Inventory List":
              if (inventoryList) {
                route.path = "/inventory/lock";
                route.name = "Inventory List (Lock)";
                route.component = Lock;
                route.icon = "nc-icon nc-lock-circle-open";
              }
              break;
            case "Marketplace":
              if (marketplace) {
                route.path = "/marketplace/lock";
                route.name = "Marketplace (Lock)";
                route.component = Lock;
                route.icon = "nc-icon nc-lock-circle-open";
              }
              break;
            case "Split Inventory List":
              if(splitInventoryList){
                route.path = "/split-inventory/lock";
                route.name = "Split Inventory (Lock)";
                route.component = Lock;
                route.icon = "nc-icon nc-lock-circle-open";
              }
              // if(splitInventoryList){
              //   route.path = "/split-inventory/lock";
              //   route.name = "Split Inventory (Lock)";
              //   route.component = Lock;
              //   route.icon = "nc-icon nc-lock-circle-open";
              // }
              break
            case "Integration":
              if (integration) {
                route.path = "/integration/lock";
                route.name = "Integration (Lock)";
                route.component = Lock;
                route.icon = "nc-icon nc-lock-circle-open";
              }
              break;
            case "Orders":
              if (orders) {
                route.path = "/order/lock";
                route.name = "Orders (Lock)";
                route.component = Lock;
                route.icon = "nc-icon nc-lock-circle-open";
              }
              break;
            case "Failed Orders":
              if (orderFailedList) {
                route.path = "/order/failedlist/lock";
                route.name = "Failed Orders (Lock)";
                route.component = Lock;
                route.icon = "nc-icon nc-lock-circle-open";
              }else{
                route.name = <span>Failed Orders {count>0&&<Badge color="danger">{count}</Badge>}</span> 
              }
              break;
            case "Dashboard":
              if (dashboard) {
                route.path = "/dashboard/lock";
                route.name = "Dashboard (Lock)";
                route.component = Lock;
                route.icon = "nc-icon nc-lock-circle-open";
              }
              break;
          }
        });
        
        this.setState({ routesOnUse: routes });
      } catch (error) {
      }
    };
    fetchUserSetting();
    
    //Get username from localstorage
    const payload = this.parseJwt(localStorage.getItem("jwtToken"));
    let role = payload.role;
    let userName = localStorage.getItem("user_name");
    this.setState({ userName: userName });
    if (role === "admin") {
      this.setState({ routesOnUse: routes_admin });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  render() {
    return (
      <div className="wrapper">
        <Sidebar
          userName={this.state.userName}
          {...this.props}
          routes={this.state.routesOnUse}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel" >
          <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />

          <Switch>{this.getRoutes(this.state.routesOnUse)}</Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
              -1 ? null : (
              <Footer fluid />
            )
          }
        </div>
        {/*
          <FixedPlugin
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          sidebarMini={this.state.sidebarMini}
          handleActiveClick={this.handleActiveClick}
          handleBgClick={this.handleBgClick}
          handleMiniClick={this.handleMiniClick}/>*/
        }

      </div>
    );
  }
}

export default Admin;
