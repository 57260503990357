import React, { useState } from 'react'
import { Card, CardHeader, CardBody, CardFooter, Row, Col, Input, Button } from 'reactstrap'
import CardTitle from 'reactstrap/lib/CardTitle';
import Form from 'reactstrap/lib/Form'
import FormGroup from 'reactstrap/lib/FormGroup'

import ReactBSAlert from 'react-bootstrap-sweetalert';

import { AddStoreShopify } from './AddStoreShopify';
import { AddStoreZalora } from './AddStoreZalora';
import { AddStoreLazada } from './AddStoreLazada';
import { AddStoreMagento } from './AddStoreMagento';
import { AddStoreShopee } from './AddStoreShopee';
import { AddStoreWooCommerce } from './AddStoreWooCommerce';
import { AddStoreTiktok } from './AddStoreTiktok';
import { AddStoreZalora2 } from './AddStoreZalora2';

import zaloraLogo from 'assets/img/zalora.jpg';
import magentoLogo from 'assets/img/magentologo.png'
import shoplineLogo from 'assets/img/SHOPLINE.png'
import { AddStoreShopline } from './AddStoreShopline';

export default function AddStore(props) {

   const [selectedMarketplace, setSelectMarketplace] = useState({ name: "Shopee", id: 10 });

   const [alert, setAlert] = useState(null);

   const showFail = (msg) => {
      setAlert(
         <ReactBSAlert
            danger
            style={{ display: "block", marginTop: "-100px" }}
            title={msg}
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="info"
            btnSize=""
         >
         </ReactBSAlert>
      )
   };

   const stores = [
      { name: "Shopee", logo: "https://vectorise.net/logo/wp-content/uploads/2017/09/Logo-Shopee.png", id: 10 },
      //{ name: "ShopeeV2", logo: "https://vectorise.net/logo/wp-content/uploads/2017/09/Logo-Shopee.png", id: 10 },
      { name: "Lazada (MY)", logo: "//laz-img-cdn.alicdn.com/images/ims-web/TB1HKyxaMFY.1VjSZFqXXadbXXa.png", id: 3 },
      { name: "Lazada (SG)", logo: "//laz-img-cdn.alicdn.com/images/ims-web/TB1HKyxaMFY.1VjSZFqXXadbXXa.png", id: 9 },
      { name: "Shopify", logo: "https://cdn.shopify.com/assets/images/logos/shopify-bag.png", id: 4 },
      { name: "Zalora", logo: zaloraLogo, id: 21 },
      { name: "Zalora (MY)", logo: zaloraLogo, id: 15 },
      // { name: "Zalora", logo: zaloraLogo, id: 5 },
      // { name: "Zalora (MY)", logo: zaloraLogo, id: 6 },
      //{ name: "Magento (V2)", logo: magentoLogo, id: 27 },
      { name: "WooCommerce", logo: "https://woocommerce.com/wp-content/themes/woo/images/logo-woocommerce.svg", id: 8 },
      { name: "Tiktok", logo: "https://p-vcloud.byteimg.com/tos-cn-i-em5hxbkur4/0f6c6aa81b1f4aa4b2f0151f03e2c2a2~tplv-em5hxbkur4-noop.image?width=400%height=151", id: 14},
      { name: "Magento2.0", logo: magentoLogo, id: 22 },
      { name: "Shopline", logo: shoplineLogo, id: 23 }
   ]

   const selectStore = (store) => {
      setSelectMarketplace({ name: store.name, marketplaceId: store.id });
   }
   const Stores = stores.map(x => {
      return (
         <Col md="3" key={x.name}>
            <Card>
               <CardBody>
                  <img style={{ maxHeight: "50px" }} src={x.logo}></img>
               </CardBody>
               <CardFooter>
                  <Row>
                     <Col md="6"><strong>{x.name}</strong></Col>
                     <Col md="6"><Button color="primary" onClick={() => selectStore(x)}>Select</Button></Col>
                  </Row>
               </CardFooter>
            </Card>
         </Col>
      )
   });

   const StoreForm = () => {

      if (selectedMarketplace.name === "Shopify") {
         return (
            <AddStoreShopify showFail={showFail} selectedMarketplace={selectedMarketplace} />
         )
      }
      else if (selectedMarketplace.name === "Zalora" || selectedMarketplace.name === "Zalora (MY)") {
         return (
            <AddStoreZalora2 showFail={showFail} selectedMarketplace={selectedMarketplace} />
         )
      }
      else if (selectedMarketplace.name === "Shopee"|| selectedMarketplace.name === "ShopeeV2") { 
         return (
            <AddStoreShopee showFail={showFail} selectedMarketplace={selectedMarketplace} />
         )
      }
      else if (selectedMarketplace.name.includes("Lazada")) {
         return (
            <AddStoreLazada showFail={showFail} selectedMarketplace={selectedMarketplace} />
         )
      }
      // else if (selectedMarketplace.name == "Magento (V2)") {
      //    return (
      //       <AddStoreMagento showFail={showFail} selectedMarketplace={selectedMarketplace} />
      //    )
      // }
      else if (selectedMarketplace.name == "WooCommerce") {
         return (
            <AddStoreWooCommerce showFail={showFail} selectedMarketplace={selectedMarketplace} />
         )
      }
      else if (selectedMarketplace.name == "Tiktok") {
         return (
            <AddStoreTiktok showFail={showFail} selectedMarketplace={selectedMarketplace} />
         )
      }
      else if (selectedMarketplace.name == "Magento2.0") {
         return (
            <AddStoreMagento showFail={showFail} selectedMarketplace={selectedMarketplace} />
         )
      }
      else if (selectedMarketplace.name == "Shopline") {
         return (
            <AddStoreShopline showFail={showFail} selectedMarketplace={selectedMarketplace} />
         )
      }
   }

   return (
      <div className="content">
         {alert}
         <Card>
            <CardHeader>
               <CardTitle tag="h4">Select Store</CardTitle>
            </CardHeader>
            <CardBody>
               <Row>
                  {Stores}
               </Row>
            </CardBody>
         </Card>
         <StoreForm />
      </div>
   )
}