import {useEffect, useState} from 'react'
import { isAuthorize } from "services/dataservice"

export function useAuth() {
   const [auth, setAuth] = useState(true);
   
   useEffect(() => {
      const checkIsAuth = async () => {
         try {
            const resp = await isAuthorize();
            setAuth(true);
         }
         catch {
            setAuth(false);
         }
      }

      checkIsAuth();
   });

   return auth;
}

function parseJwt (token) {
   var base64Url = token.split('.')[1];
   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
   var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
   }).join(''));

   return JSON.parse(jsonPayload);
};