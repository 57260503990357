import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row, Table } from "reactstrap";
import { Line, Bar, HorizontalBar } from "react-chartjs-2";
import { lineOptions, lineTimeOptions, barOptions, barTimeOptions } from "./chartOptions";
import { getSales3, getSalesByDate, getSalesByMarketplace, getSalesByMarketplaceAndDate } from "services/dataservice";

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export default function Report(props) {
  const [days, setDays] = useState(7);
  //date label
  const [dateLabel, setDateLabel] = useState("");
  //type of chart
  const [type, setType] = useState(props.location.state);
  //total sales
  const [salesData, setSalesData] = useState({});
  const [salesTimeData, setSalesTimeData] = useState({});
  const [totalSales, setTotalSales] = useState(0);
  //total counts
  const [ordersData, setOrdersData] = useState({});
  const [ordersTimeData, setOrdersTimeData] = useState({});
  const [totalOrders, setTotalOrders] = useState(0);
  //top sales
  const [orderDataGroupByMarketplace, setOrderDataGroupByMarketplace] = useState({});
  //table data
  const [tableData, setTableData] = useState([]);
  const [topSalesTableData, setTopSalesTableData] = useState([]);

  //custom date
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  useEffect(() => {
    fetchSalesByDays(7);
  }, []);

  function convertThousand(value) {
    function func(v) {
      const reg = /(\d+)(\d{3})/;
      let str = v.toString();
      while (reg.test(str)) {
        str = str.replace(reg, "$1,$2");
      }
      return str;
    }
    let amount = value.toFixed(2);
    let res = amount.substr(amount.indexOf(".") + 1, 2);
    amount=amount.substr(0, amount.length - 3);
    amount = func(amount);
    return `${amount}.${res}`;
  }
  const calcMonthToDate = () => {
    let currentDatetime = new Date();
    let day = currentDatetime.getDate();
    fetchSalesByDays(day);
  };

  function computedSalesData(data) {
    let amounts = data.map((x) => x.totalAmount);
    let totalAmounts = amounts.reduce((total, currentVal) => total + currentVal, 0);
    let orders = data.map((x) => x.totalOrder);
    let totalOrders = orders.reduce((total, currentVal) => total + currentVal, 0);

    let labels = data.map((x) => x.date);
    let chartSalesData = {
      labels,
      datasets: [
        {
          label: "Amount",
          borderColor: "#6bd098",
          pointHoverRadius: 5,
          pointStyle: "circle",
          pointBackgroundColor: "#6bd098",
          pointBorderWidth: 1,
          pointRadius: 5,
          fill: true,
          borderWidth: 3,
          data: amounts,
        },
      ],
    };

    let chartOrdersData = {
      labels,
      datasets: [
        {
          label: "Count",
          borderColor: "#6bd098",
          fill: true,
          borderWidth: 3,
          barPercentage: 0.4,
          data: orders,
        },
      ],
    };
    if (type === "total-sales") {
      setTotalSales(convertThousand(totalAmounts));
      setSalesData(chartSalesData);
    } else {
      setTotalOrders(totalOrders);
      setOrdersData(chartOrdersData);
    }

    let tableDataMapped = data.map((x) => {
      let dateObj = new Date(x.date);
      let day = dateObj.getDate();
      let m = dateObj.getMonth();
      let month = months[m];
      let year = dateObj.getFullYear();
      let dateString = `${month} ${day}, ${year}`;

      return {
        date: dateString,
        totalAmount: convertThousand(x.totalAmount),
        totalOrder: x.totalOrder,
      };
    });

    setTableData(tableDataMapped);
  }

  const fetchSalesByDays = (days) => {
    let count = 0;
    if (days === -1) {
      let dateObj = new Date();
      dateObj.setDate(0);
      count = dateObj.getDate();
      let month = months[dateObj.getMonth()];
      let year = dateObj.getFullYear();
      setDateLabel(`1 ${month}, ${year} - ${count} ${month}, ${year}`);
    } else if (days === 1) {
      let dateObj = new Date();
      count = days;
      setDateLabel(`${dateObj.getDate()} ${months[dateObj.getMonth()]}, ${dateObj.getFullYear()}`);
    } else {
      let eDate = new Date();
      let sDate = new Date();
      sDate.setDate(eDate.getDate() - (days - 1));
      let sDateLabel = `${sDate.getDate()} ${months[sDate.getMonth()]}, ${sDate.getFullYear()}`;
      let eDateLabel = `${eDate.getDate()} ${months[eDate.getMonth()]}, ${eDate.getFullYear()}`;
      setDateLabel(`${sDateLabel} - ${eDateLabel}`);
      count = days;
    }
    setDays(days);

    if (type === "total-sales" || type === "total-orders") {
      getSales3(days).then((res) => {
        if (days === 1) {
          let { data } = res;
          // console.log(data)
          let totalAmounts = 0;
          let totalOrders = 0;
          let labelsObj = {};

          for (let i = 0; i < 24; i++) {
            labelsObj[i] = {
              totalAmount: 0,
              totalOrder: 0,
            };
          }
          data.forEach((item) => {
            totalAmounts += item.totalAmount;
            totalOrders += item.totalOrder;
            labelsObj[item.date]["totalAmount"] = item.totalAmount;
            labelsObj[item.date]["totalOrder"] = item.totalOrder;
          });

          let labels = [];
          let amounts = [];
          let orders = [];
          let tableData = [];
          for (let key in labelsObj) {
            labels.push(`${key.toString().length > 1 ? key : `0${key}`}:00`);
            amounts.push(labelsObj[key].totalAmount);
            orders.push(labelsObj[key].totalOrder);
            tableData.push({
              date: `${key.toString().length > 1 ? key : `0${key}`}:00`,
              totalAmount: (labelsObj[key].totalAmount).toFixed(2),
              totalOrder: labelsObj[key].totalOrder,
            });
          }
          let chartSalesData = {
            labels,
            datasets: [
              {
                label: "Amount",
                borderColor: "#6bd098",
                pointHoverRadius: 5,
                pointStyle: "circle",
                pointBackgroundColor: "#6bd098",
                pointBorderWidth: 1,
                pointRadius: 5,
                fill: true,
                borderWidth: 3,
                data: amounts,
              },
            ],
          };
          let chartOrdersData = {
            labels,
            datasets: [
              {
                label: "Count",
                borderColor: "#6bd098",
                fill: true,
                borderWidth: 3,
                barPercentage: 0.4,
                data: orders,
              },
            ],
          };

          if (type === "total-sales") {
            setTotalSales(totalAmounts.toFixed(2));
            setSalesTimeData(chartSalesData);
          } else {
            setTotalOrders(totalOrders);
            setOrdersTimeData(chartOrdersData);
          }
          setTableData(tableData);
        } else {
          let { data } = res;
          computedSalesData(data);
        }
      });
    } else {
      getSalesByMarketplace(days).then((res) => {
        let { data } = res;
        computedSalesDataByMarketplace(data, count);
      });
    }
  };
  function computedSalesDataByMarketplace(data, count) {
    let amounts = data.map((x) => x.totalAmount);
    let totalAmounts = amounts.reduce((total, currentVal) => total + currentVal, 0);
    let marketplaceOrder = {
      labels: data.map((x) => x.name),
      datasets: [
        {
          label: "Sales Amount",
          borderColor: "#6bd098",
          pointRadius: 0,
          pointHoverRadius: 0,
          fill: true,
          borderWidth: 2,
          barPercentage: 0.8,
          data: amounts,
        },
      ],
    };
    let tableData = data.map((x) => {
      return {
        name: x.name,
        totalAmount: convertThousand(x.totalAmount),
        totalCount: x.totalCount,
        avgAmount: convertThousand(x.totalAmount / count),
      };
    });
    setOrderDataGroupByMarketplace(marketplaceOrder);
    setTopSalesTableData(tableData);
    setTotalSales(convertThousand(totalAmounts))
  }

  const fetchSalesByDate = () => {
    if (!startDate || !endDate) {
      alert("Please Select Date");
      return;
    }

    let sDate = new Date(startDate);
    let eDate = new Date(endDate);
    // console.log(sDate.getDate())
    if (sDate > eDate) {
      alert("Invalid Date Selection");
      return;
    }
    let sDateLabel = `${sDate.getDate()} ${months[sDate.getMonth()]}, ${sDate.getFullYear()}`;
    let eDateLabel = `${eDate.getDate()} ${months[eDate.getMonth()]}, ${eDate.getFullYear()}`;
    setDateLabel(`${sDateLabel} - ${eDateLabel}`);
    let startDateTS = sDate.getTime();
    let endDateTS = eDate.getTime();
    let diffDays = (endDateTS - startDateTS) / (1000 * 3600 * 24);

    setDays(diffDays + 1);

    if (type === "total-sales" || type === "total-orders") {
      getSalesByDate(startDate, endDate).then((res) => {
        let { data } = res;
        computedSalesData(data);
      });
    } else {
      getSalesByMarketplaceAndDate(startDate, endDate).then((res) => {
        let { data } = res;
        computedSalesDataByMarketplace(data, diffDays + 1);
      });
    }
  };
  return (
    <div className="content">
      <div className="pull-Left">
        <Button color="success" onClick={() => fetchSalesByDays(1)} size="sm">
          Today
        </Button>
        <Button color="success" onClick={() => fetchSalesByDays(7)} size="sm">
          Last 7 Days
        </Button>
        <Button color="success" onClick={() => fetchSalesByDays(30)} size="sm">
          Last 30 Days
        </Button>
        <Button color="success" onClick={() => calcMonthToDate()} size="sm">
          Month To Date
        </Button>
        <Button color="success" onClick={() => fetchSalesByDays(-1)} size="sm">
          Last Month
        </Button>
        <Button color="success" size="sm" style={{ padding: "3px 15px", cursor: "default" }}>
          <input
            type="date"
            style={{ border: "1px solid transparent", borderRadius: "3px", backgroundColor: "#6bd098", color: "white", fontWeight: 600 }}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <span style={{ margin: "0 10px" }}>to</span>
          <input
            type="date"
            style={{ border: "1px solid transparent", borderRadius: "3px", backgroundColor: "#6bd098", color: "white", fontWeight: 600 }}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <span style={{ marginLeft: "10px", color: "#126246", cursor: "pointer" }} onClick={fetchSalesByDate}>
            Apply
          </span>
        </Button>
      </div>
      <CardTitle tag="h6" style={{ fontStyle: "italic" }}>
        Information shown from {dateLabel}
      </CardTitle>
      <Row>
        {(type === "total-sales" && (
          <Col>
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="7">
                    <div className="pull-left">
                      <h6>
                        <strong>Total Sales</strong>
                      </h6>
                      <h5>
                        <strong>MYR {totalSales}</strong>
                      </h5>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="7">
                    <h6>SALES OVER TIME</h6>
                  </Col>
                </Row>
                <div style={{ height: "380px" }}>
                  {(days === -1 || days > 1) && <Line data={salesData} options={lineOptions} height={380} width={826} />}
                  {days === 1 && <Line data={salesTimeData} options={lineTimeOptions} height={380} width={826} />}
                </div>
              </CardBody>
            </Card>
          </Col>
        )) ||
          (type === "total-orders" && (
            <Col>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <div className="pull-left">
                        <h6>
                          <strong>Total Orders</strong>
                        </h6>
                        <h5>
                          <strong>{totalOrders}</strong>
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="7">
                      <h6>ORDER OVER TIME</h6>
                    </Col>
                  </Row>
                  <div style={{ height: "380px" }}>
                    {(days === -1 || days > 1) && <Bar data={ordersData} options={barOptions} height={380} width={826} />}
                    {days === 1 && <Bar data={ordersTimeData} options={barTimeOptions} height={380} width={826} />}
                  </div>
                </CardBody>
              </Card>
            </Col>
          )) ||
          (type === "top-sales" && (
            <Col>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="7">
                      <div className="pull-left">
                        <h6>
                          <strong>Top Sales By Store</strong>
                        </h6>
                        <h5>
                          <strong>MYR {totalSales}</strong>
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="7">
                      <h6>SALES SHARE BY STORES</h6>
                    </Col>
                  </Row>
                  <div style={{ height: "380px" }}>
                    <HorizontalBar data={orderDataGroupByMarketplace} options={{ maintainAspectRatio: false }} height={380} width={826} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
      </Row>
      <Card>
        <CardBody>
          {type === "top-sales" ? (
            <Table striped>
              <thead>
                <tr>
                  <th>Store Name</th>
                  <th>No. of Order</th>
                  <th>Avg. Sale per Day</th>
                  <th>Sale</th>
                </tr>
              </thead>
              <tbody>
                {topSalesTableData.map((t, index) => (
                  <tr key={index}>
                    <td>{t.name}</td>
                    <td>{t.totalCount}</td>
                    <td>MYR {t.avgAmount}</td>
                    <td>MYR {t.totalAmount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <Table striped>
              <thead>
                <tr>
                  <th>Date/Time</th>
                  <th>No. of Order</th>
                  <th>Sale</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((t, index) => (
                  <tr key={index}>
                    <td>{t.date}</td>
                    <td>{t.totalOrder}</td>
                    <td>MYR {t.totalAmount}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </div>
  );
}
