import React, { memo } from "react";
import { Button, CardBody, CardTitle, Col, FormGroup, Input, Row } from "reactstrap";
import { SelectAntd } from "views/product/ProductAdd/Components/AntdComponent";
import OptionName from "./OptionName";

export default memo(function Options2(props) {
  let { optionList, setOptionList, optionImages, setOptionImages, options, setOptions, objOptions, variations, setVariations, showFail, platformName } = props;
  // console.log(platformName)
  // console.log(options)
  const shopifyValueChange = (value, index) => {
    if (value.length < 1) return;
    let optionsClone = [...options];
    optionsClone[index]["value"] = value;
    setOptions(optionsClone);
  };

  const zaloraValueChange = (value, index) => {
    if (value.length < 1) return;
    let optionsClone = [...options];
    optionsClone[index]["value"] = value;
    setOptions(optionsClone);
  };

  const selectValueChange = (value, index) => {
    if (value.length < 1) return;
    let optionsClone = [...options];
    optionsClone[index]["value"] = value;
    setOptions(optionsClone);
  };

  const generateSku = () => {
    // checking
    let validate = true;

    options.forEach((option) => {
      option.value.forEach((v) => {
        if (v.trim().length === 0 || v === "Undefined") {
          validate = false;
        }
      });
    });

    if (!validate) {
      showFail("Option value cannot be null/empty/undefined");
      return;
    }
    //optionList  = options

    let oListMapped = optionList.map((item, index) => {
      // return (item = options[index].value.map((v) => v));

      return {
        name: options[index].name,
        value: options[index].value.map((v) => v),
      };
    });

    setOptionList(oListMapped);

    if(platformName!=="Zalora"){
      let optionImagesClone = { ...optionImages };
      oListMapped[0].value.forEach((item) => {
        if (!(item in optionImages)) {
          optionImagesClone[item] = [];
          console.log(`${item} not in optionImages`);
        }
      });
      setOptionImages(optionImagesClone);
    }
     

    // return;
    let variationTemplate = {
      inventorySKU: "",
      inventorySKUState: true,
      inventorySKUFound: false,
      sellingPrice: "",
      sellingPriceState: true,
      // quantity: "",
      // costPrice: "",
      barcode1: "",
      barcode1State: true,
      barcode2: "",
      weight: "1",
      weightState: true,
      height: "1",
      heightState: true,
      width: "1",
      widthState: true,
      length: "",
      lengthState: true,
      active: true,
    };

    let variationsGenerated = [];

    if (options.length === 1) {
      variationsGenerated = options[0].value.map((item) => {
        let optionList = [];
        // let option1 = item
        optionList.push({ oValue: item });
        let variant = item;
        return {
          ...variationTemplate,
          optionList,
          // option1,
          variant,
        };
      });
    } else {
      variationsGenerated = options[0].value.flatMap((item) =>
        options[1].value.map((subItem) => {
          let optionList = [];
          // let option1 = item;
          // let option2 = subItem;
          optionList.push({ oValue: item }, { oValue: subItem });
          let variant = `${item}/${subItem}`;
          return {
            ...variationTemplate,
            optionList,
            // option1,
            // option2,
            variant,
          };
        })
      );
    }

    // current existing variant
    console.log(variationsGenerated);

    // [...,"blue"]
    // [...,"purple"]

    let generatedVariant = variationsGenerated.map((item) => item.variant);
    let variant = variations.map((item) => item.variant);
    console.log("current:", variant);
    // compare variationGenerated and variant to find whether have new variant
    let newVariant = variationsGenerated.filter((item) => !variant.includes(item.variant));
    console.log("new variant:", newVariant);
    // if newVariant.length more than 0 means newVariant exist
    if (newVariant.length > 0) {
      let variationsClone = [...variations];
      // push in variations
      let variationsCloneFiltered = variationsClone.filter((item) => generatedVariant.includes(item.variant));

      variationsCloneFiltered.push(...newVariant);
      variationsCloneFiltered.sort((e1, e2) => (e1.variant > e2.variant ? 1 : e2.variant > e1.variant ? -1 : 0));
      console.log(variationsCloneFiltered);
      setVariations(variationsCloneFiltered);
    } else {
      let variationsClone = [...variations];
      // generated variant
      let remainVariant = variationsGenerated.map((item) => item.variant);
      // return only the variant that exist in generated variant
      let finalVariant = variationsClone.filter((item) => remainVariant.includes(item.variant));

      setVariations(finalVariant);
    }
  };

  // let o = [
  //   {
  //     value: [
  //       {
  //         url: "",
  //         new: "",
  //         name: "",
  //       },
  //     ],
  //     option: "red",
  //   },
  //   {
  //     value: [
  //       {
  //         url: "",
  //         new: "",
  //         name: "",
  //       },
  //     ],
  //     option: "blue",
  //   },
  // ];

  const optionNameProps = {
    options,setOptions,
    platformName,
    optionList,
    setOptionList,
    optionImages,
    setOptionImages,
    variations,
    setVariations,
    objOptions,
    showFail,
  };
  return (
    <CardBody>
      <CardBody style={{ border: "1px solid #e3e3e3", borderRadius: "8px" }}>
        <CardTitle tag="h6" style={{ textDecoration: "underline" }}>
          Options
        </CardTitle>

        {/* {(platformName === "Shopify" && (
          <Row form>
            {options.map((option, index) => (
              <Col key={option.name}>
                <FormGroup>
                  <Input type="text" value={option.name} disabled />
                  <div style={{ marginTop: "10px" }}>
                    <SelectAntd mode="tags" allowClear={false} value={option.value} onChange={(value) => selectValueChange(value, index)} />
                  </div>
                </FormGroup>
              </Col>
            ))}
          </Row>
        )) ||
          (platformName === "Lazada" && (
            <Row form>
              {options.map((option, index) => (
                <Col key={option.name}>
                  <FormGroup>
                    <Input type="text" value={option.name} disabled />
                    <div style={{ marginTop: "10px" }}>
                      {objOptions[option.name]?.length > 0 ? (
                        <SelectAntd mode="multiple" data={objOptions[option.name]} allowClear={false} value={option.value} onChange={(value) => selectValueChange(value, index)} />
                      ) : (
                        <SelectAntd mode="tags" allowClear={false} value={option.value} onChange={(value) => selectValueChange(value, index)} />
                      )}
                    </div>
                  </FormGroup>
                </Col>
              ))}
            </Row>
          )) ||
          (platformName === "Zalora" && (
            <Row form>
              {options.map((option, index) => (
                <Col key={option.name}>
                  <FormGroup>
                    <Input type="text" value={option.name} disabled />
                    <div style={{ marginTop: "10px" }}>
                      <SelectAntd
                        mode="multiple"
                        data={option.name === "Size" ? objOptions["Variation"] : objOptions["ColorFamily"]}
                        allowClear={false}
                        value={option.value}
                        onChange={(value) => selectValueChange(value, index)}
                      />
                    </div>
                  </FormGroup>
                </Col>
              ))}
            </Row>
          ))} */}

        <Row form>
          {options.map((option, index) => (
            <Col key={option.name}>
              <FormGroup>
                <Input type="text" value={option.name} disabled />{" "}
                {((platformName === "Shopify" || platformName === "ShopeeV2") && (
                  <div style={{ marginTop: "10px" }}>
                    <SelectAntd mode="tags" allowClear={false} value={option.value} onChange={(value) => selectValueChange(value, index)} />
                  </div>
                )) ||
                  (platformName === "Lazada" && (
                    <div style={{ marginTop: "10px" }}>
                      {objOptions[option.name]?.length > 0 ? (
                        <SelectAntd mode="multiple" data={objOptions[option.name]} allowClear={false} value={option.value} onChange={(value) => selectValueChange(value, index)} />
                      ) : (
                        <SelectAntd mode="tags" allowClear={false} value={option.value} onChange={(value) => selectValueChange(value, index)} />
                      )}
                    </div>
                  )) ||
                  (platformName === "Zalora" && (
                    <div style={{ marginTop: "10px" }}>
                      <SelectAntd
                        mode="multiple"
                        placeholder="Multi Select"
                        data={option.name === "Size" ? objOptions["Variation"] : objOptions["ColorFamily"]}
                        allowClear={false}
                        value={option.value}
                        onChange={(value) => selectValueChange(value, index)}
                      />
                    </div>
                  ))}
              </FormGroup>
            </Col>
          ))}
        </Row>

        <Button color="info" style={{ backgroundColor: "#5170da" }} onClick={generateSku}>
          Generate Sku
        </Button>
        <OptionName {...optionNameProps} />
      </CardBody>
    </CardBody>
  );
});
