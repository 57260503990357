/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Buttons from "views/components/Buttons.js";
import Calendar from "views/Calendar.js";
import Charts from "views/Charts.js";
// import Dashboard from "views/Dashboard.js";
// import Dashboard2 from "views/Dashboard2.js";
import Dashboard from "views/dashboard/Dashboard.js"
import Report from "views/dashboard/Report.js"
import ExtendedForms from "views/forms/ExtendedForms.js";
import ExtendedTables from "views/tables/ExtendedTables.js";
import FullScreenMap from "views/maps/FullScreenMap.js";
import GoogleMaps from "views/maps/GoogleMaps.js";
import GridSystem from "views/components/GridSystem.js";
import Icons from "views/components/Icons.js";
import LockScreen from "views/pages/LockScreen.js";
import Login from "views/pages/Login.js";
import Notifications from "views/components/Notifications.js";
import Panels from "views/components/Panels.js";
import ReactTables from "views/tables/ReactTables.js";
import Register from "views/pages/Register.js";
import RegularForms from "views/forms/RegularForms.js";
import RegularTables from "views/tables/RegularTables.js";
import SweetAlert from "views/components/SweetAlert.js";
import Timeline from "views/pages/Timeline.js";
import Typography from "views/components/Typography.js";
import UserProfile from "views/pages/UserProfile.js";
import ValidationForms from "views/forms/ValidationForms.js";
import VectorMap from "views/maps/VectorMap.js";
import Widgets from "views/Widgets.js";
import Wizard from "views/forms/Wizard.js";

//Product
import ProductAdd from "views/product/ProductAdd";
//import ShopifyProductAdd from "views/product/ProductAdd/Shopify2/ShopifyProductAdd";
import ProductList from "views/product/ProductList";
// import Shopify3 from "views/product/ProductAdd2/Shopify/ShopifyProductAdd"
//import Shopee3 from "views/product/ProductAdd2/Shopee2/ShopeeProductAdd"
import ShopifyProductDetail from "views/product/ProductDetail/Shopify/ShopifyProductDetail";
import LazadaProductDetail from "views/product/ProductDetail/Lazada/LazadaProductDetail";
import ZaloraProductDetail from "views/product/ProductDetail/Zalora/ZaloraProductDetail";
import ShopeeProductDetail from "views/product/ProductDetail/Shopee/ShopeeProductDetail";

import InventoryAdd from "views/inventory/InventoryAdd"
import InventoryList from "views/inventory/InventoryList"
import InventoryUpload from "views/inventory/InventoryUpload"
import InventoryPreOrder from "views/inventory/InventoryPreOrder"

import OrderUpload from "views/order/OrderUpload"
import OrderList from "views/order/OrderList"
import OrderAdd from "views/order/OrderAdd";
import OrderPrint from "views/order/OrderPrint"
import Integration from "views/integrations/Integration"
import MarketplaceList from "views/marketplace/MarketplaceList"
import UserRegister from "views/user/UserRegister"
import AddStore from "views/integrations/AddStore";
import OrderDetail from "views/order/OrderDetail";
import OrderExport from "views/order/OrderExport"

import FailedOrderList from "views/order/FailedOrderList"

import UomManagement from "views/management/UomManagement";
import ChangePassword from "views/user/ChangePassword";
import CatalogueKit from "views/marketplace/CatalogueKit"
import CatalogueDetail from "views/marketplace/CatalogueDetail"
import { EditStore } from "views/integrations/EditStore";

import PromotionList from "views/promotion/PromotionList";
import PromotionAdd from "views/promotion/PromotionAdd";
import PromotionDetail from "views/promotion/PromotionDetail";

import CatalogueKitUpload from "views/marketplace/CatalogueKitUpload";
import SplitInventoryList from "views/split-inventory/SplitInventoryList";
import SplitInventoryAdd from "views/split-inventory/Add";
import SplitInventoryEdit from "views/split-inventory/Edit";

const routes = [
  // {
  //   path: "/oridashboard",
  //   name: "oriDashboard",
  //   icon: "nc-icon nc-bank",
  //   component: Dashboard2,
  //   layout: "/admin",
  //   invisible: false
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
    invisible: false
  },
  {
    path: "/report",
    name: "Report",
    icon: "nc-icon nc-chart-bar-32",
    component: Report,
    layout: "/admin",
    invisible: true
  },
  
  {
    path: "/order/list",
    name: "Orders",
    icon: "nc-icon nc-box-2",
    component: OrderList,
    layout: "/admin",
    invisible: false
  },
  {
    path: "/order/failedlist",
    name: "Failed Orders",
    icon: "nc-icon nc-box-2",
    component: FailedOrderList,
    layout: "/admin",
    invisible: false
  },
  {
    path: "/order/upload",
    name: "Orders Upload",
    icon: "nc-icon nc-box-2",
    component: OrderUpload,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/order/export",
    name: "Orders Export",
    icon: "nc-icon nc-box-2",
    component: OrderExport,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/order/print",
    name: "Orders Print",
    icon: "nc-icon nc-box-2",
    component: OrderPrint,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/product/list",
    name: "Product List (Beta)",
    icon: "nc-icon nc-box-2",
    component: ProductList,
    layout: "/admin",
    invisible: false,
  },
  {
    path: "/product/add",
    name: "Product Add",
    icon: "nc-icon nc-box-2",
    component: ProductAdd,
    layout: "/admin",
    invisible: true,
  },
  // {
  //   path: "/product/add-shopify",
  //   name: "Product Add-shopify",
  //   icon: "nc-icon nc-box-2",
  //   component: ShopifyProductAdd,
  //   layout: "/admin",
  //   invisible: true,
  // },
  // {
  //   path: "/product/shopify3",
  //   name: "Shopify 3",
  //   icon: "nc-icon nc-box-2",
  //   component: Shopify3,
  //   layout: "/admin",
  //   invisible: true,
  // },
  // {
  //   path: "/product/shopee3",
  //   name: "Shopee 3",
  //   icon: "nc-icon nc-box-2",
  //   component: Shopee3,
  //   layout: "/admin",
  //   invisible: true,
  // },
  {
    path: "/product/shopify/detail/:id",
    name: "Shopify Product Detail",
    icon: "nc-icon nc-box-2",
    component: ShopifyProductDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/product/lazada/detail/:id",
    name: "Lazada Product Detail",
    icon: "nc-icon nc-box-2",
    component: LazadaProductDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/product/zalora/detail/:id",
    name: "Zalora Product Detail",
    icon: "nc-icon nc-box-2",
    component: ZaloraProductDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/product/shopeev2/detail/:id",
    name: "Shopee Product Detail",
    icon: "nc-icon nc-box-2",
    component: ShopeeProductDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/inventory/list",
    name: "Inventory List",
    icon: "nc-icon nc-paper",
    component: InventoryList,
    layout: "/admin",
    invisible: false
  },
  {
    path: "/inventory/upload",
    name: "Inventory Upload",
    icon: "nc-icon nc-paper",
    component: InventoryUpload,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/inventory/add-pre-order",
    name: "Inventory Pre Order",
    icon: "nc-icon nc-paper",
    component: InventoryPreOrder,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/split-inventory/list",
    name: "Split Inventory List",
    icon: "nc-icon nc-paper",
    component: SplitInventoryList,
    layout: "/admin",
    invisible: false
  },
  {
    path: "/split-inventory/add",
    name: "Split Inventory Add",
    icon: "nc-icon nc-paper",
    component: SplitInventoryAdd,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/split-inventory/edit",
    name: "Split Inventory Edit",
    icon: "nc-icon nc-paper",
    component: SplitInventoryEdit,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/promotion/list",
    name: "Promotion List",
    icon: "nc-icon nc-paper",
    component: PromotionList,
    layout: "/admin",
    invisible: false
  },
  {
    path: "/promotion/add",
    name: "Add Promotion",
    icon: " nc-icon nc-paper",
    component: PromotionAdd,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/promotion/detail/:action/:id",
    name: "Promotion Detail",
    icon: "nc-icon nc-box-2",
    component: PromotionDetail,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/marketplace/list",
    name: "Marketplace",
    icon: "nc-icon nc-shop",
    component: MarketplaceList,
    layout: "/admin",
    invisible: false
  },
  {
    path: "/integration/list",
    name: "Integration",
    icon: "nc-icon nc-vector",
    component: Integration,
    layout: "/admin",
    invisible: false
  },
  {
    path: "/integration/edit/:id",
    name: "Integration",
    icon: "nc-icon nc-vector",
    component: EditStore,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/store/add",
    name: "Add Store",
    icon: "nc-icon nc-vector",
    component: AddStore,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/inventory/add",
    name: "Add Inventory",
    icon: "nc-icon nc-bank",
    component: InventoryAdd,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/inventory/edit/:id",
    name: "Edit Inventory",
    icon: "nc-icon nc-bank",
    component: InventoryAdd,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/order/add",
    name: "Add Order",
    icon: "nc-icon nc-box-2",
    component: OrderAdd,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/order/detail/:id",
    name: "Order Detail",
    icon: "nc-icon nc-box-2",
    component: OrderDetail,
    layout: "/admin",
    invisible: true
  },
  {
    path: "/user/changepassword",
    name: "Change Password",
    icon: "nc-icon nc-box-2",
    component: ChangePassword,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/catalogue/addkit",
    name: "Kit Add",
    icon: "nc-icon nc-box-2",
    component: CatalogueKit,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/catalogue/editkit/:id",
    name: "Kit Add",
    icon: "nc-icon nc-box-2",
    component: CatalogueKit,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/catalogue/detail/:id",
    name: "Catalogue Detail",
    icon: "nc-icon nc-box-2",
    component: CatalogueDetail,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/catalogue/uploadkit",
    name: "Kit Upload",
    icon: "nc-icon nc-box-2",
    component: CatalogueKitUpload,
    layout: "/admin",
    invisible: true,
  },
  {
    collapse: true,
    name: "Management",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    invisible: true,
    views: [
      {
        path: "/management/uom",
        name: "UOM Management",
        mini: "U",
        component: UomManagement,
        layout: "/admin"
      },
      {
        path: "/user/add",
        name: "Add User",
        mini: "A",
        component: UserRegister,
        layout: "/admin"
      },
    ]
  }
  ,
  {
    collapse: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    invisible: true,
    views: [
      {
        path: "/timeline",
        name: "Timeline",
        mini: "T",
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/lock-screen",
        name: "LockScreen",
        mini: "LS",
        component: LockScreen,
        layout: "/auth",
      },
      {
        path: "/user-profile",
        name: "UserProfile",
        mini: "UP",
        component: UserProfile,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    invisible: true,
    name: "Components",
    icon: "nc-icon nc-layout-11",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        mini: "B",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/grid-system",
        name: "Grid System",
        mini: "GS",
        component: GridSystem,
        layout: "/admin",
      },
      {
        path: "/panels",
        name: "Panels",
        mini: "P",
        component: Panels,
        layout: "/admin",
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        mini: "SA",
        component: SweetAlert,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        mini: "N",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        mini: "I",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        mini: "T",
        component: Typography,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    invisible: true,
    name: "Forms",
    icon: "nc-icon nc-ruler-pencil",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        mini: "RF",
        component: RegularForms,
        layout: "/admin",
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        mini: "EF",
        component: ExtendedForms,
        layout: "/admin",
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        mini: "VF",
        component: ValidationForms,
        layout: "/admin",
      },
      {
        path: "/wizard",
        name: "Wizard",
        mini: "W",
        component: Wizard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    invisible: true,
    name: "Tables",
    icon: "nc-icon nc-single-copy-04",
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        mini: "RT",
        component: RegularTables,
        layout: "/admin",
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        mini: "ET",
        component: ExtendedTables,
        layout: "/admin",
      },
      {
        path: "/react-tables",
        name: "React Tables",
        mini: "RT",
        component: ReactTables,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    invisible: true,
    name: "Maps",
    icon: "nc-icon nc-pin-3",
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        mini: "GM",
        component: GoogleMaps,
        layout: "/admin",
      },
      {
        path: "/full-screen-map",
        name: "Full Screen Map",
        mini: "FSM",
        component: FullScreenMap,
        layout: "/admin",
      },
      {
        path: "/vector-map",
        name: "Vector Map",
        mini: "VM",
        component: VectorMap,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    invisible: true,
    name: "Widgets",
    icon: "nc-icon nc-box",
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/charts",
    invisible: true,
    name: "Charts",
    icon: "nc-icon nc-chart-bar-32",
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    invisible: true,
    name: "Calendar",
    icon: "nc-icon nc-calendar-60",
    component: Calendar,
    layout: "/admin",
  },
];


export default routes;
