import React, { useEffect, memo } from "react";
import { Button, CardBody, CardTitle, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Card, Checkbox, Switch } from "antd";
import { getMarketplaceLogistics } from "services/dataservice";

import styled from "styled-components";

export default memo(function Shipping({ marketplaces, logisticInfo, setLogisticInfo }) {
  // useEffect(() => {
  //   const getLogistics = async () => {
  //     try {
  //       let response = await getMarketplaceLogistics(marketplaces[0].value);
  //       // console.log(response);
  //       if (response.status === 200) {
  //         // console.log(response);
  //         let { logistics_channel_list } = response.data.response;
  //         let formattedList = formatShopeeLogisticsChannelList(logistics_channel_list);
  //         let logisticInfoClone = { ...logisticInfo };
  //         logisticInfoClone["logistics_channel_list"] = formattedList;
  //         setLogisticInfo(logisticInfoClone);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getLogistics();
  // }, []);

  // function formatShopeeLogisticsChannelList(logisticsChannelList) {
  //   return logisticsChannelList.map((channel) => {
  //     let { logistics_channel_id, logistics_channel_name, logistics_description, enabled, weight_limit, volume_limit, fee_type } = channel;
  //     if (channel.fee_type === "SIZE_INPUT") {
  //       return {
  //         logistics_channel_id,
  //         logistics_channel_name,
  //         logistics_description,
  //         enabled,
  //         fee_type,
  //         weight_limit,
  //         volume_limit,
  //         is_free: false,
  //         default_price: "8",
  //         shipping_fee: "8",
  //       };
  //     } else {
  //       return {
  //         logistics_channel_id,
  //         logistics_channel_name,
  //         logistics_description,
  //         enabled,
  //         fee_type,
  //         weight_limit,
  //         volume_limit,
  //         is_free: false,
  //         default_price: "0",
  //         shipping_fee: "0",
  //       };
  //     }
  //   });
  // }

  // function formatShopeeLogisticInfo(logisticInfo) {}
  // const [log, setLog] = useState({
  //   logistics_channel_list: [
  //     {
  //       logistics_channel_id: 20011,
  //       logistics_channel_name: "J&T Express",
  //       cod_enabled: true,
  //       enabled: true,
  //       fee_type: "SIZE_INPUT",
  //       size_list: [],
  //       weight_limit: {
  //         item_max_weight: 50,
  //         item_min_weight: 0,
  //       },
  //       item_max_dimension: {
  //         height: 0,
  //         width: 0,
  //         length: 0,
  //         unit: "",
  //         dimension_sum: 0,
  //       },
  //       preferred: false,
  //       force_enable: false,
  //       mask_channel_id: 0,
  //       logistics_description:
  //         "Enable this shipping option if you want to offer shipping via J&T Express and enjoy the preferred shipping rates. If you have joined our RM15 / RM40 Free Shipping Program, your buyers can get Free shipping up to 3KG for deliveries to West Malaysia and up to 1kg for deliveries to East Malaysia!<br><br>1. Make sure you have a valid pick-up address set and that there is a J&T Express branch near you.<br>2. The product weight and/or dimensions you key in will be used to calculate the estimated shipping fee.<br>3. Shopee rebate is capped for West Malaysia and East Malaysia regardless of posting location. Please refer to the FAQ for more info.<br>4. Booking cut-off time for same day pick-up (Mon-Sun) is at 11:00am.<br>5. You will need to arrange shipment for your J&T Express parcels and print out the AWBs via Seller Centre to fulfil your order.<br><br>Please email help@support.shopee.com.my or call 03-27779222 if you have any queries on the Shopee X J&T Express Supported Logistics Program.",
  //       volume_limit: {
  //         item_max_volume: 300000,
  //         item_min_volume: 0,
  //       },
  //     },
  //     {
  //       logistics_channel_id: 20020,
  //       logistics_channel_name: "Poslaju (Free Return)",
  //       cod_enabled: false,
  //       enabled: true,
  //       fee_type: "CUSTOM_PRICE",
  //       size_list: [],
  //       weight_limit: {
  //         item_max_weight: 30,
  //         item_min_weight: 0,
  //       },
  //       item_max_dimension: {
  //         height: 0,
  //         width: 0,
  //         length: 0,
  //         unit: "",
  //         dimension_sum: 0,
  //       },
  //       preferred: true,
  //       force_enable: false,
  //       mask_channel_id: 0,
  //       logistics_description: "",
  //       volume_limit: {
  //         item_max_volume: 180000,
  //         item_min_volume: 0,
  //       },
  //     },
  //   ],
  // });

  const switchChange = (bool, index) => {
    let logisticInfoClone = { ...logisticInfo };
    logisticInfoClone.logistics_channel_list[index]["enabled"] = bool;
    setLogisticInfo(logisticInfoClone);
  };
  const checkboxChange = (e, index) => {
    let { checked } = e.target;
    let logisticInfoClone = { ...logisticInfo };
    if (checked) {
      logisticInfoClone.logistics_channel_list[index]["shipping_fee"] = "0";
    } else {
      logisticInfoClone.logistics_channel_list[index]["shipping_fee"] = logisticInfoClone.logistics_channel_list[index]["default_price"];
    }
    let isFree = logisticInfoClone.logistics_channel_list[index]["is_free"];
    logisticInfoClone.logistics_channel_list[index]["is_free"] = !isFree;
    setLogisticInfo(logisticInfoClone);
  };

  const customPriceChange = (e, index) => {
    let { value } = e.target;
    let logisticInfoClone = { ...logisticInfo };
    if (value.length === 0) {
      logisticInfoClone.logistics_channel_list[index]["shipping_fee"] = logisticInfoClone.logistics_channel_list[index]["default_price"];
    } else {
      logisticInfoClone.logistics_channel_list[index]["shipping_fee"] = value;
    }

    setLogisticInfo(logisticInfoClone);
  };
  const shippingChange = (e) => {
    let { value, name } = e.target;
    let logisticInfoClone = { ...logisticInfo };
    if (name === "weight") {
      logisticInfoClone[name] = value;
      logisticInfoClone[`${name}State`] = true;
    } else {
      if (!value) {
        logisticInfoClone[name] = "";
      } else {
        logisticInfoClone[name] = parseInt(value);
      }
    }
    // if (name === "weight") {
    // logisticInfoClone[`${name}State`] = true;
    // }
    setLogisticInfo(logisticInfoClone);
    // console.log(parseInt(value));
    // if (parseFloat(value) > 30) {
    // console.log("great")
    // }
  };
  return (
    <CardBody>
      <CardBody style={{ border: "1px solid #e3e3e3", borderRadius: "8px" }}>
        <CardTitle tag="h6" style={{ textDecoration: "underline" }}>
          Shipping
        </CardTitle>

        <Row form>
          <Col>
            <FormGroup>
              <Label>
                Weight (kg) <Span>*</Span>
              </Label>
              <Input type="number" step="any" value={logisticInfo.weight} name="weight" min="0.1" onChange={(e) => shippingChange(e)} {...(!logisticInfo.weightState && { invalid: true })} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                Parcel Size (Width) cm 
              </Label>
              <Input type="number" step="any" value={logisticInfo.width} name="width" min="1" onChange={(e) => shippingChange(e)} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                Parcel Size (Length) cm 
              </Label>
              <Input type="number" step="any" value={logisticInfo.length} name="length" min="1" onChange={(e) => shippingChange(e)} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>
                Parcel Size (Height) cm 
              </Label>
              <Input type="number" step="any" value={logisticInfo.height} name="height" min="1" onChange={(e) => shippingChange(e)} />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label>
            Shipping Fee <Span>*</Span>
          </Label>
          <Card style={{ borderColor: "#dddddd" }}>
            <P>Enable at least one shipping option</P>

            {logisticInfo.logistics_channel_list.map((logistic, index) => (
              <Row key={logistic.logistics_channel_id} {...(!(logisticInfo.logistics_channel_list.length - 1 === index) && { style: { marginBottom: "15px" } })}>
                <Col md="6">
                  <Label style={{ fontWeight: "bold" }}>
                    {logistic.logistics_channel_name} (max {logistic.weight_limit.item_max_weight}kg)
                  </Label>
                </Col>
                <Col>
                  <Switch
                    checked={logistic.enabled}
                    onChange={(bool) => switchChange(bool, index)}
                    {...(logistic.enabled === false && parseFloat(logisticInfo.weight) > logistic.weight_limit.item_max_weight && { disabled: true })}
                  />
                  <Label style={{ marginLeft: "20px", color: "#df5a1a", fontWeight: "bold" }}>
                    {/* {(parseFloat(logisticInfo.weight) > logistic.weight_limit.item_max_weight && "Overweight") ||
                      (logistic.fee_type === "CUSTOM_PRICE" ? `RM${parseFloat(logistic.shipping_fee).toFixed(2)}` : `RM${parseFloat(logistic.price).toFixed(2)}`)} */}

                    {(parseFloat(logisticInfo.weight) > logistic.weight_limit.item_max_weight && "Overweight") || `RM${parseFloat(logistic.shipping_fee).toFixed(2)}`}
                  </Label>
                  {/* <Checkbox disabled={!logistic.enabled} onChange={(e) => checkboxChange(e, index)}>
                    cover shipping fee?
                  </Checkbox>
                  {logistic.fee_type === "CUSTOM_PRICE" && <Input type="number" step="any" placeholder="Custom Price" onChange={(e) => customPriceChange(e, index)} />} */}
                </Col>
                <Col>
                  <Checkbox disabled={!logistic.enabled} onChange={(e) => checkboxChange(e, index)}>
                    cover shipping fee?
                  </Checkbox>
                  {logistic.fee_type === "CUSTOM_PRICE" && (
                    <Col>
                      <Label style={{ color: "#cf31cf", fontWeight: "bold" }}>{logistic.logistics_channel_name} Custom Fee</Label>
                      <Input
                        type="number"
                        step="any"
                        placeholder="Custom Price"
                        value={logistic.shipping_fee}
                        onChange={(e) => customPriceChange(e, index)}
                        disabled={!logistic.enabled || logistic.is_free}
                      />
                    </Col>
                  )}
                </Col>
              </Row>
            ))}
          </Card>
        </FormGroup>
      </CardBody>

      {/* <Button onClick={() => console.log(logisticInfo)}>Show Shipping Info In Console</Button> */}
    </CardBody>
  );
});

const Span = styled.span`
  color: red;
  font-style: italic;
  font-weight: bold;
`;

const P = styled.p`
  color: blue;
  font-style: italic;
  font-weight: bold;
`;
