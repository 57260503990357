import React from "react";
import { Button, CardFooter } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import { editProduct  } from "services/dataservice";
import { formatImages,  formatAttributesValue } from "views/product/common";

export default function EditFormSubmit(props) {
  const history = useHistory();
  let {
    setAlert,
    product,
    brand,
    setBrand,
    basicInformation,
    parentSKU,
    productImages,
    // attrMandatoryNormals,
    // setAttrMandatoryNormals,
    // attrKeys,
    attrNormals,
    setAttrNormals,
    // packageContent,
    optionImages,
    optionList,
    variations,
    setVariations,
    showFail,
  } = props;

  const handleSubmit = async () => {
    let variationsClone = [...variations];
    // let attrMandatoryNormalsClone = [...attrMandatoryNormals];
    let attrNormalsClone = [...attrNormals];
    // let validateImage = true;
    let validateVariations = true;
    let validateAttributes = true;

    if (basicInformation.productName.trim() === "" || basicInformation.description.trim() === "") {
      showFail("Product name / description cannot be empty");
      return;
    }

    // if (productImages.length === 0) {
    //   showFail("Product must have at least one image");
    //   return;
    // }

    if (brand.value.trim() === "") {
      console.log("product brand");
      let brandClone = { ...brand };
      brandClone.state = false;
      setBrand(brandClone);
      validateAttributes = false;
    }

    optionList.forEach(o=>{
      o.value.forEach(v=>{
        if(v.trim().length===0){
          validateVariations=false
        }
      })
    })
    if(!validateVariations){
      showFail("Option value cannot be null/empty/undefined")
      return
    }
    // attrMandatoryNormals.forEach((attr, i) => {
    //   if (Array.isArray(attr.value)) {
    //     if (!attr.value.length > 0) {
    //       attrMandatoryNormalsClone[i]["state"] = false;
    //       setAttrMandatoryNormals(attrMandatoryNormalsClone);
    //       validateAttributes = false;
    //     }
    //   } else {
    //     if (attr.value?.trim() === "" || attr.value === undefined) {
    //       attrMandatoryNormalsClone[i]["state"] = false;
    //       setAttrMandatoryNormals(attrMandatoryNormalsClone);
    //       validateAttributes = false;
    //     }
    //   }
    // });

    attrNormals.forEach((attr, i) => {
      if (attr.is_mandatory) {
        if (Array.isArray(attr.value)) {
          if (attr.value.length === 0) {
            attrNormalsClone[i]["state"] = false;
            setAttrNormals(attrNormalsClone);
            validateAttributes = false;
          }
        } else if (attr.value.trim() === "") {
          attrNormalsClone[i]["state"] = false;
          setAttrNormals(attrNormalsClone);
          validateAttributes = false;
        }
      }
    });

    if (!validateAttributes) {
      showFail("* field must be fill in");
      return;
    }

    //validation
    variationsClone.forEach((v) => {
      if (!v.inventoryId) {
        if (v.inventorySKU.trim() === "") {
          v.inventorySKUState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
        if (v.barcode1.trim() === "") {
          v.barcode1State = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
        if (v.sellingPrice === "" || v.sellingPrice <= 0) {
          v.sellingPriceState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
        if (v.weight === "" || v.weight <= 0) {
          v.weightState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
        if (v.height === "" || v.height <= 0) {
          v.heightState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
        if (v.width === "" || v.width <= 0) {
          v.widthState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
        if (v.length === "" || v.length <= 0) {
          v.lengthState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
      }else{
        if (v.sellingPrice === "" || v.sellingPrice <= 0) {
          v.sellingPriceState = false;
          setVariations(variationsClone);
          validateVariations = false;
        }
      }
      // v.optionList.forEach((o) => {
      //   if (o.oValue.trim() === "") {
      //     o.oState = false;
      //     setVariations(variationsClone);
      //     validateVariations = false;
      //   }
      // });

      // if (v.skuImages.length === 0) {
      //   validateImage = false;
      // }
    });

    // if (!validateImage) {
    //   showFail("Each sku must have at least one image");
    //   return;
    // }

    if (!validateVariations) {
      showFail("* must be filled");
      return;
    }

    if (window.confirm("Are you sure you want to update the product?")) {
      // let attrObj = {};
      // attrMandatoryNormals.forEach((attr) => {
      //   // let attrObj = {};
      //   // attrObj["AttributeName"] = attr["name"];
      //   // attrObj["AttributeValue"] = attr.value;
      //   // attrO
      //   attrObj[attr.name] = [attr.value];
      // });

      // let resultAttrKeys = formatAttributesValue(attrKeys);

      let resultAttrNormals = formatAttributesValue(attrNormals);

      // let attrs = { ...resultAttrKeys, ...resultAttrNormals, ...attrObj };

      // if (packageContent.AttributeValue.trim() !== "") {
      //   // attrs.push(packageContent);
      //   attrs[packageContent.AttributeName] = packageContent.AttributeValue;
      // }
      // console.log(attrs);

      let productImagesReduced = formatImages(productImages);

      // for (let option in optionImages) {
      //   optionImages[option] = formatImages(optionImages[option]);
      // }

      let newOptionImages = [];

      if (product.isVariation) {
        // console.log(optionImages)
        for (let key in optionImages) {
          let oImages = {};
          oImages["option"] = key;
          oImages["image"] = formatImages(optionImages[key]);
          newOptionImages.push(oImages);
        }
      }

      // let oriVariations = variations.map((v) => {
      //   let { sellingPriceState, skuImages, ...oriVariation } = v;
      //   let skuImagesFormatted = formatImages(skuImages);
      //   return { skuImages: skuImagesFormatted, ...oriVariation };
      // });

      let newVariations = variations.map((variation) => {
        let { variant,inventorySKUState, inventorySKUFound, sellingPriceState, barcode1State, weightState, heightState, widthState, lengthState, ...newVariation } = variation;
        return newVariation;
      });

      // let newVariations = newSkus.map((sku) => {
      //   let { Option1State, Option2State, skuImages, inventorySKUState, inventorySKUFound, sellingPriceState, barcode1State, weightState, lengthState, widthState, heightState, ...newVariation } = sku;
      //   let skuImagesReduced = reduceImagesProperty(skuImages);
      //   return { skuImages: skuImagesReduced, ...newVariation };
      // });

      let productEdited = {
        productId:product.productId,
        catalogueBasicInfoId: product.catalogueBasicInfoId,
        description: basicInformation.description,
        productName: basicInformation.productName,
        parentSKU,
        categoryId: product.categoryId,
        categoryValue: product.categoryValue,
        // tags: product.tags,
        active: product.active,
        brand: brand.value,
        isVariation: product.isVariation,
        platformName: product.platformName,
        userMarketplaceId: product.userMarketplaceId,
        // status: product.status,
        variations: newVariations,
        optionImages: newOptionImages,
        // newVariations,
        options: optionList,
        attributes: resultAttrNormals,
        productImages: productImagesReduced,
      };
      //console.log(productEdited);
      // return;
      // if (window.confirm("Are you sure you want to edit product")) {
      try {
        let response = await editProduct(productEdited);
        if (response.status === 200) {
          showSuccess("Product Updated", () => history.push("/admin/product/list"));
        }
      } catch (error) {
        showFail("Server Error [EditProduct]");
      }
      // }
    }
  };

   

  const showSuccess = (msg, callBack = null) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title={msg}
        onConfirm={() => (callBack == null ? setAlert(null) : callBack())}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
      ></SweetAlert>
    );
  };
  return (
    <CardFooter>
      <Button onClick={handleSubmit} color="primary">
        Submit
      </Button>
      {/* <Button onClick={() => console.log(product)}>Preview Product In Console</Button> */}
       
    </CardFooter>
  );
}
