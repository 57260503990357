import React from 'react';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { Table } from 'antd';

import { checkSKU } from 'services/dataservice';
import "./index.css";

export default function PromotionItemsGift(props) {
    let { tempGiftSKU,setTempGiftSKU,dataSourceItemsGift, setDataSourceItemsGift, promotion, setPromotion, setAlert } = props;

    const columnsItemsGift = [
        {
            title: '#',
            key: 'index',
            render: (text, record, index) => index + 1,
        },
        {
            key: '1',
            title: 'Gift Product SKU',
            dataIndex: 'gift',
        },
        {
            key: '2',
            title: 'Product Name',
            dataIndex: 'productName',
        },
        {
            key: "3",
            title: "Base UOM",
            dataIndex: "baseUOM",
          },
        {
            key: '4',
            title: 'Unit',
            dataIndex: 'unit',
        },
        {
            key: '5',
            title: 'Set Limit',
            dataIndex: 'limit',
        },
        {
            key: '6',
            title: 'Action',
            render: (record) => {
                return (
                    <>
                        <Button
                            id="btnDelete"
                            size="sm"
                            className="btn-icon btn-link remove"
                            color="danger"
                            onClick={() => {
                                clickDeleteButtonItemsGift(record);
                            }}>
                            <i className="fa fa-times" />
                        </Button>
                    </>
                )
            }
        },
    ];

    const clickDeleteButtonItemsGift = (record) => {
        promotion["edit"] = true;
        setPromotion({ ...promotion });
        setDataSourceItemsGift((pre) => {
            return pre.filter((newRecord2) => newRecord2.gift !== record.gift);
        });
    }

    const clickAddButtonItemsGift = async () => {
        if (dataSourceItemsGift.length === 10) {
            showFail("Max 10 gifts");
            return;
        }

        var txtPrimary = document.getElementById("gift").value;
        var txtQuantity = document.getElementById("unit").value;
        var txtLimit = document.getElementById("limit").value;
        var duplicate = false;

        if (txtPrimary === '' || txtQuantity === '' || txtLimit === '') {
            showFail("Please do not leave empty");
            return;
        }

        const regex = /^\d{1,10}$/;
        if (!(txtQuantity).match(regex) || !(txtLimit).match(regex)) {
            showFail("Unit and Stock Limit must be in whole number");
            return;
        }
        if (parseInt(txtQuantity) < 1) {
            showFail("Unit must not less then 1");
            return;
        }
        if (parseInt(txtLimit) < parseInt(txtQuantity)) {
            showFail("Stock limit should not less than unit");
            return;
        }

        for (var i = 0; i < dataSourceItemsGift.length; i++) {
            promotion["edit"] = true;
            setPromotion({ ...promotion });
            if (dataSourceItemsGift[i].gift.toLowerCase() === txtPrimary.toLowerCase()) {
                duplicate = true;
            }
        }

        if (duplicate) {
            showFail(txtPrimary + " already in list");
        } else {
            try {
                let res = await checkSKU(txtPrimary);
                if (res.data.exist) {
                    const newRecord = {
                        //gift: txtPrimary,
                        gift:res.data.inventorySKU,
                        baseUOM:res.data.baseUOM,
                        unit: txtQuantity,
                        limit: txtLimit,
                        productName: res.data.productName
                    }; 
                    setDataSourceItemsGift((pre) => {
                        return [...pre, newRecord];
                    });
                    promotion["edit"] = true;
                    setPromotion({ ...promotion });
                    document.getElementById("gift").value = "";
                    document.getElementById("unit").value = "";
                    document.getElementById("limit").value = "";
                    document.getElementById("gift").focus();
                }
                else {
                    showFail(txtPrimary + " not in system");
                }
            } catch {
                showFail('Connection to server failed');
                return;
            }
        }
        promotion["GWPGift"] = dataSourceItemsGift;
        setPromotion({ ...promotion });
    }

    const showFail = (msg) => {
        setAlert(
            <ReactBSAlert
                danger
                style={{ display: "block", marginTop: "-100px" }}
                title={msg}
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="info"
                btnSize=""
            >
            </ReactBSAlert>
        )
    }

    return (
        <>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label>Gift Product SKU</Label>
                        <Input type="text" name="giftSKU" id="gift" value={tempGiftSKU} onChange={(e)=>{setTempGiftSKU(e.target.value)}} />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <FormGroup>
                        <Label>Unit</Label>
                        <Input type="number" name="unit" id="unit" />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <FormGroup>
                        <Label>Stock Limit</Label>
                        <Input type="number" name="stockLimit" id="limit" />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <Button
                        id="giftButton"
                        style={{ margin: 25, backgroundColor: "#f6eee0", color: "black" }}
                        onClick={clickAddButtonItemsGift}>
                        Add
                    </Button>
                </Col>
            </Row>
            <Table
                className='itemsGift'
                columns={columnsItemsGift}
                dataSource={dataSourceItemsGift}
            />
            <br></br>
        </>
    )
}