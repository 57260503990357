/**
 *
 * @param {array} attributes - Attributes from Api getMarketplaceAttribute
 * @returns an object which contains mandatories[array],non-mandatories[array],and options[object] attributes
 */
 function formatZaloraAttributes(attributes) {
    if (attributes.length === 0) return;
  
    /**
     * id?
     * name
     * label
     * options
     * input_type
     * attribute_type?
     * max_length
     */
    // attribute that wont be used
    let filterMandatories = ["PrimaryCategory", "Name", "Price", "Brand", "Variation"];
    let filterNormals = [
      "OfficialStore",
      "Description",
      "ParentSku",
      "SellerSku",
      "SalePrice",
      "SaleStartDate",
      "SaleEndDate",
      "Quantity",
      "BoxHeightSimple",
      "BoxWidthSimple",
      "BoxLengthSimple",
      "WeightSimple",
      "ProductId",
    ];
  
    let attributesReduced = attributes.reduce(
      (reduced, item) => {
        let { AttributeType: attribute_type, Label: label, MaxLength: max_length, Options, isMandatory: is_mandatory, FeedName: name, GroupName, InputType: input_type } = item;
  
        let optionsMapped = undefined;
  
        if (Options.hasOwnProperty("Option")) {
          if (Options.Option.length > 0) {
            optionsMapped = Options.Option.map((option) => {
              return { label: option["Name"], value: option["Name"] };
            });
          } else {
            let o = { label: Options.Option.Name, value: Options.Option.Name };
            optionsMapped = [o];
          }
        } else {
          optionsMapped = Options;
        }
        let newItem = { attribute_type, label, max_length, options: optionsMapped, name, input_type, value: "", is_mandatory: parseInt(is_mandatory) };
  
        if (name === "Variation" || name === "ColorFamily") {
          reduced.objOptions[name] = optionsMapped;
        }
  
        if (parseInt(is_mandatory) && !filterMandatories.includes(name)) {
          reduced.attrMandatories.push({ ...newItem, state: true });
        } else if (!parseInt(is_mandatory) && GroupName !== "Internal Use Only") {
          if (GroupName === "Main") {
            // reduced.attrMandatories.push({ ...newItem, state: true });
            reduced.attrMains.push(newItem);
          } else if (!filterNormals.includes(name)) {
            ["textarea"].includes(input_type) ? reduced.attrTextAreas.push(newItem) : reduced.attrNormals.push(newItem);
          }
        }
        return reduced;
      },
      { attrMandatories: [], attrMains: [], attrNormals: [], objOptions: {}, attrTextAreas: [] }
    );
  
    return attributesReduced;
  }
  function formatLazadaAttributes(attributes) {
    if (!attributes.length > 0) return;
  
    let filterNormals = ["description", "short_description", "description_ms"];
    let filterMandatoryNormals = ["name", "brand"];
  
    let attributesReduced = attributes.reduce(
      (reduced, item) => {
        let { id, name, label, options, input_type, attribute_type, advanced, is_sale_prop, is_mandatory } = item;
        let optionsMapped = options.map((option) => {
          return { label: option["name"], value: option["name"] };
        });
  
        let newItem = { id, name, label, options: optionsMapped, input_type, value: "", is_mandatory };
  
        if (advanced.is_key_prop) {
          reduced["attrKeys"].push({ ...newItem, key: true });
        } else if (is_sale_prop) {
          reduced["attrOptions"].push({ id, name, label, options, input_type, value: "", is_mandatory });
          reduced["objOptions"][name] = optionsMapped;
        } else if (is_mandatory && attribute_type === "normal" && !filterMandatoryNormals.includes(name)) {
          // reduced["attrMandatoryNormals"].push({ ...newItem, state: true });
          reduced["attrNormals"].unshift({ ...newItem, state: true });
          // } else if (!is_mandatory && attribute_type === "normal" && name !== "description" && name !== "short_description" && name !== "description_ms") {
        } else if (!is_mandatory && attribute_type === "normal" && !filterNormals.includes(name)) {
          reduced["attrNormals"].push(newItem);
        }
        return reduced;
      },
      { attrKeys: [], attrNormals: [], attrMandatoryNormals: [], attrOptions: [], objOptions: {} }
    );
    return attributesReduced;
  }
  
  function formatShopeeAttributes(attributes) {
    let attributesReduced = attributes.reduce(
      (reduced, item) => {
        let {
          attribute_id,
          original_attribute_name: label,
          display_attribute_name,
          is_mandatory,
          input_validation_type,
          format_type,
          input_type,
          date_format_type,
          attribute_unit,
          attribute_value_list,
        } = item;
  
        let optionsMapped = [];
        // if (attribute_value_list.length > 0) {
        //   optionsMapped = attribute_value_list.map((option) => {
        //     return { label: option["original_value_name"], value: option["value_id"] };
        //   });
        // }
        // attribute_value_list.forEach((item) => {
        //   delete item.display_value_name;
        // });
  
        let newItem = { attribute_id, label, options: attribute_value_list, attribute_unit, input_validation_type, format_type, input_type, value: "", temp_value: "",is_mandatory };
        if (is_mandatory) {
          if (date_format_type) {
            reduced.attrMandatories.push({ ...newItem, date_format_type, state: true });
          } else {
            reduced.attrMandatories.push({ ...newItem, state: true });
          }
        } else {
          if (date_format_type) {
            reduced.attrNormals.push({ ...newItem, date_format_type });
          } else {
            reduced.attrNormals.push(newItem);
          }
        }
  
        return reduced;
      },
      { attrMandatories: [], attrNormals: [] }
    );
    // let;
    return attributesReduced;
  }
  
  function formatZaloraCategories(categories) {
    categories.forEach((c) => {
      // console.log("parent:",c)
      if (c.Children?.hasOwnProperty("Category")) {
        if (c.Children.Category.length === undefined) {
          c["children"] = [c.Children.Category];
        } else {
          c["children"] = c.Children.Category;
        }
        delete c.Children;
        delete c.GlobalIdentifier;
      }
      if (c.hasOwnProperty("children")) {
        processCategoryChild(c.children);
      }
    });
    function processCategoryChild(child) {
      child.forEach((c) => {
        // console.log("child:", c);
        if (c.Children.hasOwnProperty("Category")) {
          if (c.Children.Category.length === undefined) {
            c["children"] = [c.Children.Category];
          } else {
            c["children"] = c.Children.Category;
          }
          delete c.Children;
          delete c.GlobalIdentifier;
          if (c["children"].length > 0) {
            processCategoryChild(c["children"]);
          }
        } else {
          delete c.Children;
          delete c.GlobalIdentifier;
        }
      });
    }
    // console.log(categories)
    return categories;
  }
  
  function formatShopeeCategories(categories) {
    function test(parent_id) {
      let child = categories.filter((item) => item.parent_category_id === parent_id);
      child.forEach((subItem) => {
        if (subItem.has_children) {
          subItem.data = test(subItem.category_id);
        }
      });
      return child;
    }
    return test(0);
  }
  
  function formatShopeeLogisticsChannelList(logisticsChannelList) {
    let logisticEnabledTrue=logisticsChannelList.filter(item=>item.enabled);
    return logisticEnabledTrue.map((channel) => {
      let { logistics_channel_id, logistics_channel_name, logistics_description,cod_enabled, enabled, weight_limit, volume_limit, fee_type } = channel;
      if (channel.fee_type === "SIZE_INPUT") {
        return {
          logistics_channel_id,
          logistics_channel_name,
          logistics_description,
          cod_enabled,
          enabled,
          fee_type,
          weight_limit,
          volume_limit,
          is_free: false,
          default_price: "8",
          shipping_fee: "8",
        };
      } else {
        return {
          logistics_channel_id,
          logistics_channel_name,
          logistics_description,
          cod_enabled,
          enabled,
          fee_type,
          weight_limit,
          volume_limit,
          is_free: false,
          default_price: "0",
          shipping_fee: "0",
        };
      }
    });
  }
  
  /**
   *
   * @param {array} formattedAttributes - from formatAttributes
   * @param {object} attributesObj - return from Api getProductDetail
   */
  
  function assignValue(formattedAttributes, attributes) {
    return formattedAttributes.map((attr) => {
      let { value, ...attrs } = attr;
      let v = attributes[attr.name];
      value = v ? (v.length > 1 ? v : v[0]) : "";
      return { ...attrs, value };
    });
  }
  
  function assignShopeeAttributeValue(formattedAttributes, attributes) {
    return formattedAttributes.map((attr) => {
      let { value, temp_value, ...attrs } = attr;
      let key = attributes[attr.label];
      if (key) {
        let v = attributes[attr.label]["attribute_value_list"];
        let temp_v = attributes[attr.label]["value"];
        temp_value = temp_v ? (temp_v.length > 1 ? temp_v : temp_v[0]) : "";
        value = v ? (v.length > 1 ? v : v[0]) : "";
        return { ...attrs, value, temp_value };
      } else {
        return { ...attrs, value, temp_value };
      }
    });
  }
  
  function generateSku(options) {}
  function generateSKU(options){
    let templateSKU = {
      inventorySKU: "",
      costPrice: "",
      barcode1: "",
      barcode2: "",
      inventorySKUState: true,
      inventorySKUFound: false,
      costPriceState: true,
      barcode1State: true,
      weight: "",
      weightState: true,
      height: "",
      heightState: true,
      width: "",
      widthState: true,
      length: "",
      lengthState: true,
    };
  //console.log(options)
  const filterOptions=options.filter(o=>o.oValues.length>0)
  console.log(filterOptions)
    let SKU = [];
    if (filterOptions.length === 1) {
      SKU = filterOptions[0].oValues.map((item) => {
        return { ...templateSKU,option1:item, variant: item };
      });
    } else {
      SKU = filterOptions[0].oValues.flatMap((item) =>
      filterOptions[1].oValues.map((subItem) => {
          return { ...templateSKU,option1:item,option2:subItem, variant: `${item}/${subItem}` };
        })
      );
    }
    return SKU;
  }
  
  function formatShopeeAttributesValue(attributes) {
    // let attrArr = [];
  
    let attrObj = {};
    attributes.forEach((attr) => {
      let v = attr.temp_value;
  
      // console.log(v)
      // console.log(v.trim().length)
      // return
      if (v) {
        if (Array.isArray(v)) {
          if (v.length > 0) {
            attrObj[attr.label] = {
              attribute_id: attr.attribute_id,
              attribute_value_list: attr.value,
              value: attr.temp_value,
            };
          }
        }
        // else if (typeof v === "object") {
        //   attrObj[attr.label] = [
        //     {
        //       attribute_id: attr.attribute_id,
        //       attribute_value_list: [attr.value],
        //       value: [attr.temp_value],
        //     },
        //   ];
        // }
        else if (v.trim().length > 0) {
          attrObj[attr.label] = {
            attribute_id: attr.attribute_id,
            attribute_value_list: [attr.value],
            value: [attr.temp_value],
          };
        }
      }
    });
    return attrObj;
  }
  
  function formatAttributesValue(attributes) {
    // return attributes.reduce((reduced, attr) => {
    //   if (attr.value && attr.value.length > 0) {
    //     let attrObj = {};
    //     if (Array.isArray(attr.value)) {
    //       attrObj["AttributeName"] = attr["name"];
    //       attrObj["AttributeValue"] = attr.value.join("||");
    //     } else if (attr.value.trim().length > 0) {
    //       attrObj["AttributeName"] = attr["name"];
    //       attrObj["AttributeValue"] = attr.value;
    //     }
    //     reduced.push(attrObj);
    //   }
    //   return reduced;
    // }, []);
    let attrObj = {};
    attributes.forEach((attr) => {
      let v = attr.value;
      if (v) {
        if (Array.isArray(v)) {
          if (v.length > 0) {
            attrObj[attr.name] = attr.value;
          }
        } else if (v.trim().length > 0) {
          attrObj[attr.name] = [attr.value];
        }
      }
  
      // if (attr.value && attr.value.length > 0) {
      // if (Array.isArray(attr.value)) {
      //   attrObj[attr.name] = attr.value;
      // } else if (attr.value.trim().length > 0) {
      //   attrObj[attr.name] = attr.value;
      // }
      // }
    });
    return attrObj;
  
    //   if (attr.value && attr.value.length !== 0) {
    //     let attrObj = {};
    //     if (Array.isArray(attr.value) && attr.value.length > 0) {
    //       attrObj["AttributeName"] = attr["name"];
    //       attrObj["AttributeValue"] = attr.value.join("||");
    //     } else {
    //       attrObj["AttributeName"] = attr["name"];
    //       attrObj["AttributeValue"] = attr.value;
    //     }
    //     reduced.push(attrObj);
    //   }
    //   return reduced;
    // }, []);
  }
  
  /**
   * To distinguish which are newly uploaded image
   * @param {array} images - can be product images / sku images (not for "new" sku images) from ProductDetail
   * @returns an array
   */
  function formatImages(images) {
    const IMAGE_URL = `/api/marketplace-product/getImage?fileName=`;
    return images.reduce((reduced, image) => {
      if (!image.hasOwnProperty("new")) {
        if (!image.hasOwnProperty("error") && image.hasOwnProperty("response")) {
          let { name, url } = image.response.data;
          url = `${IMAGE_URL}${name}`;
          reduced.push({ name, url, new: true });
        }
        if (!image.hasOwnProperty("error") && image.hasOwnProperty("url")) {
          let { catalogueImageId, name, url } = image;
          url = `${IMAGE_URL}${name}`;
          reduced.push({ catalogueImageId, name, url, new: false });
        }
      } else {
        let { catalogueImageId, name, url } = image;
        reduced.push({ catalogueImageId, name, url, new: image.new });
      }
  
      return reduced;
    }, []);
  }
  
  function reduceImagesProperty(images) {
    return images.reduce((reduced, file) => {
      // console.log(file)
      if (!file.hasOwnProperty("error")) {
        if (file.hasOwnProperty("url")) {
          reduced.push({ name: file.name, url: file.url });
        } else {
          let { name, url } = file.response.data;
          reduced.push({ name, url });
        }
      }
      return reduced;
    }, []);
  }
  
  export {
    formatZaloraAttributes,
    formatLazadaAttributes,
    formatShopeeAttributes,
    formatZaloraCategories,
    formatShopeeCategories,
    assignValue,
    assignShopeeAttributeValue,
    formatShopeeAttributesValue,
    formatShopeeLogisticsChannelList,
    formatAttributesValue,
    formatImages,
    reduceImagesProperty,
    generateSKU
  };
  